import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { SkeletonSelectionListDataTableItem } from "components/table.components/list.data.table.component/list.data.table.component";
import StudioDeviceStore from "stores/studio.device.store";
import DeviceStore from "stores/device.store";
import { Device } from "schemas/device.schemas/device.schema";
import DeviceList from "components/table.components/device.list.component/device.list.component";
import { HttpStudioDeviceService } from "services/httpClients/http.studio.device.client";
import { toast } from "react-toastify";
import Row from "components/general.compoenents/row.component/row.component";
import { Logging } from "globals/helpers/logging.helper";
import ModalHeader from "../modal.header.component/modal.header.component";
import { ContainerSizes } from "globals/enums/global.enum";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import Headline from "components/text.components/headline.component/headline.component";
import ExerciseStore from "stores/exercise.store";
import Gleap from "gleap";

interface StudioDeviceSelectionModalProps {
  modalStore?: ModalStore;
  studioDeviceStore?: StudioDeviceStore;
  deviceStore?: DeviceStore;
  exerciseStore?: ExerciseStore;
}

const StudioDeviceSelectionModal = ({
  modalStore,
  studioDeviceStore,
  deviceStore,
  exerciseStore,
}: StudioDeviceSelectionModalProps): JSX.Element => {
  const avaliableDevices = deviceStore?.devices?.data;
  const studioDevices = studioDeviceStore?.devices?.data;
  const currentSelectedDevices =
    studioDeviceStore?.getCurrentSelectedDevices?.data;

  const maxSelection = modalStore?.customData?.maxSelection ?? undefined;

  const [isSearchActive, setIsSearchActive] = useState(false);
  const devicesSearchResult = deviceStore?.devicesSearchResult;

  const devicesToRender = isSearchActive
    ? devicesSearchResult
    : avaliableDevices;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    // fetch the template devices for the selection
    deviceStore?.fetchAndSetDevices({});

    // set the current studio devices to current selected devices
    studioDeviceStore?.setCurrentSelectedDevices({
      studioDevices: studioDevices?.filter((item) => {
        return !item.archived;
      }),
    });
  };

  // handle the assignment of exercises to the device
  const handleSubmit = async (): Promise<void> => {
    if (currentSelectedDevices != null && currentSelectedDevices?.length > 0) {
      try {
        await HttpStudioDeviceService.getInstance().handleStudioDeviceAssign({
          deviceIDs: currentSelectedDevices.map((item) => item._id) as string[],
        });

        // fetch the device and exercises again and close the modal
        studioDeviceStore?.fetchAndSetStudioDevices({
          refresh: true,
        });

        exerciseStore?.fetchAndSetExercises({
          refresh: true,
        });

        modalStore?.closeModal();
        toast.success("Änderungen wurden erfolgreich gespeichert");
      } catch (err) {
        modalStore?.closeModal();

        Logging.error({
          className: "StudioDeviceSelectionModal",
          methodName: "handleSubmit",
          message: "Änderungen konnten nicht gespeichert werden",
          exception: err,
        });
      }
    }
  };

  const handleDeviceListClick = (device: Device): void => {
    // check if the device is already selected
    const selectedExerciseExists = currentSelectedDevices?.find((item) => {
      return item._id === device._id;
    });
    // if the device is already selected, remove it from the list
    if (selectedExerciseExists != null) {
      const fitered = currentSelectedDevices?.filter(
        (item) => item._id !== device._id
      );
      studioDeviceStore?.setCurrentSelectedDevices({ devices: fitered });
    } else {
      // only allow o to be selected

      if (
        maxSelection != null &&
        currentSelectedDevices &&
        currentSelectedDevices?.length >= maxSelection
      ) {
        return;
      }

      // if the device is not selected, add it to the list
      studioDeviceStore?.setCurrentSelectedDevices({
        devices: [...(currentSelectedDevices ?? []), device],
      });
    }
  };

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader
        title="Geräte hinzufügen"
        description="Hier kannst du deine Geräte verwalten."
      >
        <Row justifyContent="space-between" alignItems="center">
          <SizedContainer size={ContainerSizes.M}>
            <OutlinedTextInput
              placeholder="Geräte suchen"
              onChange={(value) => {
                if (
                  value != null &&
                  value?.trim().length > 0 &&
                  avaliableDevices != null &&
                  avaliableDevices?.length > 0
                ) {
                  setIsSearchActive(true);
                  deviceStore?.searchAndSetDevices(value);
                } else {
                  setIsSearchActive(false);
                }
              }}
            />
          </SizedContainer>
          <Row justifyContent="flex-end" alignItems="center">
            <Headline className="mr-15">
              {currentSelectedDevices?.length ?? 0} x
            </Headline>
            <FilledButton
              className="mr-10"
              color="secondary"
              label="Fehlendes Gerät hinzufügen"
              onClick={() => {
                Gleap.open();
              }}
            />
            <FilledButton
              label="Speichern"
              color="secondary"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Row>
        </Row>
      </ModalHeader>
    );
  };

  const _buildExercisesList = (): JSX.Element => {
    if (studioDeviceStore?.devices?.isLoading === true) {
      return <SkeletonSelectionListDataTableItem count={5} />;
    } else {
      return (
        <div className="exercises-list-wrapper">
          <DeviceList
            devices={devicesToRender ?? []}
            selectedDevices={currentSelectedDevices ?? []}
            onClick={(devices: Device) => {
              handleDeviceListClick(devices);
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container"> {_buildTopBar()}</div>
      <div className="selection-modal-body-container">
        {_buildExercisesList()}
      </div>
    </div>
  );
};

export default inject(
  "studioDeviceStore",
  "modalStore",
  "deviceStore",
  "exerciseStore"
)(observer(StudioDeviceSelectionModal));
