export const getEmailValidationPattern = (): RegExp => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
};

export const checkIfVersionIsValid = (args: {
  version: string;
  newVersion: string;
}): boolean => {
  const { version, newVersion } = args;

  // check if new version has the correct format (x.x.x)
  const versionRegex = /^[0-9]\.[0-9]\.[0-9]$/;

  if (!versionRegex.test(newVersion)) {
    return false;
  }

  // check if the version is the same as the new version
  if (version === newVersion) {
    return false;
  }

  // check if new version is higher than current version
  const versionArray = version.split(".");
  const newVersionArray = newVersion.split(".");
  const versionArrayLength = versionArray.length;
  const newVersionArrayLength = newVersionArray.length;

  // if the version array length is not the same as the new version array length, return false
  if (versionArrayLength !== newVersionArrayLength) {
    return false;
  }

  // check if the new version is higher than the current version
  for (let i = 0; i < versionArrayLength; i++) {
    if (parseInt(versionArray[i]) < parseInt(newVersionArray[i])) {
      return true;
    } else if (parseInt(versionArray[i]) > parseInt(newVersionArray[i])) {
      return false;
    }
  }

  return true;
};

export const getFormattedErrorMessage = (
  proerty: string,
  error: any
): string | undefined => {
  if (error == null || error === "") {
    return;
  }

  return `${proerty} ist verpflichtend`;
};
