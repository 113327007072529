import Image from "components/image.component/image.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import React from "react";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import classNames from "classnames";
import { Device } from "schemas/device.schemas/device.schema";

interface DeviceListProps {
  devices: Device[];
  selectedDevices: Device[];
  onClick: (device: Device) => void;
}

const DeviceList = ({
  devices,
  selectedDevices,
  onClick,
}: DeviceListProps): JSX.Element => {
  return (
    <ListDataTable
      data={devices}
      onClick={onClick}
      columns={[
        {
          flex: 0,
        },
        {
          flex: 0,
        },
        {
          flex: 2,
        },
      ]}
      dataTableItemBuilder={(dataItem: Device, index: number) => {
        const isSelected = selectedDevices?.find((selectedItem: any) => {
          return selectedItem._id === dataItem._id;
        });

        return {
          key: dataItem._id!,
          className: classNames({
            "list-data-table-body-item-selected": isSelected,
          }),
          children: [
            {
              child: <></>,
              className: "mr-10",
            },

            {
              child: (
                <Image
                  rounded={false}
                  imageUrl={dataItem.coverImageUrl}
                  size={ImageSizes.M}
                />
              ),
            },
            {
              child: (
                <div>
                  <RunningText
                    className={isSelected ? "text-color-inverted" : ""}
                  >
                    {dataItem.title.de ?? ""}
                  </RunningText>
                  <SmallText
                    className={isSelected ? "text-color-inverted" : ""}
                  >
                    {dataItem.brand?.title ?? ""}
                  </SmallText>
                </div>
              ),
            },
          ],
        };
      }}
      itemClassName="device-brand-table-item"
      gap={30}
      disableHeader={true}
    />
  );
};

export default DeviceList;
