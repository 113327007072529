import { faTrashCan, faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React from "react";
import { FormState, UseFormRegister } from "react-hook-form";
import { InfoData, InfoList } from "schemas/exercise.schemas/exercise.schema";
import StudioStore from "stores/studio.store";

interface ExerciseInfoContentProps {
  studioStore?: StudioStore;
  data: InfoData;
  index: number;
  register: UseFormRegister<any>;
  formState: FormState<InfoList>;
  onDeleted: (index: number) => void;
  disableDelete?: boolean;
}

const ExerciseInfoContent = ({
  studioStore,
  data,
  index,
  register,
  formState,
  onDeleted,
  disableDelete = false,
}: ExerciseInfoContentProps): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage;

  const { errors } = formState;

  return (
    <div>
      <Row justifyContent="space-between" alignItems="center">
        <SizedContainer size={ContainerSizes.M} className="mb-10">
          <OutlinedTextInput
            placeholder="Titel"
            className="mt-0"
            inputRef={register(
              `data[${index}].label.${currentLanguage!.value}`
            )}
            validationMessage={
              errors?.data?.[index]?.label?.[currentLanguage!.value]?.message
            }
          />
        </SizedContainer>
        <div>
          <FontAwesomeIcon icon={faBars} className="cursor-pointer" />
          {!disableDelete ? (
            <IconButton
              icon={faTrashCan}
              key="2"
              className="ml-10"
              onClick={() => {
                onDeleted(index);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </Row>
      <SizedContainer size={ContainerSizes.XXL}>
        <OutlinedTextArea
          placeholder="Beschreibung"
          className="mb-0"
          inputRef={register(
            `data[${index}].description.${currentLanguage!.value}`
          )}
          validationMessage={
            errors?.data?.[index]?.description?.[currentLanguage!.value]
              ?.message
          }
        />
      </SizedContainer>
    </div>
  );
};

export default inject("studioStore")(observer(ExerciseInfoContent));
