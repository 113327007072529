/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import EventStore from "stores/event.store";
import { inject, observer } from "mobx-react";
import {
  Calendar,
  EventPropGetter,
  Formats,
  momentLocalizer,
} from "react-big-calendar";
import "moment/locale/de";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import moment from "moment";
import "moment-timezone";
import "./event.page.scss";
import classNames from "classnames";
import { ModalStore, ModalType } from "stores/modal.store";
import { PreparedCalendarEvent } from "globals/intefaces/global.interface";
import CalendarToolbar from "../components/calendar.toolbar.component";
import CalendarItem from "../components/calender.item.component";
import ServiceStore from "stores/service.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import StudioStore from "stores/studio.store";
import { useNavigate } from "react-router";

interface EventPageProps {
  eventStore?: EventStore;
  modalStore?: ModalStore;
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
}

const EventPage = ({
  eventStore,
  modalStore,
  serviceStore,
  studioStore,
}: EventPageProps): JSX.Element => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const navigate = useNavigate();

  const preparedEvents = eventStore?.prepareEventsForCalendar(
    eventStore?.events?.data ?? []
  );

  const eventPageClassName = classNames({
    "calendar-page-wrapper": true,
    "calendar-page-wrapper--loading": eventStore?.events?.isLoading,
  });

  useEffect(() => {
    loadInitialData();
  }, []);

  // load the current and the next month
  const loadInitialData = async (): Promise<void> => {
    await eventStore?.loadMonthForCalendar({
      date: currentMonth,
    });

    // define and load the next month
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );

    await eventStore?.loadMonthForCalendar({
      date: nextMonth,
    });

    // fetch services for the event modal
    serviceStore?.fetchAndSetServices({ refresh: true });
  };

  // set the locale for the calendar
  moment.locale("de");

  // set the default timezone
  moment.tz.setDefault("Europe/Vienna");

  const localizer = momentLocalizer(moment);

  // customize the calendar format for the weekday names (e.g. Mo, Di, Mi)
  const customFormats: Partial<Formats> = {
    weekdayFormat: (date, culture, localizer) => {
      if (localizer) {
        return localizer.format(date, "dd", culture).toUpperCase();
      }
      return "";
    },
  };

  // style the background color of the calendar events
  const eventStyleGetter: EventPropGetter<PreparedCalendarEvent> = (
    event,
    start,
    end,
    isSelected
  ) => {
    let backgroundColor = "#FC2947";

    if (event.event.status !== "CANCELED") {
      // if a color is set use otherwise use the default color
      backgroundColor = event.event?.color ?? "#313634e0";
    }

    const style = {
      backgroundColor,
    };

    return { style };
  };

  const handleOnSelectEvent = (event: any): void => {
    eventStore?.setCurrentPreparedEvent(event);
    modalStore?.openModal(ModalType.EVENT_MODAL, undefined, {
      isEditing: true,
    });
  };

  const handleOnSelectSlot = (slotInfo: any): void => {
    const { start } = slotInfo;
    eventStore?.createInitialEvent(start);
    modalStore?.openModal(ModalType.EVENT_MODAL, undefined, {
      isEditing: false,
    });
  };

  const WrappedCalendarToolbar = (toolbarProps: any): JSX.Element => {
    return <CalendarToolbar toolbar={toolbarProps} />;
  };

  return (
    <MainLayout
      topBars={[
        <PageHeadline
          title="Buchungen"
          key="events-page-headline"
          actions={[
            <FilledButton
              color="secondary"
              key="open-service"
              label="Listansicht öffnen"
              onClick={() => {
                if (studioStore?.studio?._id == null) {
                  return;
                }
                navigate(`/studios/${studioStore?.studio?._id}/bookings`);
              }}
            />,
          ]}
        />,
      ]}
    >
      <div className={eventPageClassName}>
        <Calendar
          selectable={true}
          views={["month"]}
          className="mt-15"
          localizer={localizer}
          events={preparedEvents}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          formats={customFormats}
          components={{
            toolbar: WrappedCalendarToolbar,
            event: CalendarItem,
          }}
          onSelectEvent={(event) => {
            handleOnSelectEvent(event);
          }}
          onSelectSlot={(slotInfo) => {
            handleOnSelectSlot(slotInfo);
          }}
        />
      </div>
    </MainLayout>
  );
};

export default inject(
  "eventStore",
  "modalStore",
  "serviceStore",
  "studioStore"
)(observer(EventPage));
