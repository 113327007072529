import AddressForm from "components/stripe.components/address.form.component/address.from.component";
import React from "react";

const BillingAddressModal = (): JSX.Element => {
  return (
    <div className="subscription-modal-wrapper">
      <AddressForm />
    </div>
  );
};

export default BillingAddressModal;
