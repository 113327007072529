import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import React from "react";
import { ModalStore, ModalType } from "stores/modal.store";
import previewIcon from "assets/icons/female_avatar.png";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import Center from "components/general.compoenents/center.component/center.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import Column from "components/general.compoenents/column.component/column.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { useNavigate, useParams } from "react-router";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import StudioDeviceStore from "stores/studio.device.store";
import "./device.setup.page.scss";
import { Device } from "schemas/device.schemas/device.schema";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import StudioStore from "stores/studio.store";

interface DeviceSetupPageProps {
  modalStore?: ModalStore;
  studioDeviceStore?: StudioDeviceStore;
  studioStore?: StudioStore;
}

const DeviceSetupPage = ({
  modalStore,
  studioDeviceStore,
  studioStore,
}: DeviceSetupPageProps): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage;

  const navigate = useNavigate();
  const { studioID } = useParams();

  const devices = studioDeviceStore?.devices?.data ?? [];

  const _buildDeviceItem = (device: Device): JSX.Element => {
    return (
      <Row className="device-setup-device" alignItems="center">
        <Image imageUrl={device.coverImageUrl} className="mr-10" />
        <Column>
          <TitleText>{device.title[currentLanguage!.value]}</TitleText>
          <RunningText>{device.brand?.title}</RunningText>
        </Column>
      </Row>
    );
  };

  const _buildForm = (): JSX.Element => {
    return (
      <Center>
        <SizedContainer size={ContainerSizes.L}>
          <Column>
            <Headline className="mt-15">
              Wähle ein Gerät aus deinem{" "}
              <span className="color-primary">Studio</span>
            </Headline>
            <RunningText className="mb-20">
              Später kannst du alle deine Geräte wählen.
            </RunningText>
            <Center>
              <div className="device-preview-container mb-20">
                {devices.length === 0 && (
                  <div
                    onClick={() => {
                      modalStore?.openModal(
                        ModalType.STUDIO_DEVICE_SELECTION_MODAL,
                        undefined,
                        {
                          maxSelection: 1,
                        }
                      );
                    }}
                  >
                    <RunningText className="choose-device">
                      Gerät wählen
                    </RunningText>
                  </div>
                )}
                {devices.length > 0 && (
                  <Column>
                    {devices.map((studioDevice) =>
                      _buildDeviceItem(studioDevice.device as any)
                    )}
                  </Column>
                )}
              </div>
            </Center>
            <FilledButton
              label="Weiter"
              disabled={devices?.length === 0}
              onClick={() => {
                navigate(`/studios/${studioID}/app-setup`);
              }}
            />
          </Column>
        </SizedContainer>
      </Center>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="user-setup-preview-container">
        <ImageWithAuthHeader
          className="user-setup-preview-image"
          src={previewIcon}
        />
      </div>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildPreview()}
      leftGrow={1}
      rightChild={_buildForm()}
      rightGrow={1}
    />
  );
};

export default inject(
  "modalStore",
  "studioDeviceStore",
  "studioStore"
)(observer(DeviceSetupPage));
