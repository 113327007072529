import { exerciseSchema } from "schemas/exercise.schemas/exercise.schema";
import * as yup from "yup";
import { exerciseSetSchema } from "./training.plan.schema";

export const planExerciseSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  exercise: exerciseSchema([]).required(),
  sets: yup
    .array()
    .of(yup.lazy(() => exerciseSetSchema))
    .required(),
  sort: yup.number().integer().notRequired(),
  info: yup.string(),
});

export interface PlanExercise
  extends yup.InferType<typeof planExerciseSchema> {}
