import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import AddressElementComponent from "../address.element.component/address.element.component";
import { Elements } from "@stripe/react-stripe-js";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey!);

const AddressForm = (): JSX.Element => {
  return (
    <Elements stripe={stripePromise}>
      <AddressElementComponent />
    </Elements>
  );
};

export default AddressForm;
