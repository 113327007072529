import * as yup from "yup";

export const feedPromotionSchema = yup.object().shape({
  label: yup.string().required(),
  subLabel: yup.string(),
  type: yup.string().oneOf(["URL", "GLEAP"]).required(),
  action: yup.string().required(),
});

export interface FeedPromotion
  extends yup.InferType<typeof feedPromotionSchema> {}
