import { TeamMember, User } from "schemas/user.schemas/user.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export interface ChangePasswordCredentials {
  password: string;
  newPassword: string;
}

export interface ResetPasswordCredentials {
  email: string;
  newPassword: string;
}

export class HttpUserService extends GenericHttpClient<User> {
  static _instance: HttpUserService;
  static getInstance(): HttpUserService {
    if (this._instance == null) {
      this._instance = new HttpUserService("/users");
    }
    return this._instance;
  }

  async getTeamMembers(): Promise<TeamMember[] | undefined> {
    const response = await this.get("/users");
    return response.data as TeamMember[];
  }

  async getCurrentUser(): Promise<any> {
    return await this.get("/users/me");
  }

  async updateCurrentUser(data: User): Promise<any> {
    return await this.put("/users/me", data);
  }

  async changeUserRole(userId: string, roleID: string): Promise<any> {
    return await this.put(`/users/${userId}/role`, { roleID });
  }

  async getClientPermissions(): Promise<any> {
    return await this.get("/auth/client-permissions");
  }

  async handleChangePassword(data: ChangePasswordCredentials): Promise<any> {
    return await this.post("/auth/change-password", data);
  }

  async handleResetPassword(data: ResetPasswordCredentials): Promise<any> {
    return await this.post("/auth/reset-password", data);
  }

  async getUserAccountKinds(): Promise<any> {
    return await this.get("/auth/user-accounts");
  }
}
