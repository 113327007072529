/* eslint-disable @typescript-eslint/no-unused-vars */
import LinkButton from "components/input.components/link.button.component/link.button.component";
import PropertyNavList from "components/list.components/nav.list.components/property.nav.list.component/property.nav.list.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { PropertyType } from "globals/enums/global.enum";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import React from "react";
import { useOutlet, useNavigate } from "react-router-dom";

const PropertyPage = (): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const handleFilter = (filter: string): void => {
    const queryParams = new URLSearchParams();
    queryParams.append("filter", filter);
    navigate({
      search: queryParams.toString(),
    });
  };

  // TODO make a global component for this and use it in the other pages
  const _buildTopBar = (): JSX.Element => {
    return (
      <PageHeadline>
        <LinkButton
          label="Muskelgruppen"
          onClick={() => {
            handleFilter(PropertyType.MUSCLE_GROUP);
          }}
        />
        <LinkButton
          label="Muskelgruppen (Lesbar)"
          onClick={() => {
            handleFilter(PropertyType.READABLE_MUSCLE_GROUP);
          }}
        />

        <LinkButton
          label="Tags"
          onClick={() => {
            handleFilter(PropertyType.TAG);
          }}
        />

        <LinkButton
          label="Geräte Eigenscahften"
          onClick={() => {
            handleFilter(PropertyType.DEVICE);
          }}
        />
      </PageHeadline>
    );
  };
  return (
    <MainLayout /* topBars={[_buildTopBar()]} */>
      <SplitLayout
        useAsPage
        leftChild={<PropertyNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
      ></SplitLayout>
    </MainLayout>
  );
};

export default PropertyPage;
