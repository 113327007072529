import { Device } from "schemas/device.schemas/device.schema";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpDeviceService extends GenericHttpClient<Device> {
  static _instance: HttpDeviceService;
  static getInstance(): HttpDeviceService {
    if (this._instance == null) {
      this._instance = new HttpDeviceService("/devices/template");
    }
    return this._instance;
  }

  async getDeviceExercises(deviceID: string): Promise<Exercise[]> {
    const response = await this.get(`/devices/template/${deviceID}/exercises`);
    return response.data as Exercise[];
  }

  async handleDeviceExerciseAssign(args: {
    deviceID: string;
    exerciseIDs: string[];
  }): Promise<Exercise> {
    const response = await this.put(
      `/devices/template/${args.deviceID}/exercises`,
      {
        deviceExercises: args.exerciseIDs,
      }
    );
    return response.data as Exercise;
  }

  async archiveStudioDevice(deviceID: string): Promise<any> {
    const response = await this.delete(`/devices/${deviceID}`);
    return response.data;
  }
}
