import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import FeedNavList from "components/list.components/nav.list.components/feed.nav.list.component/feed.nav.list.component";
import React from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import newsPlaceholder from "assets/illustrations/placeholders/no_topic_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import { inject, observer } from "mobx-react";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface NewsPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
}

const NewsPage = ({ feedStore, studioStore }: NewsPageProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const feed = feedStore?.feed?.data ?? [];
  const feedConfigs = feedStore?.feedConfigs?.data ?? [];

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<FeedNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Du hast noch keine Beiträge hinzugefügt"
            description="Füge ein Beitrag hinzu, um ihn hier anzuzeigen."
            titleNothingSelected="Du hast noch keinen Beitrag ausgewählt"
            descriptionNothingSelected="Wähle einen Beitrag aus, um ihn hier anzuzeigen."
            imagePath={newsPlaceholder}
            data={feed}
            noDataActions={
              <FilledButton
                label="Beitrag hinzufügen"
                disabled={feedConfigs.length === 0}
                onClick={() => {
                  if (studioStore?.studio?._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/news-feed/new`
                    );
                  }
                }}
              />
            }
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject("feedStore", "studioStore")(observer(NewsPage));
