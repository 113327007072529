import Gleap from "gleap";
import React from "react";

const FiboPreviewVideoPage = (): JSX.Element => {
  const videoUrl =
    "https://storage.gymo.io/exercise-videos%2F08f889f30c77e2eeaf9d0a6d360e376014c23141cc7cf9dc694fdbe90cf918d7.mp4";

  const videoWrapperStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 60px)",
    width: "100%",
    backgroundColor: "black",
  };

  Gleap.showFeedbackButton(false);

  return (
    <div style={videoWrapperStyle}>
      <video
        height="100%"
        width="100%"
        style={{ objectFit: "cover", borderRadius: "10px" }}
        controls
        controlsList="nodownload"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default FiboPreviewVideoPage;
