import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import React, { useEffect, useState } from "react";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import StudioStore from "stores/studio.store";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import TrainingPlanStore from "stores/training.plan.store";
import TrainingPlanForm from "./components/training.plan.form.component/training.plan.form.component";
import TrainingPlanExerciseList from "../../../components/table.components/training.plan.exercise.list.component/training.plan.exercise.list.component";
import { ModalStore } from "stores/modal.store";

interface TrainingPlanDetailPageProps {
  studioStore?: StudioStore;
  trainingPlanStore?: TrainingPlanStore;
  modalStore?: ModalStore;
}

const TrainingPlanPage = ({
  studioStore,
  trainingPlanStore,
  modalStore,
}: TrainingPlanDetailPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { trainingPlanID } = useParams();
  const [isEditing, setIsEditing] = useState(trainingPlanID !== "new");
  const [formIsDirty, setFormIsDirty] = useState(false);

  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  useEffect(() => {
    initializePage();
  }, [trainingPlanID]);

  const onSubmit = async (data: any): Promise<void> => {
    if (!trainingPlan) {
      return;
    }

    trainingPlanStore?.setCurrentTrainingPlan({
      ...trainingPlan,
      ...data,
    });

    await trainingPlanStore?.updateTrainingPlan({
      id: trainingPlan._id!,
      trainingPlan: data,
    });

    setFormIsDirty(false);
  };

  const initializePage = async (): Promise<void> => {
    if (trainingPlanID == null) {
      return;
    }

    if (trainingPlanID !== "new") {
      trainingPlanStore?.fetchAndSetTrainingPlan({
        trainingPlanID,
      });
      setIsEditing(true);
    }

    if (trainingPlanID === "new") {
      trainingPlanStore?.createInitialTrainingPlan({ type: "STUDIO" });
      setIsEditing(false);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab
            label="Informationen"
            key="plan-info"
            path="info"
            dirty={formIsDirty}
          />,
          <Tab
            label="Übungen"
            key="plan-exercise"
            path="exercises"
            disabled={trainingPlanID === "new"}
            dirty={formIsDirty}
          />,
        ]}
      />
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="training-plans"
        actions={[
          trainingPlanID !== "new" ? (
            <FilledButton
              key="feed-archive-button"
              label="Plan entfernen"
              color={"danger"}
              onClick={() => {
                modalStore?.showConfirmAlert({
                  onConfirm: async () => {
                    if (trainingPlan?._id != null) {
                      trainingPlanStore?.archivePlan(trainingPlan);

                      if (studioStore?.studio?._id != null) {
                        navigate(
                          `/studios/${studioStore?.studio?._id}/training-plans`
                        );
                      }
                    }
                  },
                  confirmLabel: "Entfernen",
                  title: "Plan entfernen",
                  description: "Möchtest du diesen Plan wirklich entfernen?",
                });
              }}
            />
          ) : (
            <></>
          ),
        ]}
      >
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (trainingPlanStore?.currentTrainingPlan?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer
        key={trainingPlanID === "new" ? Math.random() : trainingPlanID}
      >
        <Routes>
          <Route element={<Outlet />}>
            <Route
              index={true as any}
              path="exercises"
              element={
                <TrainingPlanExerciseList
                  onSubmit={(data) => {
                    onSubmit(data);
                  }}
                  isEditing={trainingPlanID !== "new"}
                  onDirty={(isDirty) => {
                    setFormIsDirty(isDirty);
                  }}
                />
              }
            />
            <Route
              path="info"
              element={
                <TrainingPlanForm
                  isEditing={isEditing}
                  onDirty={(isDirty) => {
                    if (trainingPlanID !== "new") {
                      setFormIsDirty(isDirty);
                    }
                  }}
                />
              }
            />
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "studioStore",
  "trainingPlanStore",
  "modalStore"
)(observer(TrainingPlanPage));
