import { Logging } from "globals/helpers/logging.helper";
import { ChartData } from "schemas/chart.schemas/chart.data.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpStatisticService extends GenericHttpClient<any> {
  static _instance: HttpStatisticService;
  static getInstance(): HttpStatisticService {
    if (this._instance == null) {
      this._instance = new HttpStatisticService("/statistics");
    }
    return this._instance;
  }

  getStatisticsForTimeInStudio = async (args: {
    userID: string;
  }): Promise<ChartData | undefined> => {
    try {
      const response = await this.get(`/statistics/studio/time/${args.userID}`);
      return response.data as ChartData;
    } catch (err) {
      Logging.error({
        className: "HttpStatisticService",
        methodName: "getStatisticsForTimeInStudio",
        message: "Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };

  getStatisticForWorkouts = async (args: {
    userID: string;
  }): Promise<ChartData | undefined> => {
    try {
      const response = await this.get(`/statistics/workout/${args.userID}`);
      return response.data as ChartData;
    } catch (err) {
      Logging.error({
        className: "HttpStatisticService",
        methodName: "getStatisticForWorkouts",
        message: "Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };

  getStatisticForBodyStat = async (args: {
    userID: string;
    type: string;
  }): Promise<ChartData | undefined> => {
    try {
      const response = await this.get(
        `/statistics/body-stats/${args.userID}?type=${args.type}`
      );
      return response.data as ChartData;
    } catch (err) {
      Logging.error({
        className: "HttpStatisticService",
        methodName: "getStatisticForBodyStat",
        message: "Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };
}
