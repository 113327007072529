import React from "react";
import classNames from "classnames";
import { useRive } from "@rive-app/react-canvas";
import gymoLoading from "assets/animations/gymo.riv";
import gymoLoadingBlack from "assets/animations/gymo_black.riv";
import "./loading.component.scss";

interface LoadingProps {
  size?: "small" | "medium" | "large";
  theme?: "light" | "dark";
  className?: string;
}

const Loading = ({
  size = "small",
  className,
  theme = "light",
}: LoadingProps): JSX.Element => {
  const loadingClassName = classNames(
    "loading-indicator-container",
    `loading-indicator-container--${size}`,
    className
  );

  const { RiveComponent } = useRive({
    src: theme === "light" ? gymoLoading : gymoLoadingBlack,
    animations: "loading",
    autoplay: true,
  });

  return <RiveComponent className={loadingClassName} />;
};

export default Loading;
