import React, { useEffect } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";
import { NavListItemSkeleton } from "../nav.list.item.component/nav.list.item.component";
import Row from "components/general.compoenents/row.component/row.component";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import EventStore from "stores/event.store";
import { formatDate, isSameDay } from "globals/helpers/global.helper";
import { EventWithBookingInfo } from "schemas/service.schemas/booking.info.schema";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import BookingNavListItem from "./components/booking.nav.list.item.component/booking.nav.list.item.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { LargeText } from "components/text.components/large.text.component/large.text.component";
import {
  getEventTypeIcon,
  getStatusIcon,
} from "globals/helpers/calendar.helper";
import moment from "moment";
import { Grouped } from "schemas/grouped.schema";

interface BookingNavListProps {
  studioStore?: StudioStore;
  eventStore?: EventStore;
}

const BookingNavList = ({
  studioStore,
  eventStore,
}: BookingNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const eventsGrouped = eventStore?.groupedEvents?.data;
  const { eventID, timeSlotID, date } = useParams();

  useEffect(() => {
    const from = new Date();
    const to = new Date();
    to.setDate(to.getDate() + 60);
    to.setHours(0, 0, 0, 0);
    to.setDate(to.getDate() + 1);

    eventStore?.fetchAndSetGroupedEvents({
      from,
      to,
      refresh: true,
    });
  }, []);

  const _getItemColor = (event: EventWithBookingInfo): string => {
    if (event?.status === "CANCELED") {
      return "#FC2947";
    }

    return event?.color ?? "#313634e0";
  };

  const _getWeekDay = (groupedItem: Grouped<EventWithBookingInfo>): string => {
    return moment(new Date(groupedItem.label)).format("dd").toUpperCase();
  };

  const _getDay = (groupedItem: Grouped<EventWithBookingInfo>): string => {
    return moment(new Date(groupedItem.label)).format("D");
  };

  const _getProgressBarValue = (event: EventWithBookingInfo): number => {
    // if the event is canceled, the progress bar should be 0 (dont show it)
    if (event?.status === "CANCELED") {
      return 0;
    }

    // calculate the percentage of the progress bar if the event is not canceled
    // and the booking info is not null
    if (
      event?.bookingInfo?.attendeesCount != null &&
      event?.bookingInfo?.maxParticipants != null &&
      event?.bookingInfo?.status != null
    ) {
      return (
        (event?.bookingInfo?.attendeesCount /
          event?.bookingInfo?.maxParticipants) *
        100
      );
    }

    return 0;
  };

  const _itemIsSelected = (event: EventWithBookingInfo): boolean => {
    // convert the date string to a date object to compare with event.date
    const dateParam = date ? new Date(date) : null;
    const eventDate = event?.date ? new Date(event.date) : null;

    // check if id is not null then check if id is equal to the id in the url
    if (
      event?._id != null &&
      eventID != null &&
      event?._id !== "null" &&
      event?._id === eventID
    ) {
      return true;

      // check if timeSlotID and date are equal with the ones in the url
    } else if (
      event?.timeSlotID != null &&
      timeSlotID != null &&
      event?.timeSlotID === timeSlotID &&
      eventDate != null &&
      dateParam != null &&
      isSameDay(new Date(event.date), new Date(dateParam))
    ) {
      return true;
    }

    return false;
  };

  const _handleOnClick = (event: EventWithBookingInfo): void => {
    if (
      studioStore?.studio?._id != null &&
      event?.service?._id != null &&
      event?.date != null
    ) {
      navigate(
        `/studios/${studioStore?.studio?._id}/bookings/${
          event?._id ?? "null"
        }/${event?.timeSlotID ?? "null"}/${formatDate(event?.date)}/info`
      );
    }
  };

  const _builBookingList = (): JSX.Element => {
    if (eventStore?.groupedEvents?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {eventsGrouped?.length === 0 && eventID !== "new" && (
            <ListPlaceholder
              title="Du hast noch keine Buchungen."
              description="Sobald du eine Dienstleistung mit einem Zeitplan erstellt hast, kannst du hier deine Buchungen verwalten."
            />
          )}

          {eventsGrouped?.map((groupedItem, i) => {
            return (
              <Row
                key={groupedItem.label}
                alignItems="flex-start"
                justifyContent="space-between"
                className="border-box-sizing mt-35 mb-35 pr-20"
              >
                <SizedContainer
                  size={ContainerSizes.CUSTOM}
                  customSize={70}
                  className="ml-10 mr-10"
                >
                  <Column alignItems="center" className="mr-25">
                    <LargeText
                      className={
                        isSameDay(new Date(), new Date(groupedItem.label))
                          ? "color-primary"
                          : ""
                      }
                    >
                      {_getWeekDay(groupedItem)}
                    </LargeText>
                    <SmallHeadline
                      className={
                        isSameDay(new Date(), new Date(groupedItem.label))
                          ? "color-primary mt-5"
                          : "mt-5"
                      }
                    >
                      {_getDay(groupedItem)}
                    </SmallHeadline>
                  </Column>
                </SizedContainer>
                <Column>
                  {groupedItem.data &&
                    groupedItem.data.length > 0 &&
                    groupedItem.data.map((dataItem, i) => {
                      const eventIsCanceled = dataItem?.status !== "ACTIVE";
                      const typeIcon = getEventTypeIcon(dataItem?.type);
                      const statusIcon = getStatusIcon(eventIsCanceled);

                      return (
                        <>
                          <BookingNavListItem
                            key={dataItem?._id ?? i}
                            icon={eventIsCanceled ? statusIcon : typeIcon}
                            progressBarValue={_getProgressBarValue(dataItem)}
                            color={_getItemColor(dataItem)}
                            isActive={_itemIsSelected(dataItem)}
                            className="mb-10"
                            onClick={() => {
                              _handleOnClick(dataItem);
                            }}
                          >
                            <TitleText className="text-color-inverted mb-5">
                              {eventIsCanceled ? (
                                <s>{dataItem?.service?.title}</s>
                              ) : (
                                <>{dataItem?.service?.title}</>
                              )}
                            </TitleText>
                            <RunningText className="text-color-inverted">
                              {dataItem?.startTime} - {dataItem?.endTime} Uhr
                            </RunningText>
                          </BookingNavListItem>
                        </>
                      );
                    })}
                </Column>
              </Row>
            );
          })}
        </div>
      );
    }
  };

  return <Column>{_builBookingList()}</Column>;
};

export default inject("studioStore", "eventStore")(observer(BookingNavList));
