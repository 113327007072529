/* eslint-disable @typescript-eslint/no-misused-promises */
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import React, { useState } from "react";
import FormItemWrapper from "components/general.compoenents/form.item.wrapper.component/form.item.wrapper.component";
import UserStore from "stores/user.store";
import { inject, observer } from "mobx-react";
import { userSchema } from "schemas/user.schemas/user.schema";
import { useForm } from "react-hook-form";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import Row from "components/general.compoenents/row.component/row.component";
import { ModalStore, ModalType } from "stores/modal.store";
import { yupResolver } from "@hookform/resolvers/yup";
import Gleap from "gleap";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { getFormattedDateValue } from "globals/helpers/global.helper";
import { toast } from "react-toastify";

interface ProfileTabPageProps {
  userStore?: UserStore;
  modalStore?: ModalStore;
}

const ProfileTabPage = ({
  userStore,
  modalStore,
}: ProfileTabPageProps): JSX.Element => {
  const user = userStore?.user;
  // check if user has internal account (for change password)
  const hasInternalAccount = userStore?.userAccountKinds?.find(
    (accountKind) => accountKind === "INTERNAL"
  );

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: user,
  });

  if (user == null) {
    return <></>;
  }

  const onSubmit = async (data: any): Promise<void> => {
    const updatedUser = await userStore?.updateCurrentUser(data);

    if (updatedUser != null) {
      toast.success("Deine Änderungen wurden gespeichert.");

      setFormIsDirty(false);
    }
  };

  const _buildContent = (): JSX.Element => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          toast.error("Bitte überprüfe deine Eingaben");
          setFormIsDirty(false);
        })}
        onChange={() => {
          setFormIsDirty(true);
        }}
        className="profile-tab-page-wrapper"
      >
        <Row
          className="mb-20"
          justifyContent="space-between"
          alignItems="center"
        >
          <Headline>Profil</Headline>
          <FilledButton
            label="Speichern"
            color="secondary"
            type="submit"
            disabled={isUploading || !formIsDirty}
          />
        </Row>
        <ComponentWrapper>
          <FormItemWrapper
            title="Profilbild"
            description="Hier kannst du ein Bild hochladen oder ändern, welches als dein Profilbild angezeigt wird."
          >
            <FileUpload
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              folder="profile-images"
              inputRef={register("profileImageUrl")}
              validationMessage={errors.profileImageUrl?.message?.toString()}
              fileUrl={getProperty(user, "profileImageUrl")}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValue("profileImageUrl", files[0].path);
                  clearErrors("profileImageUrl");
                  setFormIsDirty(true);
                }
              }}
            />
          </FormItemWrapper>

          <FormItemWrapper title="Name">
            <OutlinedTextInput
              label="Vorname"
              inputRef={register("firstName")}
              validationMessage={errors.firstName?.message?.toString()}
            />
            <OutlinedTextInput
              label="Nachname"
              className="mt-30"
              inputRef={register("lastName")}
              validationMessage={errors.lastName?.message?.toString()}
            />
          </FormItemWrapper>

          <FormItemWrapper title="E-Mail Adresse" description="">
            <OutlinedTextInput
              disabled
              inputRef={register("email")}
              validationMessage={errors.email?.message?.toString()}
            />
          </FormItemWrapper>

          <FormItemWrapper
            title="Geburtsdatum"
            description="Hier kannst du dein Geburtsdatum ändern oder hinzufügen. Es kann genutzt werden, um passende Angebote oder Geburtstagsgrüße zu erhalten."
          >
            <OutlinedTextInput
              type="date"
              initialValue={getFormattedDateValue(user?.dateOfBirth as any)}
              onChange={(value) => {
                if (value != null && value !== "") {
                  setValue("dateOfBirth", new Date(value));

                  setFormIsDirty(true);
                }
              }}
              validationMessage={errors.dateOfBirth?.message?.toString()}
            />
          </FormItemWrapper>

          {/* // TODO COMMENT OUT FOR THE FIRST VERSION */}
          {/* <FormItemWrapper
            title="Sprache"
            description="Lorem ipsum dolor sit amet elit."
          >
            <SelectDropDown
              placeholder="Sprache"
              labelPropertyName="name"
              valuePropertyName="code"
              items={languages}
              selectedItem={languages.find((item) => {
                return item.code === user.language;
              })}
              onChange={(item) => {
                setValue("language", item.code);
                setFormIsDirty(true);
              }}
              inputRef={register("language")}
              validationMessage={errors.language?.message?.toString()}
            />
          </FormItemWrapper> */}

          {hasInternalAccount != null && (
            <FormItemWrapper
              title="Passwort"
              description="Hier kannst du dein Passwort ändern. Verwende ein sicheres Passwort, um dein Konto zu schützen."
            >
              <FilledButton
                label="Passwort ändern"
                onClick={() => {
                  modalStore?.openModal(ModalType.CHANGE_PASSWORD_MODAL);
                }}
              />
            </FormItemWrapper>
          )}

          <FormItemWrapper
            title="Löschen Sie Ihren Account"
            description="Alle deine Daten werden unwiderruflich aus dem Gymo-System gelöscht."
          >
            <FilledButton
              color="danger"
              label="Löschen beantragen"
              onClick={() => {
                Gleap.open();
              }}
            />
          </FormItemWrapper>
        </ComponentWrapper>
      </form>
    );
  };

  return <div>{_buildContent()}</div>;
};

export default inject("userStore", "modalStore")(observer(ProfileTabPage));
