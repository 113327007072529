import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CustomerStore from "stores/customer.store";
import StudioStore from "stores/studio.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface CustomerNavListProps {
  studioStore?: StudioStore;
  customerStore?: CustomerStore;
}

const CustomerNavList = ({
  studioStore,
  customerStore,
}: CustomerNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { customerID } = useParams();
  const customers = customerStore?.customers?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);
  const customersSearchResult = customerStore?.customersSearchResult ?? [];

  // if isSearch is active, use customersSearchResult otherwise use customers
  const customerToRender = isSearchActive ? customersSearchResult : customers;

  useEffect(() => {
    customerStore?.fetchAndSetCustomers({});
  }, []);

  const _buildCustomerList = (): JSX.Element => {
    if (customerStore?.customers?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {customerToRender.length === 0 &&
            customers.length > 0 &&
            isSearchActive &&
            customerID !== "new" && <NavListNoSearchResultComponent />}

          {customerToRender.map((item, i) => {
            if (item == null) {
              return <></>;
            }
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  imageUrl={getProperty(item, "profileImageUrl")}
                  title={`${item?.firstName} ${item?.lastName ?? ""}`}
                  subtitle={getProperty(item, "email")}
                  isActive={item._id === customerID}
                  key={i}
                  onClick={() => {
                    if (studioStore?.studio?._id != null && item._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/customers/${item?._id}/overview`
                      );
                    }
                  }}
                />

                <Spacer width="88%" />
              </Column>
            );
          })}

          {customers.length <= 1 && customerID !== "new" && (
            <ListPlaceholder
              title="Es haben sich noch keine Mitglieder registriert."
              description="Sobald sich Mitglieder bei deiner App registrieren, werden sie hier angezeigt."
            />
          )}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline className="border-right" classNameActions="full-width">
        <OutlinedTextInput
          placeholder="Kunden suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              customers?.length > 0
            ) {
              setIsSearchActive(true);
              customerStore?.searchAndSetCustomers(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildCustomerList()}
    </Column>
  );
};

export default inject(
  "studioStore",
  "customerStore"
)(observer(CustomerNavList));
