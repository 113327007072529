import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ArchiveList from "components/table.components/archive.list.component/archive.list.component";
import StudioDeviceStore from "stores/studio.device.store";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import { StudioDevice } from "schemas/device.schemas/device.schema";
import { HttpStudioDeviceService } from "services/httpClients/http.studio.device.client";
import { toast } from "react-toastify";

interface ArchiveDeviceTabProps {
  studioDeviceStore?: StudioDeviceStore;
}

const ArchiveDeviceTab = ({
  studioDeviceStore,
}: ArchiveDeviceTabProps): JSX.Element => {
  const archivedDevices = studioDeviceStore?.archivedDevices?.data;
  const isLoading = studioDeviceStore?.archivedDevices?.isLoading;

  useEffect(() => {
    studioDeviceStore?.fetchAndSetStudioDevices({});
  }, []);

  if (archivedDevices == null) {
    return <></>;
  }

  const _handleRestore = async (item: StudioDevice): Promise<void> => {
    if (item.device._id != null) {
      const response = await HttpStudioDeviceService.getInstance().unarchiveOne(
        {
          id: item.device._id,
        }
      );

      if (response != null) {
        studioDeviceStore?.fetchAndSetStudioDevices({ refresh: true });
        toast.success("Gerät wurde wiederhergestellt");
      }
    }
  };

  return (
    <>
      <Headline className="mb-10 mt-20">Geräte</Headline>
      <ComponentWrapper noPadding>
        <ArchiveList
          isLoading={isLoading}
          items={archivedDevices}
          fields={[
            {
              fieldPath: "device.coverImageUrl",
              fieldType: "image",
            },
            { fieldPath: "device.title.de", header: "Titel" },
            {
              header: "Archivert am",
              fieldPath: "archivedAt",
              fieldType: "date",
            },
          ]}
          restoreOnClick={(item: StudioDevice) => {
            _handleRestore(item);
          }}
        />
      </ComponentWrapper>
    </>
  );
};

export default inject("studioDeviceStore")(observer(ArchiveDeviceTab));
