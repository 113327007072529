import { GenericHttpClient } from "./config/http.generic.client";

export class HttpPaymentService extends GenericHttpClient<any> {
  static _instance: HttpPaymentService;
  static getInstance(): HttpPaymentService {
    if (this._instance == null) {
      this._instance = new HttpPaymentService("/studios");
    }
    return this._instance;
  }

  createSetupIntent = async (): Promise<any> => {
    return await this.post("/studios/payment-setup-intent");
  };

  listPaymentMethods = async (): Promise<any> => {
    return await this.get("/studios/payment-methods");
  };

  deletePaymentMethod = async (args: {
    paymentMethodID: string;
  }): Promise<any> => {
    return await this.delete(
      `/studios/payment-methods/${args.paymentMethodID}`
    );
  };

  createSubscription = async (args: {
    priceID: string;
    paymentMethodID: string;
    promotionCode?: string;
  }): Promise<any> => {
    return await this.post("/studios/subscriptions", {
      priceID: args.priceID,
      paymentMethodID: args.paymentMethodID,
      promotionCode: args.promotionCode,
    });
  };

  getSusbscription = async (): Promise<any> => {
    return await this.get("/studios/subscriptions");
  };

  cancelSubscription = async (args: {
    subscriptionID: string;
  }): Promise<any> => {
    return await this.delete(`/studios/subscriptions/${args.subscriptionID}`);
  };

  getInvoices = async (): Promise<any> => {
    return await this.get("/studios/invoices");
  };

  getPaymentCustomer = async (): Promise<any> => {
    return await this.get("/studios/payment-customers");
  };

  updatePaymentCustomer = async (args: { customer: any }): Promise<any> => {
    return await this.put("/studios/payment-customers", args.customer);
  };
}
