import AppPreviewComponent from "components/app.preview.component/app.preview.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";

import widget1 from "assets/widget_templates/widget_component_1.png";
// import widget2 from "assets/widget_templates/widget_component_2.png";
import widget3 from "assets/widget_templates/widget_component_3.png";
// import widget4 from "assets/widget_templates/widget_component_4.png";
// import widget5 from "assets/widget_templates/widget_component_5.png";

// import layout1_1 from "assets/widget_templates/layout_1-1.png";
// import layout3_4 from "assets/widget_templates/layout_3-4.png";
// import layout4_3 from "assets/widget_templates/layout_4-3.png";

import swipeHorizontal from "assets/widget_templates/swipe_horizontal.png";
import swipeVertical from "assets/widget_templates/swipe_vertical.png";
// import swipeGrid from "assets/widget_templates/swipe_grid.png";

import Column from "components/general.compoenents/column.component/column.component";
import WidgetTemplate from "components/widget.template.component/widget.template.component";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedConfigSchema } from "schemas/feed.schemas/feed.config.schema";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { toast } from "react-toastify";

const widgetTemplates = [
  {
    id: "WIDGET_6404b5968d11259cff840ef0",
    title: "Design 1",
    description:
      "Basic Design mit einem großen Bild und einer kurzen Beschreibung.",
    imageUrl: widget3,
  },
  {
    id: "WIDGET_6404b5e38d11259cff840ef1",
    title: "Design 2",
    description: "Großes Bild mit einer Überschrift.",
    imageUrl: widget1,
  },
  // {
  //   id: "widget_2",
  //   title: "Design 2",
  //   description:
  //     "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
  //   imageUrl: widget2,
  // },
  // {
  //   id: "widget_4",
  //   title: "Design 4",
  //   description:
  //     "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
  //   imageUrl: widget4,
  // },
  // {
  //   id: "widget_5",
  //   title: "Design 5",
  //   description:
  //     "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
  //   imageUrl: widget5,
  // },
];

const swipeTemplates = [
  {
    id: "VERTICAL",
    title: "Vertikal swipen",
    description:
      "Mit dem vertikalen Swipen kannst du deine Nachrichten in einer Liste darstellen.",
    imageUrl: swipeVertical,
  },
  {
    id: "HORIZONTAL",
    title: "Horizontal swipen",
    description:
      "Mit dem horizontalen Swipen kannst du deine Nachrichten in einer horizontalen Liste darstellen.",
    imageUrl: swipeHorizontal,
  },
  // {
  //   id: "GRID",
  //   title: "Grid swipen",
  //   description:
  //     "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
  //   imageUrl: swipeGrid,
  // },
];

// const layoutTemplates = [
//   {
//     id: "1-1",
//     title: "Kachelformat 1:1",
//     description:
//       "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
//     imageUrl: layout1_1,
//   },
//   {
//     id: "3-4",
//     title: "Kachelformat 3:4",
//     description:
//       "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
//     imageUrl: layout3_4,
//   },
//   {
//     id: "4-3",
//     title: "Kachelformat 4:3",
//     description:
//       "Mit dieser Kachelsystem ist deine Massage groß im Blick und du erholst volle Aufmerksamkeit.",
//     imageUrl: layout4_3,
//   },
// ];

const dataSourceTypes = [
  {
    value: "FEED",
    label: "Feed",
  },
  {
    value: "SERVICE",
    label: "Kurs",
  },
];

interface FeedConfigFormProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
  onFormSubmit: (data: any) => void;
  onDirty: (isDirty: boolean) => void;
}

const FeedConfigForm = ({
  studioStore,
  feedStore,
  onFormSubmit,
  onDirty,
}: FeedConfigFormProps): JSX.Element => {
  const editingStudio = studioStore?.editingStudio;
  const feedConfig = feedStore?.currentFeedConfig?.data;

  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(feedConfigSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: feedConfig,
  });

  useEffect(() => {
    studioStore?.setEditingProject();
  }, []);

  useEffect(() => {
    onDirty(formIsDirty);
  }, [formIsDirty]);

  const _buildPreview = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <div className="app-configurator-preview-container">
        <AppPreviewComponent />
      </div>
    );
  };

  const _buildForm = (): JSX.Element => {
    return (
      <form
        id="feed-config-form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onFormSubmit, (errors) => {
          toast.error("Bitte überprüfe deine Eingaben");
          setFormIsDirty(false);
        })}
        onChange={() => {
          if (!formIsDirty) {
            setFormIsDirty(true);
          }
        }}
      >
        <Column>
          {_buildDetails()}
          {/* {_buildLayoutTemplates()} */}
          {_buildWidgetTemplates()}
          {_buildSwipeTemplates()}
        </Column>
      </form>
    );
  };

  const _buildDetails = (): JSX.Element => {
    return (
      <ComponentWrapper title="Beitrag" className="full-width mb-15">
        <Wrap>
          <OutlinedTextInput
            label="Titel"
            placeholder="Titel (z.b. News, Angebote, Kurse, etc.)"
            inputRef={register("title")}
            validationMessage={errors.title?.message?.toString()}
            onChange={(value) => {
              setProperty(feedConfig, "title", value);
              feedStore?.setEditingFeedConfig({ ...feedConfig! });
            }}
          />
          <OutlinedTextInput
            label="Subtitel"
            placeholder="Titel (z.b. News, Angebote, Kurse, etc.)"
            inputRef={register("subTitle")}
            validationMessage={errors.subTitle?.message?.toString()}
            onChange={(value) => {
              setProperty(feedConfig, "subTitle", value);
              feedStore?.setEditingFeedConfig({ ...feedConfig! });
            }}
          />
          <SelectDropDown
            label="Datenquelle"
            className="mb-10"
            selectedItem={dataSourceTypes.find(
              (item) => item.value === feedConfig?.dataType
            )}
            items={dataSourceTypes ?? []}
            onChange={(item) => {
              setValue("dataType", item?.value);
              clearErrors("dataType");
              if (item !== undefined) setFormIsDirty(true);
            }}
            inputRef={register("dataType")}
            validationMessage={errors.dataType?.message?.toString()}
          />
        </Wrap>
      </ComponentWrapper>
    );
  };

  const _buildWidgetTemplates = (): JSX.Element => {
    return (
      <ComponentWrapper title="Design-Komponente" className="full-width mb-15">
        <Wrap>
          {widgetTemplates.map((item, i) => {
            return (
              <WidgetTemplate
                key={item.id}
                title={item.title}
                description={item.description}
                imageUrl={item.imageUrl}
                isActive={item.id === feedConfig?.widgetID}
                onClick={() => {
                  setProperty(feedConfig, "widgetID", item.id);
                  setValue("widgetID", item.id);
                  clearErrors("widgetID");
                  setFormIsDirty(true);
                  feedStore?.setEditingFeedConfig({ ...feedConfig! });
                }}
              />
            );
          })}
        </Wrap>
      </ComponentWrapper>
    );
  };

  const _buildSwipeTemplates = (): JSX.Element => {
    return (
      <ComponentWrapper title="Swipesystem" className="full-width mb-15">
        <Wrap>
          {swipeTemplates.map((item, i) => {
            return (
              <WidgetTemplate
                key={item.id}
                title={item.title}
                description={item.description}
                imageUrl={item.imageUrl}
                isActive={item.id === feedConfig?.scrollDirection}
                onClick={() => {
                  setProperty(feedConfig, "scrollDirection", item.id);
                  setValue("scrollDirection", item.id);
                  clearErrors("scrollDirection");
                  setFormIsDirty(true);
                  feedStore?.setEditingFeedConfig({ ...feedConfig! });
                }}
              />
            );
          })}
        </Wrap>
      </ComponentWrapper>
    );
  };

  // const _buildLayoutTemplates = (): JSX.Element => {
  //   return (
  //     <ComponentWrapper title="Kachelsystem" className="full-width mb-15">
  //       <Wrap>
  //         {layoutTemplates.map((item, i) => {
  //           return (
  //             <WidgetTemplate
  //               key={item.id}
  //               title={item.title}
  //               description={item.description}
  //               imageUrl={item.imageUrl}
  //               isActive={item.id === feedConfig?.scrollDirection}
  //               onClick={() => {
  //                 setProperty(feedConfig, "scrollDirection", item.id);
  //                 setValue("scrollDirection", item.id);
  //                 feedStore?.setEditingFeedConfig({ ...feedConfig! });
  //               }}
  //             />
  //           );
  //         })}
  //       </Wrap>
  //     </ComponentWrapper>
  //   );
  // };

  return (
    <SplitLayout
      leftChild={_buildForm()}
      leftGrow={2}
      rightChild={_buildPreview()}
      rightGrow={1}
    />
  );
};

export default inject("studioStore", "feedStore")(observer(FeedConfigForm));
