import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import FeedConfigNavList from "components/list.components/nav.list.components/feed.config.nav.list.component/feed.config.nav.list.component";
import React from "react";
import { useOutlet } from "react-router-dom";
import homeConfigPlaceholder from "assets/illustrations/placeholders/no_topic_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";

const HomeConfiguratorPage = (): JSX.Element => {
  const outlet = useOutlet();

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        collapseable
        leftChild={<FeedConfigNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Du hast noch keine Kategorie ausgewählt"
            description="Wähle eine Kategorie aus, um sie hier anzuzeigen."
            imagePath={homeConfigPlaceholder}
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default HomeConfiguratorPage;
