import { User } from "schemas/user.schemas/user.schema";
import { GenericHttpClient } from "./config/http.generic.client";
import { Booking } from "schemas/booking.schemas/booking.schema";

export class HttpCustomerService extends GenericHttpClient<User> {
  static _instance: HttpCustomerService;
  static getInstance(): HttpCustomerService {
    if (this._instance == null) {
      this._instance = new HttpCustomerService("/customers");
    }
    return this._instance;
  }

  getBookingsForCustomer = async (
    userID: string
  ): Promise<Booking[] | undefined> => {
    const response = await this.get(`customers/${userID}/bookings`);
    return response.data as Booking[];
  };
}
