import React from "react";
import CustomerStore from "stores/customer.store";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import Headline from "components/text.components/headline.component/headline.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { calculateAge, formatDate } from "globals/helpers/global.helper";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import CustomerFactCardComponent from "components/card.components/customer.fact.card.component/customer.fact.card.component";

interface CustomerInfoTabProps {
  customerStore?: CustomerStore;
  studioStore?: StudioStore;
}

const CustomerInfoTab = ({
  customerStore,
  studioStore,
}: CustomerInfoTabProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;

  if (!currentCustomer) return <></>;

  const _buildCustomerDataTable = (): JSX.Element => {
    return (
      <>
        <ListDataTable
          disableHeader={true}
          data={[
            {
              title: "Geschlecht:",
              value: getProperty(currentCustomer, "gender"),
            },
            {
              title: "Geburtsdatum:",
              value: formatDate(
                getProperty(currentCustomer, "dateOfBirth"),
                "DD.MM.YYYY"
              ),
            },

            {
              title: "Größe:",
              value: `${getProperty(currentCustomer, "bodyHeight")} cm`,
            },
            {
              title: "Alter:",
              value: calculateAge(getProperty(currentCustomer, "dateOfBirth")),
            },
            {
              title: "Registriert am:",
              value: getProperty(currentCustomer, "system.createdAt"),
            },
          ]}
          columns={[
            {
              child: <></>,
            },

            {
              child: <></>,
            },
          ]}
          dataTableItemBuilder={(dataItem: {
            title: string;
            value: string;
          }) => {
            return {
              key: dataItem.title,
              children: [
                {
                  child: <RunningText>{dataItem.title}</RunningText>,
                },
                {
                  child: <RunningText>{dataItem.value}</RunningText>,
                },
              ],
            };
          }}
        />
      </>
    );
  };

  const _buildCustomerInfoDataWidget = (): JSX.Element => {
    return (
      <ComponentWrapper title="Stammdaten" className="mr-5">
        <Row justifyContent="flex-start">
          <Column className="mr-20">
            <Image
              size={ImageSizes.FULL_SIZE}
              imageUrl={getProperty(currentCustomer, "profileImageUrl")}
            ></Image>
          </Column>
          <Column>
            <SmallHeadline className="ml-15">
              {`${currentCustomer.firstName} ${currentCustomer.lastName ?? ""}`}
            </SmallHeadline>
            <RunningText className="ml-15 mt-5 mb-15">
              {currentCustomer.email ?? "-"}
            </RunningText>
            {_buildCustomerDataTable()}
          </Column>
        </Row>
      </ComponentWrapper>
    );
  };

  const _buildCustomerFlexibleInfoDataWidget = (): JSX.Element => {
    return (
      <ComponentWrapper title="Flexible Daten" className="mr-5">
        <Wrap>
          <CustomerFactCardComponent title="Aktuelles Gewicht" value="-" />
          <CustomerFactCardComponent title="Zielgewicht" value="-" />
          <CustomerFactCardComponent title="Rythmus" value="-" />
          <CustomerFactCardComponent title="Niveau" value="-" />
        </Wrap>
      </ComponentWrapper>
    );
  };

  return (
    <form>
      <Headline className="mb-20">
        {`${currentCustomer.firstName} ${currentCustomer.lastName ?? ""}`}
      </Headline>

      <Row className="mb-20">
        <Column>{_buildCustomerInfoDataWidget()}</Column>
        <Column>{_buildCustomerFlexibleInfoDataWidget()}</Column>
      </Row>

      <InfoBox>Mehr Konfigurationsmöglichkeiten folgen in kürze.</InfoBox>
    </form>
  );
};

export default inject(
  "customerStore",
  "studioStore"
)(observer(CustomerInfoTab));
