import React, { useEffect, useState } from "react";
import ExerciseStore from "stores/exercise.store";
import { inject, observer } from "mobx-react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import "./exercise.detail.page.scss";
import ExerciseForm from "./components/exercise.form.component/exercise.form.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import PropertyStore from "stores/property.store";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import ExerciseInfos from "./components/exercise.infos.component/exercise.infos.component";
import ExerciseInfoDetail from "./components/exercise.info.detail.component/exercise.info.detail.component";
import LanguageDropdown from "components/input.components/dropdown.components/language.dropdown.component/language.dropdown.component";

interface ExerciseDetailPageProps {
  exerciseStore?: ExerciseStore;
  propertyStore?: PropertyStore;
}

const ExerciseDetailPage = ({
  exerciseStore,
  propertyStore,
}: ExerciseDetailPageProps): JSX.Element => {
  const { exerciseID } = useParams();
  const [isEditing, setIsEditing] = useState(exerciseID !== "new");
  const [formIsDirty, setFormIsDirty] = useState(false);

  useEffect(() => {
    initializePage();
  }, [exerciseID]);

  const initializePage = async (): Promise<void> => {
    if (exerciseID == null) {
      return;
    }

    propertyStore?.fetchAndSetProperties({});

    if (exerciseID !== "new") {
      await exerciseStore?.fetchAndSetExercise({ exerciseID });
      setIsEditing(true);
    }

    if (exerciseID === "new") {
      exerciseStore?.createInitialExercise();
      setIsEditing(false);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        actions={[
          <SizedContainer
            className="mr-10"
            size={ContainerSizes.M}
            key="info,topics*"
          >
            <LanguageDropdown />
          </SizedContainer>,
        ]}
        tabs={[
          <Tab
            label="Informationen"
            key="exercise-info-tab"
            path="info"
            rootPath={exerciseID}
            dirty={formIsDirty}
          />,
          <Tab
            label="Beschreibung"
            key="exercise-infos-tab"
            path="topics"
            rootPath={exerciseID}
            dirty={formIsDirty}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="exercises">
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (exerciseStore?.currentExercise?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={exerciseID === "new" ? Math.random() : exerciseID}>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path="info"
              element={
                <ExerciseForm
                  isEditing={isEditing}
                  onDirty={(isDirty) => {
                    if (exerciseID !== "new") {
                      setFormIsDirty(isDirty);
                    }
                  }}
                />
              }
            />
            <Route path="topics" element={<ExerciseInfos />}>
              <Route
                path=":topicID"
                element={
                  <ExerciseInfoDetail
                    onDirty={(isDirty) => {
                      if (exerciseID !== "new") {
                        setFormIsDirty(isDirty);
                      }
                    }}
                  />
                }
              />
            </Route>
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "exerciseStore",
  "propertyStore"
)(observer(ExerciseDetailPage));
