import React, { useEffect, useState } from "react";
import ExerciseStore from "stores/exercise.store";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import {
  CollectionDataType,
  ContainerSizes,
  PropertyType,
} from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { difficultyLevelOptions } from "globals/data/globals.data";
import PropertyStore from "stores/property.store";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import StudioStore from "stores/studio.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { exerciseSchema } from "schemas/exercise.schemas/exercise.schema";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useNavigate, useParams } from "react-router";
import { ModalStore, ModalType } from "stores/modal.store";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { toast } from "react-toastify";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { HttpExerciseService } from "services/httpClients/http.exercise.client";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";

interface ExerciseFormProps {
  isEditing?: boolean;
  propertyStore?: PropertyStore;
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  onDirty: (isDirty: boolean) => void;
}

const ExerciseForm = ({
  isEditing = false,
  propertyStore,
  exerciseStore,
  studioStore,
  modalStore,
  onDirty,
}: ExerciseFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { exerciseID } = useParams();

  const exercise = exerciseStore?.currentExercise?.data;
  const currentLanguage = studioStore?.currentLanguage;
  const currentLanguages = studioStore?.studio?.localizations ?? [];
  const muscleGroupProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.MUSCLE_GROUP
  );
  const deviceAssignmentCount = exercise?.devices?.length ?? 0;
  const infoIsMissing =
    exercise?.infos == null || exercise?.infos?.length === 0;

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [updateIsAvaliable, setUpdateIsAvaliable] = useState(false);
  const autoUpdate = exercise?.autoUpdate ?? false;
  const isTemplate = exercise?.type === CollectionDataType.TEMPLATE;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(exerciseSchema(currentLanguages)),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: exercise,
  });

  useEffect(() => {
    checkIfUpdateIsAvalibale();
    onDirty(formIsDirty);
  }, [formIsDirty]);

  const checkIfUpdateIsAvalibale = async (): Promise<void> => {
    if (
      exerciseID != null &&
      isEditing &&
      exerciseStore?.currentExercise?.data?.autoUpdate === false
    ) {
      const updateIsAvaliable =
        await HttpExerciseService.getInstance().checkIfExerciseUpdateIsAvailable(
          { exerciseID }
        );

      if (
        updateIsAvaliable.isUpdateAvailable != null &&
        updateIsAvaliable.isUpdateAvailable
      ) {
        setUpdateIsAvaliable(true);
      }
    }
  };

  // handle exercise  form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (exercise == null) {
      return;
    }

    exerciseStore?.setCurrentExercise({
      ...exercise,
      ...data,
    });

    if (!isEditing) {
      const createdExercise = await exerciseStore?.createExercise(data);

      // navigate to created exercise
      if (createdExercise?._id != null && studioStore?.studio?._id != null) {
        navigate(
          `/studios/${studioStore?.studio?._id}/exercises/${createdExercise._id}/info`
        );
      }
    } else {
      // when exercise is not template update exercise default
      if (
        exercise.type !== CollectionDataType.TEMPLATE &&
        exercise._id != null
      ) {
        await exerciseStore?.updateExercise({
          id: exercise._id,
          exercise: data,
        });
      } else {
        // when exercise is template open version open version selection modal
        modalStore?.openModal(
          ModalType.EXERCISE_TEMPLATE_VERSION_SELECTION_MODAL
        );
      }
    }

    setFormIsDirty(false);
  };

  const _buildAdminInfos = (): JSX.Element => {
    if (exercise?.type === CollectionDataType.TEMPLATE) {
      return (
        <>
          {infoIsMissing && (
            <HighlightedInfoText className="mr-15" color="error">
              Beschreibung fehlt
            </HighlightedInfoText>
          )}
          <HighlightedInfoText
            className="mr-15"
            color={deviceAssignmentCount > 0 ? "success" : "error"}
          >
            {deviceAssignmentCount > 0
              ? `Geräte zugewiesen: ${deviceAssignmentCount} `
              : "Keine Geräte zugewiesen"}
          </HighlightedInfoText>
          <HighlightedInfoText className="mr-25">
            Version {getProperty(exercise, "templateVersion")}
          </HighlightedInfoText>
        </>
      );
    }

    return <></>;
  };

  if (exercise == null || currentLanguage == null) {
    return <></>;
  }

  const _buildExerciseUpdateInfo = (): JSX.Element => {
    if (!updateIsAvaliable) {
      return <></>;
    }

    return (
      <InfoBox className="mb-20">
        Es ist eine neue Version dieser Übung verfügbar. Wenn du diese Übung
        aktualisierst, werden deine Änderungen überschrieben.
        <LinkButton
          className="mt-10"
          label="Übung aktualisieren"
          onClick={() => {
            if (exerciseID == null) {
              return;
            }

            modalStore?.showConfirmAlert({
              title: "Übung Aktualisieren",
              description:
                "Wenn du die Übung aktualisierst, werden deine Änderungen überschrieben und du erhältst die neuste Version dieser Übung.",
              confirmLabel: "Aktualisieren",
              onConfirm: async () => {
                await exerciseStore?.updateExercise({
                  id: exerciseID,
                  updatetpLatestVersion: true,
                });
              },
            });
          }}
        />
      </InfoBox>
    );
  };

  return (
    <>
      {_buildExerciseUpdateInfo()}

      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit, (errors) => {
          toast.error("Bitte überprüfe deine Eingaben");
          setFormIsDirty(false);
        })}
        onChange={() => {
          if (!formIsDirty) {
            setFormIsDirty(true);
          }
        }}
      >
        <PageHeader label={isEditing ? "Übung bearbeiten" : "Übung hinzufügen"}>
          {_buildAdminInfos()}
          {!updateIsAvaliable && isEditing && !autoUpdate && !isTemplate && (
            <FilledButton
              disabled={isUploading}
              label="Übung zurücksetzen"
              color="secondary"
              className="mr-15"
              onClick={async () => {
                if (exerciseID == null) {
                  return;
                }

                modalStore?.showConfirmAlert({
                  title: "Übung zurücksetzen",
                  description:
                    "Möchtest du diese Übung wirklich zurücksetzen? Alle Änderungen gehen verloren und die Übung wird auf den ursprünglichen Stand zurückgesetzt.",
                  confirmLabel: "Zurücksetzen",
                  onConfirm: async () => {
                    await exerciseStore?.updateExercise({
                      id: exerciseID,
                      updatetpLatestVersion: true,
                    });
                  },
                });
              }}
            />
          )}

          <FilledButton
            disabled={isUploading || !formIsDirty}
            label="Speichern"
            type="submit"
            color="secondary"
          />
        </PageHeader>
        <ComponentWrapper>
          <SizedContainer size={ContainerSizes.XL}>
            <OutlinedTextInput
              label="Titel"
              inputRef={register(`title.${currentLanguage?.value}`)}
              validationMessage={getFormattedErrorMessage(
                "Titel",
                errors.title
              )}
            />

            <SelectDropDown
              label="Schwierigkeitsgrad"
              selectedItem={difficultyLevelOptions?.find(
                (item) => item.value === exercise?.difficultyLevel
              )}
              items={difficultyLevelOptions ?? []}
              onChange={(item) => {
                setValue("difficultyLevel", item?.value);
                clearErrors("difficultyLevel");
                if (item !== undefined) setFormIsDirty(true);
              }}
              inputRef={register("difficultyLevel")}
              validationMessage={errors.difficultyLevel?.message?.toString()}
            />

            <SelectDropDown
              isMulti
              label="Agonist"
              isLoading={propertyStore?.properties?.isLoading}
              selectedItem={exercise?.muscleGroups?.agonist}
              labelPropertyName={`title.${currentLanguage.value}`}
              valuePropertyName="_id"
              items={muscleGroupProperties ?? []}
              onChange={(item) => {
                setValue("muscleGroups.agonist", item);
                clearErrors("muscleGroups.agonist");
                if (item !== undefined) setFormIsDirty(true);
              }}
              inputRef={register("muscleGroups.agonist")}
              validationMessage={getFormattedErrorMessage(
                "Agonist",
                errors.muscleGroups?.agonist
              )}
            />
            <SelectDropDown
              isMulti
              label="Synergist"
              isLoading={propertyStore?.properties?.isLoading}
              selectedItem={exercise?.muscleGroups?.synergist}
              labelPropertyName={`title.${currentLanguage.value}`}
              valuePropertyName="_id"
              items={muscleGroupProperties ?? []}
              onChange={(item) => {
                setValue("muscleGroups.synergist", item);
                clearErrors("muscleGroups.synergist");
                if (item !== undefined) setFormIsDirty(true);
              }}
              inputRef={register("muscleGroups.synergist")}
              validationMessage={getFormattedErrorMessage(
                "Synergist",
                errors.muscleGroups?.synergist
              )}
            />
            <SelectDropDown
              isMulti
              label="Antagonist"
              isLoading={propertyStore?.properties?.isLoading}
              selectedItem={exercise?.muscleGroups?.antagonist}
              labelPropertyName={`title.${currentLanguage.value}`}
              valuePropertyName="_id"
              items={muscleGroupProperties ?? []}
              onChange={(item) => {
                setValue("muscleGroups.antagonist", item);
                clearErrors("muscleGroups.antagonist");
                if (item !== undefined) setFormIsDirty(true);
              }}
              inputRef={register("muscleGroups.antagonist")}
              validationMessage={getFormattedErrorMessage(
                "Antagonist",
                errors.muscleGroups?.antagonist
              )}
            />
          </SizedContainer>
          <Wrap className="mt-30" alignItems="flex-start">
            <SizedContainer size={ContainerSizes.M} className="mr-30">
              <FileUpload
                label="Übungsbild"
                isUploading={(uploading) => {
                  setIsUploading(uploading);
                }}
                aspectRatio={1 / 1}
                folder="exercise-images"
                inputRef={register("coverImageUrl")}
                fileUrl={getProperty(exercise, "coverImageUrl")}
                validationMessage={errors.coverImageUrl?.message?.toString()}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValue("coverImageUrl", files[0].path);
                    clearErrors("coverImageUrl");
                    setFormIsDirty(true);
                  }
                }}
              />
            </SizedContainer>
            <SizedContainer size={ContainerSizes.M}>
              <FileUpload
                label="Übungsvideo"
                isUploading={(uploading) => {
                  setIsUploading(uploading);
                }}
                accept={{ "video/*": [".mp4"] }}
                aspectRatio={9 / 16}
                folder="exercise-videos"
                inputRef={register("videoUrl")}
                fileUrl={getProperty(exercise, "videoUrl")}
                validationMessage={errors.videoUrl?.message?.toString()}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValue("videoUrl", files[0].path);
                    clearErrors("videoUrl");
                    setFormIsDirty(true);
                  } else {
                    setValue("videoUrl", undefined);
                  }
                }}
              />
            </SizedContainer>
          </Wrap>
        </ComponentWrapper>
      </form>
    </>
  );
};

export default inject(
  "exerciseStore",
  "propertyStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseForm));
