import React, { useEffect, useState } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface FeedNavListProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
}

const FeedNavList = ({
  studioStore,
  feedStore,
}: FeedNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { feedID } = useParams();
  const feed = feedStore?.feed?.data ?? [];
  const [isSearchActive, setIsSearchActive] = useState(false);

  const feedSearchResult = feedStore?.feedSearchResult ?? [];
  const feedConfigs = feedStore?.feedConfigs?.data ?? [];

  // if isSearch is active, use feedSearchResult otherwise use feed
  const feedToRender = isSearchActive ? feedSearchResult : feed;

  useEffect(() => {
    feedStore?.fetchAndSetFeed({});
    feedStore?.fetchAndSetFeedConfigs({});
  }, []);

  const _buildNewFeedPreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(`/studios/${studioStore?.studio?._id}/news-feed/new`);
          }
        }}
        title="Neuer Beitrag"
        imageUrl={feedStore?.currentFeed?.data?.coverImageUrl}
        subtitle="Erstelle einen neuen Beitrag"
        isActive
      />
    );
  };

  const _builFeedList = (): JSX.Element => {
    if (feedStore?.feed?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {feedID === "new" && _buildNewFeedPreview()}

          {feed.length === 0 && feedID !== "new" && (
            <ListPlaceholder
              title={
                feedConfigs.length > 0
                  ? "Du hast noch keine Beiträge hinzugefügt!"
                  : "Du hast noch keine Kategorien hinzugefügt!"
              }
              description={
                feedConfigs.length > 0
                  ? "Erstelle einen neuen Beitrag um deine Dienstleistungen zu präsentieren."
                  : "Erstelle zuerst eine Kategorie um Beiträge zu erstellen."
              }
              actionLabel="Neuen Beitrag erstellen"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  if (feedConfigs.length === 0) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/feed-configs`
                    );
                  } else {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/news-feed/new`
                    );
                  }
                }
              }}
            />
          )}

          {feedToRender.length === 0 &&
            feed.length > 0 &&
            isSearchActive &&
            feedID !== "new" && <NavListNoSearchResultComponent />}

          {feedToRender.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  index={i + 1}
                  imageUrl={getProperty(item, "coverImageUrl")}
                  title={getProperty(item, "title")}
                  subtitle={getProperty(item, "subTitle")}
                  isActive={item._id === feedID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/news-feed/${item._id}`
                      );
                    }
                  }}
                  rightLabel={
                    !item.isPublished ? (
                      <HighlightedInfoText color="warning">
                        ENTWURF
                      </HighlightedInfoText>
                    ) : (
                      <></>
                    )
                  }
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            color="secondary"
            label="Hinzufügen"
            disabled={feedConfigs.length === 0}
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(`/studios/${studioStore?.studio?._id}/news-feed/new`);
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Beitrag suchen"
          onChange={(value) => {
            if (value != null && value?.trim().length > 0 && feed?.length > 0) {
              setIsSearchActive(true);
              feedStore?.searchAndSetFeed(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_builFeedList()}
    </Column>
  );
};

export default inject("studioStore", "feedStore")(observer(FeedNavList));
