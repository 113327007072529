import React, { useEffect, useState } from "react";
import TrainingPlanExerciseList from "components/table.components/training.plan.exercise.list.component/training.plan.exercise.list.component";
import { inject, observer } from "mobx-react";
import TrainingPlanStore from "stores/training.plan.store";
import { useNavigate, useParams } from "react-router-dom";
import CustomerStore from "stores/customer.store";
import Column from "components/general.compoenents/column.component/column.component";
import { ModalStore } from "stores/modal.store";

interface CreateCustomerTrainingPlanProps {
  trainingPlanStore?: TrainingPlanStore;
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
}

const CreateCustomerTrainingPlan = ({
  trainingPlanStore,
  customerStore,
  modalStore,
}: CreateCustomerTrainingPlanProps): JSX.Element => {
  const { trainingPlanID, studioID, customerID } = useParams();
  const navigate = useNavigate();

  const [formKey, setFormKey] = useState(trainingPlanID);
  const currentTrainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  useEffect(() => {
    if (trainingPlanID == null) {
      return;
    }

    if (trainingPlanID === "new") {
      trainingPlanStore?.createInitialTrainingPlan({
        type: "CUSTOMER",
        user: customerStore?.currentCustomer?.data,
      });

      setFormKey(Math.random().toString());
    } else {
      trainingPlanStore?.fetchAndSetTrainingPlan({
        trainingPlanID,
      });
    }
  }, [trainingPlanID]);

  const handleSubmit = async (
    data: any,
    notifyCustomer?: boolean
  ): Promise<void> => {
    if (currentTrainingPlan == null) {
      return;
    }

    // Set isPublished by default to true on customer plan
    data = { ...data, isPublished: true };

    trainingPlanStore?.setCurrentTrainingPlan({
      ...currentTrainingPlan,
      ...data,
    });

    if (trainingPlanID === "new") {
      const createdTrainingPlan =
        await customerStore?.createCustomerTrainingPlan({
          trainingPlan: data,
          notifyCustomer,
        });

      // navigate to created device
      if (
        createdTrainingPlan?._id != null &&
        studioID != null &&
        customerID != null
      ) {
        // fetch and set customer training plans
        customerStore?.fetchAndSetCustomerTrainingPlans({
          customerID,
          refresh: true,
        });
        navigate(
          `/studios/${studioID}/customers/${customerID}/training-plans/${createdTrainingPlan._id}`
        );
      }
    } else {
      if (currentTrainingPlan._id != null && customerID != null) {
        await customerStore?.updateCustomerTrainingPlan({
          id: currentTrainingPlan?._id,
          trainingPlan: data,
          notifyCustomer,
        });

        // fetch and set customer training plans
        customerStore?.fetchAndSetCustomerTrainingPlans({
          customerID,
          refresh: true,
        });
      }
    }
  };

  if (
    currentTrainingPlan == null ||
    trainingPlanStore?.currentTrainingPlan?.isLoading
  ) {
    return <></>;
  }

  return (
    <Column>
      <TrainingPlanExerciseList
        type="CUSTOMER"
        key={formKey}
        useTrainingPlanSchema
        onSubmit={(data) => {
          if (trainingPlanID === "new") {
            modalStore?.showConfirmAlert({
              title: "Kunde Benachrichtigen?",
              description:
                "Möchten Sie den Kunden über den neuen Trainingsplan informieren?",
              confirmLabel: "Erstellen und Benachrichtigen",
              cancelLabel: "Erstellen",
              onConfirm: () => {
                handleSubmit(data, true);
              },
              onCancel: () => {
                handleSubmit(data);
              },
            });
          } else {
            modalStore?.showConfirmAlert({
              title: "Kunde Benachrichtigen?",
              description:
                "Möchten Sie den Kunden über die Änderungen an seinem Trainingsplan informieren?",
              confirmLabel: "Speichern und Benachrichtigen",
              cancelLabel: "Speichern",
              onConfirm: () => {
                handleSubmit(data, true);
              },
              onCancel: () => {
                handleSubmit(data);
              },
            });
          }
        }}
        isEditing={trainingPlanID !== "new"}
        onDirty={(isDirty) => {}}
        showBackButton
      />
    </Column>
  );
};

export default inject(
  "trainingPlanStore",
  "customerStore",
  "modalStore"
)(observer(CreateCustomerTrainingPlan));
