import { SmallText } from "components/text.components/small.text.component/small.text.component";
import React from "react";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import DateText from "components/text.components/date.text.component/date.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface archiveFields {
  header?: string;
  fieldPath: string;
  fieldType?: "image" | "text" | "date";
  flex?: number;
}

interface ArchiveListProps {
  items: any[];
  restoreOnClick: (item: any) => void;
  fields: archiveFields[];
  isLoading?: boolean;
}

const ArchiveList = ({
  items,
  restoreOnClick,
  fields,
  isLoading,
}: ArchiveListProps): JSX.Element => {
  return (
    <ListDataTable
      isLoading={isLoading}
      data={items}
      noDataMessage="Keine Archivierten Einträge vorhanden"
      columns={[
        ...fields.map((field) => {
          return {
            child: <RunningText>{field.header ?? ""}</RunningText>,
            flex: field?.flex ? field.flex : 1,
          };
        }),
        {
          child: <></>,
        },
      ]}
      dataTableItemBuilder={(dataItem: any, index: number) => {
        return {
          key: `archive-list-item-${index}`,
          children: [
            ...fields.map((field) => {
              if (field?.fieldType === "image") {
                return {
                  child: (
                    <Image
                      imageUrl={getProperty(dataItem, field.fieldPath)}
                      size={ImageSizes.S}
                    />
                  ),
                };
              }

              if (field?.fieldType === "date") {
                return {
                  child: (
                    <DateText format="DD.MM.YYYY">
                      {getProperty(dataItem, field.fieldPath)}
                    </DateText>
                  ),
                };
              }

              return {
                child: (
                  <SmallText>
                    {getProperty(dataItem, field.fieldPath)}
                  </SmallText>
                ),
              };
            }),
            {
              child: (
                <FilledButton
                  label="Wiederherstellen"
                  onClick={() => restoreOnClick(dataItem)}
                />
              ),
            },
          ],
        };
      }}
      gap={30}
      disableHeader={false}
    />
  );
};

export default ArchiveList;
