/* eslint-disable @typescript-eslint/no-misused-promises */
import { ModalStore, ModalType } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import EventStore from "stores/event.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes, ImageSizes } from "globals/enums/global.enum";
import Column from "components/general.compoenents/column.component/column.component";
import Image from "components/image.component/image.component";
import { formatDate, getWeekDayFromDate } from "globals/helpers/global.helper";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { useForm } from "react-hook-form";
import { eventSchema } from "schemas/events.schemas/event.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Headline from "components/text.components/headline.component/headline.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import BookingUserList from "pages/booking.pages/booking.detail.page/components/booking.user.list.component/booking.user.list.component";
import BookingStore from "stores/booking.store";
import TitleText from "components/text.components/title.text.component/title.text.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import {
  getEventTypeIcon,
  getStatusIcon,
  getStatusLabel,
} from "globals/helpers/calendar.helper";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import ServiceStore from "stores/service.store";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";

interface EventModalProps {
  eventStore?: EventStore;
  modalStore?: ModalStore;
  bookingStore?: BookingStore;
  serviceStore?: ServiceStore;
}

const EventModal = ({
  eventStore,
  modalStore,
  bookingStore,
  serviceStore,
}: EventModalProps): JSX.Element => {
  const currentEvent = eventStore?.currentPreparedCalendarEvent?.data?.event;
  const formatedDate = formatDate(currentEvent?.date, "DD.MM.YYYY");
  const weekDay = getWeekDayFromDate(currentEvent?.date ?? new Date());

  const isEditing = modalStore?.customData?.isEditing;

  const eventIsCanceled = isEditing && currentEvent?.status !== "ACTIVE";
  const readableStatus = eventIsCanceled ? "Abgesagt" : "Aktiv";
  const statusIcon = getStatusIcon(eventIsCanceled);
  const readableEventType = getStatusLabel(currentEvent?.type ?? "");

  const servies = serviceStore?.service?.data;

  const bookings = bookingStore?.currentBookingsForEvent?.data.filter(
    (booking) => {
      return booking.status === "BOOKED";
    }
  );

  const canceledBookings = bookingStore?.currentBookingsForEvent?.data?.filter(
    (booking) => {
      return booking.status === "CANCELED";
    }
  );

  useEffect(() => {
    bookingStore?.setCurrentBookingsForEvent([]);

    setFormIsDirty(false);

    // only fetch bookings for existing events (dynamic events have id null)
    if (currentEvent?._id != null) {
      bookingStore?.fetchAndSetCurrentBookingsForEvent({
        eventID: currentEvent._id,
      });
    }
  }, [currentEvent]);

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);
  const [activeTab, setActiveTab] = useState("info-tab");

  const handleTabClick = (key: string): void => {
    setActiveTab(key);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventSchema),
    mode: "onChange",
    defaultValues: currentEvent,
  });

  // handle service  form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (isEditing) {
      // check if event should be canceled or updated
      if (cancelEvent) {
        // set cancel event false and cancel event
        eventStore?.updateEvent({
          event: data,
          id: currentEvent?._id ?? "null",
          cancelEvent: true,
        });
        setCancelEvent(false);
      } else {
        // update event
        eventStore?.updateEvent({
          id: currentEvent?._id ?? "null",
          event: data,
        });
        setFormIsDirty(false);
      }
    } else {
      eventStore?.createEvent(data);
    }

    modalStore?.closeModal();
  };

  if (!currentEvent) {
    return <></>;
  }

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader className="mobile-row-break">
        <SizedContainer size={ContainerSizes.XML}>
          <Row alignItems="center">
            <Image
              size={ImageSizes.XL}
              className="mr-15"
              imageUrl={currentEvent?.service?.coverImageUrl}
            />
            <Column>
              <Headline>
                {isEditing ? currentEvent?.service?.title : "Neuer Termin"}
              </Headline>
              <RunningText className="mt-5">{formatedDate}</RunningText>

              <Row alignItems="center" className="mt-10">
                <FontAwesomeIcon icon={getEventTypeIcon(currentEvent?.type)} />
                <RunningText className="ml-5">{readableEventType}</RunningText>
              </Row>

              {eventIsCanceled && (
                <Row alignItems="center" className="mt-5">
                  <FontAwesomeIcon icon={statusIcon} />
                  <RunningText className="ml-10">{readableStatus}</RunningText>
                </Row>
              )}
            </Column>
          </Row>
        </SizedContainer>
        <Row justifyContent="flex-end" alignItems="flex-end">
          {isEditing && !eventIsCanceled && (
            <FilledButton
              label="Termin absagen"
              type="submit"
              form="event-form"
              color="danger"
              onClick={() => {
                setCancelEvent(true);
              }}
            />
          )}
          {!eventIsCanceled && (
            <FilledButton
              color="secondary"
              disabled={!formIsDirty}
              className="ml-15"
              label={isEditing ? "Speichern" : "Hinzufügen"}
              onClick={() => {
                setCancelEvent(false);
              }}
              type="submit"
              form="event-form"
            />
          )}
        </Row>
      </ModalHeader>
    );
  };

  const _buildBookedBookingList = (): JSX.Element => {
    return (
      <ComponentWrapper
        noPadding
        className="mb-15"
        title="Buchungen"
        actions={
          <FilledButton
            onClick={() => {
              if (currentEvent?.date != null) {
                // create initial booking item and open modal
                bookingStore?.createInitialBookingItem({
                  currentEvent,
                  date: new Date(currentEvent?.date),
                  timeSlotID: currentEvent?.timeSlotID ?? "null",
                });
                modalStore?.openModal(
                  ModalType.ADD_BOOKING_FOR_USER_MODAL,
                  undefined,
                  {
                    isCalenderEvent: true,
                  }
                );
              }
            }}
            disabled={eventIsCanceled}
            label="Buchung hinzufügen"
            key="add-booking"
          />
        }
      >
        <BookingUserList
          bookings={bookings ?? []}
          bookingAction={(bookingID: string) => {
            modalStore?.showConfirmAlert({
              title: "Buchung stornieren",
              description:
                "Möchtest du diese Buchung wirklich stornieren? Die Teilnehmer werden benachrichtigt.",
              confirmLabel: "Stornieren",
              onConfirm: async () => {
                bookingStore?.cancelBookingFromEvent({ bookingID });
              },
            });
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildUserBookingList = (): JSX.Element => {
    return (
      <>
        {_buildBookedBookingList()}
        {canceledBookings != null && canceledBookings.length > 0 && (
          <ComponentWrapper title="Stornierungen" noPadding>
            <BookingUserList bookings={canceledBookings} />
          </ComponentWrapper>
        )}
      </>
    );
  };

  const _buildEventForm = (): JSX.Element => {
    return (
      <form
        id="event-form"
        onSubmit={handleSubmit(onSubmit, (errors) => {
          toast.error("Bitte überprüfe deine Eingaben");
          setFormIsDirty(false);
        })}
        onChange={() => {
          if (!formIsDirty) {
            setFormIsDirty(true);
          }
        }}
      >
        <Row alignItems="center">
          <SizedContainer size={ContainerSizes.L}>
            <Column>
              <TitleText className="mb-10">Dienstleistung</TitleText>
              <SelectDropDown
                isLoading={serviceStore?.service?.isLoading}
                disabled={isEditing || eventIsCanceled}
                selectedItem={currentEvent?.service}
                labelPropertyName={"title"}
                valuePropertyName="_id"
                imagePropertyName="coverImageUrl"
                items={servies ?? []}
                onChange={(item) => {
                  setValue("service", item);

                  if (item?.color != null) {
                    setValue("color", item?.color);
                  }

                  if (item !== undefined) setFormIsDirty(true);
                }}
                inputRef={register("service")}
                validationMessage={getFormattedErrorMessage(
                  "service",
                  errors.service
                )}
              />
            </Column>
          </SizedContainer>
        </Row>

        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mt-25 mobile-row-break"
        >
          <SizedContainer size={ContainerSizes.S}>
            <Column>
              <TitleText className="mb-5">Wochentag</TitleText>
              <OutlinedTextInput
                className="mr-30"
                placeholder={weekDay}
                disabled
              />
            </Column>
          </SizedContainer>
          <SizedContainer size={ContainerSizes.L}>
            <Column>
              <TitleText className="mb-5">Zeit</TitleText>
              <Row alignItems="center" justifyContent="space-between">
                <OutlinedTextInput
                  disabled={eventIsCanceled}
                  className="mr-15"
                  type="time"
                  inputRef={register("startTime")}
                  validationMessage={errors?.startTime?.message?.toString()}
                />
                <OutlinedTextInput
                  disabled={eventIsCanceled}
                  className="mr-15"
                  type="time"
                  inputRef={register("endTime")}
                  validationMessage={errors?.endTime?.message?.toString()}
                />
              </Row>
            </Column>
          </SizedContainer>
          <SizedContainer size={ContainerSizes.S}>
            <Column>
              <TitleText className="mb-5">Kapazität</TitleText>
              <OutlinedTextInput
                disabled={eventIsCanceled}
                type="number"
                inputRef={register("maxParticipants")}
                validationMessage={errors?.maxParticipants?.message?.toString()}
              />
            </Column>
          </SizedContainer>
        </Row>

        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mt-25"
        >
          <Column>
            <TitleText className="mb-5">Zusatz Info</TitleText>
            <OutlinedTextArea
              disabled={eventIsCanceled}
              rows={7}
              placeholder="Notiz"
              inputRef={register("info")}
              validationMessage={errors?.info?.message?.toString()}
            ></OutlinedTextArea>
          </Column>
        </Row>

        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mt-25"
        >
          <Column>
            <TitleText>Termin Eigenschaften</TitleText>
            <ColorPicker
              className="mt-10"
              name="color"
              inputRef={register}
              defaultValue={getValues("color") ?? "#313634e0"}
              validationMessage={errors.color?.message?.toString()}
              onChange={(color: any) => {
                setValue("color", color);
                setFormIsDirty(true);
              }}
            />
          </Column>
        </Row>
      </form>
    );
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container">{_buildTopBar()}</div>
      <div className="selection-modal-body-container">
        <LinkTabs
          tabs={[
            <Tab
              className="ml-30"
              active={activeTab === "info-tab"}
              label="Informationen"
              key="info-tab"
              onClick={() => {
                handleTabClick("info-tab");
              }}
            />,
            <Tab
              active={activeTab === "bookings-tab"}
              disabled={!isEditing}
              label="Buchungen"
              key="bookings-tab"
              onClick={() => {
                handleTabClick("bookings-tab");
              }}
            />,
          ]}
        >
          <PageContainer>
            {activeTab === "info-tab" && _buildEventForm()}
            {activeTab === "bookings-tab" && _buildUserBookingList()}
          </PageContainer>
        </LinkTabs>
      </div>
    </div>
  );
};

export default inject(
  "eventStore",
  "modalStore",
  "bookingStore",
  "serviceStore"
)(observer(EventModal));
