import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExerciseStore from "stores/exercise.store";
import { infoListSchema } from "schemas/exercise.schemas/exercise.schema";
import { arrayMoveImmutable } from "array-move";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import ExerciseInfoContent from "./exercise.info.content.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import StudioStore from "stores/studio.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { CollectionDataType } from "globals/enums/global.enum";
import { ModalStore, ModalType } from "stores/modal.store";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import { toast } from "react-toastify";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";

interface ExerciseInfoDetailProps {
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  onDirty: (isDirty: boolean) => void;
}

const ExerciseInfoDetail = ({
  exerciseStore,
  studioStore,
  modalStore,
  onDirty,
}: ExerciseInfoDetailProps): JSX.Element => {
  const navigate = useNavigate();
  const { topicID } = useParams();
  const currentLanguage = studioStore?.currentLanguage;
  const currentLanguages = studioStore?.studio?.localizations ?? [];

  const [formIsDirty, setFormIsDirty] = useState(false);

  const exercise = exerciseStore?.currentExercise?.data;
  const currentTopic = exercise?.infos![topicID != null ? +topicID : 0];

  const topicData = (currentTopic?.data ?? []).map((data) => {
    return { ...data, id: Math.random().toString() };
  });

  const { register, formState, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(infoListSchema(currentLanguages)),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: currentTopic,
  });

  useEffect(() => {
    onDirty(formIsDirty);
  }, [formIsDirty]);

  const onSubmit = async (data: any): Promise<void> => {
    if (exercise == null) {
      return;
    }

    const updatedInfos = exercise.infos;
    updatedInfos![topicID != null ? +topicID : 0] = data;

    exerciseStore?.setCurrentExercise({
      ...exercise,
      infos: updatedInfos,
    });

    if (exercise.type !== CollectionDataType.TEMPLATE) {
      await exerciseStore?.updateExercise({
        id: exercise._id!,
        exercise: {
          infos: updatedInfos,
        } as any,
      });
    } else {
      // when exercise is template open version open version selection modal
      modalStore?.openModal(
        ModalType.EXERCISE_TEMPLATE_VERSION_SELECTION_MODAL
      );
    }

    setFormIsDirty(false);
  };

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    const sortedData = arrayMoveImmutable(
      getValues("data"),
      oldIndex,
      newIndex
    );

    setValue("data", sortedData);

    setFormIsDirty(true);
  };

  const buildHeadline = (): JSX.Element => {
    return (
      <PageHeader showBackButton label="Übungsinformation bearbeiten">
        <FilledButton
          color="danger"
          label="Element löschen"
          className="ml-15"
          onClick={() => {
            modalStore?.showConfirmAlert({
              onConfirm: async () => {
                exerciseStore?.removeExerciseInfoListItem(+topicID!);
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/exercises/${exercise?._id}/topics`
                  );
                }
              },
              confirmLabel: "Löschen",
              title: "Info Element löschen",
              description: "Möchtest du dieses Element wirklich löschen?",
            });
          }}
        />
        <FilledButton
          disabled={!formIsDirty}
          label="Speichern"
          color="secondary"
          type="submit"
          className="ml-15"
        />
      </PageHeader>
    );
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) setFormIsDirty(true);
      }}
    >
      {buildHeadline()}
      <ComponentWrapper className="mb-15">
        <OutlinedTextInput
          placeholder="Titel"
          inputRef={register(`label.${currentLanguage!.value}`)}
          validationMessage={
            formState.errors.label?.[currentLanguage!.value]?.message
          }
        />
      </ComponentWrapper>
      <ComponentWrapper>
        <SortableList
          data={topicData}
          onSortEnd={onSortEnd}
          itemBuilder={(data, index) => {
            return (
              <>
                <ExerciseInfoContent
                  disableDelete={topicData.length === 1}
                  data={data}
                  index={index}
                  register={register}
                  formState={formState}
                  onDeleted={(indexItem: number) => {
                    const updatedInfoTopic = getValues("data").filter(
                      (_, i) => i !== indexItem
                    );

                    setValue("data", updatedInfoTopic);

                    const updatedTopic = {
                      ...currentTopic!,
                      data: updatedInfoTopic,
                    };

                    const updatedInfos = exercise?.infos;
                    updatedInfos![topicID != null ? +topicID : 0] =
                      updatedTopic;

                    exerciseStore?.setCurrentExercise({
                      ...exercise!,
                      infos: updatedInfos ?? [],
                    });

                    setFormIsDirty(true);
                  }}
                />
                {index !== topicData.length - 1 && (
                  <Spacer className="mb-20 mt-20" />
                )}
              </>
            );
          }}
        />
        <LinkButton
          label="Punkt hinzufügen"
          disabled={
            currentTopic?.data != null ? currentTopic?.data.length >= 20 : false
          }
          onClick={() => {
            exerciseStore?.addNewExerciseInfoDataItem(
              topicID != null ? +topicID : 0
            );
          }}
        />
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "exerciseStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseInfoDetail));
