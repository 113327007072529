import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import { formatDate } from "globals/helpers/global.helper";
import { ColorInputReadOnly } from "components/input.components/color.input.component/color.input.component";

interface TrainingPlanListProps {
  trainingPlans: TrainingPlan[];
  onClick?: (dataItem: TrainingPlan) => void;
  noDataMessage: string;
}

const TrainingPlanList = ({
  trainingPlans,
  onClick,
  noDataMessage,
}: TrainingPlanListProps): JSX.Element => {
  return (
    <ListDataTable
      noDataMessage={noDataMessage}
      onClick={onClick}
      data={trainingPlans}
      columns={[
        {
          child: <></>,
        },
        {
          child: <RunningText>Name</RunningText>,
          flex: 2,
        },
        {
          child: <RunningText>Übungen</RunningText>,
        },
        {
          child: <RunningText>Rythmus</RunningText>,
        },
        {
          child: <RunningText>Geändert am </RunningText>,
        },
      ]}
      dataTableItemBuilder={(dataItem: TrainingPlan) => {
        return {
          key: dataItem._id ?? `trainingplan-${dataItem.title}`,
          children: [
            {
              child: <ColorInputReadOnly value={dataItem?.coverColor ?? ""} />,
            },
            { child: <RunningText>{dataItem.title}</RunningText> },
            { child: <RunningText>{dataItem.exercises.length}</RunningText> },
            {
              child: <RunningText>{dataItem.rhythm ?? "-"}</RunningText>,
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem.system?.modifiedAt, "DD.MM.YYYY")}
                </RunningText>
              ),
            },
          ],
        };
      }}
    />
  );
};

export default TrainingPlanList;
