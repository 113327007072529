import { Studio } from "schemas/studio.schemas/studio.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpGymoCustomerService extends GenericHttpClient<Studio> {
  static _instance: HttpGymoCustomerService;
  static getInstance(): HttpGymoCustomerService {
    if (this._instance == null) {
      this._instance = new HttpGymoCustomerService("/gymo-customers");
    }
    return this._instance;
  }

  async getAccessTokenForStudioDemoUser(studioID: string): Promise<any> {
    const response = await this.get(
      `/gymo-customers/${studioID}/app-access-token`
    );
    return response.data;
  }

  async getAccessTokenForStudioDashboardUser(studioID: string): Promise<any> {
    const response = await this.get(
      `/gymo-customers/${studioID}/dashboard-access-token`
    );
    return response.data;
  }

  async getAccessTokenForStudioAppUser(args: {
    studioID: string;
    userID: string;
  }): Promise<any> {
    const response = await this.get(
      `/gymo-customers/${args.studioID}/${args.userID}/access-token`
    );
    return response.data;
  }

  async publishApp(args: {
    studioID: string;
    workflow?: string;
  }): Promise<any> {
    const response = await this.post(
      `/gymo-customers/${args.studioID}/publish-app`,
      {},
      { params: { workflow: args.workflow } }
    );
    return response.data;
  }

  async getGymoCustomerAppUsers(args: {
    studioID: string;
    query: any;
  }): Promise<any> {
    const response = await this.get(
      `/gymo-customers/${args.studioID}/app-users`,
      { params: args.query }
    );
    return response.data;
  }
}
