import React, { useEffect, useState } from "react";
import { ModalStore } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import ExerciseStore from "stores/exercise.store";
import { checkIfVersionIsValid } from "globals/helpers/validation.helper";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import ModalHeader from "../modal.header.component/modal.header.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";

interface TemplateVersionSelectionModalProps {
  modalStore?: ModalStore;
  exerciseStore?: ExerciseStore;
}

const TemplateVersionSelectionModal = ({
  modalStore,
  exerciseStore,
}: TemplateVersionSelectionModalProps): JSX.Element => {
  const [currentVersion, setCurrentVersion] = useState(
    "Keine Version zugewiesen"
  );

  const [newVersion, setNewVersion] = useState("");

  const currentExercise = exerciseStore?.currentExercise?.data;

  // save exercise with new version
  const handleSaveExerciseWithNewVersion = async (data: any): Promise<void> => {
    data.preventDefault();

    if (newVersion == null || currentExercise == null) {
      return;
    }

    // set new version to current exercise
    setProperty(currentExercise, "templateVersion", newVersion);
    exerciseStore?.setCurrentExercise(currentExercise);

    // update exercise and close modal
    await exerciseStore?.updateExercise({
      id: currentExercise._id!,
      exercise: currentExercise,
    });
    modalStore?.closeModal();
  };

  // save exercise with current version
  const handleSaveExercise = async (data: any): Promise<void> => {
    data.preventDefault();

    if (currentExercise == null) {
      return;
    }

    // update exercise with same version and close modal
    await exerciseStore?.updateExercise({
      id: currentExercise._id!,
      exercise: currentExercise,
    });

    modalStore?.closeModal();
  };

  useEffect(() => {
    if (currentExercise != null) {
      setCurrentVersion(
        currentExercise?.templateVersion ?? "Keine Version zugewiesen"
      );
    }
  }, []);

  return (
    <Column>
      <ModalHeader
        title="Template Version"
        description="Hier kannst du die Version des Templates ändern oder die Änderungen mit
        der aktuellen Version speichern."
      >
        <Row className="mt-15" justifyContent="flex-end">
          <FilledButton
            label="Speicher mit aktueller Version"
            color="secondary"
            className="mr-10"
            onClick={(event: any) => {
              handleSaveExercise(event);
            }}
          />
          <FilledButton
            disabled={
              !checkIfVersionIsValid({
                version: currentVersion,
                newVersion,
              })
            }
            label="Speichern mit neuer Version"
            onClick={(event: any) => {
              handleSaveExerciseWithNewVersion(event);
            }}
          />
        </Row>
      </ModalHeader>
      <PageContainer>
        <Row>
          <OutlinedTextInput
            disabled={true}
            className="mr-20"
            label="Aktuelle Version"
            value={currentVersion}
          />
          <OutlinedTextInput
            value={newVersion}
            label="Neue Version"
            onChange={(value) => {
              if (value == null) {
                return;
              }
              setNewVersion(value);
            }}
          />
        </Row>
      </PageContainer>
    </Column>
  );
};

export default inject(
  "modalStore",
  "exerciseStore"
)(observer(TemplateVersionSelectionModal));
