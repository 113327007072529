import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";
import { ModalStore } from "stores/modal.store";
import PropertyStore from "stores/property.store";
import PropertyForm from "./components/property.form";

interface PropertyDetailPageProps {
  propertyStore?: PropertyStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const PropertyDetailPage = ({
  propertyStore,
  studioStore,
  modalStore,
}: PropertyDetailPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { propertyID } = useParams();
  const [isEditing, setIsEditing] = useState(propertyID !== "new");

  const currentProperty = propertyStore?.currentProperty?.data;

  useEffect(() => {
    initializePage();
  }, [propertyID]);

  const initializePage = async (): Promise<void> => {
    if (propertyID == null) {
      return;
    }

    if (propertyID !== "new") {
      propertyStore?.fetchAndSetProperty({ propertyID });
      setIsEditing(true);
    }

    if (propertyID === "new") {
      propertyStore?.createInitialProperty();
      setIsEditing(false);
    }
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="properties"
        actions={[
          propertyID !== "new" ? (
            <FilledButton
              key="property-archive-button"
              label={"Property entfernen"}
              color={"danger"}
              onClick={() => {
                modalStore?.showConfirmAlert({
                  onConfirm: async () => {
                    if (currentProperty?._id != null) {
                      propertyStore?.archiveProperty({
                        property: currentProperty,
                      });

                      if (studioStore?.studio?._id != null) {
                        navigate(
                          `/studios/${studioStore?.studio?._id}/properties`
                        );
                      }
                    }
                  },
                  confirmLabel: "Entfernen",
                  title: "Property entfernen",
                  description:
                    "Möchtest du dieses Property wirklich entfernen?",
                });
              }}
            />
          ) : (
            <></>
          ),
        ]}
      ></PageHeadline>
    );
  };

  if (propertyStore?.currentProperty?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={propertyID === "new" ? Math.random() : propertyID}>
        <PropertyForm isEditing={isEditing} />
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "propertyStore",
  "studioStore",
  "modalStore"
)(observer(PropertyDetailPage));
