import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { Booking, bookingToJson } from "schemas/booking.schemas/booking.schema";
import { Event } from "schemas/events.schemas/event.schema";
import { HttpBookingService } from "services/httpClients/http.booking.client";
import EventStore from "./event.store";

class BookingStore {
  private readonly _eventStore: EventStore = new EventStore();

  // Properties
  private _bookingDataList: PaginationDataList<Booking> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentBookingsForEventDataList: PaginationDataList<Booking> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _initialBooking: DataItem<Booking> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setBookings = (bookings: Booking[]): void => {
    this._bookingDataList.data = bookings;
  };

  setInitialBooking = (booking: Booking): void => {
    this._initialBooking.data = booking;
  };

  setCurrentBookingsForEvent = (booking: Booking[] | undefined): void => {
    if (booking == null) {
      return;
    }
    this._currentBookingsForEventDataList.data = booking;
  };

  //! Getters
  get booking(): PaginationDataList<Booking> | undefined {
    if (this._bookingDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._bookingDataList));
  }

  get initialBooking(): DataItem<Booking> | undefined {
    if (this._initialBooking == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._initialBooking));
  }

  get currentBookingsForEvent(): PaginationDataList<Booking> | undefined {
    if (this._currentBookingsForEventDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentBookingsForEventDataList));
  }

  //! Methods
  cancelBookingFromEvent = async (args: {
    bookingID: string;
  }): Promise<void> => {
    try {
      this._currentBookingsForEventDataList.isLoading = true;

      const canceldBooking =
        await HttpBookingService.getInstance().cancelBookingFromEvent({
          bookingID: args.bookingID,
        });

      if (canceldBooking == null) {
        this._currentBookingsForEventDataList.isLoading = false;
        return;
      }

      // remove the booking from the list of bookings and update the list
      const bookings = this.currentBookingsForEvent?.data.slice() ?? [];
      const filteredBookings = bookings?.filter(
        (booking: Booking) => booking._id !== args.bookingID
      );
      this.setCurrentBookingsForEvent([...filteredBookings, canceldBooking]);

      this._currentBookingsForEventDataList.isLoading = false;
    } catch (err) {
      this._currentBookingsForEventDataList.isLoading = false;

      Logging.error({
        className: "BookingStore",
        methodName: "cancelBookingFromService",
        message: "Booking konnte nicht storniert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialBookingItem = (args: {
    currentEvent: Event;
    date: Date;
    timeSlotID: string;
  }): Booking => {
    const initialBookingItem: any = {
      event: args.currentEvent,
      date: args.date,
      timeSlotID: args.timeSlotID,
      status: "BOOKED",
    };

    this.setInitialBooking(initialBookingItem);

    return initialBookingItem;
  };

  addBookingToEvent = async (args: {
    booking: Booking;
    eventID: string;
    updateGroupedEvents?: boolean;
  }): Promise<void> => {
    try {
      this._currentBookingsForEventDataList.isLoading = true;

      // create booking
      const createdBooking = await HttpBookingService.getInstance().addBooking({
        booking: {
          ...bookingToJson(args.booking),
          service: args.booking?.event?.service?._id,
        },
        eventID: args.eventID,
      });

      if (createdBooking == null) {
        toast.error("Buchung konnte nicht erstellt werden");
        this._currentBookingsForEventDataList.isLoading = false;
        return;
      }

      // if event ist a dynamic event the server will return the booking with the created event
      // and then we need to update the list of events
      if (args.eventID == null || args.eventID?.toLowerCase() === "null") {
        this._eventStore.updateEventByIdOrTimeslotAndDate({
          eventID: args.eventID,
          date: args.booking.event.date,
          timeSlotID: args.booking.event.timeSlotID ?? "",
          updatedEvent: createdBooking.event,
          updateGroupedEvents: args.updateGroupedEvents ?? false,
        });
      }

      // add the created booking to the list of bookings
      const updatedBookings = this.currentBookingsForEvent?.data.slice() ?? [];
      updatedBookings.push(createdBooking);
      this.setCurrentBookingsForEvent(updatedBookings);

      toast.success("Buchung erfolgreich erstellt");

      this._currentBookingsForEventDataList.isLoading = false;
    } catch (err) {
      this._currentBookingsForEventDataList.isLoading = false;

      Logging.error({
        className: "BookingStore",
        methodName: "addBookingToService",
        message: "Booking konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCurrentBookingsForEvent = async (args: {
    eventID: string;
  }): Promise<void> => {
    try {
      this._currentBookingsForEventDataList.isLoading = true;

      const bookings = await HttpBookingService.getInstance().getBookings({
        eventID: args.eventID,
        status: "ALL",
      });

      if (bookings == null) {
        this._currentBookingsForEventDataList.isLoading = false;
        return;
      }

      this.setCurrentBookingsForEvent(bookings);
      this._currentBookingsForEventDataList.isLoading = false;
    } catch (err) {
      this._currentBookingsForEventDataList.isLoading = false;

      Logging.error({
        className: "BookingStore",
        methodName: "getBookingsFromServiceForDateAndTimeSlot",
        message: "Booking konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default BookingStore;
