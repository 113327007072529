import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ArchiveList from "components/table.components/archive.list.component/archive.list.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import { toast } from "react-toastify";
import ServiceStore from "stores/service.store";
import { Service } from "schemas/service.schemas/service.schema";
import { HttpServiceProvider } from "services/httpClients/http.service.client";

interface ArchiveServiceTabProps {
  serviceStore?: ServiceStore;
}

const ArchiveServiceTab = ({
  serviceStore,
}: ArchiveServiceTabProps): JSX.Element => {
  const archivedServices = serviceStore?.archivedServices?.data;
  const isLoading = serviceStore?.archivedServices?.isLoading;

  useEffect(() => {
    serviceStore?.fetchAndSetArchivedServices();
  }, []);

  if (archivedServices == null) {
    return <></>;
  }

  const _handleRestore = async (item: Service): Promise<void> => {
    if (item._id != null) {
      const response = await HttpServiceProvider.getInstance().unarchiveOne({
        id: item._id,
      });

      if (response != null) {
        serviceStore?.fetchAndSetArchivedServices();
        toast.success("Service wurde wiederhergestellt");
      }
    }
  };

  return (
    <>
      <Headline className="mb-10 mt-20">Kurse</Headline>
      <ComponentWrapper noPadding>
        <ArchiveList
          isLoading={isLoading}
          items={archivedServices}
          fields={[
            {
              fieldPath: "coverImageUrl",
              fieldType: "image",
            },
            { fieldPath: "title", header: "Titel" },
            {
              header: "Archivert am",
              fieldPath: "system.archivedAt",
              fieldType: "date",
            },
          ]}
          restoreOnClick={(item: Service) => {
            _handleRestore(item);
          }}
        />
      </ComponentWrapper>
    </>
  );
};

export default inject("serviceStore")(observer(ArchiveServiceTab));
