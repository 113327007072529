import { GenericHttpClient } from "./config/http.generic.client";
import { Booking } from "schemas/booking.schemas/booking.schema";

export class HttpBookingService extends GenericHttpClient<Booking> {
  static _instance: HttpBookingService;
  static getInstance(): HttpBookingService {
    if (this._instance == null) {
      this._instance = new HttpBookingService("/bookings");
    }
    return this._instance;
  }

  async addBooking(args: { booking: any; eventID: string }): Promise<Booking> {
    const response = await this.post(
      `/bookings/events/${args.eventID}`,
      args.booking
    );
    return response.data as Booking;
  }

  async cancelBookingFromEvent(args: { bookingID: string }): Promise<Booking> {
    const response = await this.put(`/bookings/${args.bookingID}/cancel`);
    return response.data as Booking;
  }

  //! new logic
  async getBookings(args: {
    eventID: string;
    serviceID?: string;
    fromDate?: Date;
    status?: string;
  }): Promise<Booking[]> {
    // if a serviceID is provided, we will filter the bookings by that service
    const query: any =
      args.serviceID != null ? { service: args.serviceID } : {};

    // if a fromDate is provided, we will filter the bookings by that date
    if (args.fromDate != null) {
      query.date = args.fromDate;
    }

    if (args.status != null) {
      query.status = args.status;
    }

    const response = await this.get(`/bookings/events/${args.eventID}`, {
      params: query,
    });
    return response.data as Booking[];
  }
}
