import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import Column from "../../general.compoenents/column.component/column.component";
import Row from "../../general.compoenents/row.component/row.component";
import "./page.placeholder.component.scss";

interface PlaceholderProps {
  imagePath?: string;
  title: string;
  titleNothingSelected?: string;
  description: string;
  descriptionNothingSelected?: string;
  data?: any[];
  actions?: JSX.Element | JSX.Element[];
  noDataActions?: JSX.Element | JSX.Element[];
  imageMaxWidth?: number;
}

const Placeholder = ({
  imagePath,
  title,
  titleNothingSelected,
  description,
  descriptionNothingSelected,
  data = [],
  actions,
  noDataActions,
  imageMaxWidth = 600,
}: PlaceholderProps): JSX.Element => {
  return (
    <Column justifyContent="center" alignItems="center">
      {imagePath && (
        <img
          style={{ maxWidth: imageMaxWidth }}
          className="placeholder-image mb-25"
          src={imagePath}
          alt="placeholder"
        />
      )}
      <Headline className="mb-10">
        {data.length > 0 ? titleNothingSelected : title}
      </Headline>
      <RunningText>
        {data.length > 0 ? descriptionNothingSelected : description}
      </RunningText>
      {data.length === 0 && (
        <Row justifyContent="center" className="mt-15">
          {noDataActions}
        </Row>
      )}
      <Row justifyContent="center">{actions}</Row>
    </Column>
  );
};

export default Placeholder;
