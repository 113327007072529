import React, { useEffect, useState } from "react";
import TrainingPlanStore from "stores/training.plan.store";
import { inject, observer } from "mobx-react";
import "./training.plan.exercise.list.component.scss";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TrainingPlanExerciseListItem from "./components/training.plan.exercise.list.item.component";
import { arrayMoveImmutable } from "array-move";
import { planExerciseSchema } from "schemas/training.plan.schemas/plan.exercise.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { ModalStore, ModalType } from "stores/modal.store";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import { toast } from "react-toastify";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";

const trainingPlanFormSchema = yup.object().shape({
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  rhythm: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  duration: yup
    .number()
    .integer()
    .min(1, "Dauer muss mindestens 1 sein")
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  exercises: yup.array().of(planExerciseSchema).required().default([]),
});

const exerciseListFormSchema = yup.object().shape({
  exercises: yup.array().of(planExerciseSchema).required(),
});

export interface ExerciseListFormSchema
  extends yup.InferType<typeof exerciseListFormSchema> {}

interface TrainingPlanExerciseListProps {
  trainingPlanStore?: TrainingPlanStore;
  modalStore?: ModalStore;
  onDirty: (isDirty: boolean) => void;
  isEditing: boolean;
  onSubmit: (data: any) => void;
  showBackButton?: boolean;
  useTrainingPlanSchema?: boolean;
  type?: "STUDIO" | "CUSTOMER";
}

const TrainingPlanExerciseList = ({
  trainingPlanStore,
  modalStore,
  onDirty,
  isEditing = false,
  showBackButton,
  onSubmit,
  type = "STUDIO",
  useTrainingPlanSchema = false,
}: TrainingPlanExerciseListProps): JSX.Element => {
  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;
  const planExercises = trainingPlan?.exercises ?? [];

  const [formIsDirty, setFormIsDirty] = useState(false);

  const [openIndex, setOpenIndex] = useState(0);

  const handleOpen = (index: number): void => {
    setOpenIndex(index);
  };

  useEffect(() => {
    // Automatically open collapsable
    setOpenIndex(openIndex);
  }, [planExercises]);

  const handleRemoveExercise = (exerciseIndex: number): void => {
    const exercises = getValues("exercises");

    // check if the exercise with the index exists
    if (exercises[exerciseIndex] == null) {
      return;
    }

    // remove the exercise from the list
    exercises.splice(exerciseIndex, 1);

    if (trainingPlanStore?.currentTrainingPlan?.data != null) {
      // update the form
      setValue("exercises", exercises);

      // update the training plan store
      trainingPlanStore?.setCurrentTrainingPlan({
        ...trainingPlanStore?.currentTrainingPlan?.data,
        exercises,
      });
    }
  };

  const handleRemoveSetFromExercise = (
    exerciseIndex: number,
    setIndex: number
  ): void => {
    const exercises = getValues("exercises");

    // check if the set with the index exists
    if (exercises[exerciseIndex]?.sets[setIndex] == null) {
      return;
    }

    // remove the set from the exercise
    exercises[exerciseIndex].sets.splice(setIndex, 1);

    if (trainingPlanStore?.currentTrainingPlan?.data != null) {
      // update the form
      setValue("exercises", exercises);

      // update the training plan store
      trainingPlanStore?.setCurrentTrainingPlan({
        ...trainingPlanStore?.currentTrainingPlan?.data,
        exercises,
      });
    }
  };

  const handleAddSetToExercise = (exerciseIndex: number): void => {
    if (!trainingPlan) return;

    const planExercises = getValues("exercises");

    // get the exercise with the index
    const exercise = planExercises[exerciseIndex];
    let newSet = {
      reps: 3,
      weight: 0,
      type: "WEIGHT_SET",
      set: exercise.sets.length + 1,
    };

    // if exerxise has copy the last set
    // otherwise create a new set with default values
    if (exercise.sets.length > 0) {
      const lastSet = exercise.sets[exercise.sets.length - 1];

      newSet = {
        reps: lastSet.reps,
        weight: lastSet.weight,
        type: "WEIGHT_SET",
        set: exercise.sets.length + 1,
      };
    }

    const newSets = [...exercise.sets, newSet];

    // find the exercise in the list and replace the sets with the new sets
    const newPlanExercises = planExercises.map((planExercise, index) => {
      if (index === exerciseIndex) {
        return {
          ...planExercise,
          sets: newSets,
        };
      }

      return planExercise;
    });

    if (newPlanExercises !== null) {
      setValue("exercises", newPlanExercises as any);

      trainingPlanStore?.setCurrentTrainingPlan({
        ...trainingPlan,
        exercises: newPlanExercises,
      } as any);
    }
  };

  const { register, formState, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(
      useTrainingPlanSchema ? trainingPlanFormSchema : exerciseListFormSchema
    ),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: useTrainingPlanSchema
      ? {
          ...trainingPlan!,
          type,
          isPublished: false,
        }
      : { exercises: planExercises, type },
  });

  const { errors } = formState;

  useEffect(() => {
    onDirty(formIsDirty);
  }, [formIsDirty]);

  useEffect(() => {
    setValue("exercises", planExercises);
  }, [planExercises]);

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    if (!planExercises) return;

    const sortedPlanExercises: any = arrayMoveImmutable(
      getValues("exercises"),
      oldIndex,
      newIndex
    );

    setValue("exercises", sortedPlanExercises);

    trainingPlanStore?.setCurrentTrainingPlan({
      ...trainingPlan,
      exercises: sortedPlanExercises,
    } as any);

    setFormIsDirty(true);
  };

  const _buildNoExericsesMessage = (): JSX.Element => {
    return (
      <div className="training-plan-list-container">
        <ComponentWrapper noPadding>
          <RunningText className="ml-15 mt-15 mb-15 mr-15">
            Diesem Trainingsplan wurden noch keine Übungen hinzugefügt.
          </RunningText>
        </ComponentWrapper>
      </div>
    );
  };

  const _buildExercises = (): JSX.Element => {
    return (
      <ComponentWrapper noPadding>
        <SortableList
          data={planExercises.map((exercise) => {
            return { ...exercise, id: exercise._id ?? exercise.exercise._id };
          })}
          onSortStart={() => {
            handleOpen(-1);
          }}
          onSortEnd={onSortEnd}
          itemBuilder={(data, index) => {
            return (
              <>
                <TrainingPlanExerciseListItem
                  planExercise={data}
                  exerciseIndex={index}
                  register={register}
                  formState={formState}
                  isOpened={index === openIndex}
                  onOpening={() => handleOpen(index)}
                  onRemoveSet={(exerciseIndex: number, setIndex: number) => {
                    handleRemoveSetFromExercise(exerciseIndex, setIndex);
                    setFormIsDirty(true);
                  }}
                  onRemoveExercise={(exerciseIndex: number) => {
                    handleRemoveExercise(exerciseIndex);
                    setFormIsDirty(true);
                  }}
                  onAddSet={(exerciseIndex: number) => {
                    handleAddSetToExercise(exerciseIndex);
                    setFormIsDirty(true);
                    handleOpen(index);
                  }}
                  setFormIsDirty={setFormIsDirty}
                />
                {index !== planExercises.length - 1 && <Spacer />}
              </>
            );
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildCustomerTrainingPlanInfo = (): JSX.Element => {
    return (
      <ComponentWrapper className="mb-15">
        <Wrap>
          <OutlinedTextInput
            label="Titel"
            className="training-plan-info-input mr-15"
            inputRef={register("title")}
            validationMessage={(errors as any)?.title?.message?.toString()}
            onChange={(value) => {
              if (value != null) {
                trainingPlan!.title = value;
              }
            }}
          />
          <OutlinedTextInput
            label="Subtitle"
            className="training-plan-info-input"
            inputRef={register("subTitle")}
            validationMessage={(errors as any).subTitle?.message?.toString()}
            onChange={(value) => {
              if (value != null) {
                trainingPlan!.subTitle = value;
              }
            }}
          />
          <OutlinedTextInput
            type="number"
            label="Wochenrhythmus"
            className="training-plan-info-input mr-15"
            inputRef={register("rhythm")}
            validationMessage={(errors as any).rhythm?.message?.toString()}
            onChange={(value) => {
              if (value != null) {
                trainingPlan!.rhythm = +value;
              }
            }}
          />

          <OutlinedTextInput
            type="number"
            label="Dauer in Minuten"
            className="training-plan-info-input"
            inputRef={register("duration")}
            validationMessage={(errors as any).duration?.message?.toString()}
            onChange={(value) => {
              if (value != null) {
                trainingPlan!.duration = +value;
              }
            }}
          />

          <ColorPicker
            name="color"
            label="Farbe"
            inputRef={register("coverColor")}
            defaultValue={getValues("coverColor") ?? "#313634e0"}
            validationMessage={(errors as any).coverColor?.message?.toString()}
            onChange={(color: any) => {
              setValue("coverColor", color);
              setFormIsDirty(true);
            }}
          />
        </Wrap>
      </ComponentWrapper>
    );
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(
        (data) => {
          onSubmit(data);
          setFormIsDirty(false);
        },
        (errors) => {
          toast.error("Bitte überprüfe deine Eingaben");
          setFormIsDirty(false);
        }
      )}
      onChange={() => {
        if (!formIsDirty) setFormIsDirty(true);
      }}
    >
      <PageHeader
        label={
          isEditing ? "Trainingsplan bearbeiten" : "Trainingsplan erstellen"
        }
        showBackButton={showBackButton}
      >
        <FilledButton
          className="mr-15"
          label="Übung hinzufügen"
          color="secondary"
          onClick={() => {
            modalStore?.openModal(ModalType.PLAN_EXERCISE_SELECTION_MODAL);
            setFormIsDirty(true);
          }}
        />
        <FilledButton
          disabled={!formIsDirty}
          label={isEditing ? "Speichern" : "Erstellen"}
          type="submit"
          color="secondary"
        />
      </PageHeader>
      {useTrainingPlanSchema && _buildCustomerTrainingPlanInfo()}
      {planExercises == null ||
        (planExercises.length === 0
          ? _buildNoExericsesMessage()
          : _buildExercises())}
    </form>
  );
};

export default inject(
  "modalStore",
  "trainingPlanStore"
)(observer(TrainingPlanExerciseList));
