import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import Column from "../column.component/column.component";
import Row from "../row.component/row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faBars } from "@fortawesome/pro-regular-svg-icons";
import "./select.list.item.component.scss";

interface SelectListItemProps {
  title: string;
  subTitle: string;
  draggable?: boolean;
  onClick: () => void;
}

const SelectListItem = ({
  title,
  subTitle,
  draggable = false,
  onClick,
}: SelectListItemProps): JSX.Element => {
  return (
    <Row
      className="pt-20 pb-20 pl-20 pr-20 cursor-pointer hoverable"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
    >
      <Column>
        <TitleText className="mb-5">{title}</TitleText>
        <RunningText>{subTitle}</RunningText>
      </Column>
      <Row className="select-list-item-action-row">
        {draggable && (
          <FontAwesomeIcon icon={faBars} className="cursor-pointer" />
        )}

        <FontAwesomeIcon className="ml-15" icon={faChevronRight} />
      </Row>
    </Row>
  );
};

export default SelectListItem;
