import {
  LanguageCode,
  PropertyType,
  TrainingLevel,
} from "globals/enums/global.enum";
import { LanguageDropdownOption } from "globals/intefaces/global.interface";

export const languageOptions: LanguageDropdownOption[] = [
  { label: "Deutsch", value: LanguageCode.DE },
  { label: "Englisch", value: LanguageCode.EN },
];

export const difficultyLevelOptions = [
  { label: "Beginner", value: TrainingLevel.BEGINNER },
  { label: "Anfänger", value: TrainingLevel.ROOKIE },
  { label: "Fortgeschritten", value: TrainingLevel.ADVANCED },
  { label: "Profi", value: TrainingLevel.EXPERT },
  { label: "Arnold", value: TrainingLevel.ARNOLD },
];

export const traningsPlanPublishedStatus = [
  { label: "Öffentlich (Für alle in der App verfügbar)", value: true },
  { label: "Vorlage (Nicht in der App sichtbar)", value: false },
];

export const customerPlanPublishedStatus = [
  { label: "Öffentlich (Für den Kunden in der App sichtbar)", value: true },
  { label: "Entwurf (Nicht in der App sichtbar)", value: false },
];

export const feedPublishedStatus = [
  { label: "Öffentlich (Für alle in der App verfügbar)", value: true },
  { label: "Entwurf (Nicht in der App sichtbar)", value: false },
];

export const servicePublishedStatus = [
  { label: "Öffentlich (Für alle in der App verfügbar)", value: true },
  { label: "Entwurf (Nicht in der App sichtbar)", value: false },
];

export const appConfigurationIsValid = [
  { label: "App Config Valid", value: true },
  { label: "App Config nicht OK", value: false },
];

export const weekDaysOptions: any[] = [
  { label: "Montag", value: 1 },
  { label: "Dienstag", value: 2 },
  { label: "Mittwoch", value: 3 },
  { label: "Donnerstag", value: 4 },
  { label: "Freitag", value: 5 },
  { label: "Samstag", value: 6 },
  { label: "Sonntag", value: 7 },
];

export const propertyTypeOptions = [
  { label: "Muskelgruppe", value: PropertyType.MUSCLE_GROUP },
  { label: "Tag", value: PropertyType.TAG },
  { label: "Geräte Eigenschaft", value: PropertyType.DEVICE },
  { label: "Service", value: PropertyType.SERVICE },
  {
    label: "Muskelgruppe (READABLE)",
    value: PropertyType.READABLE_MUSCLE_GROUP,
  },
];
