import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useParams } from "react-router";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import GymoCustomerStore from "stores/gymo.customer.store";
import { inject, observer } from "mobx-react";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import AppConfigurationForm from "./components/app.configuration.form";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { User } from "schemas/user.schemas/user.schema";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import DateText from "components/text.components/date.text.component/date.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { HttpGymoCustomerService } from "services/httpClients/http.gymo.customer.client";
import { toast } from "react-toastify";

interface GymoCustomerDetailPageProps {
  gymoCustomerStore?: GymoCustomerStore;
}

const GymoCustomerDetailPage = ({
  gymoCustomerStore,
}: GymoCustomerDetailPageProps): JSX.Element => {
  const { gymoCustomerID } = useParams();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const customerAppUser =
    gymoCustomerStore?.currentGymoCustomerAppUsers?.data ?? [];
  const currentGymoCustomer = gymoCustomerStore?.currentGymoCustomer?.data;

  useEffect(() => {
    initializePage();
  }, [gymoCustomerID]);

  const initializePage = async (): Promise<void> => {
    if (gymoCustomerID == null) {
      return;
    }
    gymoCustomerStore?.fetchAndSetCurrentGymoCustomer({ gymoCustomerID });

    gymoCustomerStore?.fetchAndSetCurrentGymoCustomerAppUsers({
      gymoCustomerID,
      refresh: true,
    });
  };

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text);
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="gymo-customers">
        <LinkTabs
          tabs={[
            <Tab
              label="Informationen"
              key="gymo-customer-info-tab"
              path="info"
              rootPath={gymoCustomerID}
              dirty={formIsDirty}
            />,
            <Tab
              label={`App User (${customerAppUser.length})`}
              key="gymo-customer-app-users-tab"
              path="app-users"
              rootPath={gymoCustomerID}
              dirty={formIsDirty}
            />,
            <Tab
              label="Statistiken"
              key="gymo-customer-statistics-tab"
              path="statistics"
              rootPath={gymoCustomerID}
              dirty={formIsDirty}
            />,
          ]}
        >
          <Outlet />
        </LinkTabs>
      </PageHeadline>
    );
  };

  const _buildStatisticTab = (): JSX.Element => {
    return (
      <InfoBox>
        Wir arbeiten gerade an dieser Funktion. Bitte schau später noch einmal
        vorbei.
      </InfoBox>
    );
  };

  const _buildAppUserTab = (): JSX.Element => {
    return (
      <ComponentWrapper
        noPadding
        className="mt-5"
        title={`App Nutzer von ${currentGymoCustomer?.name}`}
      >
        <ListDataTable
          data={customerAppUser}
          noDataMessage="Keine Nutzer gefunden"
          columns={[
            {
              child: <></>,
            },
            {
              child: <RunningText>Name</RunningText>,
              flex: 2,
            },
            {
              child: <RunningText>Email</RunningText>,
              flex: 2,
            },
            {
              child: <RunningText>Geboren am</RunningText>,
            },
            {
              child: <RunningText>Registriert seit</RunningText>,
            },
            {
              child: <></>,
            },
          ]}
          dataTableItemBuilder={(dataItem: User) => {
            if (dataItem.firstName == null && dataItem.lastName == null) {
              // skip this item
            }

            return {
              key: dataItem._id,
              children: [
                {
                  child: (
                    <Image
                      imageUrl={dataItem.profileImageUrl}
                      size={ImageSizes.S}
                      rounded={true}
                    />
                  ),
                },
                {
                  child: (
                    <RunningText>
                      {dataItem.firstName} {dataItem.lastName}
                    </RunningText>
                  ),
                },

                { child: <RunningText>{dataItem.email}</RunningText> },

                {
                  child: (
                    <DateText format="DD.MM.YYYY">
                      {dataItem?.dateOfBirth as any}
                    </DateText>
                  ),
                },
                {
                  child: (
                    <DateText format="DD.MM.YYYY">
                      {dataItem?.system?.createdAt as any}
                    </DateText>
                  ),
                },
                {
                  child: (
                    <FilledButton
                      label="token"
                      color="secondary"
                      onClick={async () => {
                        try {
                          if (currentGymoCustomer == null || dataItem == null) {
                            return;
                          }

                          const response =
                            await HttpGymoCustomerService.getInstance().getAccessTokenForStudioAppUser(
                              {
                                studioID: currentGymoCustomer?._id,
                                userID: dataItem._id,
                              }
                            );

                          copyToClipboard(response.access_token ?? "undefined");
                          toast.success(
                            "Token wurde in die Zwischenablage Kopiert!"
                          );
                        } catch (err) {
                          toast.error("Fehler beim anfordern des Tokens!");
                        }
                      }}
                    />
                  ),
                },
              ],
            };
          }}
        />
      </ComponentWrapper>
    );
  };

  if (gymoCustomerStore?.currentGymoCustomer?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={gymoCustomerID}>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path="info"
              element={
                <AppConfigurationForm
                  onDirty={(isDirty: boolean) => {
                    setFormIsDirty(isDirty);
                  }}
                />
              }
            />
            <Route path="app-users" element={_buildAppUserTab()}></Route>
            <Route path="statistics" element={_buildStatisticTab()}></Route>
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default inject("gymoCustomerStore")(observer(GymoCustomerDetailPage));
