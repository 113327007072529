/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from "@hookform/resolvers/yup";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { propertyTypeOptions } from "globals/data/globals.data";
import { ContainerSizes, PropertyType } from "globals/enums/global.enum";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { propertySchema } from "schemas/property.schemas/property.schema";
import PropertyStore from "stores/property.store";
import StudioStore from "stores/studio.store";

interface PropertyFormProps {
  propertyStore?: PropertyStore;
  studioStore?: StudioStore;
  isEditing?: boolean;
}

const PropertyForm = ({
  propertyStore,
  studioStore,
  isEditing,
}: PropertyFormProps): JSX.Element => {
  const navigate = useNavigate();

  const [isTypeMuscleGroup, setIsTypeMuscleGroup] = useState(false);
  const property = propertyStore?.currentProperty?.data;
  const muscleGroupProperties = propertyStore?.properties?.data?.filter(
    (property) => property.type === PropertyType.READABLE_MUSCLE_GROUP
  );

  const currentLanguage = studioStore?.currentLanguage;
  const currentLanguages = studioStore?.studio?.localizations ?? [];

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(propertySchema(currentLanguages)),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: property,
  });

  useEffect(() => {
    if (property?.type === PropertyType.MUSCLE_GROUP) {
      setIsTypeMuscleGroup(true);
    }
  }, []);

  const onSubmit = async (data: any): Promise<void> => {
    if (property == null) {
      return;
    }

    propertyStore?.setCurrentProperty({
      ...property,
      ...data,
    });

    if (!isEditing) {
      const createdProperty = await propertyStore?.createProperty({
        property: data,
      });

      // navigate to created property
      if (createdProperty?._id != null && studioStore?.studio?._id != null) {
        navigate(
          `/studios/${studioStore?.studio?._id}/properties/${createdProperty._id}`
        );
      }
    } else {
      propertyStore?.updateProperty({ property: data });
    }

    setFormIsDirty(false);
  };

  if (property == null || currentLanguage == null) {
    return <></>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader
        label={isEditing ? "Eigenschaft bearbeiten" : "Eigenschaft hinzufügen"}
      >
        <FilledButton
          disabled={isUploading || !formIsDirty}
          label="Speichern"
          type="submit"
          color="secondary"
        />
      </PageHeader>
      <ComponentWrapper>
        <SizedContainer size={ContainerSizes.XL}>
          <OutlinedTextInput
            label="Titel"
            inputRef={register(`title.${currentLanguage.value}`)}
            validationMessage={getFormattedErrorMessage("Titel", errors.title)}
          />

          <SelectDropDown
            label="Type"
            className="mb-15"
            selectedItem={propertyTypeOptions?.find(
              (item) => item.value === property?.type
            )}
            items={propertyTypeOptions ?? []}
            onChange={(item) => {
              setValue("type", item?.value);
              clearErrors("type");
              if (item !== undefined) setFormIsDirty(true);

              if (item?.value === PropertyType.MUSCLE_GROUP) {
                setIsTypeMuscleGroup(true);
              }
            }}
            inputRef={register("type")}
            validationMessage={errors.type?.message?.toString()}
          />

          {isTypeMuscleGroup && (
            <SelectDropDown
              label="ParentID (App Muskelgruppe)"
              isLoading={propertyStore?.properties?.isLoading}
              selectedItem={property?.parentID}
              labelPropertyName={`title.${currentLanguage.value}`}
              valuePropertyName="_id"
              items={muscleGroupProperties ?? []}
              onChange={(item) => {
                setValue("parentID", item);
                clearErrors("parentID");
                if (item !== undefined) setFormIsDirty(true);
              }}
              inputRef={register("parentID")}
              validationMessage={getFormattedErrorMessage(
                "ParentID",
                errors.parentID?.message
              )}
            />
          )}

          <SizedContainer size={ContainerSizes.M}>
            <FileUpload
              label="Coverbild"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              className="mt-15"
              folder="properties"
              inputRef={register("coverImageUrl")}
              fileUrl={getProperty(property, "coverImageUrl")}
              validationMessage={errors.coverImageUrl?.message?.toString()}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValue("coverImageUrl", files[0].path);
                  clearErrors("coverImageUrl");
                  setFormIsDirty(true);
                } else {
                  setValue("coverImageUrl", undefined);
                }
              }}
            />
          </SizedContainer>
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject("studioStore", "propertyStore")(observer(PropertyForm));
