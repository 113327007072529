import React from "react";
import "./skeleton.detail.page.component.scss";
import MainLayout from "layouts/main.layout/main.layout";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Row from "components/general.compoenents/row.component/row.component";

const SkeletonDetailPage = (): JSX.Element => {
  const _buildPageHeadline = (): JSX.Element => {
    return (
      <SkeletonTheme>
        <Row
          className="skeleton-detail-header-wrapper"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="skeleton-detail-header-container">
            <div className="skeleton-detail-seperator">
              <Skeleton count={1} width={40} height={40} />
              <Skeleton count={1} width={260} height={35} />
            </div>
            <div className="skeleton-detail-seperator">
              <Skeleton count={1} width={110} height={40} />
            </div>
          </div>
        </Row>
      </SkeletonTheme>
    );
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        <Skeleton count={1} width={"70%"} height={30} />
        <Skeleton count={1} width={"60%"} height={30} />
        <Skeleton count={1} width={"70%"} height={30} />
        <Skeleton count={1} width={"60%"} height={30} />
        <Skeleton count={1} width={"40%"} height={30} />
      </PageContainer>
    </MainLayout>
  );
};

export default SkeletonDetailPage;
