import React from "react";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import { useLocation } from "react-router-dom";
import AuthForm from "components/auth.components/auth.form.component/auth.form.component";
import "./invitation.page.scss";
import Headline from "components/text.components/headline.component/headline.component";
import Row from "components/general.compoenents/row.component/row.component";

const InvitationPage = (): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="invitation-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="invitation-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="invitation-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="invitation-page">
      <div className="invitation-page-wrapper">
        {_buildPreview()}
        <div className="invitation-form-wrapper">
          <AuthForm
            prefillEmail={params.get("recipientEmail")?.toString()}
            title={`Hey! ${params.get("senderName") ?? "Jemand"} hat dich zu ${
              params.get("studioName") ?? "Gymo"
            } eingeladen!`}
            subTitle="Melde dich an, um die Einladung anzunehmen."
          />
        </div>
      </div>
    </Row>
  );
};

export default InvitationPage;
