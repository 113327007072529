import React, { useEffect, useState } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import ServiceStore from "stores/service.store";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";

interface ServiceNavListProps {
  studioStore?: StudioStore;
  serviceStore?: ServiceStore;
}

const ServiceNavList = ({
  studioStore,
  serviceStore,
}: ServiceNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { serviceID } = useParams();
  const services = serviceStore?.service?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);

  const serviceSearchResult = serviceStore?.serviceSearchResult ?? [];

  // if isSearch is active, use serviceSearchResult otherwise use service
  const servicesToRender = isSearchActive ? serviceSearchResult : services;

  useEffect(() => {
    serviceStore?.fetchAndSetServices({});
  }, []);

  const _buildNewServicePreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(`/studios/${studioStore?.studio?._id}/services/new/info`);
          }
        }}
        title="Neue Dienstleistung"
        imageUrl={serviceStore?.currentService?.data?.coverImageUrl}
        subtitle="Erstelle eine neue Dienstleistung"
        isActive
      />
    );
  };

  const _builServiceList = (): JSX.Element => {
    if (serviceStore?.service?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {serviceID === "new" && _buildNewServicePreview()}

          {services.length === 0 && serviceID !== "new" && (
            <ListPlaceholder
              title="Du hast noch keine Dienstleistungen hinzugefügt!"
              description="Füge Dienstleistungen hinzu, um sie in deinem Studio zu verwalten."
              actionLabel="Dienstleistung hinzufügen"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/services/new/info`
                  );
                }
              }}
            />
          )}

          {servicesToRender.length === 0 &&
            services.length > 0 &&
            isSearchActive &&
            serviceID !== "new" && <NavListNoSearchResultComponent />}

          {servicesToRender.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  index={i + 1}
                  imageUrl={getProperty(item, "coverImageUrl")}
                  title={getProperty(item, "title")}
                  subtitle={getProperty(item, "subTitle")}
                  isActive={item._id === serviceID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/services/${item._id}/info`
                      );
                    }
                  }}
                  rightLabel={
                    !item?.isPublished ? (
                      <HighlightedInfoText color="warning">
                        ENTWURF
                      </HighlightedInfoText>
                    ) : (
                      <></>
                    )
                  }
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/services/new/info`
                );
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Dienstleistung suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              services?.length > 0
            ) {
              setIsSearchActive(true);
              serviceStore?.searchAndSetServices(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_builServiceList()}
    </Column>
  );
};

export default inject("studioStore", "serviceStore")(observer(ServiceNavList));
