import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import { inject, observer } from "mobx-react";
import DeviceBrandStore from "stores/device.brand.store";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import LanguageDropdown from "components/input.components/dropdown.components/language.dropdown.component/language.dropdown.component";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import StudioDeviceForm from "./components/studio.device.form.component/studio.device.form.component";
import StudioDeviceStore from "stores/studio.device.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import DeviceExercisesList from "components/table.components/device.exercises.list.component/device.exercises.list.component";
import Gleap from "gleap";
import { HttpDeviceService } from "services/httpClients/http.device.client";
import { toast } from "react-toastify";
import { ModalStore } from "stores/modal.store";
import StudioStore from "stores/studio.store";
import PropertyStore from "stores/property.store";

interface DeviceDetailPageProps {
  studioDeviceStore?: StudioDeviceStore;
  deviceBrandStore?: DeviceBrandStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
  propertyStore?: PropertyStore;
}

const StudioDeviceDetailPage = ({
  deviceBrandStore,
  studioDeviceStore,
  modalStore,
  studioStore,
  propertyStore,
}: DeviceDetailPageProps): JSX.Element => {
  const { studioDeviceID } = useParams();
  const navigate = useNavigate();
  const deviceExercises = studioDeviceStore?.currentDeviceExercises?.data ?? [];

  useEffect(() => {
    initializePage();
  }, [studioDeviceID]);

  const initializePage = async (): Promise<void> => {
    if (studioDeviceID == null) {
      return;
    }

    deviceBrandStore?.fetchAndSetDeviceBrands({});
    propertyStore?.fetchAndSetProperties({});

    studioDeviceStore?.fetchAndSetStudioDeviceWithDeviceExercises({
      studioDeviceID,
    });
  };

  // archive device with all device exercises and fetch all devices again
  const archiveDevice = async (): Promise<void> => {
    if (studioDeviceID != null) {
      // archive device and wait for response to fetch all devices again
      const response =
        await HttpDeviceService.getInstance().archiveStudioDevice(
          studioDeviceID
        );

      if (response != null) {
        studioDeviceStore?.fetchAndSetStudioDevices({
          refresh: true,
        });

        toast.success("Gerät wurde erfolgreich archiviert.");
      }
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab
            label="Informationen"
            key="studio-device-info-tab"
            path="info"
          />,
          <Tab
            label="Übungen"
            key="studio-device-exercises-tab"
            path="exercises"
          />,
        ]}
      />
    );
  };

  const _buildDeviceExercisesList = (): JSX.Element => {
    return (
      <ComponentWrapper
        title="Übungen"
        noPadding
        actions={
          <FilledButton
            onClick={() => {
              Gleap.open();
            }}
            label="Übung hinzufügen"
            color="secondary"
          />
        }
      >
        <DeviceExercisesList
          isLoading={studioDeviceStore?.currentDeviceExercises?.isLoading}
          exercises={deviceExercises}
          onClick={(exercise) => {
            if (studioStore?.studio?._id != null && exercise?._id != null) {
              modalStore?.openNavigateModal({
                navigate,
                path: `/studios/${studioStore?.studio?._id}/exercises/${exercise?._id}/info`,
                title: "Möchten Sie diese Übung anzeigen?",
                description:
                  "Klicken Sie auf OK oder Abbrechen, um auf der aktuellen Seite zu bleiben.",
              });
            }
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="devices"
        actions={[
          <LanguageDropdown key="language-dropdown" />,
          <FilledButton
            color="danger"
            label="Gerät entfernen"
            key="archive-device"
            onClick={() => {
              modalStore?.showConfirmAlert({
                onConfirm: async () => {
                  archiveDevice();
                  if (studioStore?.studio?._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/studio-devices`
                    );
                  }
                },
                confirmLabel: "Entfernen",
                title: "Gerät entfernen",
                description:
                  "Möchten Sie das Gerät wirklich entfernen? Alle zugehörigen Übungen werden ebenfalls entfernt.",
              });
            }}
          />,
        ]}
      >
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (studioDeviceStore?.currentDevice?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        <div className="device-detail-wrapper">
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index={true as any}
                path="exercises"
                element={_buildDeviceExercisesList()}
              />
              <Route path="info" element={<StudioDeviceForm />} />
            </Route>
          </Routes>
        </div>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "deviceBrandStore",
  "studioDeviceStore",
  "modalStore",
  "studioStore",
  "propertyStore"
)(observer(StudioDeviceDetailPage));
