import SidebarNavigation from "components/navigation.components/sidebar.component/sidebar.component";
import MainLayout from "layouts/main.layout/main.layout";
import React from "react";
import { Outlet } from "react-router-dom";

const DashboardPage = (): JSX.Element => {
  return (
    <MainLayout sideBars={[SidebarNavigation({})]}>
      <Outlet />
    </MainLayout>
  );
};

export default DashboardPage;
