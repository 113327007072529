import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import TrainingPlanList from "components/table.components/training.plan.list.component/training.plan.list.component";
import Headline from "components/text.components/headline.component/headline.component";
import { filterAndSortTrainingPlans } from "globals/helpers/global.helper";
import { inject, observer } from "mobx-react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import CustomerStore from "stores/customer.store";
import { ModalStore, ModalType } from "stores/modal.store";

interface TrainingPlansOverViewTabProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
}

const TrainingPlansOverViewTab = ({
  customerStore,
  modalStore,
}: TrainingPlansOverViewTabProps): JSX.Element => {
  const { studioID, customerID } = useParams();
  const navigate = useNavigate();
  const trainingPlans = customerStore?.customerTrainingPlans?.data ?? [];

  // filter training plans by type (user or customer) and sort them by modified date
  const customerTrainingPlans = filterAndSortTrainingPlans(
    trainingPlans,
    "CUSTOMER"
  );
  const userTrainingPlans = filterAndSortTrainingPlans(trainingPlans, "USER");

  const _buildCustomerTrainingPlanList = (): JSX.Element => {
    return (
      <ComponentWrapper title="Pläne" className="mb-25" noPadding>
        <TrainingPlanList
          noDataMessage="Es wurden noch keine Trainingspläne erstellt"
          trainingPlans={customerTrainingPlans}
          onClick={(trainingPlan: TrainingPlan) => {
            if (studioID != null && customerID != null) {
              navigate(
                `/studios/${studioID}/customers/${customerID}/training-plans/${trainingPlan._id}`
              );
            }
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildUserTrainingPlanList = (): JSX.Element => {
    return (
      <ComponentWrapper title="Kundenpläne" noPadding>
        <TrainingPlanList
          noDataMessage="Vom Kunden wurden noch keine Trainingspläne erstellt"
          trainingPlans={userTrainingPlans}
          onClick={(trainingPlan: TrainingPlan) => {
            if (studioID != null && customerID != null) {
              navigate(
                `/studios/${studioID}/customers/${customerID}/training-plans/${trainingPlan._id}`
              );
            }
          }}
        />
      </ComponentWrapper>
    );
  };

  return (
    <Column>
      <Row className="mb-20 mobile-row-break" alignItems="center">
        <Headline>Trainingspläne</Headline>
        <FilledButton
          label="Hinzufügen"
          className="ml-15"
          onClick={() => {
            if (studioID != null && customerID != null) {
              navigate(
                `/studios/${studioID}/customers/${customerID}/training-plans/new`
              );
            }
          }}
        />
        <FilledButton
          label="Aus Vorlage erstellen"
          className="ml-15"
          onClick={() => {
            modalStore?.openModal(ModalType.TRAINING_PLAN_SELECTION_MODAL);
          }}
        />
      </Row>
      {_buildCustomerTrainingPlanList()}
      {_buildUserTrainingPlanList()}
    </Column>
  );
};

export default inject(
  "customerStore",
  "modalStore"
)(observer(TrainingPlansOverViewTab));
