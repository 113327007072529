/* eslint-disable @typescript-eslint/no-misused-promises */
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import TrainingPlanStore from "stores/training.plan.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import {
  difficultyLevelOptions,
  traningsPlanPublishedStatus,
} from "globals/data/globals.data";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { yupResolver } from "@hookform/resolvers/yup";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import { toast } from "react-toastify";
import Row from "components/general.compoenents/row.component/row.component";
import * as yup from "yup";
import { propertySchema } from "schemas/property.schemas/property.schema";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";

const trainingPlanFormSchema = yup.object().shape({
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  description: yup.string(),
  coverImageUrl: yup.string().required("Bild ist verpflichtend"),
  rhythm: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  properties: yup.array().of(propertySchema([])).default(undefined),
  niveau: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .default(null),
  duration: yup
    .number()
    .integer()
    .min(1, "Dauer muss mindestens 1 sein")
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  isPublished: yup.boolean().required("Verfügbarkeit ist verpflichtend"),
  coverColor: yup.string().notRequired(),
});

interface TrainingPlanFormProps {
  isEditing?: boolean;
  trainingPlanStore?: TrainingPlanStore;
  onDirty: (isDirty: boolean) => void;
}

const TrainingPlanForm = ({
  isEditing,
  trainingPlanStore,
  onDirty,
}: TrainingPlanFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { studioID } = useParams();

  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(trainingPlanFormSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: trainingPlan,
  });

  useEffect(() => {
    localStorage.setItem("shouldNavigateToExercises", "false");
    onDirty(formIsDirty);
  }, [formIsDirty]);

  const showPublishInfo = (): boolean => {
    if (
      isPublished &&
      (trainingPlan?.exercises == null || trainingPlan?.exercises?.length === 0)
    ) {
      return true;
    }

    return false;
  };

  // handle training plan form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (trainingPlan == null) {
      return;
    }

    trainingPlanStore?.setCurrentTrainingPlan({
      ...trainingPlan,
      ...data,
    });

    if (!isEditing) {
      const createdTrainingPlan = await trainingPlanStore?.createTrainingPlan({
        trainingPlan: { ...data, type: "STUDIO" },
      });

      const shouldNavigateToExercises =
        localStorage.getItem("shouldNavigateToExercises") === "true";

      // navigate to created device
      if (createdTrainingPlan?._id != null && studioID != null) {
        navigate(
          `/studios/${studioID}/training-plans/${createdTrainingPlan._id}/${
            shouldNavigateToExercises ? "exercises" : "info"
          }`
        );

        trainingPlanStore?.setCurrentTrainingPlan(createdTrainingPlan);

        return;
      }
    } else {
      const updatedTrainingPlan = await trainingPlanStore?.updateTrainingPlan({
        id: trainingPlan._id!,
        trainingPlan: data,
      });

      const shouldNavigateToExercises =
        localStorage.getItem("shouldNavigateToExercises") === "true";

      // navigate to created device
      if (updatedTrainingPlan?._id != null && studioID != null) {
        navigate(
          `/studios/${studioID}/training-plans/${updatedTrainingPlan._id}/${
            shouldNavigateToExercises ? "exercises" : "info"
          }`
        );
      }
    }

    setFormIsDirty(false);
  };

  if (trainingPlan == null) {
    return <></>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader
        label={
          isEditing ? "Trainingsplan bearbeiten" : "Trainingsplan hinzufügen"
        }
      >
        <FilledButton
          disabled={isUploading || !formIsDirty}
          label="Speichern"
          type="submit"
        />
      </PageHeader>

      <ComponentWrapper
        title="Verfügbarkeit"
        className=" mb-15"
        info={{
          title: "Info zu Verfügbarkeit",
          content:
            "In diesem Punkt, kannst du entscheiden, ob der Plan für deine Mitglieder sichtbar und zu verfügung steht. Oder du erstellst eine interne Vorlage, die nur du siehst und kannst diese dann individuell einzelnen Mitgliedern zuweisen.",
        }}
      >
        <SizedContainer size={ContainerSizes.XL}>
          <SelectDropDown
            label="Verfügbarkeit"
            className="mb-10"
            selectedItem={traningsPlanPublishedStatus.find(
              (item) => item.value === trainingPlan?.isPublished
            )}
            items={traningsPlanPublishedStatus ?? []}
            onChange={(item) => {
              setValue("isPublished", item?.value);
              clearErrors("isPublished");
              if (item !== undefined) setFormIsDirty(true);
              setIsPublished(item?.value);
            }}
            inputRef={register("isPublished")}
            validationMessage={errors.isPublished?.message?.toString()}
          />
          {showPublishInfo() && (
            <InfoBox>
              Trainingspläne ohne Übungen werden nicht veröffentlicht.
            </InfoBox>
          )}
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper title="Informationen" className="mb-15">
        <SizedContainer size={ContainerSizes.XL}>
          <OutlinedTextInput
            label="Plan Name"
            inputRef={register("title")}
            validationMessage={errors.title?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-30"
            label="Subtitle"
            inputRef={register("subTitle")}
            validationMessage={errors.subTitle?.message?.toString()}
          />

          <OutlinedTextArea
            className="mt-30"
            label="Beschreibung"
            inputRef={register("description")}
            validationMessage={errors.description?.message?.toString()}
          />

          <ColorPicker
            className="mt-10"
            name="color"
            label="Farbe"
            inputRef={register("coverColor")}
            defaultValue={getValues("coverColor") ?? "#313634e0"}
            validationMessage={errors.coverColor?.message?.toString()}
            onChange={(color: any) => {
              setValue("coverColor", color);
              setFormIsDirty(true);
            }}
          />

          <SizedContainer className="mt-30" size={ContainerSizes.M}>
            <FileUpload
              label="Coverbild"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              className="mt-15"
              folder="training-plans"
              inputRef={register("coverImageUrl")}
              fileUrl={getProperty(trainingPlan, "coverImageUrl")}
              validationMessage={errors.coverImageUrl?.message?.toString()}
              onLibraryChoosed={(data) => {
                setValue("coverImageUrl", data.url);
                clearErrors("coverImageUrl");
                setFormIsDirty(true);
              }}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValue("coverImageUrl", files[0].path);
                  clearErrors("coverImageUrl");
                  setFormIsDirty(true);
                }
              }}
            />
          </SizedContainer>
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper title="Sonstiges">
        <SizedContainer size={ContainerSizes.XL}>
          <SelectDropDown
            label="Schwierigkeitsgrad"
            selectedItem={difficultyLevelOptions?.find((item) => {
              return item.value === trainingPlan?.niveau;
            })}
            items={difficultyLevelOptions ?? []}
            onChange={(item) => {
              setValue("niveau", item?.value);
              clearErrors("niveau");
              if (item !== undefined) setFormIsDirty(true);
            }}
            inputRef={register("niveau")}
            validationMessage={errors.niveau?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-30"
            type="number"
            label="Wochenrhythmus"
            placeholder="zb. 2 x Wöchentlich"
            inputRef={register("rhythm")}
            validationMessage={errors.rhythm?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-30"
            type="number"
            label="Dauer in Minuten"
            placeholder="zb. 60 Minuten"
            inputRef={register("duration")}
            validationMessage={errors.duration?.message?.toString()}
          />
        </SizedContainer>
      </ComponentWrapper>

      <Row justifyContent="flex-end" className="mt-20">
        <FilledButton
          className="mr-35"
          disabled={isUploading || !formIsDirty}
          label="Speichern und weiter zu Übungen"
          color="secondary"
          onClick={() => {
            localStorage.setItem("shouldNavigateToExercises", "true");
            handleSubmit(onSubmit, (errors) => {
              toast.error("Bitte überprüfe deine Eingaben");
              setFormIsDirty(false);
            })();
          }}
        />
      </Row>
    </form>
  );
};

export default inject("trainingPlanStore")(observer(TrainingPlanForm));
