import { getAccessToken, getResource } from "globals/helpers/storage.helper";
import React, { useState, useEffect } from "react";

interface ImageWithAuthHeaderProps {
  src?: string;
  alt?: string;
  className?: string;
}

const ImageWithAuthHeader = ({
  src,
  alt,
  className,
}: ImageWithAuthHeaderProps): JSX.Element => {
  const [imageSrc, setImageSrc] = useState(null as any);

  const useAuthHeader = src?.includes(
    "https://api.gymo.io" || "http://localhost:3001"
  );

  useEffect(() => {
    if (!src || !useAuthHeader) {
      return;
    }

    const fetchImage = async (): Promise<void> => {
      const response = await fetch(src, {
        headers: {
          Authorization: getAccessToken(),
          gymo_resource: getResource(),
        },
      });

      const blob = await response.blob();
      setImageSrc(URL.createObjectURL(blob));
    };

    fetchImage();
  }, [src]);

  if (imageSrc == null || !useAuthHeader) {
    return <img className={className} src={src} alt={alt} />;
  }

  return <img className={className} src={imageSrc} alt={alt} />;
};

export default ImageWithAuthHeader;
