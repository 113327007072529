import { makeAutoObservable } from "mobx";
import {
  DataItem,
  getSkipAndLimitFromPage,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import {
  TrainingPlan,
  trainingPlanToJson,
} from "schemas/training.plan.schemas/training.plan.schema";
import { HttpTrainingPlanService } from "services/httpClients/http.training.plan.client";
import { toast } from "react-toastify";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { Logging } from "globals/helpers/logging.helper";
import { User } from "schemas/user.schemas/user.schema";

class TrainingPlanStore {
  // Properties
  private _trainingPlanDataList: PaginationDataList<TrainingPlan> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _trainingPlanDataListSearchResult: TrainingPlan[] = [];

  private _archivedTrainingPlanDataList: PaginationDataList<TrainingPlan> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentTrainingPlansData: DataItem<TrainingPlan> = {
    data: undefined,
    isLoading: false,
  };

  private _currentSelectedExercises?: Exercise[] = [];

  private _currentSelectedTrainingPlans?: TrainingPlan[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setTrainingPlans = (trainingPlans: TrainingPlan[]): void => {
    this._trainingPlanDataList.data = [
      ...this._trainingPlanDataList.data,
      ...trainingPlans,
    ];
  };

  setTrainingPlansSearchResult = (trainingPlans: TrainingPlan[]): void => {
    this._trainingPlanDataListSearchResult = trainingPlans;
  };

  setArchivedTrainingPlans = (trainingPlans: TrainingPlan[]): void => {
    this._archivedTrainingPlanDataList.data = [
      ...this._archivedTrainingPlanDataList.data,
      ...trainingPlans,
    ];
  };

  setCurrentTrainingPlan = (trainingPlan: TrainingPlan | undefined): void => {
    this._currentTrainingPlansData.data = trainingPlan;
  };

  setCurrentSelectedExercises = (exercises: Exercise[] | undefined): void => {
    this._currentSelectedExercises = exercises;
  };

  setCurrentSelectedTrainingPlan = (
    trainingPlan: TrainingPlan[] | undefined
  ): void => {
    this._currentSelectedTrainingPlans = trainingPlan;
  };

  //! Getter
  get trainingPlans(): PaginationDataList<TrainingPlan> | undefined {
    if (this._trainingPlanDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._trainingPlanDataList));
  }

  get trainingPlansSearchResult(): TrainingPlan[] | undefined {
    if (this._trainingPlanDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._trainingPlanDataListSearchResult));
  }

  get archivedTrainingPlans(): PaginationDataList<TrainingPlan> | undefined {
    if (this._archivedTrainingPlanDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._archivedTrainingPlanDataList));
  }

  get currentTrainingPlan(): DataItem<TrainingPlan> | undefined {
    if (this._currentTrainingPlansData == null) {
      return;
    }
    return this._currentTrainingPlansData; // Work on instance
  }

  get currentSelectedExercises(): Exercise[] | undefined {
    if (this._currentSelectedExercises == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentSelectedExercises));
  }

  get currentSelectedTrainingPlan(): TrainingPlan[] | undefined {
    if (this._currentSelectedTrainingPlans == null) {
      return;
    }

    return JSON.parse(JSON.stringify(this._currentSelectedTrainingPlans));
  }

  //! Methods
  fetchAndSetTrainingPlans = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this.trainingPlans != null &&
        this._trainingPlanDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._trainingPlanDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._trainingPlanDataList.pageIndex++;
      } else {
        this._trainingPlanDataList.pageIndex = 0;
        this._trainingPlanDataList.data = [];
      }

      this._trainingPlanDataList.isLoading = true;

      const trainingPlans = await HttpTrainingPlanService.getInstance().find({
        query: {
          type: "STUDIO",
          ...getSkipAndLimitFromPage({
            pageIndex: this._trainingPlanDataList.pageIndex,
            itemsInPage: this._trainingPlanDataList.itemsInPage,
          }),
        },
      });

      if (trainingPlans == null) {
        this._trainingPlanDataList.isLoading = false;
        return;
      }

      this.setTrainingPlans(trainingPlans);

      this._trainingPlanDataList.isLoading = false;
    } catch (err) {
      this._trainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "fetchAndSetTrainingPlans",
        message: "TrainingPlans konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetTrainingPlan = async (args: {
    trainingPlanID: string;
  }): Promise<void> => {
    try {
      if (this._currentTrainingPlansData.isLoading) {
        return;
      }

      this._currentTrainingPlansData.isLoading = true;

      const trainingPlan = await HttpTrainingPlanService.getInstance().findOne({
        id: args.trainingPlanID,
      });

      if (trainingPlan == null) {
        this._currentTrainingPlansData.isLoading = false;
        return;
      }

      this.setCurrentTrainingPlan(trainingPlan);
      this._currentTrainingPlansData.isLoading = false;
    } catch (err) {
      this._currentTrainingPlansData.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "fetchAndSetTrainingPlan",
        message: "TrainingPlan konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialTrainingPlan = async (args: {
    type: "STUDIO" | "CUSTOMER";
    user?: User;
  }): Promise<TrainingPlan | undefined> => {
    const initialPlan: TrainingPlan = {
      type: args.type,
    } as any;

    if (args.user != null) {
      initialPlan.user = args.user;
    }

    this.setCurrentTrainingPlan(initialPlan);

    return initialPlan;
  };

  createTrainingPlan = async (args: {
    trainingPlan: TrainingPlan;
    fromTemplate?: string;
  }): Promise<TrainingPlan | undefined> => {
    try {
      this._currentTrainingPlansData.isLoading = true;
      this._trainingPlanDataList.isLoading = true;

      const newPlan = await HttpTrainingPlanService.getInstance().create({
        data: trainingPlanToJson(args.trainingPlan),
        query:
          args.fromTemplate != null ? { templateID: args.fromTemplate } : {},
      });

      if (newPlan == null) {
        this._currentTrainingPlansData.isLoading = false;
        this._trainingPlanDataList.isLoading = false;
        return;
      }

      if (this._trainingPlanDataList.data != null) {
        this._trainingPlanDataList.data.unshift(newPlan);
      }
      this.setCurrentTrainingPlan(newPlan);

      this._currentTrainingPlansData.isLoading = false;
      this._trainingPlanDataList.isLoading = false;
      toast.success("TrainingsPlan wurde erfolgreich erstellt");
      return newPlan;
    } catch (err) {
      this._currentTrainingPlansData.isLoading = false;
      this._trainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "createTrainingPlan",
        message: "TrainingPlan konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateTrainingPlan = async (args: {
    id: string;
    trainingPlan: TrainingPlan;
  }): Promise<TrainingPlan | undefined> => {
    try {
      this._currentTrainingPlansData.isLoading = true;
      this._trainingPlanDataList.isLoading = true;

      const updatedPlan = await HttpTrainingPlanService.getInstance().updateOne(
        {
          id: args.id,
          data: trainingPlanToJson(args.trainingPlan),
        }
      );

      if (updatedPlan == null) {
        this._currentTrainingPlansData.isLoading = false;
        this._trainingPlanDataList.isLoading = false;
        return;
      }

      // find updated plan in this._trainingPlanDataList.data and replace it
      // and set plan as first item in list
      const plansWithoutUpdatedItem = this._trainingPlanDataList.data.filter(
        (item) => item._id !== updatedPlan._id
      );

      this._trainingPlanDataList.data = [
        updatedPlan,
        ...plansWithoutUpdatedItem,
      ];

      this.setCurrentTrainingPlan(updatedPlan);

      this._currentTrainingPlansData.isLoading = false;
      this._trainingPlanDataList.isLoading = false;
      toast.success("TrainingsPlan wurde erfolgreich aktualisiert");
      return updatedPlan;
    } catch (err) {
      this._currentTrainingPlansData.isLoading = false;
      this._trainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "updateTrainingPlan",
        message: "TrainingPlan konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetTrainingPlans = async (searchTerm: string): Promise<void> => {
    try {
      if (this._trainingPlanDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetTrainingPlans({ refresh: true });
        return;
      }

      this._trainingPlanDataList.isLoading = true;

      const plans = await HttpTrainingPlanService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (plans == null) {
        this._trainingPlanDataList.isLoading = false;
        return;
      }

      this.setTrainingPlansSearchResult(plans);
      this._trainingPlanDataList.isLoading = false;
    } catch (err) {
      this._trainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "searchAndSetTrainingPlans",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };

  archivePlan = async (plan: TrainingPlan): Promise<void> => {
    try {
      this._trainingPlanDataList.isLoading = true;
      this._currentTrainingPlansData.isLoading = true;

      const archivedPlan =
        await HttpTrainingPlanService.getInstance().archiveOne({
          id: plan._id!,
        });

      if (archivedPlan == null) {
        this._trainingPlanDataList.isLoading = false;
        this._currentTrainingPlansData.isLoading = false;
        return;
      }

      // remove archived service from this._serviceDataList.data
      const plansWithoutArchivedItem = this._trainingPlanDataList.data.filter(
        (item) => item._id !== plan._id
      );

      this._trainingPlanDataList.data = plansWithoutArchivedItem;

      this._trainingPlanDataList.isLoading = false;
      this._currentTrainingPlansData.isLoading = false;
      toast.success("TrainingsPlan wurde erfolgreich archiviert");
    } catch (err) {
      this._trainingPlanDataList.isLoading = false;
      this._currentTrainingPlansData.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "archivePlan",
        message: "Plan konnte nicht archiviert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetArchivedTrainingPlans = async (): Promise<void> => {
    try {
      this._archivedTrainingPlanDataList.isLoading = true;

      const archivedPlans =
        await HttpTrainingPlanService.getInstance().findArchived();

      if (archivedPlans == null) {
        this._archivedTrainingPlanDataList.isLoading = false;
        return;
      }

      this._archivedTrainingPlanDataList.data = archivedPlans;
      this._archivedTrainingPlanDataList.isLoading = false;
    } catch (err) {
      this._archivedTrainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "fetchAndSetArchivedTrainingPlans",
        message: "Archivierte Pläne konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default TrainingPlanStore;
