import { GenericHttpClient } from "./config/http.generic.client";

export class HttpActionrService extends GenericHttpClient<any> {
  static _instance: HttpActionrService;
  static getInstance(): HttpActionrService {
    if (this._instance == null) {
      this._instance = new HttpActionrService("/actions");
    }
    return this._instance;
  }

  generateActionsForCurrentStudio = async (args: {
    studioID: string;
  }): Promise<void> => {
    await this.post(`actions/devices`, {}, { params: args });
  };

  exportQrCodeForCurrentStudio = async (args: {
    studioID: string;
  }): Promise<void> => {
    const response = await this.get("actions/qr-codes", {
      params: args,
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "qr-codes.pdf");
    document.body.appendChild(link);
    link.click();
  };
}
