/* eslint-disable @typescript-eslint/no-unused-vars */
import StatisticLineChart from "components/chart.components/line.chart.component";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapperComponent from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import Headline from "components/text.components/headline.component/headline.component";
import { ImageSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { ChartData } from "schemas/chart.schemas/chart.data.schema";
import CustomerStore from "stores/customer.store";
import blur1 from "assets/illustrations/placeholders/customer_blur_chart_1.png";
import blur2 from "assets/illustrations/placeholders/customer_blur_chart_2.png";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import Row from "components/general.compoenents/row.component/row.component";
import "./customer.overview.tab.scss";

interface CustomerOverviewTabProps {
  customerStore?: CustomerStore;
}

const CustomerOverviewTab = ({
  customerStore,
}: CustomerOverviewTabProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;
  const studioTimeStatistic = customerStore?.studioTimeStatistic?.data;
  const workoutStatistic = customerStore?.workoutStatistic?.data;
  const bodystatStatistic = customerStore?.bodyStatistic?.data;

  useEffect(() => {
    customerStore?.getStudioTimeStatistics();
    customerStore?.getStatisticForWorkouts();
  }, []);

  const _buildStatisticItem = (
    title: string,
    chartData?: ChartData
  ): JSX.Element => {
    if (!chartData) return <></>;

    return (
      <ComponentWrapperComponent className="mr-15" title={title} autoWidth>
        <StatisticLineChart chartData={chartData} />
      </ComponentWrapperComponent>
    );
  };

  if (!currentCustomer) return <></>;

  return (
    <Column>
      <Headline className="mb-15">
        {customerStore?.currentCustomerFullName}
      </Headline>
      <Wrap>
        <Row className="comming-soon-info">
          <img src={blur2} alt="" className="mr-20" style={{ width: 390 }} />
          <img src={blur1} alt="" className="mr-20" style={{ width: 390 }} />
          <img src={blur2} alt="" style={{ width: 390 }} />
        </Row>

        <Row>
          <InfoBox className="full-width mt-30 mr-30">
            Dieses Feature ist noch nicht verfügbar. Wir arbeiten daran!
          </InfoBox>
        </Row>

        {/* COMMENT OUT - NOT FINSIH */}
        {/* {_buildStatisticItem("Zeit im Studio", studioTimeStatistic)}
        {_buildStatisticItem("Trainingspläne", workoutStatistic)}
        {_buildStatisticItem("Bodystats", bodystatStatistic)} */}
      </Wrap>
    </Column>
  );
};

export default inject("customerStore")(observer(CustomerOverviewTab));
