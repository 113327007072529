import Image from "components/image.component/image.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import React from "react";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import classNames from "classnames";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";

interface TrainingPlanSelectionListProps {
  trainingPlans: TrainingPlan[];
  selectedTrainingPlans: TrainingPlan[];
  onClick?: (trainingPlan: TrainingPlan) => void;
}

const TrainingPlanSelectionList = ({
  trainingPlans,
  selectedTrainingPlans,
  onClick,
}: TrainingPlanSelectionListProps): JSX.Element => {
  const _getPlanLabel = (plan: TrainingPlan): JSX.Element => {
    if (
      plan.type === "STUDIO" &&
      (plan?.isPublished == null || plan.isPublished)
    ) {
      return (
        <HighlightedInfoText color="success">STUDIO PLAN</HighlightedInfoText>
      );
    } else if (plan.type === "STUDIO" && !plan.isPublished) {
      return <HighlightedInfoText color="warning">VORLAGE</HighlightedInfoText>;
    } else {
      return <></>;
    }
  };

  return (
    <ListDataTable
      data={trainingPlans}
      onClick={onClick}
      columns={[
        {
          flex: 0,
        },

        {
          flex: 0,
        },
        {
          flex: 0,
        },
        {
          flex: 2,
        },
        {
          flex: 0,
        },
      ]}
      dataTableItemBuilder={(dataItem: TrainingPlan, index: number) => {
        const isSelected = selectedTrainingPlans?.find((selectedItem: any) => {
          return selectedItem._id === dataItem._id;
        });

        return {
          key: dataItem._id ?? `trainingplan-${index}`,
          className: classNames({
            "list-data-table-body-item-selected": isSelected,
          }),
          children: [
            {
              child: <></>,
              className: "mr-10",
            },

            {
              child: (
                <Image
                  rounded={false}
                  imageUrl={dataItem?.coverImageUrl}
                  size={ImageSizes.M}
                />
              ),
            },

            {
              child: (
                <div>
                  <RunningText
                    className={isSelected ? "text-color-inverted" : ""}
                  >
                    {dataItem?.title}
                  </RunningText>
                </div>
              ),
            },

            {
              child: <></>,
            },
            {
              child: _getPlanLabel(dataItem),
              className: "min-width-100",
            },
          ],
        };
      }}
      gap={30}
      disableHeader={true}
    />
  );
};

export default TrainingPlanSelectionList;
