import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import Image from "components/image.component/image.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { ContainerSizes, ImageSizes } from "globals/enums/global.enum";
import React, { useEffect } from "react";
import { ModalStore } from "stores/modal.store";
import ServiceStore from "stores/service.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";
import { Booking } from "schemas/booking.schemas/booking.schema";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import { formatDate } from "globals/helpers/global.helper";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import StudioStore from "stores/studio.store";
import { useNavigate } from "react-router";

interface ArchiveServiceModalProps {
  modalStore?: ModalStore;
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
}

const ArchiveServiceModal = ({
  modalStore,
  serviceStore,
  studioStore,
}: ArchiveServiceModalProps): JSX.Element => {
  const { serviceID, bookings } = modalStore?.customData || {};
  const service = serviceStore?.currentService?.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      serviceID == null ||
      service?._id == null ||
      serviceID?.toString() !== service?._id.toString()
    ) {
      toast.error("Dienstleistung konnte nicht gefunden werden.");
      modalStore?.closeModal();
    }
  }, []);

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader>
        <SizedContainer size={ContainerSizes.XXL}>
          <Row alignItems="center">
            <Image
              size={ImageSizes.XL}
              className="mr-15"
              imageUrl={service?.coverImageUrl}
            />
            <Column>
              <Headline>
                {service?.title} {"entfernen"}
              </Headline>
              <RunningText className="mt-5">
                {bookings?.length} Buchungen vorhanden
              </RunningText>
            </Column>
          </Row>
        </SizedContainer>
        <Row justifyContent="flex-end" alignItems="flex-end">
          <FilledButton
            color="secondary"
            className="ml-15"
            label={"Dienstleistung entfernen"}
            onClick={() => {
              if (service != null) {
                serviceStore?.archiveService(service);
                modalStore?.closeModal();

                if (studioStore?.studio?._id != null) {
                  navigate(`/studios/${studioStore?.studio?._id}/services`);
                }
              }
            }}
            type="submit"
            form="event-form"
          />
        </Row>
      </ModalHeader>
    );
  };

  const _buildBookingList = (): JSX.Element => {
    return (
      <ListDataTable
        tableClassName="mt-30"
        noDataMessage="Keine Buchungen vorhanden."
        data={bookings}
        columns={[
          {
            child: <>Teilnehmer</>,
            flex: 2,
          },
          {
            child: <>Termin</>,
          },
          {
            child: <>Status</>,
          },
        ]}
        dataTableItemBuilder={(dataItem: Booking, index: number) => {
          return {
            key: dataItem._id,

            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      className="mr-15"
                      rounded={false}
                      imageUrl={dataItem?.user?.profileImageUrl}
                      size={ImageSizes.M}
                    />
                    <div>
                      <RunningText>
                        {dataItem?.user?.firstName} {dataItem?.user?.lastName}
                      </RunningText>
                      <SmallText>{dataItem?.user?.email}</SmallText>
                    </div>
                  </Row>
                ),
              },
              {
                child: (
                  <div>
                    <RunningText>
                      {formatDate(dataItem?.event?.date, "DD.MM.YYYY")}
                    </RunningText>
                    <SmallText>
                      {dataItem?.event?.startTime} - {dataItem?.event?.endTime}{" "}
                      Uhr
                    </SmallText>
                  </div>
                ),
              },
              {
                child: (
                  <HighlightedInfoText color="success">
                    {dataItem?.status === "BOOKED" && "Gebucht"}
                    {dataItem?.status === "CANCELED" && "Storniert"}
                  </HighlightedInfoText>
                ),
              },
            ],
          };
        }}
        gap={50}
      />
    );
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container">{_buildTopBar()}</div>
      <div className="selection-modal-body-container">
        <PageContainer>
          <InfoBox>
            <TitleText>
              Zu dieser Dinstleistung wurden Buchungen getätigt. Diese können
              Sie in der Liste unten einsehen. Wenn Sie diese Dienstleistung
              entfernen, werden alle Buchungen Storniert und die Teilnehmer
              werden benachrichtigt.
              <br />
            </TitleText>
          </InfoBox>
          {_buildBookingList()}
        </PageContainer>
      </div>
    </div>
  );
};

export default inject(
  "modalStore",
  "serviceStore",
  "studioStore"
)(observer(ArchiveServiceModal));
