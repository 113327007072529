import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { Property } from "schemas/property.schemas/property.schema";
import { HttpPropertyService } from "services/httpClients/http.property.client";

class PropertyStore {
  // Properties
  private _propertiesDataList: PaginationDataList<Property> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _propertiesDataListSearchResult: Property[] = [];

  private _currentProperty: DataItem<Property> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setProperties = (properties: Property[]): void => {
    this._propertiesDataList.data = properties;
  };

  setPropertiesSearchResult = (properties: Property[]): void => {
    this._propertiesDataListSearchResult = properties;
  };

  setCurrentProperty = (property: Property): void => {
    this._currentProperty.data = property;
  };

  //! Getters
  get properties(): PaginationDataList<Property> | undefined {
    if (this._propertiesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._propertiesDataList));
  }

  get propertiesSearchResult(): Property[] | undefined {
    if (this._propertiesDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._propertiesDataListSearchResult));
  }

  get currentProperty(): DataItem<Property> | undefined {
    if (this._currentProperty == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentProperty));
  }

  //! Methods
  fetchAndSetProperties = async (args: {
    refresh?: boolean;
    filter?: any;
  }): Promise<void> => {
    try {
      if (
        this.properties != null &&
        this.properties.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._propertiesDataList.isLoading) {
        return;
      }

      this._propertiesDataList.isLoading = true;

      const query = {
        ...getSkipAndLimitFromPage({
          pageIndex: this._propertiesDataList.pageIndex,
          itemsInPage: this._propertiesDataList.itemsInPage,
        }),
      };

      if (args.filter != null) {
        query.type = args.filter;
      }

      const properties = await HttpPropertyService.getInstance().find({
        query,
      });

      if (properties == null) {
        this._propertiesDataList.isLoading = false;
        return;
      }

      this.setProperties(properties);

      this._propertiesDataList.isLoading = false;
    } catch (err) {
      this._propertiesDataList.isLoading = false;

      Logging.error({
        className: "PaymentStore",
        methodName: "fetchAndSetProperties",
        message: "Properties konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetProperty = async (args: { propertyID: string }): Promise<void> => {
    try {
      this._currentProperty.isLoading = true;

      const property = await HttpPropertyService.getInstance().findOne({
        id: args.propertyID,
      });

      if (property == null) {
        this._currentProperty.isLoading = false;
        return;
      }

      this.setCurrentProperty(property);
      this._currentProperty.isLoading = false;
    } catch (err) {
      this._currentProperty.isLoading = false;

      Logging.error({
        className: "PropertyStore",
        methodName: "fetchAndSetProperty",
        message: "Property konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialProperty = (): Property => {
    const initalProperty: any = {};

    this.setCurrentProperty(initalProperty);

    return initalProperty;
  };

  createProperty = async (args: {
    property: Property;
  }): Promise<Property | undefined> => {
    try {
      this._currentProperty.isLoading = true;

      const createdProperty = await HttpPropertyService.getInstance().create({
        data: args.property,
      });

      if (createdProperty == null) {
        this._currentProperty.isLoading = false;
        return;
      }

      this._propertiesDataList.data.unshift(createdProperty);

      this.setCurrentProperty(createdProperty);
      this._currentProperty.isLoading = false;
      toast.success("Property wurde erfolgreich erstellt");

      return createdProperty;
    } catch (err) {
      this._currentProperty.isLoading = false;

      Logging.error({
        className: "PropertyStore",
        methodName: "createProperty",
        message: "Property konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateProperty = async (args: {
    property: Property;
  }): Promise<Property | undefined> => {
    try {
      this._currentProperty.isLoading = true;

      const updatedProperty = await HttpPropertyService.getInstance().updateOne(
        {
          id: args.property._id!,
          data: args.property,
        }
      );

      if (updatedProperty == null) {
        this._currentProperty.isLoading = false;
        return;
      }

      // find updated property item in this._propertiesDataList.data and replace it
      // and set property item as first item in list
      const propertyItemsWithoutUpdatedItem =
        this._propertiesDataList.data.filter(
          (item) => item._id !== updatedProperty._id
        );

      this._propertiesDataList.data = [
        updatedProperty,
        ...propertyItemsWithoutUpdatedItem,
      ];

      this.setCurrentProperty(updatedProperty);
      this._currentProperty.isLoading = false;
      toast.success("Property wurde erfolgreich aktualisiert");
      return updatedProperty;
    } catch (err) {
      this._currentProperty.isLoading = false;

      Logging.error({
        className: "PropertyStore",
        methodName: "updateProperty",
        message: "Property konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  archiveProperty = async (args: {
    property: Property;
  }): Promise<Property | undefined> => {
    try {
      this._currentProperty.isLoading = true;
      this._propertiesDataList.isLoading = true;

      const archivedProperty =
        await HttpPropertyService.getInstance().archiveOne({
          id: args.property._id!,
        });

      if (archivedProperty == null) {
        this._currentProperty.isLoading = false;
        this._propertiesDataList.isLoading = false;
        return;
      }

      // remove archived feed item from this._feedDataList.data
      const propertiesWithoutArchivedItem =
        this._propertiesDataList.data.filter(
          (item) => item._id !== args.property._id
        );

      this._propertiesDataList.data = propertiesWithoutArchivedItem;

      this._currentProperty.isLoading = false;
      this._propertiesDataList.isLoading = false;
      toast.success("Property wurde erfolgreich archiviert");
    } catch (err) {
      this._currentProperty.isLoading = false;
      this._propertiesDataList.isLoading = false;

      Logging.error({
        className: "PropertyStore",
        methodName: "archiveProperty",
        message: "Property konnte nicht archiviert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetProperties = async (
    searchTerm: string
  ): Promise<Property | undefined> => {
    try {
      if (this._propertiesDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetProperties({ refresh: true });
        return;
      }

      this._propertiesDataList.isLoading = true;

      const properties = await HttpPropertyService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (properties == null) {
        this._propertiesDataList.isLoading = false;
        return;
      }

      this.setPropertiesSearchResult(properties);
      this._propertiesDataList.isLoading = false;
    } catch (err) {
      this._propertiesDataList.isLoading = false;

      Logging.error({
        className: "PropertyStore",
        methodName: "searchAndSetProperties",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default PropertyStore;
