import classNames from "classnames";
import Image from "components/image.component/image.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React from "react";
import { ModalStore } from "stores/modal.store";
import { Exercise } from "../../../schemas/exercise.schemas/exercise.schema";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import "./device.exercises.list.component.scss";
import { SmallText } from "components/text.components/small.text.component/small.text.component";

interface DeviceExercisesListProps {
  exercises: Exercise[];
  className?: string;
  modalStore?: ModalStore;
  onClick?: (exercise: Exercise) => void;
  isLoading?: boolean;
}

const DeviceExercisesList = ({
  exercises,
  className,
  modalStore,
  isLoading,
  onClick,
}: DeviceExercisesListProps): JSX.Element => {
  const deviceExercisesListClass = classNames(className);
  return (
    <ListDataTable
      noDataMessage="Zu diesem Gerät sind noch keine Übungen zugeordnet."
      onClick={onClick}
      isLoading={isLoading}
      tableClassName={deviceExercisesListClass}
      data={exercises}
      gap={20}
      disableHeader
      columns={[
        {
          flex: 0,
        },
        {
          flex: 0,
        },
        {
          flex: 1,
        },
        {
          flex: 0,
        },
      ]}
      dataTableItemBuilder={(dataItem: Exercise, index: number) => {
        const agonistMuscleGroups = dataItem?.muscleGroups?.agonist;

        return {
          key: dataItem._id!,
          children: [
            {
              child: (
                <RunningText className="exercise-device-list-index">
                  {index + 1}
                </RunningText>
              ),
            },
            {
              child: (
                <Image
                  rounded={false}
                  imageUrl={dataItem.coverImageUrl}
                  size={ImageSizes.S}
                />
              ),
            },
            {
              child: (
                <div>
                  <RunningText>{dataItem.title.de}</RunningText>
                  {agonistMuscleGroups != null && (
                    <SmallText>
                      {agonistMuscleGroups
                        ?.map(
                          (muscleGroup, index) =>
                            `${muscleGroup?.title?.de}${
                              index !== agonistMuscleGroups?.length - 1
                                ? ","
                                : ""
                            }`
                        )
                        .join(" ")}
                    </SmallText>
                  )}
                </div>
              ),
            },
          ],
        };
      }}
      itemClassName="device-exercise-table-item"
    />
  );
};

export default inject("modalStore")(observer(DeviceExercisesList));
