import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import UserStore from "stores/user.store";
import previewIcon from "assets/icons/female_avatar.png";
import "./user.setup.page.scss";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { User } from "schemas/user.schemas/user.schema";
import StudioStore from "stores/studio.store";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import Column from "components/general.compoenents/column.component/column.component";
import Center from "components/general.compoenents/center.component/center.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { toast } from "react-toastify";
import Row from "components/general.compoenents/row.component/row.component";

interface UserSetupPageProps {
  userStore?: UserStore;
  studioStore?: StudioStore;
}

const UserSetupPage = ({
  userStore,
  studioStore,
}: UserSetupPageProps): JSX.Element => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const completeUserSetup = async (data: any): Promise<void> => {
    setIsLoading(true);

    const user: User = { ...userStore?.user, ...data, setupCompleted: true };

    const updatedUser = await userStore?.updateCurrentUser(user);
    if (updatedUser != null) {
      // Check if user got invitated to a studio
      const userInvitations =
        await HttpInvitationService.getInstance().getInvitationsOfCurrentUser();

      if (userInvitations.length > 0) {
        return navigate("/handle-invitations");
      } else {
        const initialStudio = await studioStore?.createStudio();

        if (initialStudio != null) {
          navigate(`/studios/${initialStudio._id}/device-setup`);
        }
      }
    }

    setIsLoading(false);
  };

  const _buildForm = (): JSX.Element => {
    return (
      <Center>
        <SizedContainer size={ContainerSizes.L}>
          <Column>
            <Headline className="mt-15">
              Erzähl uns was über deine{" "}
              <span className="color-primary">Person.</span>
            </Headline>
            <RunningText className="mb-20">
              Starte jetzt die vollumfänglich Fitness-App für ihre Mitglieder.
            </RunningText>
            <form
              className="user-setup-form"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(completeUserSetup, (errors) => {
                toast.error("Bitte überprüfe deine Eingaben");
              })}
              noValidate
            >
              <OutlinedTextInput
                label="Vorname"
                inputRef={register("firstName", {
                  required: "Vorname ist erforderlich",
                })}
                validationMessage={errors.firstName?.message?.toString()}
              />
              <OutlinedTextInput
                className="mt-30"
                label="Nachname"
                inputRef={register("lastName", {
                  required: "Nachname ist erforderlich",
                })}
                validationMessage={errors.lastName?.message?.toString()}
              />
              <OutlinedTextInput
                className="mt-30"
                label="Geburtsdatum"
                type="date"
                inputRef={register("dateOfBirth", {
                  required: "Geburtsdatum ist erforderlich",
                })}
                validationMessage={errors.dateOfBirth?.message?.toString()}
              />
              <Row className="mt-5 mb-5">
                <input
                  type="checkbox"
                  checked={privacyPolicyAccepted}
                  onChange={(event) => {
                    setPrivacyPolicyAccepted(event.target.checked);
                  }}
                />
                <RunningText className="ml-10">
                  Mit deiner Registrierung stimmst du unseren{" "}
                  <span
                    className="color-primary cursor-pointer"
                    onClick={() => {
                      window.open("https://www.gymo.io/datenschutz", "_blank");
                    }}
                  >
                    Datenschutzerklärungen
                  </span>{" "}
                  und unseren{" "}
                  <span
                    className="color-primary cursor-pointer"
                    onClick={() => {
                      window.open("https://www.gymo.io/agb", "_blank");
                    }}
                  >
                    AGB
                  </span>{" "}
                  zu.
                </RunningText>
              </Row>
              <FilledButton
                type="submit"
                label="Weiter"
                className="mt-15 full-width"
                disabled={!privacyPolicyAccepted}
                isLoading={isLoading}
              />
            </form>
          </Column>
        </SizedContainer>
      </Center>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="user-setup-preview-container">
        <ImageWithAuthHeader
          className="user-setup-preview-image"
          src={previewIcon}
        />
      </div>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildPreview()}
      leftGrow={1}
      rightChild={_buildForm()}
      rightGrow={1}
    />
  );
};

export default inject("userStore", "studioStore")(observer(UserSetupPage));
