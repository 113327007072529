import { arrayMoveImmutable } from "array-move";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SelectListItem from "components/general.compoenents/select.list.item.component/select.list.item.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { inject, observer } from "mobx-react";
import React from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import ExerciseStore from "stores/exercise.store";

interface ExerciseInfosProps {
  exerciseStore?: ExerciseStore;
}

const ExerciseInfos = ({ exerciseStore }: ExerciseInfosProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const currentExericse = exerciseStore?.currentExercise?.data;

  const infoTopics = (currentExericse?.infos ?? []).map((info) => {
    return { ...info, id: Math.random().toString() };
  });

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    if (!infoTopics) return;

    const sortedInfoTopics = arrayMoveImmutable(infoTopics, oldIndex, newIndex);
    currentExericse!.infos = sortedInfoTopics;

    exerciseStore?.setCurrentExercise(currentExericse);

    // auto update
    exerciseStore?.updateExercise({
      id: currentExericse!._id!,
      exercise: currentExericse!,
    });
  };

  // Detail page
  if (outlet != null) {
    return <>{outlet}</>;
  }

  return (
    <>
      <PageHeader label="Übungsinformationen">
        <FilledButton
          onClick={() => {
            exerciseStore?.addNewExerciseInfoListItem();
            navigate(`${location.pathname}/${0}`);
          }}
          label="Info-Element hinzufügen"
          color="secondary"
        />
      </PageHeader>

      <ComponentWrapper noPadding>
        <div>
          <Column>
            {infoTopics?.length === 0 && (
              <RunningText className="ml-15 mt-15 mb-15 mr-15">
                Keine Übungsinformationen vorhanden
              </RunningText>
            )}
            <SortableList
              data={infoTopics}
              onSortEnd={onSortEnd}
              itemBuilder={(data, index) => {
                return (
                  <SelectListItem
                    key={`info-topic-${index}`}
                    title={data.label?.de}
                    subTitle={`${data.data?.length} Einträge`}
                    draggable
                    onClick={() => {
                      navigate(`${location.pathname}/${index}`);
                    }}
                  />
                );
              }}
            />
          </Column>
        </div>
      </ComponentWrapper>
    </>
  );
};

export default inject("exerciseStore")(observer(ExerciseInfos));
