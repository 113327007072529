import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpTrainingPlanService extends GenericHttpClient<TrainingPlan> {
  static _instance: HttpTrainingPlanService;
  static getInstance(): HttpTrainingPlanService {
    if (this._instance == null) {
      this._instance = new HttpTrainingPlanService("/training-plans");
    }
    return this._instance;
  }
}
