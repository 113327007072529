import { Logging } from "globals/helpers/logging.helper";
import { Upload } from "schemas/upload.schema";
import { HttpClient } from "./config/http.client";

export class HttpUploadService extends HttpClient {
  static _instance: HttpUploadService;
  static getInstance(): HttpUploadService {
    if (this._instance == null || this._instance === undefined) {
      this._instance = new HttpUploadService();
    }
    return this._instance;
  }

  uploadFiles = async (args: {
    files: File[];
    folder: string;
  }): Promise<Upload[] | undefined> => {
    const formData = new FormData();

    for (let i = 0; i < args.files.length; i++) {
      formData.append("files", args.files[i]);
    }

    formData.append("folder", args.folder);

    try {
      const response = await this.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpUploadService",
        methodName: "uploadFiles",
        message: "Dateien konnten nicht hochgeladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}
