import CustomerNavList from "components/list.components/nav.list.components/customer.nav.list.component/customer.nav.list.component";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import React from "react";
import { useOutlet } from "react-router";
import "./customer.page.scss";
import customerPlaceholder from "assets/illustrations/placeholders/customer_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import { inject, observer } from "mobx-react";
import CustomerStore from "stores/customer.store";

interface CustomerPageProps {
  customerStore?: CustomerStore;
}

const CustomerPage = ({ customerStore }: CustomerPageProps): JSX.Element => {
  const outlet = useOutlet();

  let customers = customerStore?.customers?.data ?? [];

  // ignore the demo customer
  if (customers?.length === 1) {
    customers = [];
  }

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        collapseable
        leftChild={<CustomerNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Es haben sich noch keine Mitglieder registriert."
            description="Sobald sich Mitglieder bei deiner App registrieren, werden sie hier angezeigt."
            titleNothingSelected="Du hast noch kein Mitglied ausgewählt"
            descriptionNothingSelected="Wähle ein Mitglied aus, um zu den Details zu kommen."
            imagePath={customerPlaceholder}
            data={customers}
            imageMaxWidth={475}
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject("customerStore")(observer(CustomerPage));
