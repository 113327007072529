import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import FeedConfigForm from "./components/feed.config.form.component";
import StudioStore from "stores/studio.store";

interface FeedConfigDetailPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
}

const FeedConfigDetailPage = ({
  feedStore,
  studioStore,
}: FeedConfigDetailPageProps): JSX.Element => {
  const { feedConfigID } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(feedConfigID !== "new");
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formKey, setFormKey] = useState(feedConfigID);

  const feedConfig = feedStore?.currentFeedConfig?.data;

  useEffect(() => {
    initializePage();
  }, [feedConfigID]);

  const initializePage = async (): Promise<void> => {
    if (feedConfigID == null) {
      return;
    }

    if (feedConfigID !== "new") {
      feedStore?.fetchAndSetFeedConfigItem({ feedConfigID });
      setFormKey(feedConfigID);
      setIsEditing(true);
    }

    if (feedConfigID === "new") {
      feedStore?.createInitialFeedConfigItem();
      setFormKey(Math.random().toString());
      setIsEditing(false);
    }
  };

  const onSubmit = async (data: any): Promise<void> => {
    if (feedConfig == null) {
      return;
    }

    feedStore?.setCurrentFeedConfig({
      ...feedConfig,
      ...data,
    });

    if (!isEditing) {
      const createdFeedConfig = await feedStore?.createFeedConfig({
        feedConfig: data,
      });

      // navigate to new feed config nav list
      if (createdFeedConfig != null && studioStore?.studio?._id != null) {
        // fetch and set feed configs
        feedStore?.fetchAndSetFeedConfigs({
          refresh: true,
        });

        navigate(`/studios/${studioStore?.studio?._id}/feed-configs`);
      }
    } else {
      // update feed config item
      await feedStore?.updateFeedConfig({
        id: feedConfig._id!,
        feedConfig: data,
      });

      // fetch and set feed configs
      feedStore?.fetchAndSetFeedConfigs({
        refresh: true,
      });
    }

    setFormIsDirty(false);
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        title={isEditing ? "Kategorie bearbeiten" : "Kategorie hinzufügen"}
        showBackButton
        rootPath="feed-configs"
        actions={
          <FilledButton
            disabled={!formIsDirty}
            label="Speichern"
            type="submit"
            form="feed-config-form"
            color="secondary"
          />
        }
      ></PageHeadline>
    );
  };

  if (feedStore?.currentFeedConfig?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={feedConfigID === "new" ? formKey : feedConfigID}>
        <FeedConfigForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFormSubmit={onSubmit}
          onDirty={(isDirty) => {
            setFormIsDirty(isDirty);
          }}
        />
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "feedStore",
  "studioStore"
)(observer(FeedConfigDetailPage));
