import { serviceSchema } from "schemas/service.schemas/service.schema";
import { System } from "schemas/system.schema";
import * as yup from "yup";

export const eventSchema = yup.object().shape({
  _id: yup.string().nullable(),
  service: serviceSchema.required(),
  timeSlotID: yup.string().notRequired(),
  startTime: yup.string().required("Startzeit ist verpflichtend"),
  endTime: yup.string().required("Endzeit ist verpflichtend"),
  color: yup.string().notRequired(),
  info: yup.string().notRequired(),
  timeZone: yup
    .string()
    .oneOf(["Europe/Vienna"])
    .default("Europe/Vienna")
    .notRequired(),
  maxParticipants: yup
    .number()
    .typeError("Max Teilnehmer muss eine Zahl sein")
    .integer()
    .positive("Max Teilnehmer muss größer als 0 sein")
    .notRequired(),
  date: yup.date().required(),
});

export interface Event extends yup.InferType<typeof eventSchema> {
  type: "SINGLE" | "SERIES" | "MODIFIED_SERIES";
  status: "ACTIVE" | "CANCELED";
  system?: System;
}

export const eventToJson = (event: Event): any => {
  return {
    ...event,
    date: event.date?.toISOString(),
    service: event.service._id,
  };
};
