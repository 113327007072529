import * as yup from "yup";
import { propertySchema } from "schemas/property.schemas/property.schema";
import { systemSchema } from "schemas/system.schema";

const timeSlotSchema = yup.object().shape({
  _id: yup.string(),
  weekDay: yup
    .number()
    .integer()
    .min(1)
    .max(7)
    .typeError("Wochentag muss gesetzt sein")
    .required("Wochentag ist verpflichtend"),
  startTime: yup.string().required("Startzeit ist verpflichtend"),
  endTime: yup.string().required("Endzeit ist verpflichtend"),
  timeZone: yup
    .string()
    .oneOf(["Europe/Vienna"])
    .default("Europe/Vienna")
    .notRequired(),
  maxParticipants: yup
    .number()
    .typeError("Max Teilnehmer muss eine Zahl sein")
    .integer()
    .positive("Max Teilnehmer muss größer als 0 sein")
    .required("Max Teilnehmer ist verpflichtend"),
  info: yup.string(),
});

export interface TimeSlot extends yup.InferType<typeof timeSlotSchema> {}

export const serviceSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  content: yup.mixed().required("Kursinhalt ist verpflichtend"),
  coverImageUrl: yup.string().required("Titelbild ist verpflichtend"),
  timeSlots: yup.array().of(timeSlotSchema).required().default([]),
  category: propertySchema([])
    .required("Kategorie ist verpflichtend")
    .default(undefined),
  properties: yup.array().of(propertySchema([])),
  availableFrom: yup
    .date()
    .typeError("Verfügbar von muss ein Datum sein")
    .notRequired(),
  availableUntil: yup
    .date()
    .typeError("Verfügbar bis muss ein Datum sein")
    .notRequired(),
  isPublished: yup.boolean().required().default(false),
  color: yup.string().notRequired(),
  system: systemSchema.notRequired().default(undefined),
});

export interface Service extends yup.InferType<typeof serviceSchema> {}

export const serviceToJson = (service: Service): any => {
  return {
    ...service,
    category: service.category._id,
    properties: service.properties?.map((property) => property._id),
  };
};
