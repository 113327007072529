import { System } from "schemas/system.schema";
import { User } from "schemas/user.schemas/user.schema";
import { Event } from "schemas/events.schemas/event.schema";

export interface Booking {
  _id: string;
  user?: User;
  userInfo?: BookingUserInfo;
  status?: "BOOKED" | "QUEUE" | "CANCELED";
  additionalBookingInfo?: string;
  date: Date;
  event: Event;
  timeSlotID?: string;
  system?: System;
}

interface BookingUserInfo {
  firstName: string;
  lastName: string;
  gender?: string;
  dateOfBirth?: Date;
  email: string;
}

export const bookingToJson = (booking: Booking): any => {
  return {
    ...booking,
    user: booking.user?._id,
  };
};
