import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useOutlet } from "react-router-dom";
import DeviceNavList from "components/list.components/nav.list.components/device.nav.list.component/device.nav.list.component";

const DevicePage = (): JSX.Element => {
  const outlet = useOutlet();

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<DeviceNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
      ></SplitLayout>
    </MainLayout>
  );
};

export default DevicePage;
