import React from "react";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import "./sign.in.page.scss";
import { inject, observer } from "mobx-react";
import UserStore from "stores/user.store";
import AuthForm from "components/auth.components/auth.form.component/auth.form.component";
import Headline from "components/text.components/headline.component/headline.component";
import Row from "components/general.compoenents/row.component/row.component";

interface SignInPageProps {
  userStore?: UserStore;
}

const SignInPage = ({ userStore }: SignInPageProps): JSX.Element => {
  const _buildPreview = (): JSX.Element => {
    return (
      <div className="sign-in-form-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="sign-in-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="sign-in-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="sign-in-page">
      <div className="sign-in-page-wrapper">
        {_buildPreview()}
        <div className="auth-form-wrapper">
          <AuthForm />
        </div>
      </div>
    </Row>
  );
};

export default inject("userStore")(observer(SignInPage));
