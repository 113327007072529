import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useNavigate, useOutlet } from "react-router-dom";
import TrainingPlanNavList from "components/list.components/nav.list.components/training.plan.nav.list.component/training.plan.nav.list.component";
import trainingPlanPlaceholder from "assets/illustrations/placeholders/plan_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import TrainingPlanStore from "stores/training.plan.store";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface TrainingPlanPageProps {
  trainingPlanStore?: TrainingPlanStore;
  studioStore?: StudioStore;
}

const TrainingPlanPage = ({
  trainingPlanStore,
  studioStore,
}: TrainingPlanPageProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const trainingPlans = trainingPlanStore?.trainingPlans?.data ?? [];

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<TrainingPlanNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Du hast noch keine Trainingspläne hinzugefügt"
            description="Füge ein Trainingsplan hinzu, um ihn hier anzuzeigen."
            titleNothingSelected="Du hast noch keinen Trainingsplan ausgewählt"
            descriptionNothingSelected="Wähle einen Trainingsplan aus, um ihn hier anzuzeigen."
            imagePath={trainingPlanPlaceholder}
            data={trainingPlans}
            noDataActions={
              <FilledButton
                label="Trainingsplan hinzufügen"
                onClick={() => {
                  if (studioStore?.studio?._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/training-plans/new/info`
                    );
                  }
                }}
              />
            }
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject(
  "trainingPlanStore",
  "studioStore"
)(observer(TrainingPlanPage));
