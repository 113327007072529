import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { FeedConfig } from "schemas/feed.schemas/feed.config.schema";
import { Feed, feedToJson } from "schemas/feed.schemas/feed.schema";
import { HttpFeedService } from "services/httpClients/http.feed.client";
import { HttpFeedConfigService } from "services/httpClients/http.feed.config.client";

class FeedStore {
  // Properties
  private _feedConfigList: PaginationDataList<FeedConfig> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _articleConfigList: PaginationDataList<FeedConfig> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentFeedConfigData: DataItem<FeedConfig> = {
    data: undefined,
    isLoading: false,
  };

  private _feedDataList: PaginationDataList<Feed> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _feedDataListSearchResult: Feed[] = [];

  private _archivedFeedDataList: PaginationDataList<Feed> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentFeedData: DataItem<Feed> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setFeed = (feed: Feed[]): void => {
    this._feedDataList.data = feed;
  };

  setFeedSearchResult = (feed: Feed[]): void => {
    this._feedDataListSearchResult = feed;
  };

  setArchivedFeed = (feed: Feed[]): void => {
    this._archivedFeedDataList.data = feed;
  };

  setCurrentFeed = (feed: Feed | undefined): void => {
    this._currentFeedData.data = feed;
  };

  setFeedConfigs = (feedConfigs: FeedConfig[]): void => {
    this._feedConfigList.data = feedConfigs;
  };

  setArticleConfigs = (articleConfigs: FeedConfig[]): void => {
    this._articleConfigList.data = articleConfigs;
  };

  setCurrentFeedConfig = (feedConfig: FeedConfig | undefined): void => {
    this._currentFeedConfigData.data = feedConfig;
  };

  setEditingFeedConfig = (feedConfig: FeedConfig | undefined): void => {
    this._currentFeedConfigData.data = feedConfig;
  };

  //! Getters
  get feed(): PaginationDataList<Feed> | undefined {
    if (this._feedDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._feedDataList));
  }

  get feedSearchResult(): Feed[] | undefined {
    if (this._feedDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._feedDataListSearchResult));
  }

  get archivedFeed(): PaginationDataList<Feed> | undefined {
    if (this._archivedFeedDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._archivedFeedDataList));
  }

  get currentFeed(): DataItem<Feed> | undefined {
    if (this._feedDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentFeedData));
  }

  get feedConfigs(): PaginationDataList<FeedConfig> | undefined {
    if (this._feedConfigList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._feedConfigList));
  }

  get articleConfigs(): PaginationDataList<FeedConfig> | undefined {
    if (this._articleConfigList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._articleConfigList));
  }

  get currentFeedConfig(): DataItem<FeedConfig> | undefined {
    if (this._currentFeedConfigData == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentFeedConfigData));
  }

  //! Methods
  fetchAndSetFeed = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._feedDataList != null &&
        this._feedDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._feedDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._feedDataList.pageIndex += 1;
      } else {
        this._feedDataList.pageIndex = 0;
        this._feedDataList.data = [];
      }

      this._feedDataList.isLoading = true;

      const feed = await HttpFeedService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._feedDataList.pageIndex,
            itemsInPage: this._feedDataList.itemsInPage,
          }),
        },
      });

      if (feed == null) {
        this._feedDataList.isLoading = false;
        return;
      }

      this.setFeed(feed);
      this._feedDataList.isLoading = false;
    } catch (err) {
      this._feedDataList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetFeed",
        message: "Feed konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetFeedItem = async (args: { feedID: string }): Promise<void> => {
    try {
      this._currentFeedData.isLoading = true;

      const article = await HttpFeedService.getInstance().findOne({
        id: args.feedID,
      });

      if (article == null) {
        this._currentFeedData.isLoading = false;
        return;
      }

      this.setCurrentFeed(article);
      this._currentFeedData.isLoading = false;
    } catch (err) {
      this._currentFeedData.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetFeedItem",
        message: "Beitrag konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetFeed = async (searchTerm: string): Promise<void> => {
    try {
      if (this._feedDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetFeed({ refresh: true });
        return;
      }

      this._feedDataList.isLoading = true;

      const feedItems = await HttpFeedService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (feedItems == null) {
        this._feedDataList.isLoading = false;
        return;
      }

      this.setFeedSearchResult(feedItems);
      this._feedDataList.isLoading = false;
    } catch (err) {
      this._feedDataList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "searchAndSetFeed",
        message: "Feed konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetFeedConfigs = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._feedConfigList != null &&
        this._feedConfigList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._feedConfigList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._feedConfigList.pageIndex += 1;
      } else {
        this._feedConfigList.pageIndex = 0;
        this._feedConfigList.data = [];
      }

      this._feedConfigList.isLoading = true;

      const feedConfigs = await HttpFeedConfigService.getInstance().find({});

      if (feedConfigs == null) {
        this._feedConfigList.isLoading = false;
        return;
      }

      this.setFeedConfigs(feedConfigs);
      this._feedConfigList.isLoading = false;
    } catch (err) {
      this._feedConfigList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetFeedConfigs",
        message: "Feed konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetArticleFeedConfigs = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._articleConfigList != null &&
        this._articleConfigList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._articleConfigList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._articleConfigList.pageIndex += 1;
      } else {
        this._articleConfigList.pageIndex = 0;
        this._articleConfigList.data = [];
      }

      this._articleConfigList.isLoading = true;

      const feedConfigs = await HttpFeedConfigService.getInstance().find({
        query: { dataType: "FEED" },
      });

      if (feedConfigs == null) {
        this._articleConfigList.isLoading = false;
        return;
      }

      this.setArticleConfigs(feedConfigs);
      this._articleConfigList.isLoading = false;
    } catch (err) {
      this._articleConfigList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetArticleFeedConfigs",
        message: "Feed konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetFeedConfigItem = async (args: {
    feedConfigID: string;
  }): Promise<void> => {
    try {
      this._currentFeedConfigData.isLoading = true;

      const feedConfigConfig =
        await HttpFeedConfigService.getInstance().findOne({
          id: args.feedConfigID,
        });

      if (feedConfigConfig == null) {
        this._currentFeedConfigData.isLoading = false;
        return;
      }

      this.setCurrentFeedConfig(feedConfigConfig);
      this._currentFeedConfigData.isLoading = false;
    } catch (err) {
      this._currentFeedConfigData.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetFeedConfigItem",
        message: "Feed konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateFeedConfig = async (args: {
    id: string;
    feedConfig: FeedConfig;
  }): Promise<void> => {
    try {
      this._currentFeedConfigData.isLoading = true;

      const updatedFeedConfig =
        await HttpFeedConfigService.getInstance().updateOne({
          id: args.id,
          data: args.feedConfig,
        });

      if (updatedFeedConfig == null) {
        this._currentFeedConfigData.isLoading = false;
        return;
      }

      this.setCurrentFeedConfig(updatedFeedConfig);

      // filter item in this._feedConfigList.data and update it
      const feedConfigListWithoutUpdatedItem = this._feedConfigList.data.filter(
        (item) => item._id !== updatedFeedConfig._id
      );

      this._feedConfigList.data = [
        updatedFeedConfig,
        ...feedConfigListWithoutUpdatedItem,
      ];

      this._currentFeedConfigData.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "FeedStore",
        methodName: "updateFeedConfig",
        message: "Feed konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  archiveFeedItem = async (feedItem: Feed): Promise<void> => {
    try {
      this._currentFeedData.isLoading = true;
      this._feedDataList.isLoading = true;

      const archivedFeedItem = await HttpFeedService.getInstance().archiveOne({
        id: feedItem._id!,
      });

      if (archivedFeedItem == null) {
        this._currentFeedData.isLoading = false;
        this._feedDataList.isLoading = false;
        return;
      }

      // remove archived feed item from this._feedDataList.data
      const feedItemsWithoutArchivedItem = this._feedDataList.data.filter(
        (item) => item._id !== feedItem._id
      );

      this._feedDataList.data = feedItemsWithoutArchivedItem;

      this._currentFeedData.isLoading = false;
      this._feedDataList.isLoading = false;
      toast.success("Beitrag wurde erfolgreich archiviert");
    } catch (err) {
      this._currentFeedData.isLoading = false;
      this._feedDataList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "archiveFeedItem",
        message: "Beitrag konnte nicht archiviert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialFeedItem = (): Feed => {
    const initialFeedItem: any = {};

    this.setCurrentFeed(initialFeedItem);

    return initialFeedItem;
  };

  createInitialFeedConfigItem = (): FeedConfig => {
    const initialFeedConfigItem: any = {};

    this.setCurrentFeedConfig(initialFeedConfigItem);

    return initialFeedConfigItem;
  };

  createFeedConfig = async (args: {
    feedConfig: FeedConfig;
  }): Promise<FeedConfig | undefined> => {
    try {
      this._currentFeedConfigData.isLoading = true;

      const createdFeedConfig =
        await HttpFeedConfigService.getInstance().create({
          data: args.feedConfig,
        });

      if (createdFeedConfig == null) {
        this._currentFeedConfigData.isLoading = false;
        return;
      }

      this._feedConfigList.data = [
        createdFeedConfig,
        ...this._feedConfigList.data,
      ];

      this.setCurrentFeedConfig(createdFeedConfig);

      this._currentFeedConfigData.isLoading = false;

      toast.success("Feed wurde erfolgreich erstellt");
      return createdFeedConfig;
    } catch (err) {
      this._currentFeedConfigData.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "createFeedConfig",
        message: "Feed konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createFeedItem = async (feedItem: Feed): Promise<Feed | undefined> => {
    try {
      this._currentFeedData.isLoading = true;
      this._feedDataList.isLoading = true;

      const createdFeedItem = await HttpFeedService.getInstance().create({
        data: feedToJson(feedItem),
      });

      if (createdFeedItem == null) {
        this._currentFeedData.isLoading = false;
        this._feedDataList.isLoading = false;
        return;
      }

      this._feedDataList.data.unshift(createdFeedItem);

      this._currentFeedData.isLoading = false;
      this._feedDataList.isLoading = false;
      toast.success("Beitrag wurde erfolgreich erstellt");
      return createdFeedItem;
    } catch (err) {
      this._currentFeedData.isLoading = false;
      this._feedDataList.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "createFeedItem",
        message: "Beitrag konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateFeedItem = async (feedItem: Feed): Promise<Feed | undefined> => {
    try {
      this._feedDataList.isLoading = true;
      this._currentFeedData.isLoading = true;

      const updatedFeedItem = await HttpFeedService.getInstance().updateOne({
        id: feedItem._id!,
        data: feedToJson(feedItem),
      });

      if (updatedFeedItem == null) {
        this._feedDataList.isLoading = false;
        this._currentFeedData.isLoading = false;
        return;
      }

      // find updated feed item in this._feedDataList.data and replace it
      // and set feed item as first item in list
      const feedItemsWithoutUpdatedItem = this._feedDataList.data.filter(
        (item) => item._id !== updatedFeedItem._id
      );

      this._feedDataList.data = [
        updatedFeedItem,
        ...feedItemsWithoutUpdatedItem,
      ];

      this.setCurrentFeed(updatedFeedItem);

      this._feedDataList.isLoading = false;
      this._currentFeedData.isLoading = false;
      toast.success("Beitrag wurde erfolgreich aktualisiert");
      return updatedFeedItem;
    } catch (err) {
      this._feedDataList.isLoading = false;
      this._currentFeedData.isLoading = false;

      Logging.error({
        className: "FeedStore",
        methodName: "updateFeedItem",
        message: "Beitrag konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetArchivedFeedItems = async (): Promise<void> => {
    try {
      this._archivedFeedDataList.isLoading = true;

      const feedItems = await HttpFeedService.getInstance().findArchived();

      if (feedItems == null) {
        this._archivedFeedDataList.isLoading = false;
        return;
      }

      this.setArchivedFeed(feedItems);
      this._archivedFeedDataList.isLoading = false;
    } catch (err) {
      this._archivedFeedDataList.isLoading = false;
      Logging.error({
        className: "FeedStore",
        methodName: "fetchAndSetArchivedFeedItems",
        message: "Archivierte Beiträge konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default FeedStore;
