import { makeAutoObservable } from "mobx";
import {
  DeviceBrand,
  deviceBrandToJson,
} from "schemas/device.schemas/device.brand.schema";
import { HttpDeviceBrandService } from "services/httpClients/http.device.brand.client";
import { toast } from "react-toastify";
import {
  DataItem,
  getSkipAndLimitFromPage,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { Device } from "schemas/device.schemas/device.schema";
import { HttpDeviceService } from "services/httpClients/http.device.client";
import { Logging } from "globals/helpers/logging.helper";

class DeviceBrandStore {
  // Properties

  private _deviceBrandsDataList: PaginationDataList<DeviceBrand> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _deviceBrandsDataListSearchResult: DeviceBrand[] = [];

  private _currentDeviceBrandDevicesDataList: PaginationDataList<Device> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentDeviceBrand: DataItem<DeviceBrand> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setDeviceBrands = (deviceBrands: DeviceBrand[]): void => {
    this._deviceBrandsDataList.data = [
      ...this._deviceBrandsDataList.data,
      ...deviceBrands,
    ];
  };

  setDeviceBrandsSearchResult = (deviceBrands: DeviceBrand[]): void => {
    this._deviceBrandsDataListSearchResult = deviceBrands;
  };

  //! Setter
  setDeviceBrandDevices = (devices: Device[]): void => {
    this._currentDeviceBrandDevicesDataList.data = [
      ...this._currentDeviceBrandDevicesDataList.data,
      ...devices,
    ];
  };

  setCurrentDeviceBrand = (deviceBrand: DeviceBrand): void => {
    this._currentDeviceBrand.data = deviceBrand;
  };

  //! Getters
  get deviceBrands(): PaginationDataList<DeviceBrand> | undefined {
    if (this._deviceBrandsDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._deviceBrandsDataList));
  }

  get deviceBrandsSearchResult(): DeviceBrand[] | undefined {
    if (this._deviceBrandsDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._deviceBrandsDataListSearchResult));
  }

  get currentDeviceBrand(): DataItem<DeviceBrand> | undefined {
    if (this._currentDeviceBrand == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceBrand));
  }

  get currentDeviceBrandDevices(): PaginationDataList<Device> | undefined {
    if (this._currentDeviceBrandDevicesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceBrandDevicesDataList));
  }

  //! Methods
  fetchAndSetDeviceBrands = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._deviceBrandsDataList != null &&
        this._deviceBrandsDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._deviceBrandsDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._deviceBrandsDataList.pageIndex += 1;
      } else {
        this._deviceBrandsDataList.pageIndex = 0;
        this._deviceBrandsDataList.data = [];
      }

      this._deviceBrandsDataList.isLoading = true;

      const deviceBrands = await HttpDeviceBrandService.getInstance().find({
        query: getSkipAndLimitFromPage({
          pageIndex: this._deviceBrandsDataList.pageIndex,
          itemsInPage: this._deviceBrandsDataList.itemsInPage,
        }),
      });

      if (deviceBrands == null) {
        this._deviceBrandsDataList.isLoading = false;
        return;
      }

      this.setDeviceBrands(deviceBrands);
      this._deviceBrandsDataList.isLoading = false;
    } catch (err) {
      this._deviceBrandsDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "fetchAndSetDeviceBrands",
        message: "Device Brands konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetDeviceBrand = async (args: {
    deviceBrandID: string;
  }): Promise<void> => {
    try {
      this._currentDeviceBrand.isLoading = true;

      const deviceBrand = await HttpDeviceBrandService.getInstance().findOne({
        id: args.deviceBrandID,
      });

      if (deviceBrand == null) {
        this._currentDeviceBrand.isLoading = false;
        return;
      }

      this.setCurrentDeviceBrand(deviceBrand);
      this._currentDeviceBrand.isLoading = false;
    } catch (err) {
      this._currentDeviceBrand.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "fetchAndSetDeviceBrand",
        message: "Device Brand konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetDeviceBrandDevices = async (args: {
    deviceBrandID: string;
    loadMore?: boolean;
    refresh?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._currentDeviceBrandDevicesDataList != null &&
        this._currentDeviceBrandDevicesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._currentDeviceBrandDevicesDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._currentDeviceBrandDevicesDataList.pageIndex += 1;
      } else {
        this._currentDeviceBrandDevicesDataList.pageIndex = 0;
        this._currentDeviceBrandDevicesDataList.data = [];
      }

      this._currentDeviceBrandDevicesDataList.isLoading = true;

      const devices = await HttpDeviceService.getInstance().find({
        query: {
          brand: args.deviceBrandID,
          ...getSkipAndLimitFromPage({
            pageIndex: this._deviceBrandsDataList.pageIndex,
            itemsInPage: this._deviceBrandsDataList.itemsInPage,
          }),
        },
      });

      if (devices == null) {
        this._currentDeviceBrandDevicesDataList.isLoading = false;
        return;
      }

      this.setDeviceBrandDevices(devices);
      this._currentDeviceBrandDevicesDataList.isLoading = false;
    } catch (err) {
      this._currentDeviceBrandDevicesDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "fetchAndSetDeviceBrandDevices",
        message: "Devices für Brand konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialDeviceBrand = (): DeviceBrand => {
    const initialDeviceBrand: any = {};

    this.setCurrentDeviceBrand(initialDeviceBrand);

    return initialDeviceBrand;
  };

  createDeviceBrand = async (
    deviceBrand: DeviceBrand
  ): Promise<DeviceBrand | undefined> => {
    try {
      this._currentDeviceBrand.isLoading = true;
      this._deviceBrandsDataList.isLoading = true;

      const newDeviceBrand = await HttpDeviceBrandService.getInstance().create({
        data: deviceBrandToJson(deviceBrand),
      });

      if (newDeviceBrand == null) {
        this._currentDeviceBrand.isLoading = false;
        this._deviceBrandsDataList.isLoading = false;
        return;
      }

      this._deviceBrandsDataList.data.unshift(newDeviceBrand);
      this.setCurrentDeviceBrand(newDeviceBrand);

      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;
      toast.success("Hersteller wurde erfolgreich erstellt");
      return newDeviceBrand;
    } catch (err) {
      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "createDeviceBrand",
        message: "Hersteller konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateDeviceBrand = async (
    id: string,
    deviceBrand: DeviceBrand
  ): Promise<DeviceBrand | undefined> => {
    try {
      this._currentDeviceBrand.isLoading = true;
      this._deviceBrandsDataList.isLoading = true;

      const updatedDeviceBrand =
        await HttpDeviceBrandService.getInstance().updateOne({
          id,
          data: deviceBrandToJson(deviceBrand),
        });

      if (updatedDeviceBrand == null) {
        this._currentDeviceBrand.isLoading = false;
        this._deviceBrandsDataList.isLoading = false;
        return;
      }

      // find updated brand item in this._deviceBrandsDataList.data and replace it
      // and set brand item as first item in list
      const brandItemsWithoutUpdatedItem =
        this._deviceBrandsDataList.data.filter(
          (item) => item._id !== updatedDeviceBrand._id
        );

      this._deviceBrandsDataList.data = [
        updatedDeviceBrand,
        ...brandItemsWithoutUpdatedItem,
      ];

      this.setCurrentDeviceBrand(updatedDeviceBrand);

      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;
      toast.success("Hersteller wurde erfolgreich aktualisiert");
      return updatedDeviceBrand;
    } catch (err) {
      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "updateDeviceBrand",
        message: "Hersteller konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  deleteDeviceBrand = async (
    deviceBrand: DeviceBrand
  ): Promise<DeviceBrand | undefined> => {
    try {
      this._currentDeviceBrand.isLoading = true;
      this._deviceBrandsDataList.isLoading = true;

      const deleteDeviceBrand =
        await HttpDeviceBrandService.getInstance().deleteOne({
          id: deviceBrand._id!,
        });

      //! TODO @tobias: Hast du eine Idee wie wir hier an den status vom response kommen?
      //! weil dann können wir bessere toast messages machen wie zb: bei status 400 : es sind noch Geräte mit diesem Hersteller verknüpft
      if (deleteDeviceBrand == null) {
        this._currentDeviceBrand.isLoading = false;
        this._deviceBrandsDataList.isLoading = false;
        return;
      }

      // remove deleted device brand from this._deviceBrandsDataList.data
      const deviceBrandsWithoutDeletedItem =
        this._deviceBrandsDataList.data.filter(
          (deviceBrand) => deviceBrand._id !== deleteDeviceBrand._id
        );

      this._deviceBrandsDataList.data = deviceBrandsWithoutDeletedItem;

      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;
      toast.success("Hersteller wurde erfolgreich gelöscht");
      return;
    } catch (err) {
      this._currentDeviceBrand.isLoading = false;
      this._deviceBrandsDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "deleteDeviceBrand",
        message: "Hersteller konnte nicht gelöscht werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetDeviceBrands = async (searchTerm: string): Promise<void> => {
    try {
      if (this._deviceBrandsDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetDeviceBrands({ refresh: true });
        return;
      }

      this._deviceBrandsDataList.isLoading = true;

      const deviceBrands = await HttpDeviceBrandService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (deviceBrands == null) {
        this._deviceBrandsDataList.isLoading = false;
        return;
      }

      this.setDeviceBrandsSearchResult(deviceBrands);
      this._deviceBrandsDataList.isLoading = false;
    } catch (err) {
      this._deviceBrandsDataList.isLoading = false;

      Logging.error({
        className: "DeviceBrandStore",
        methodName: "searchAndSetDeviceBrands",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default DeviceBrandStore;
