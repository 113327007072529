import { faTrash, faBars, faXmark } from "@fortawesome/pro-regular-svg-icons";
import CollapsibleComponent from "components/general.compoenents/collapsible.component/collapsible.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { ContainerSizes } from "globals/enums/global.enum";
import Image from "components/image.component/image.component";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlanExercise } from "schemas/training.plan.schemas/plan.exercise.schema";
import { FormState, UseFormRegister } from "react-hook-form";
import { ExerciseListFormSchema } from "../training.plan.exercise.list.component";
import "./training.plan.exercise.list.item.component.scss";

interface TrainingPlanExerciseListItemProps {
  planExercise: PlanExercise;
  exerciseIndex: number;
  register: UseFormRegister<any>;
  formState: FormState<ExerciseListFormSchema>;
  onOpening?: () => void;
  isOpened?: boolean;
  onAddSet: (index: number) => void;
  onRemoveSet: (exerciseIndex: number, setIndex: number) => void;
  onRemoveExercise: (index: number) => void;
  setFormIsDirty?: (isDirty: boolean) => void;
}

const TrainingPlanExerciseListItem = ({
  planExercise,
  exerciseIndex,
  register,
  formState,
  onOpening,
  isOpened,
  onAddSet,
  onRemoveSet,
  onRemoveExercise,
  setFormIsDirty,
}: TrainingPlanExerciseListItemProps): JSX.Element => {
  const { errors } = formState;

  return (
    <CollapsibleComponent
      key={planExercise._id!}
      isOpened={isOpened}
      onOpening={onOpening}
      trigger={
        <Row alignItems="center" className="pr-15">
          <Image imageUrl={planExercise.exercise.coverImageUrl} />
          <Column className="ml-15">
            <TitleText>{planExercise.exercise.title.de}</TitleText>
            <RunningText>
              {planExercise?.exercise?.muscleGroups?.agonist?.map(
                (muscleGroup, index) =>
                  `${muscleGroup.title.de}${
                    index !==
                    planExercise?.exercise?.muscleGroups?.agonist?.length - 1
                      ? ", "
                      : ""
                  }`
              )}
            </RunningText>
          </Column>

          <IconButton
            icon={faTrash}
            className="mr-15"
            onClick={() => {
              onRemoveExercise?.(exerciseIndex);
            }}
          />
          <FontAwesomeIcon icon={faBars} className="cursor-pointer" />
        </Row>
      }
      transitionTime={200}
      overflowWhenOpen="initial"
      className="training-plan-exercise-item"
      openedClassName="training-plan-exercise-item-opened"
    >
      <Row>
        <Column
          className="training-plan-exercise-item-content mt-15 mr-15"
          alignItems="flex-start"
        >
          {planExercise.sets.map((set, index) => {
            return (
              <SizedContainer
                key={`$set-item-${index}`}
                size={ContainerSizes.L}
              >
                <Row alignItems="center">
                  <div className="exercise-index-label">
                    <TitleText
                      className={index !== 0 ? "mr-10" : "mr-10 mt-25"}
                    >{`${index + 1}.`}</TitleText>
                  </div>
                  <Column>
                    {index === 0 && (
                      <TitleText className="mt-15">Wdh.</TitleText>
                    )}
                    <OutlinedTextInput
                      type="number"
                      inputRef={register(
                        `exercises[${exerciseIndex}].sets[${index}].reps`
                      )}
                      validationMessage={errors?.exercises?.[
                        exerciseIndex
                      ]?.sets?.[index]?.reps?.message?.toString()}
                    />
                  </Column>
                  <FontAwesomeIcon
                    className={`x-mark-icon ${index === 0 ? "mt-30" : ""}`}
                    icon={faXmark}
                  />
                  <Column>
                    {index === 0 && <TitleText className="mt-15">KG</TitleText>}
                    <OutlinedTextInput
                      type="number"
                      allowDecimalNumbers
                      inputRef={register(
                        `exercises[${exerciseIndex}].sets[${index}].weight`
                      )}
                      validationMessage={errors?.exercises?.[
                        exerciseIndex
                      ]?.sets?.[index]?.weight?.message?.toString()}
                    />
                  </Column>
                  <div className="trash-icon">
                    {planExercise.sets.length > 1 ? (
                      <IconButton
                        icon={faTrash}
                        className={index === 0 ? "mt-30" : ""}
                        onClick={() => {
                          onRemoveSet?.(exerciseIndex, index);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Row>
              </SizedContainer>
            );
          })}
          <LinkButton
            disabled={planExercise.sets.length >= 9}
            label="Satz hinzufügen"
            onClick={() => {
              onAddSet?.(exerciseIndex);
            }}
          />
        </Column>
        <Column
          className="mt-25 ml-15"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <TitleText className="mt-10">Notiz</TitleText>
          <OutlinedTextArea
            rows={3}
            inputRef={register(`exercises[${exerciseIndex}].info`)}
            validationMessage={formState.errors?.exercises?.[
              exerciseIndex
            ]?.info?.message?.toString()}
          />
        </Column>
      </Row>
    </CollapsibleComponent>
  );
};

export default TrainingPlanExerciseListItem;
