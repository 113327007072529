import { languageSchema } from "schemas/language.string.schema";
import { deviceBrandSchema } from "./device.brand.schema";
import { propertySchema } from "schemas/property.schemas/property.schema";
import * as yup from "yup";
import { Localization } from "schemas/studio.schemas/studio.schema";
import { systemSchema } from "schemas/system.schema";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const deviceSchema = (languages: Localization[]) =>
  yup.object().shape({
    _id: yup.string().notRequired(),
    title: languageSchema(languages).required("Überschrift ist erforderlich"),
    coverImageUrl: yup.string().notRequired(),
    sku: yup.string().notRequired(),
    brand: deviceBrandSchema
      .required("Hersteller ist erforderlich")
      .default(undefined),
    properties: yup
      .array()
      .of(propertySchema(languages))
      .required("Eigenschaften sind erforderlich"),
    system: systemSchema.notRequired().default(undefined),
  });

export interface Device extends yup.InferType<ReturnType<typeof deviceSchema>> {
  coverImageUrl?: string;
}

export const studioDeviceSchema = yup.object().shape({
  archivedAt: yup.date().nullable(),
  archived: yup.boolean().default(false),
  device: deviceSchema([]).required(),
});

export interface StudioDevice
  extends yup.InferType<typeof studioDeviceSchema> {}

export const deviceToJson = (device: Device): any => {
  return {
    ...device,
    brand: device.brand?._id,
    properties: device.properties.map((property) => property._id),
  };
};
