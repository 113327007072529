import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { GenericHttpClient } from "./config/http.generic.client";

interface ErciseUpdateIsAvailableResponse {
  isUpdateAvailable: boolean;
  currentVersion?: string;
  latestVersion?: string;
}

export class HttpExerciseService extends GenericHttpClient<Exercise> {
  static _instance: HttpExerciseService;
  static getInstance(): HttpExerciseService {
    if (this._instance == null) {
      this._instance = new HttpExerciseService("/exercises");
    }
    return this._instance;
  }

  async checkIfExerciseUpdateIsAvailable(args: {
    exerciseID: string;
  }): Promise<ErciseUpdateIsAvailableResponse> {
    const response = await this.get(
      `/exercises/${args.exerciseID}/check-if-update-is-available`
    );
    return response.data as ErciseUpdateIsAvailableResponse;
  }

  async updateExerciseToLatestVersion(args: {
    exerciseID: string;
  }): Promise<Exercise> {
    const response = await this.put(
      `/exercises/${args.exerciseID}/latest-version`
    );
    return response.data as Exercise;
  }
}
