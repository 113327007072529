import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import StudioStore from "stores/studio.store";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import Column from "../column.component/column.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import "./intro.component.scss";
import TitleText from "components/text.components/title.text.component/title.text.component";

interface IntroProps {
  studioStore?: StudioStore;
}

const Intro = ({ studioStore }: IntroProps): JSX.Element => {
  const [{ run, steps }, setJoyride] = useState({
    run: false,
    steps: [
      {
        target: ".page-container-wrapper",
        content: (
          <Column>
            <Headline className="mb-15">Willkommen im Gymo Dashboard!</Headline>
            <RunningText>
              Schön, dass du dein Studio ins nächste Level bringst. Wir zeigen
              dir in einer kurzen Einführung wie unser Dashboard funktioniert.
              Lies dir bitte die Einführung gut durch, um gleich los starten zu
              können. Keine Angst, die Punkte sind sehr einfach aufgebaut. ;)
            </RunningText>
          </Column>
        ),
        locale: {
          next: <span>Weiter</span>,
        },
        placement: "center",
      },
      {
        target: "#intro-devices",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              Studio Geräte
            </TitleText>
            <RunningText>
              Bevor du Übungen und Trainingspläne verwalten kannst, musst du
              zunächst deine bestehenden Studio Geräte hinzufügen. Wähle aus
              unsere Geräte Bibliothek deine bestehenden Geräte aus. Die
              dazugehörigen Übungen werden automatisch hinzugefügt. Die Übungen
              findest du später im Punkt Übungen wieder.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: "#intro-exercises",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              Übungen
            </TitleText>
            <RunningText>
              Nachdem du deine Geräte hinzugefügt hast, befinden sich unter
              diesem Punkt alle Übungen zu deinen Geräten. Falls du eigene
              Übungen hinzufügen möchtest hast du die Möglichkeit die Übung von
              uns Freigeschaltet zu lassen. Nach der Freischaltung kannst du
              eigene Bilder und Videos hinzufügen. Nach und nach aktualisieren
              wir die Übungen mit professionellen Videos und Bildern. So
              garantieren wir eine übersichtliche Übungsstruktur.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: "#intro-training-plans",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              Trainingspläne
            </TitleText>
            <RunningText>
              Hier kannst du Trainingspläne erstellen und verwalten. Du kannst
              deinen Mitgliedern öffentliche Pläne zu verfügung stellen wie zb.
              Starter Pläne. Zudem kannst du hier Vorlagen erstellen die du dann
              exklusiv einzelnen Mitglieder zu verfügung stellen kannst.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: "#intro-app-configurator",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              App Konfigurator
            </TitleText>
            <RunningText>
              Dein Studio, deine App. Hier hast du die Möglichkeit deine App
              individuell anzupassen. Füge dein Studio-Logo und deine Farbe
              hinzu und lass dein Studio immer bei deinen Mitgliedern dabei
              sein.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: "#intro-news-feed",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              Beiträge
            </TitleText>
            <RunningText>
              Nachdem du die Kategorien für deine Beiträge erstellt hast, kannst
              du hier diese mit Inhalt befüllen. Zb. Kategorie Neuigkeiten -
              hier kannst du mehrere Beiträge in der Woche veröffentlichen, um
              deine Mitglieder auf dem laufenden zu halten.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: "#intro-customers",
        content: (
          <Column>
            <TitleText color="text-primary" className="mb-15">
              Kunden
            </TitleText>
            <RunningText>
              Der wichtigste Punkt kommt zum Schluss, deine Kunden. Hier
              verwaltet du alle deine Kunden die deine App nutzen. Diese werden
              nach dem ersten Registrieren automatisch in diesem Punkt
              hinzugefügt und du kannst ihnen somit zb. Trainingspläne zuweisen
              oder auch wichtige Push-Notifications senden.
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          next: <span>Weiter</span>,
        },
      },
      {
        target: ".page-container-wrapper",
        content: (
          <Column>
            <Headline className="mb-15">Bereit zum Loslegen?</Headline>
            <RunningText>
              Deine App ist nur so gut, wie du sie pflegst und in deinen Alltag
              integrierst. Je mehr du deine Mitglieder in das System einbindest
              und hilfreiche Informationen, Tipps, Trainingspläne, etc. zur
              Verfügung stellst, desto mehr Mehrwert bietest du deinen Kunden.
              Nutze die Chance und verschaffe dir und deinen Mitgliedern einen
              echten Vorteil gegenüber anderen Studios. Du kümmerst dich um den
              Content, wir kümmern uns um den Rest. ;) Lass uns gemeinsam das
              nächste Level erreichen!
            </RunningText>
          </Column>
        ),
        locale: {
          back: <span>Zurück</span>,
          last: <span>Fertig</span>,
        },
        placement: "center",
      },
    ],
  } as any);

  useEffect(() => {
    if (studioStore?.showIntro) {
      setTimeout(() => {
        handleClickStart();
      }, 3000);
    }
  }, [studioStore?.showIntro]);

  const handleClickStart = (): void => {
    setJoyride({
      steps,
      run: true,
    });
  };

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride({ run: false });
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: "#30D598",
        },
      }}
    />
  );
};

export default inject("studioStore")(observer(Intro));
