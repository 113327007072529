/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import ServiceStore from "stores/service.store";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import StudioStore from "stores/studio.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { yupResolver } from "@hookform/resolvers/yup";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useNavigate, useParams } from "react-router";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { eventSchema } from "schemas/events.schemas/event.schema";
import TitleText from "components/text.components/title.text.component/title.text.component";
import EventStore from "stores/event.store";
import { formatDate, getWeekDayFromDate } from "globals/helpers/global.helper";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import {
  getEventTypeIcon,
  getStatusIcon,
  getStatusLabel,
} from "globals/helpers/calendar.helper";
import { toast } from "react-toastify";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface EventInfoPageProps {
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
  eventStore?: EventStore;
}

const EventInfoPage = ({
  serviceStore,
  studioStore,
  eventStore,
}: EventInfoPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { eventID } = useParams();

  const isEditing = eventID !== "new";
  const currentEvent = eventStore?.currentEvent?.data;

  const formatedDate = formatDate(currentEvent?.date, "DD.MM.YYYY");
  const weekDay = getWeekDayFromDate(currentEvent?.date ?? new Date());

  const eventIsCanceled = isEditing && currentEvent?.status !== "ACTIVE";

  const readableStatus = eventIsCanceled ? "Abgesagt" : "Aktiv";
  const statusIcon = getStatusIcon(eventIsCanceled);
  const readableEventType = getStatusLabel(currentEvent?.type ?? "");

  const servies = serviceStore?.service?.data;

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: currentEvent,
  });

  useEffect(() => {
    reset(currentEvent);
  }, [currentEvent, reset]);

  if (currentEvent == null) {
    return <></>;
  }

  // handle service  form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (isEditing) {
      // check if event should be canceled or updated
      if (cancelEvent) {
        // set cancel event false and cancel event
        eventStore?.updateGroupedEvent({
          event: data,
          id: currentEvent?._id ?? "null",
          cancelEvent: true,
        });
        setCancelEvent(false);
      } else {
        const updatedEvent = await eventStore?.updateGroupedEvent({
          id: currentEvent?._id ?? "null",
          event: data,
        });

        if (
          studioStore?.studio?._id != null &&
          updatedEvent?.service?._id != null &&
          updatedEvent?.date != null
        ) {
          navigate(
            `/studios/${studioStore?.studio?._id}/bookings/${
              updatedEvent?._id ?? "null"
            }/${updatedEvent?.timeSlotID ?? "null"}/${formatDate(
              updatedEvent?.date
            )}/info`
          );
          setFormIsDirty(false);
        }
      }
    } else {
      // eventStore?.createEvent(data); // TODO create event for grouped events
    }
  };

  return (
    <>
      <PageHeader label={`${currentEvent?.service?.title ?? "Termin"}`}>
        {isEditing && !eventIsCanceled && (
          <FilledButton
            color="secondary"
            label="Termin absagen"
            type="submit"
            form="event-form-bookings"
            onClick={() => {
              setCancelEvent(true);
            }}
          />
        )}

        {!eventIsCanceled && (
          <FilledButton
            label={isEditing ? "Speichern" : "Hinzufügen"}
            type="submit"
            className="ml-15"
            color="secondary"
            form="event-form-bookings"
            onClick={() => {
              setCancelEvent(false);
            }}
          />
        )}
      </PageHeader>

      <ComponentWrapper className="mb-15">
        <RunningText className="mt-5 bold">{formatedDate}</RunningText>

        <Row alignItems="center" className="mt-10">
          <FontAwesomeIcon icon={getEventTypeIcon(currentEvent?.type)} />
          <RunningText className="ml-5">{readableEventType}</RunningText>
        </Row>

        {eventIsCanceled && (
          <Row alignItems="center" className="mt-10">
            <FontAwesomeIcon icon={statusIcon} />
            <RunningText className="ml-10">{readableStatus}</RunningText>
          </Row>
        )}
      </ComponentWrapper>

      <ComponentWrapper title="Informationen">
        <SizedContainer size={ContainerSizes.XL}>
          <form
            id="event-form-bookings"
            onSubmit={handleSubmit(onSubmit, (errors) => {
              toast.error("Bitte überprüfe deine Eingaben");
              setFormIsDirty(false);
            })}
            onChange={() => {
              if (!formIsDirty) {
                setFormIsDirty(true);
              }
            }}
          >
            <Row alignItems="center">
              <SizedContainer size={ContainerSizes.L}>
                <Column>
                  <TitleText className="mb-10">Dienstleistung</TitleText>
                  <SelectDropDown
                    isLoading={serviceStore?.service?.isLoading}
                    disabled={isEditing || eventIsCanceled}
                    selectedItem={currentEvent?.service}
                    labelPropertyName={"title"}
                    valuePropertyName="_id"
                    imagePropertyName="coverImageUrl"
                    items={servies ?? []}
                    onChange={(item) => {
                      setValue("service", item);
                      if (item !== undefined) setFormIsDirty(true);
                    }}
                    inputRef={register("service")}
                    validationMessage={getFormattedErrorMessage(
                      "service",
                      errors.service
                    )}
                  />
                </Column>
              </SizedContainer>
            </Row>

            <Row
              alignItems="center"
              justifyContent="space-between"
              className="mt-25"
            >
              <SizedContainer size={ContainerSizes.CUSTOM} customSize={130}>
                <Column>
                  <TitleText className="mb-5">Wochentag</TitleText>
                  <OutlinedTextInput
                    className="mr-30"
                    placeholder={"weekDay"}
                    disabled
                    value={weekDay}
                  />
                </Column>
              </SizedContainer>
              <SizedContainer size={ContainerSizes.L}>
                <Column>
                  <TitleText className="mb-5">Zeit</TitleText>
                  <Row alignItems="center" justifyContent="space-between">
                    <OutlinedTextInput
                      disabled={eventIsCanceled}
                      className="mr-15"
                      type="time"
                      inputRef={register("startTime")}
                      validationMessage={errors?.startTime?.message?.toString()}
                    />
                    <OutlinedTextInput
                      disabled={eventIsCanceled}
                      className="mr-15"
                      type="time"
                      inputRef={register("endTime")}
                      validationMessage={errors?.endTime?.message?.toString()}
                    />
                  </Row>
                </Column>
              </SizedContainer>
              <SizedContainer size={ContainerSizes.CUSTOM} customSize={130}>
                <Column>
                  <TitleText className="mb-5">Kapazität</TitleText>
                  <OutlinedTextInput
                    disabled={eventIsCanceled}
                    type="number"
                    inputRef={register("maxParticipants")}
                    validationMessage={errors?.maxParticipants?.message?.toString()}
                  />
                </Column>
              </SizedContainer>
            </Row>

            <Row
              alignItems="center"
              justifyContent="space-between"
              className="mt-25"
            >
              <Column>
                <TitleText className="mb-5">Zusatz Info</TitleText>
                <OutlinedTextArea
                  disabled={eventIsCanceled}
                  rows={7}
                  placeholder="Notiz"
                  inputRef={register("info")}
                  validationMessage={errors?.info?.message?.toString()}
                ></OutlinedTextArea>
              </Column>
            </Row>

            <Row
              alignItems="center"
              justifyContent="space-between"
              className="mt-25"
            >
              <Column>
                <TitleText>Termin Eigenschaften</TitleText>
                <ColorPicker
                  className="mt-10"
                  name="color"
                  inputRef={register}
                  defaultValue={getValues("color") ?? "#313634e0"}
                  validationMessage={errors.color?.message?.toString()}
                  onChange={(color: any) => {
                    setValue("color", color);
                    setFormIsDirty(true);
                  }}
                />
              </Column>
            </Row>
          </form>
        </SizedContainer>
      </ComponentWrapper>
    </>
  );
};

export default inject(
  "serviceStore",
  "studioStore",
  "eventStore"
)(observer(EventInfoPage));
