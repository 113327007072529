import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { SkeletonSelectionListDataTableItem } from "components/table.components/list.data.table.component/list.data.table.component";
import Row from "components/general.compoenents/row.component/row.component";
import TrainingPlanStore from "stores/training.plan.store";
import CustomerStore from "stores/customer.store";
import TrainingPlanSelectionList from "components/table.components/training.plan.selection.list.component/training.plan.selection.list.component";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { Logging } from "globals/helpers/logging.helper";
import { useNavigate } from "react-router-dom";
import StudioStore from "stores/studio.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";

interface TemplateTrainingPlanSelectionModalProps {
  trainingPlanStore?: TrainingPlanStore;
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const TemplateTrainingPlanSelectionModal = ({
  trainingPlanStore,
  modalStore,
  customerStore,
  studioStore,
}: TemplateTrainingPlanSelectionModalProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;
  const currentSelectedPlan = trainingPlanStore?.currentSelectedTrainingPlan;
  const trainingPlans = trainingPlanStore?.trainingPlans?.data;
  const studioID = studioStore?.studio?._id;

  const [isSearchActive, setIsSearchActive] = useState(false);

  const trainingPlanSearchResult =
    trainingPlanStore?.trainingPlansSearchResult ?? [];

  // if isSearch is active, use trainingPlanSearchResult otherwise use trainingPlan
  const plansToRender = isSearchActive
    ? trainingPlanSearchResult
    : trainingPlans;

  const navigate = useNavigate();

  useEffect(() => {
    trainingPlanStore?.setCurrentSelectedTrainingPlan([]);
    trainingPlanStore?.fetchAndSetTrainingPlans({});
  }, []);

  const handleSubmit = async (): Promise<void> => {
    if (
      currentCustomer?._id != null &&
      currentSelectedPlan != null &&
      currentSelectedPlan[0]._id != null
    ) {
      try {
        const createdPlan = await customerStore?.createCustomerTrainingPlan({
          trainingPlan: {
            user: customerStore?.currentCustomer?.data,
            type: "CUSTOMER",
            title: "Kopie von " + currentSelectedPlan[0].title,
          } as any,
          fromTemplate: currentSelectedPlan[0]._id,
        });

        modalStore?.closeModal();

        if (
          studioID != null &&
          currentCustomer._id != null &&
          createdPlan?._id != null
        ) {
          navigate(
            `/studios/${studioID}/customers/${currentCustomer._id}/training-plans/${createdPlan._id}`
          );
        }
      } catch (err) {
        modalStore?.closeModal();

        Logging.error({
          className: "TemplateTrainingPlanSelectionModal",
          methodName: "handleSubmit",
          message: "Änderungen konnten nicht gespeichert werden",
          exception: err,
          showAlert: true,
        });
      }
    }
  };

  const handlePlanListClick = (plan: TrainingPlan): void => {
    // check if the plan is already selected
    const selectedPlanExists = currentSelectedPlan?.find((item) => {
      return item._id === plan._id;
    });
    // if the plan is already selected, remove it from the list
    if (selectedPlanExists != null) {
      const fitered = currentSelectedPlan?.filter(
        (item) => item._id !== plan._id
      );
      trainingPlanStore?.setCurrentSelectedTrainingPlan(fitered);
    } else {
      // only allow one user to be selected
      if (currentSelectedPlan && currentSelectedPlan?.length > 0) {
        return;
      }

      // if the plan is not selected, add it to the lis
      trainingPlanStore?.setCurrentSelectedTrainingPlan([
        ...(currentSelectedPlan ?? []),
        plan,
      ]);
    }
  };

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader
        title="Plan auswählen"
        description="Wähle einen Plan aus, um diesen als Vorlage für einen neuen Plan zu verwenden."
      >
        <Row justifyContent="space-between" alignItems="center">
          <SizedContainer size={ContainerSizes.M}>
            <OutlinedTextInput
              placeholder="Plan suchen"
              onChange={(value) => {
                if (value != null && value?.trim().length > 0) {
                  setIsSearchActive(true);
                  trainingPlanStore?.searchAndSetTrainingPlans(value);
                } else {
                  setIsSearchActive(false);
                }
              }}
            />
          </SizedContainer>
          <Row justifyContent="flex-end" alignItems="center">
            <FilledButton
              label="Hinzufügen"
              disabled={currentSelectedPlan?.length === 0}
              onClick={() => {
                handleSubmit();
              }}
            />
          </Row>
        </Row>
      </ModalHeader>
    );
  };

  const _buildPlanList = (): JSX.Element => {
    if (trainingPlanStore?.trainingPlans?.isLoading === true) {
      return <SkeletonSelectionListDataTableItem count={5} />;
    } else {
      return (
        <div className="exercises-list-wrapper">
          {/* TODO define class to global scss */}
          <TrainingPlanSelectionList
            trainingPlans={plansToRender ?? []}
            selectedTrainingPlans={
              currentSelectedPlan == null ? [] : currentSelectedPlan
            }
            onClick={(plan: TrainingPlan) => {
              handlePlanListClick(plan);
            }}
          ></TrainingPlanSelectionList>
        </div>
      );
    }
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container"> {_buildTopBar()}</div>
      <div className="selection-modal-body-container">{_buildPlanList()}</div>
    </div>
  );
};

export default inject(
  "trainingPlanStore",
  "modalStore",
  "customerStore",
  "studioStore"
)(observer(TemplateTrainingPlanSelectionModal));
