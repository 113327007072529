import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import ArticleForm from "./components/article.form.component";
import { ModalStore } from "stores/modal.store";

interface NewsDetailPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const NewsDetailPage = ({
  feedStore,
  studioStore,
  modalStore,
}: NewsDetailPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { feedID } = useParams();
  const [isEditing, setIsEditing] = useState(feedID !== "new");

  const article = feedStore?.currentFeed?.data;

  useEffect(() => {
    initializePage();
  }, [feedID]);

  const initializePage = async (): Promise<void> => {
    if (feedID == null) {
      return;
    }

    // fetch feed configs for dropdown
    feedStore?.fetchAndSetArticleFeedConfigs({});

    if (feedID !== "new") {
      feedStore?.fetchAndSetFeedItem({ feedID });
      setIsEditing(true);
    }

    if (feedID === "new") {
      feedStore?.createInitialFeedItem();
      setIsEditing(false);
    }
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="news-feed"
        actions={
          feedID !== "new"
            ? [
                <FilledButton
                  key="feed-archive-button"
                  label={"Beitrag entfernen"}
                  color={"danger"}
                  onClick={() => {
                    modalStore?.showConfirmAlert({
                      onConfirm: async () => {
                        if (article?._id != null) {
                          feedStore?.archiveFeedItem(article);

                          if (studioStore?.studio?._id != null) {
                            navigate(
                              `/studios/${studioStore?.studio?._id}/news-feed`
                            );
                          }
                        }
                      },
                      confirmLabel: "Entfernen",
                      title: "Beitrag entfernen",
                      description:
                        "Möchtest du diesen Beitrag wirklich entfernen?",
                    });
                  }}
                />,
              ]
            : []
        }
      ></PageHeadline>
    );
  };

  if (feedStore?.currentFeed?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={feedID === "new" ? Math.random() : feedID}>
        <ArticleForm isEditing={isEditing} />
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "feedStore",
  "studioStore",
  "modalStore"
)(observer(NewsDetailPage));
