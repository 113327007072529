/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import StudioStore from "stores/studio.store";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import ServiceStore from "stores/service.store";
import ServiceForm from "./components/service.form.component";
import ScheduleForm from "./components/service.schedule.form.component";
import { ModalStore, ModalType } from "stores/modal.store";
import PropertyStore from "stores/property.store";
import { HttpBookingService } from "services/httpClients/http.booking.client";

interface ServiceDetailPageProps {
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
}

const ServiceDetailPage = ({
  serviceStore,
  studioStore,
  modalStore,
  propertyStore,
}: ServiceDetailPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { serviceID } = useParams();
  const [isEditing, setIsEditing] = useState(serviceID !== "new");
  const [formIsDirty, setFormIsDirty] = useState(false);

  const service = serviceStore?.currentService?.data;

  useEffect(() => {
    initializePage();
  }, [serviceID]);

  const initializePage = async (): Promise<void> => {
    if (serviceID == null) {
      return;
    }

    // fetch properties for service categories
    propertyStore?.fetchAndSetProperties({});

    if (serviceID !== "new") {
      serviceStore?.fetchAndSetService({ serviceID });

      // fetch single events for current service (to display in schedule tab)
      serviceStore?.fetchAndSetSingleEventsForCurrentService({
        serviceID,
        refresh: true,
      });

      setIsEditing(true);
    }

    if (serviceID === "new") {
      serviceStore?.createInitialServiceItem();
      setIsEditing(false);
    }
  };

  const _handleDeleteService = async (): Promise<void> => {
    const bookings = await HttpBookingService.getInstance().getBookings({
      eventID: "null",
      serviceID,
      fromDate: new Date(),
    });

    if (bookings != null && bookings.length > 0) {
      modalStore?.openModal(ModalType.ARCHIVE_SERVICE_MODAL, undefined, {
        serviceID,
        bookings,
      });
    } else {
      modalStore?.showConfirmAlert({
        onConfirm: async () => {
          if (service?._id != null) {
            serviceStore?.archiveService(service);

            if (studioStore?.studio?._id != null) {
              navigate(`/studios/${studioStore?.studio?._id}/services`);
            }
          }
        },
        confirmLabel: "Entfernen",
        title: "Dienstleistung entfernen",
        description:
          "Zu dieser Dienstleistung sind noch keine Buchungen vorhanden. Du kannst sie daher ohne Probleme entfernen.",
      });
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab
            label="Informationen"
            key="service-info-tab"
            path="info"
            dirty={formIsDirty}
          />,
          <Tab
            label="Zeitplan"
            key="service-schedule-tab"
            path="schedule"
            dirty={formIsDirty}
            disabled={serviceID === "new"}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="/services"
        actions={[
          serviceID !== "new" ? (
            <FilledButton
              key="service-archive-button"
              label="Dienstleistung entfernen"
              color={"danger"}
              onClick={() => {
                _handleDeleteService();
              }}
            />
          ) : (
            <></>
          ),
        ]}
      >
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (serviceStore?.currentService?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={serviceID === "new" ? Math.random() : serviceID}>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path="info"
              element={
                <ServiceForm
                  isEditing={isEditing}
                  onDirty={(isDirty) => {
                    if (serviceID !== "new") {
                      setFormIsDirty(isDirty);
                    }
                  }}
                />
              }
            />
          </Route>
        </Routes>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path="schedule"
              element={
                <ScheduleForm
                  onDirty={(isDirty) => {
                    if (serviceID !== "new") {
                      setFormIsDirty(isDirty);
                    }
                  }}
                />
              }
            />
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "serviceStore",
  "studioStore",
  "modalStore",
  "propertyStore"
)(observer(ServiceDetailPage));
