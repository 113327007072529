export const subscriptionPlans = {
  basic: {
    name: "Gymo Premium",
    description: "Alle Funktionen für dein Studio",
    roleAlias: "GROWTH_PLAN",
    paymentOptions: {
      monthly: {
        name: "Monatlich",
        priceID: process.env.REACT_APP_STRIPE_BASIC_PLAN_MONTHLY_PRICE,
        price: 198.0,
        period: 1,
        periodPrice: 198.0,
        freeTrialPeriod: 30,
      },
      quarter: {
        name: "3 Montate",
        priceID: process.env.REACT_APP_STRIPE_BASIC_PLAN_QUARTERLY_PRICE,
        price: 178.0,
        period: 3,
        periodPrice: 178.0 * 3,
        savingsLabel: "Du sparst 10%",
        freeTrialPeriod: 30,
      },
      annually: {
        name: "12 Monate",
        priceID: process.env.REACT_APP_STRIPE_BASIC_PLAN_YEARLY_PRICE,
        price: 159.0,
        period: 12,
        periodPrice: 159.0 * 12,
        savingsLabel: "Du sparst 20%",
        freeTrialPeriod: 30,
      },
    },
  },
  booking: {
    name: "Buchungssystem",
    description: "Buchungssystem für dein Studio",
    roleAlias: "BOOKINGS_ADD_ON",
    paymentOptions: {
      monthly: {
        name: "Monatlich",
        priceID: process.env.REACT_APP_STRIPE_BOOKING_PLAN_MONTHLY_PRICE,
        price: 20.0,
        period: 1,
        periodPrice: 20.0,
        freeTrialPeriod: 14,
      },
      quarter: {
        name: "3 Montate",
        priceID: process.env.REACT_APP_STRIPE_BOOKING_PLAN_QUARTERLY_PRICE,
        price: 18.0,
        period: 3,
        periodPrice: 18.0 * 3,
        savingsLabel: "Du sparst 10%",
        freeTrialPeriod: 14,
      },
      annually: {
        name: "12 Monate",
        priceID: process.env.REACT_APP_STRIPE_BOOKING_PLAN_YEARLY_PRICE,
        price: 16.0,
        period: 12,
        periodPrice: 16.0 * 12,
        savingsLabel: "Du sparst 20%",
        freeTrialPeriod: 14,
      },
    },
  },
};
