import React, { useEffect } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";

interface FeedConfigNavListProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
}

const FeedConfigNavList = ({
  studioStore,
  feedStore,
}: FeedConfigNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { feedConfigID } = useParams();
  const feedConfigs = feedStore?.feedConfigs?.data ?? [];

  useEffect(() => {
    feedStore?.fetchAndSetFeedConfigs({});
  }, []);

  const _buildNewFeedConfigPreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(`/studios/${studioStore?.studio?._id}/news-feed/new`);
          }
        }}
        title="Kategorie hinzufügen"
        imageUrl={feedStore?.currentFeed?.data?.coverImageUrl}
        subtitle="Erstelle eine neue Kategorie für deinen News Feed."
        isActive
      />
    );
  };

  const _builFeedList = (): JSX.Element => {
    if (feedStore?.feed?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {feedConfigID === "new" && _buildNewFeedConfigPreview()}

          {feedConfigs.length === 0 && feedConfigID !== "new" && (
            <ListPlaceholder
              title="Du hast noch keine Kategorien erstellt."
              description="Erstelle eine neue Kategorie für deinen News Feed."
              actionLabel="Kategorie hinzufügen"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/feed-configs/new`
                  );
                }
              }}
            />
          )}

          {feedConfigs.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  imageUrl={getProperty(item, "iconUrl")}
                  index={i + 1}
                  title={item.title}
                  subtitle={"Kategorie"}
                  isActive={item._id === feedConfigID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/feed-configs/${item._id}`
                      );
                    }
                  }}
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        title="Kategorien verwalten"
        className="border-right"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/feed-configs/new`
                );
              }
            }}
          />
        }
      />
      {_builFeedList()}
    </Column>
  );
};

export default inject("studioStore", "feedStore")(observer(FeedConfigNavList));
