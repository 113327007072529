import { Logging } from "globals/helpers/logging.helper";
import { toast } from "react-toastify";
import { Invitation } from "schemas/invitation.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpInvitationService extends GenericHttpClient<Invitation> {
  static _instance: HttpInvitationService;
  static getInstance(): HttpInvitationService {
    if (this._instance == null) {
      this._instance = new HttpInvitationService("/invitations");
    }
    return this._instance;
  }

  async getInvitationsOfCurrentUser(): Promise<Invitation[]> {
    const response = await this.get(`/invitations/me`);
    return response.data as Invitation[];
  }

  async acceptInvitation(
    invitationID: string
  ): Promise<Invitation | undefined> {
    try {
      const response = await this.put(`/invitations/${invitationID}/accept`);
      toast.success("Einladung wurde erfolgreich angenommen");
      return response.data as Invitation;
    } catch (err) {
      Logging.error({
        className: "HttpInvitationService",
        methodName: "acceptInvitation",
        message: "Einladung konnte nicht akzeptiert werden",
        exception: err,
        showAlert: true,
      });
    }
  }

  async declineInvitation(
    invitationID: string
  ): Promise<Invitation | undefined> {
    try {
      const response = await this.put(`/invitations/${invitationID}/decline`);
      toast.success("Einladung wurde erfolgreich abgelehnt");
      return response.data as Invitation;
    } catch (err) {
      Logging.error({
        className: "HttpInvitationService",
        methodName: "declineInvitation",
        message: "Einladung konnte nicht akzeptiert werden",
        exception: err,
        showAlert: true,
      });
    }
  }

  async resendInvation(invitationID: string): Promise<Invitation | undefined> {
    try {
      const response = await this.post(`/invitations/${invitationID}/resend`);
      toast.success("Einladung wurde erfolgreich erneut versendet");
      return response.data as Invitation;
    } catch (err) {
      Logging.error({
        className: "HttpInvitationService",
        methodName: "declineInvitation",
        message: "Einladung konnte nicht erneut versendet werden",
        exception: err,
        showAlert: true,
      });
    }
  }
}
