import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ExercisesList from "components/table.components/exercise.list.component/exercises.list.component";
import ExerciseStore from "stores/exercise.store";
import { ModalStore } from "stores/modal.store";
import DeviceStore from "stores/device.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { HttpDeviceService } from "services/httpClients/http.device.client";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { toast } from "react-toastify";
import { SkeletonSelectionListDataTableItem } from "components/table.components/list.data.table.component/list.data.table.component";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import Row from "components/general.compoenents/row.component/row.component";
import { Logging } from "globals/helpers/logging.helper";
import ModalHeader from "../modal.header.component/modal.header.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import Headline from "components/text.components/headline.component/headline.component";

interface ExerciseSelectionModalProps {
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
  deviceStore?: DeviceStore;
}

const ExerciseSelectionModal = ({
  exerciseStore,
  modalStore,
  deviceStore,
}: ExerciseSelectionModalProps): JSX.Element => {
  const exercises = exerciseStore?.exercises?.data ?? [];
  const currentDevice = deviceStore?.currentDevice?.data;
  const currentSelectedExercises = deviceStore?.currentSelectedExercises;

  useEffect(() => {
    exerciseStore?.fetchAndSetExercises({});

    // set the current assigned device exercises to the selected exercises store
    deviceStore?.setCurrentSelectedExercises(
      deviceStore?.currentDeviceExercises?.data
    );
  }, []);

  // handle the assignment of exercises to the device
  const handleSubmit = async (): Promise<void> => {
    if (
      currentDevice?._id != null &&
      currentSelectedExercises != null &&
      currentSelectedExercises?.length > 0
    ) {
      try {
        await HttpDeviceService.getInstance().handleDeviceExerciseAssign({
          deviceID: currentDevice?._id,
          exerciseIDs: currentSelectedExercises.map((item) => item._id!),
        });

        // fetch the device again and close the modal
        deviceStore?.fetchAndSetDeviceWithDeviceExercises({
          deviceID: currentDevice?._id,
        });

        deviceStore?.fetchAndSetDevices({
          refresh: true,
        });

        modalStore?.closeModal();
        toast.success("Änderungen wurden erfolgreich gespeichert");
      } catch (err) {
        modalStore?.closeModal();

        Logging.error({
          className: "ExerciseSelectionModal",
          methodName: "handleSubmit",
          message: "Änderungen konnten nicht gespeichert werden",
          exception: err,
          showAlert: true,
        });
      }
    }
  };

  const handleExerciseListClick = (exercise: Exercise): void => {
    // check if the exercise is already selected
    const selectedExerciseExists = currentSelectedExercises?.find((item) => {
      return item._id === exercise._id;
    });
    // if the exercise is already selected, remove it from the list
    if (selectedExerciseExists != null) {
      const fitered = currentSelectedExercises?.filter(
        (item) => item._id !== exercise._id
      );
      deviceStore?.setCurrentSelectedExercises(fitered);
    } else {
      // if the exercise is not selected, add it to the lis
      deviceStore?.setCurrentSelectedExercises([
        ...(currentSelectedExercises ?? []),
        exercise,
      ]);
    }
  };

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader
        title="Übungen hinzufügen"
        description="Füge Übungen zu deinem Gerät hinzu."
      >
        <Row justifyContent="space-between" alignItems="center">
          <SizedContainer size={ContainerSizes.M}>
            <OutlinedTextInput
              placeholder="Übungen suchen"
              onChange={(value) => {
                if (value != null) {
                  exerciseStore?.searchAndSetExercises(value);
                }
              }}
            />
          </SizedContainer>
          <Row justifyContent="flex-end" alignItems="center">
            <Headline className="mr-15">
              {currentSelectedExercises?.length ?? 0} x
            </Headline>
            <FilledButton
              label="Speichern"
              color="secondary"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Row>
        </Row>
      </ModalHeader>
    );
  };

  const _buildExercisesList = (): JSX.Element => {
    if (exerciseStore?.exercises?.isLoading === true) {
      return <SkeletonSelectionListDataTableItem count={5} />;
    } else {
      return (
        <div className="exercises-list-wrapper">
          <ExercisesList
            exercises={exercises}
            selectedExercises={currentSelectedExercises ?? []}
            onClick={(exercise: Exercise) => {
              handleExerciseListClick(exercise);
            }}
          ></ExercisesList>
        </div>
      );
    }
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container"> {_buildTopBar()}</div>
      <div className="selection-modal-body-container">
        {_buildExercisesList()}
      </div>
    </div>
  );
};

export default inject(
  "exerciseStore",
  "modalStore",
  "deviceStore"
)(observer(ExerciseSelectionModal));
