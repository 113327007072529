/* eslint-disable @typescript-eslint/no-misused-promises */
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import StudioStore from "stores/studio.store";
import FormItemWrapper from "components/general.compoenents/form.item.wrapper.component/form.item.wrapper.component";
import { studioSchema } from "schemas/studio.schemas/studio.schema";
import { useForm } from "react-hook-form";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Gleap from "gleap";
import "./studio.tab.page.scss";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import UserStore from "stores/user.store";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import { ModalStore } from "stores/modal.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

interface StudioTabPageProps {
  studioStore?: StudioStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
}

const StudioTabPage = ({
  studioStore,
  userStore,
  modalStore,
}: StudioTabPageProps): JSX.Element => {
  const studio = studioStore?.studio;

  const [formIsDirty, setFormIsDirty] = useState(false);

  const verifyCodeAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_STUDIO_VERIFY_KEY",
  });

  const editStudioAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_EDIT_STUDIO_SETTINGS",
  });

  useEffect(() => {
    initializePage();
  }, [verifyCodeAccess]);

  const initializePage = async (): Promise<void> => {
    if (verifyCodeAccess) {
      await studioStore?.fetchAndSetStudioVerifyCode();
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(studioSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: studio,
  });

  if (studio == null) {
    return <></>;
  }

  const onSubmit = async (data: any): Promise<void> => {
    await studioStore?.updateCurrentStudio(data);
  };

  const buildVerifyCodePreview = (): JSX.Element => {
    if (studioStore?.studio?.verificationCode == null) {
      return (
        <>
          <FilledButton
            label="Support kontaktieren"
            onClick={() => {
              Gleap.open();
            }}
          />
          <RunningText className="mt-10">
            Verifizierungscode konnte nicht automatisch erstellt werden, bitte
            Kontaktieren Sie uns.
          </RunningText>
        </>
      );
    }

    return (
      <Row justifyContent="flex-start" className="mt-15" alignItems="center">
        {studioStore?.studio?.verificationCode &&
          studioStore?.studio?.verificationCode
            ?.toString()
            .split("")
            .map((char, index) => {
              return (
                <div className="verification-code-preview mr-15" key={index}>
                  <SmallHeadline>{char}</SmallHeadline>
                </div>
              );
            })}
        <LinkButton
          label="Neu generieren"
          onClick={async () => {
            modalStore?.showConfirmAlert({
              title: "Neuen Verifizierungscode generieren",
              description:
                "Der aktuelle Verifizierungscode wird ungültig und ein neuer wird generiert.",
              confirmLabel: "Generieren",
              onConfirm: async () => {
                await studioStore?.regenerateAndSetStudioVerifyCode();
              },
            });
          }}
        />
      </Row>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) setFormIsDirty(true);
      }}
      className="studio-tab-page-wrapper"
    >
      <Row className="mb-20" justifyContent="space-between" alignItems="center">
        <Headline>Mein Studio</Headline>
        <FilledButton
          label="Speichern"
          type="submit"
          color="secondary"
          disabled={!formIsDirty}
        />
      </Row>
      <ComponentWrapper disabled={!editStudioAccess}>
        <FormItemWrapper
          title="Name"
          description="Name des Studios. (Nicht sichtbar für Kunden)"
        >
          <OutlinedTextInput
            label="Studio Name"
            inputRef={register("name")}
            validationMessage={errors.name?.message?.toString()}
          />
          <OutlinedTextInput
            className="mt-30"
            label="Zusatz"
            inputRef={register("nameAddition")}
            validationMessage={errors.nameAddition?.message?.toString()}
          />
        </FormItemWrapper>
        {verifyCodeAccess && (
          <FormItemWrapper
            title="Studio Verifizierungscode"
            description="Durch den Verifizierungscode könen Studio Mitglieder in der App Ihren Studio Code eingeben und in Ihr Studio einchcken."
          >
            {buildVerifyCodePreview()}
          </FormItemWrapper>
        )}
        <FormItemWrapper
          title="Löschen Sie Ihr Studio"
          description="Alle Studio Daten werden unwiderruflich aus dem Gymo-System gelöscht."
        >
          <FilledButton
            color="danger"
            label="Löschen beantragen"
            onClick={() => {
              Gleap.open();
            }}
          />
        </FormItemWrapper>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "studioStore",
  "userStore",
  "modalStore"
)(observer(StudioTabPage));
