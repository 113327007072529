/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from "@hookform/resolvers/yup";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { feedPublishedStatus } from "globals/data/globals.data";
import { ContainerSizes } from "globals/enums/global.enum";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { feedSchema } from "schemas/feed.schemas/feed.schema";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";

interface ArticleFormProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
  isEditing?: boolean;
}

const ArticleForm = ({
  feedStore,
  studioStore,
  isEditing,
}: ArticleFormProps): JSX.Element => {
  const navigate = useNavigate();

  const article = feedStore?.currentFeed?.data;
  const feedConfigs = feedStore?.articleConfigs;

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(feedSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: article,
  });

  // handle feed form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (article == null) {
      return;
    }

    feedStore?.setCurrentFeed({
      ...article,
      ...data,
    });

    if (!isEditing) {
      const createdFeedItem = await feedStore?.createFeedItem(data);

      // navigate to created feed item
      if (createdFeedItem?._id != null && studioStore?.studio?._id != null) {
        navigate(
          `/studios/${studioStore?.studio?._id}/news-feed/${createdFeedItem._id}`
        );
      }
    } else {
      feedStore?.updateFeedItem(data);
    }

    setFormIsDirty(false);
  };

  if (article == null) {
    return <></>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader
        label={isEditing ? "Beitrag bearbeiten" : "Beitrag hinzufügen"}
      >
        <FilledButton
          disabled={isUploading || !formIsDirty}
          label="Speichern"
          color="secondary"
          type="submit"
        />
      </PageHeader>

      <ComponentWrapper
        title="Verfügbarkeit"
        className=" mb-15"
        info={{
          title: "Info zu Verfügbarkeit",
          content:
            "In diesem Punkt, kannst du entscheiden, ob der Beitrag für deine Mitglieder sichtbar und zu verfügung steht. Oder du kannst ihn als Entwurf speichern und später veröffentlichen.",
        }}
      >
        <SizedContainer size={ContainerSizes.XL}>
          <SelectDropDown
            label="Verfügbarkeit"
            className="mb-10"
            selectedItem={feedPublishedStatus.find(
              (item) => item.value === article?.isPublished
            )}
            items={feedPublishedStatus ?? []}
            onChange={(item) => {
              setValue("isPublished", item?.value);
              clearErrors("isPublished");
              if (item !== undefined) setFormIsDirty(true);
            }}
            inputRef={register("isPublished")}
            validationMessage={errors.isPublished?.message?.toString()}
          />
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper title="Informationen">
        <SizedContainer size={ContainerSizes.XL}>
          <SizedContainer size={ContainerSizes.M}>
            <FileUpload
              label="Coverbild"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              aspectRatio={1 / 1}
              folder="articles"
              inputRef={register("coverImageUrl")}
              validationMessage={errors.coverImageUrl?.message?.toString()}
              fileUrl={getProperty(article, "coverImageUrl")}
              onLibraryChoosed={(data) => {
                setValue("coverImageUrl", data.url);
                clearErrors("coverImageUrl");
                setFormIsDirty(true);
              }}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValue("coverImageUrl", files[0].path);
                  clearErrors("coverImageUrl");
                  setFormIsDirty(true);
                }
              }}
            />
          </SizedContainer>
          <OutlinedTextInput
            label="Titel"
            inputRef={register("title")}
            validationMessage={errors.title?.message?.toString()}
          />
          <OutlinedTextInput
            className="mt-30"
            label="Subtitel"
            inputRef={register("subTitle")}
            validationMessage={errors.subTitle?.message?.toString()}
          />
          <SelectDropDown
            label="Kategorie"
            className="mb-15"
            labelPropertyName="title"
            valuePropertyName="_id"
            isLoading={feedConfigs?.isLoading}
            items={feedConfigs?.data ?? []}
            selectedItem={article?.feedConfig}
            onChange={(item) => {
              setValue("feedConfig", item);
              clearErrors("feedConfig");
              setFormIsDirty(true);
            }}
            inputRef={register("feedConfig")}
            validationMessage={getFormattedErrorMessage(
              "Kategorie",
              errors.feedConfig?.dataType?.message
            )}
          />
          <TextEditor
            content={article?.content}
            inputContentChanged={(content: any) => {
              setValue("content", content);
              clearErrors("content");
              setFormIsDirty(true);
            }}
            validationMessage={errors.content?.message?.toString()}
          />
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject("feedStore", "studioStore")(observer(ArticleForm));
