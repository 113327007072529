import * as yup from "yup";
import { Localization } from "./studio.schemas/studio.schema";

export const languageSchema = (
  localizations: Localization[] = []
): yup.ObjectSchema<{ [key: string]: string }> => {
  const languageShape: any = {};

  localizations.forEach((localization) => {
    languageShape[localization.language] = yup
      .string()
      .required(`${localization.language.toUpperCase()} ist erforderlich`)
      .min(1);
  });

  return yup.object().shape(languageShape) as yup.ObjectSchema<{
    [key: string]: string;
  }>;
};

export interface LanguageString {
  [key: string]: string;
}
