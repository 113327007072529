import React from "react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes, PropertyType } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import DeviceBrandStore from "stores/device.brand.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { DeviceBrand } from "schemas/device.schemas/device.brand.schema";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import StudioStore from "stores/studio.store";
import StudioDeviceStore from "stores/studio.device.store";
import PropertyStore from "stores/property.store";

interface StudioDeviceFormProps {
  studioDeviceStore?: StudioDeviceStore;
  deviceBrandStore?: DeviceBrandStore;
  studioStore?: StudioStore;
  propertyStore?: PropertyStore;
}

const StudioDeviceForm = ({
  studioDeviceStore,
  deviceBrandStore,
  studioStore,
  propertyStore,
}: StudioDeviceFormProps): JSX.Element => {
  const studioDevice = studioDeviceStore?.currentDevice?.data?.device;
  const currentLanguage = studioStore?.currentLanguage;

  // filter device properties
  const deviceProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.DEVICE
  );

  if (studioDevice == null) {
    return <></>;
  }

  return (
    <form className="device-form-input-container" id="device-form">
      <ComponentWrapper title="Informationen" disabled={true}>
        <SizedContainer size={ContainerSizes.XL}>
          <OutlinedTextInput
            type="text"
            label="Gerätename"
            value={getProperty(studioDevice, `title.${currentLanguage!.value}`)}
          />

          <OutlinedTextInput
            className="mt-30"
            type="text"
            label="Artikelnummer"
            value={getProperty(studioDevice, "sku")}
          />
          <SelectDropDown
            isLoading={deviceBrandStore?.deviceBrands?.isLoading}
            className="mb-15"
            placeholder="Hersteller"
            items={deviceBrandStore?.deviceBrands?.data ?? []}
            labelPropertyName="title"
            valuePropertyName="_id"
            selectedItem={deviceBrandStore?.deviceBrands?.data.find(
              (brand: DeviceBrand) =>
                brand._id === (studioDevice?.brand as any)?._id
            )}
            onChange={() => {}}
          />

          <SelectDropDown
            label="Eigenschaften"
            isMulti
            labelPropertyName={`title.${currentLanguage?.value}`}
            valuePropertyName="_id"
            isLoading={propertyStore?.properties?.isLoading}
            items={deviceProperties ?? []}
            selectedItem={studioDevice?.properties}
          />

          <SizedContainer className="mt-30" size={ContainerSizes.M}>
            <FileUpload
              label="Gerätebild"
              className="mt-15"
              aspectRatio={1 / 1}
              folder="devices"
              fileUrl={getProperty(studioDevice, "coverImageUrl")}
            />
          </SizedContainer>
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "studioDeviceStore",
  "deviceBrandStore",
  "studioStore",
  "propertyStore"
)(observer(StudioDeviceForm));
