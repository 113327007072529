import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ArchiveList from "components/table.components/archive.list.component/archive.list.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import { toast } from "react-toastify";
import TrainingPlanStore from "stores/training.plan.store";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { HttpTrainingPlanService } from "services/httpClients/http.training.plan.client";

interface ArchiveTrainingPlanTabProps {
  trainingPlanStore?: TrainingPlanStore;
}

const ArchiveTrainingPlanTab = ({
  trainingPlanStore,
}: ArchiveTrainingPlanTabProps): JSX.Element => {
  const archivedPlans = trainingPlanStore?.archivedTrainingPlans?.data;
  const isLoading = trainingPlanStore?.archivedTrainingPlans?.isLoading;

  useEffect(() => {
    trainingPlanStore?.fetchAndSetArchivedTrainingPlans();
  }, []);

  if (archivedPlans == null) {
    return <></>;
  }

  const _handleRestore = async (item: TrainingPlan): Promise<void> => {
    if (item._id != null) {
      const response = await HttpTrainingPlanService.getInstance().unarchiveOne(
        {
          id: item._id,
        }
      );

      if (response != null) {
        trainingPlanStore?.fetchAndSetArchivedTrainingPlans();
        toast.success("Plan wurde wiederhergestellt");
      }
    }
  };

  return (
    <>
      <Headline className="mb-10 mt-20">Trainingspläne</Headline>
      <ComponentWrapper noPadding>
        <ArchiveList
          isLoading={isLoading}
          items={archivedPlans}
          fields={[
            {
              fieldPath: "coverImageUrl",
              fieldType: "image",
            },
            { fieldPath: "title", header: "Titel" },
            {
              header: "Archivert am",
              fieldPath: "system.archivedAt",
              fieldType: "date",
            },
          ]}
          restoreOnClick={(item: TrainingPlan) => {
            _handleRestore(item);
          }}
        />
      </ComponentWrapper>
    </>
  );
};

export default inject("trainingPlanStore")(observer(ArchiveTrainingPlanTab));
