import * as yup from "yup";

export const feedConfigSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  title: yup
    .string()
    .required("Titel ist verpflichtend")
    .max(40, "Maximal 40 Zeichen"),
  iconUrl: yup.string().notRequired(),
  subTitle: yup.string().max(40, "Maximal 40 Zeichen"),
  sort: yup.number().integer().notRequired().default(0),
  layoutID: yup
    .string()
    .required("Layout ist verpflichtend")
    .default("LISTVIEW"),
  widgetID: yup.string().required("Widget ist verpflichtend"),
  scrollDirection: yup.string().required("Scrollrichtung ist verpflichtend"),
  location: yup.string().notRequired().default("HOME"),
  dataType: yup.string().required("Datentyp ist verpflichtend"),
});

export interface FeedConfig extends yup.InferType<typeof feedConfigSchema> {}
