import React, { useEffect, useState } from "react";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { useNavigate, useParams } from "react-router-dom";
import DeviceStore from "stores/device.store";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface DeviceNavListProps {
  deviceStore?: DeviceStore;
  studioStore?: StudioStore;
}

const DeviceNavList = ({
  deviceStore,
  studioStore,
}: DeviceNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { deviceID } = useParams();
  const isLoading = deviceStore?.devices?.isLoading;
  const devices = deviceStore?.devices?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);
  const deviceSearchResult = deviceStore?.devicesSearchResult ?? [];

  // if isSearch is active, use deviceSearchResult otherwise use devices
  const devicesToRender = isSearchActive ? deviceSearchResult : devices;

  useEffect(() => {
    deviceStore?.fetchAndSetDevices({});
  }, []);

  const _buildNewDevicePreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(`/studios/${studioStore?.studio?._id}/devices/new/info`);
          }
        }}
        title={
          deviceStore?.currentDevice?.data?.title?.de != null &&
          deviceStore?.currentDevice?.data?.title?.de !== ""
            ? deviceStore?.currentDevice?.data?.title?.de
            : "Neues Gerät"
        }
        imageUrl={deviceStore?.currentDevice?.data?.coverImageUrl}
        subtitle="Erstelle ein neues Gerät"
        isActive
      />
    );
  };

  const _buildDeviceList = (): JSX.Element => {
    if (isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    }

    return (
      <div className="global-nav-list-wrapper">
        {deviceID === "new" && _buildNewDevicePreview()}

        {devices.length === 0 && deviceID !== "new" && (
          <ListPlaceholder
            title="Du hast noch kein Gerät hinzugefügt!"
            description="Klicke auf den Button um ein neues Gerät hinzuzufügen."
            actionLabel="Gerät hinzufügen"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/devices/new/info`
                );
              }
            }}
          />
        )}

        {devicesToRender.length === 0 &&
          devices.length > 0 &&
          isSearchActive &&
          deviceID !== "new" && <NavListNoSearchResultComponent />}

        {devicesToRender.map((item, i) => {
          return (
            <Column key={i} alignItems="center">
              <NavListItem
                index={i + 1}
                imageUrl={item.coverImageUrl ?? ""}
                title={item.title.de}
                subtitle={item?.brand?.title ?? "kein Hersteller zugewiesen"}
                isActive={item._id === deviceID}
                onClick={() => {
                  if (studioStore?.studio?._id != null && item._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/devices/${item._id}/info`
                    );
                  }
                }}
                rightLabel={
                  item?.system?.archived ? (
                    <HighlightedInfoText color="error">
                      ARCHIVIERT
                    </HighlightedInfoText>
                  ) : (
                    <></>
                  )
                }
              />
              <Spacer width="85%" />
            </Column>
          );
        })}
      </div>
    );
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/devices/new/info`
                );
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Geräte suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              devices?.length > 0
            ) {
              setIsSearchActive(true);
              deviceStore?.searchAndSetDevices(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildDeviceList()}
    </Column>
  );
};

export default inject("deviceStore", "studioStore")(observer(DeviceNavList));
