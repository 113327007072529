import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import Column from "components/general.compoenents/column.component/column.component";
import "./subscription.form.component.scss";
import { ModalStore } from "stores/modal.store";
import ModalHeader from "components/modal.components/modal.header.component/modal.header.component";
import SubscriptionCheckout from "./components/subscription.checkout.component/subscription.checkout.component";
import SubscriptionOptions from "./components/subscription.options.component/subscription.options.component";

interface SubscriptionProps {
  modalStore?: ModalStore;
}

const Subscription = ({ modalStore }: SubscriptionProps): JSX.Element => {
  const [choosenPrice, setChoosenPrice] = useState(null as any);
  const currentPlan = modalStore?.customData?.plan;

  return (
    <Column>
      <ModalHeader
        title={currentPlan.name}
        description={currentPlan.description}
      />
      {choosenPrice == null && (
        <SubscriptionOptions
          plan={currentPlan}
          onPriceChoosen={(price: any) => {
            setChoosenPrice(price);
          }}
        />
      )}
      {choosenPrice != null && <SubscriptionCheckout price={choosenPrice} />}
    </Column>
  );
};

export default inject("modalStore")(observer(Subscription));
