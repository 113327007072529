import React, { useEffect, useState } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import "./device.detail.page.scss";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import DeviceExercisesList from "components/table.components/device.exercises.list.component/device.exercises.list.component";
import { Outlet, Route, Routes, useParams } from "react-router";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import DeviceForm from "pages/device.pages/device.detail.page/components/device.form.component/device.form.component";
import DeviceStore from "stores/device.store";
import { inject, observer } from "mobx-react";
import DeviceBrandStore from "stores/device.brand.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import MainLayout from "layouts/main.layout/main.layout";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import LanguageDropdown from "components/input.components/dropdown.components/language.dropdown.component/language.dropdown.component";
import { ContainerSizes } from "globals/enums/global.enum";
import StudioStore from "stores/studio.store";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { ModalStore, ModalType } from "stores/modal.store";
import PropertyStore from "stores/property.store";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";

interface DeviceDetailPageProps {
  studioStore?: StudioStore;
  deviceStore?: DeviceStore;
  deviceBrandStore?: DeviceBrandStore;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
}

const DeviceDetailPage = ({
  studioStore,
  deviceStore,
  deviceBrandStore,
  modalStore,
  propertyStore,
}: DeviceDetailPageProps): JSX.Element => {
  const { deviceID } = useParams();
  const [isEditing, setIsEditing] = useState(deviceID !== "new");
  const [formIsDirty, setFormIsDirty] = useState(false);
  const deviceExercises = deviceStore?.currentDeviceExercises?.data ?? [];

  useEffect(() => {
    initializePage();
  }, [deviceID]);

  const initializePage = async (): Promise<void> => {
    if (deviceID == null) {
      return;
    }

    deviceBrandStore?.fetchAndSetDeviceBrands({});
    propertyStore?.fetchAndSetProperties({});

    if (deviceID !== "new") {
      deviceStore?.fetchAndSetDeviceWithDeviceExercises({ deviceID });
      setIsEditing(true);
    }

    if (deviceID === "new") {
      deviceStore?.createInitialDevice();
      deviceStore?.setCurrentDeviceExercises(undefined);

      setIsEditing(false);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        actions={[
          <SizedContainer className="mr-10" size={ContainerSizes.M} key="info">
            <LanguageDropdown />
          </SizedContainer>,
        ]}
        tabs={[
          <Tab
            label="Informationen"
            key="device-info-tab"
            path="info"
            dirty={formIsDirty}
          />,
          <Tab
            disabled={deviceID === "new"}
            label="Übungen"
            key="device-exercises-tab"
            path="exercises"
            dirty={formIsDirty}
          />,
        ]}
      />
    );
  };

  const _buildDeviceExercisesList = (): JSX.Element => {
    return (
      <>
        <PageHeader label="Übungen verwalten">
          <FilledButton
            onClick={() => {
              modalStore?.openModal(ModalType.EDIT_DEVICE_EXERCISE_MODAL);
            }}
            label="Hinzufügen"
            key="exercises"
          />
        </PageHeader>
        <ComponentWrapper noPadding>
          <DeviceExercisesList exercises={deviceExercises} />
        </ComponentWrapper>
      </>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="devices">
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (deviceStore?.currentDevice?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={deviceID === "new" ? Math.random() : deviceID}>
        <div className="device-detail-wrapper">
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index={true as any}
                path="exercises"
                element={_buildDeviceExercisesList()}
              />
              <Route
                path="info"
                element={
                  <DeviceForm
                    isEditing={isEditing}
                    onDirty={(isDirty) => {
                      if (deviceID !== "new") {
                        setFormIsDirty(isDirty);
                      }
                    }}
                  />
                }
              />
            </Route>
          </Routes>
        </div>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "deviceStore",
  "deviceBrandStore",
  "studioStore",
  "modalStore",
  "propertyStore"
)(observer(DeviceDetailPage));
