/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import {
  faCrown,
  faMessageExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./sidebar.upsell.component.scss";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import { subscriptionPlans } from "globals/data/subscription.data";
import UserStore from "stores/user.store";

interface SidebarUpsellProps {
  modalStore?: ModalStore;
  userStore?: UserStore;
}

const SidebarUpsell = ({
  modalStore,
  userStore,
}: SidebarUpsellProps): JSX.Element => {
  const hasRole = userStore?.checkIfHasRole({
    alias: "GROWTH_PLAN",
  });

  const isGymoAdmin = userStore?.checkIfHasRole({
    alias: "GYMO_ADMIN",
  });

  if (isGymoAdmin) {
    return (
      <>
        <Row className="sidebar-admin-info-container mb-20">
          <FontAwesomeIcon
            className="upsell-icon mt-10"
            icon={faMessageExclamation}
          />
          <Column className="ml-15">
            <TitleText className="upsell-text bold">ACHTUNG !</TitleText>
            <RunningText className="upsell-text">
              Du bist Superadmin
            </RunningText>
          </Column>
        </Row>
      </>
    );
  }

  // if has growth plan don't show upsell
  if (hasRole) {
    return <></>;
  }

  return (
    <Row
      className="sidebar-upsell-container mb-20"
      onClick={() => {
        modalStore?.openModal(ModalType.SUBSCRIPTION, ModalSize.LARGE, {
          plan: subscriptionPlans.basic,
        });
      }}
    >
      <FontAwesomeIcon className="upsell-icon" icon={faCrown} />
      <Column className="ml-15">
        <TitleText className="upsell-text">Upgrade</TitleText>
        <RunningText className="upsell-text">Gymo Premium</RunningText>
      </Column>
    </Row>
  );
};

export default inject("modalStore", "userStore")(observer(SidebarUpsell));
