import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import CustomerBookingList from "components/table.components/customer.booking.list.component/customer.booking.list.component";
import Headline from "components/text.components/headline.component/headline.component";
import { formatDate } from "globals/helpers/global.helper";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Booking } from "schemas/booking.schemas/booking.schema";
import CustomerStore from "stores/customer.store";
import { ModalStore } from "stores/modal.store";
import StudioStore from "stores/studio.store";

interface CustomerBookingTabProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const CustomerBookingTab = ({
  customerStore,
  modalStore,
  studioStore,
}: CustomerBookingTabProps): JSX.Element => {
  const navigate = useNavigate();
  const customerBookings = customerStore?.customerBookings?.data ?? [];

  return (
    <Column>
      <Row className="mb-20" alignItems="center">
        <Headline>Buchungen</Headline>
      </Row>
      <ComponentWrapper
        title={`Buchungen von ${customerStore?.currentCustomerFullName}`}
        className="mb-15"
        noPadding
      >
        <CustomerBookingList
          isLoading={customerStore?.customerBookings?.isLoading}
          bookings={customerBookings}
          onCancelBooking={(booking: Booking) => {
            const bookingID = booking._id;
            modalStore?.showConfirmAlert({
              title: "Buchung stornieren",
              description:
                "Möchtest du diese Buchung wirklich stornieren? Der Teilnehmer wird benachrichtigt.",
              confirmLabel: "Stornieren",
              onConfirm: async () => {
                customerStore?.cancelBookingForCustomer({ bookingID });
              },
            });
          }}
          onClick={(booking: Booking) => {
            if (
              studioStore?.studio?._id != null &&
              booking.event?.service?._id != null &&
              booking.date != null &&
              booking.timeSlotID != null
            ) {
              modalStore?.openNavigateModal({
                navigate,
                path: `/studios/${studioStore?.studio?._id}/bookings/${
                  booking?.event?.service?._id
                }/${booking?.timeSlotID}/${formatDate(booking.date)}/users`,
                title: "Möchten Sie die Buchungsliste anzeigen?",
                description:
                  "Zur Buchungsliste navigieren? Klicken Sie auf OK oder Abbrechen, um auf der aktuellen Seite zu bleiben.",
              });
            }
          }}
        />
      </ComponentWrapper>
    </Column>
  );
};

export default inject(
  "customerStore",
  "modalStore",
  "studioStore"
)(observer(CustomerBookingTab));
