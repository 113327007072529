import * as yup from "yup";

// Localizations
export const localizationSchema = yup.object().shape({
  language: yup.string().required(),
  primary: yup.boolean().notRequired(),
});

export interface Localization
  extends yup.InferType<typeof localizationSchema> {}

// iOS configuration
export const iosConfigurationSchema = yup.object().shape({
  bundleId: yup.string(),
  appStoreId: yup.string(),
});

export interface IosConfiguration
  extends yup.InferType<typeof iosConfigurationSchema> {}

// Android configuration
export const androidConfigurationSchema = yup.object().shape({
  packageName: yup.string(),
});

export interface AndroidConfiguration
  extends yup.InferType<typeof androidConfigurationSchema> {}

// Firebase configuration
export const firebaseConfigurationSchema = yup.object().shape({
  androidClientId: yup.string(),
  iosClientId: yup.string(),
  projectId: yup.string(),
  deepLinkURLScheme: yup.string(),
  fcmServerKey: yup.string(),
});

export interface FirebaseConfiguration
  extends yup.InferType<typeof firebaseConfigurationSchema> {}

// App configuration
export const appConfigurationSchema = yup.object().shape({
  configIsValid: yup.boolean(),
  iconUrl: yup.string(),
  splashIconUrl: yup.string(),
  name: yup.string(),
  ios: iosConfigurationSchema,
  android: androidConfigurationSchema,
  firebase: firebaseConfigurationSchema,
});

export interface AppConfiguration
  extends yup.InferType<typeof appConfigurationSchema> {}

// Release history
export const releaseHistory = yup.object().shape({
  status: yup
    .string()
    .oneOf([
      "WAITING_FOR_INTERNAL_APPROVAL",
      "IN_INTERNAL_APPROVAL",
      "WAITING_FOR_EXTERNAL_APPROVAL",
      "IN_EXTERNAL_APPROVAL",
      "RELEASED",
      "REJECTED",
    ])
    .notRequired(),
  createdAt: yup.date().notRequired(),
});

export interface ReleaseHistory extends yup.InferType<typeof releaseHistory> {}

export const studioSchema = yup.object().shape({
  _id: yup.string().required(),
  name: yup.string().required(),
  nameAddition: yup.string(),
  email: yup.string().email().notRequired(),
  type: yup.string().notRequired(),
  studioProperties: yup.mixed().required(),
  verificationCode: yup.string(),
  logoUrl: yup.string(),
  localizations: yup.array().of(localizationSchema).required(),
  demoUser: yup
    .object()
    .shape({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .notRequired(),
  appConfiguration: appConfigurationSchema.notRequired(),
  releaseHistory: yup.array().of(releaseHistory).notRequired(),
});

export interface Studio extends yup.InferType<typeof studioSchema> {
  studioProperties: any;
  location?: any;
}
