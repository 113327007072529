import React, { useEffect, useState } from "react";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { useNavigate, useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import StudioDeviceStore from "stores/studio.device.store";
import { ModalStore, ModalType } from "stores/modal.store";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface StudioDeviceNavListProps {
  studioDeviceStore?: StudioDeviceStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const StudioDeviceNavList = ({
  studioDeviceStore,
  studioStore,
  modalStore,
}: StudioDeviceNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { studioDeviceID } = useParams();
  const isLoading = studioDeviceStore?.devices?.isLoading;
  const devices = studioDeviceStore?.devices?.data ?? [];
  const studioDeviceSearchResult = studioDeviceStore?.devicesSearchResult ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);

  // if isSearch is active, use studioDeviceSearchResult otherwise use devices
  const devicesToRender = isSearchActive ? studioDeviceSearchResult : devices;

  useEffect(() => {
    studioDeviceStore?.fetchAndSetStudioDevices({});
  }, []);

  const _buildDeviceList = (): JSX.Element => {
    if (isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    }

    return (
      <div className="global-nav-list-wrapper">
        {devices.length === 0 && studioDeviceID !== "new" && (
          <ListPlaceholder
            title="Du hast noch keinen Gerät hinzugefügt!"
            description="Füge jetzt deine Studio-Geräte hinzu 
            und erhalte automatisch alle Übungen dazu. "
            actionLabel="Gerät hinzufügen"
            onClick={() => {
              modalStore?.openModal(ModalType.STUDIO_DEVICE_SELECTION_MODAL);
            }}
          />
        )}

        {devicesToRender.length === 0 &&
          devices.length > 0 &&
          isSearchActive && <NavListNoSearchResultComponent />}

        {devicesToRender.map((item, i) => {
          return (
            <Column key={i} alignItems="center">
              <NavListItem
                index={i + 1}
                imageUrl={item.device?.coverImageUrl ?? ""}
                title={item.device?.title.de}
                subtitle={
                  item?.device?.brand?.title ?? "kein Hersteller zugewiesen"
                }
                isActive={item?.device?._id === studioDeviceID}
                onClick={() => {
                  if (
                    studioStore?.studio?._id != null &&
                    item?.device?._id != null
                  ) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/studio-devices/${item?.device?._id}/exercises`
                    );
                  }
                }}
              />
              <Spacer width="85%" />
            </Column>
          );
        })}
      </div>
    );
  };

  return (
    <Column>
      <PageHeadline
        className="border-right"
        classNameActions="full-width"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              modalStore?.openModal(ModalType.STUDIO_DEVICE_SELECTION_MODAL);
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Geräte suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              devices?.length > 0
            ) {
              setIsSearchActive(true);
              studioDeviceStore?.searchAndSetStudioDevices(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildDeviceList()}
    </Column>
  );
};

export default inject(
  "studioStore",
  "studioDeviceStore",
  "modalStore"
)(observer(StudioDeviceNavList));
