/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes, PropertyType } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import { useForm } from "react-hook-form";
import DeviceBrandStore from "stores/device.brand.store";
import DeviceStore from "stores/device.store";
import "./device.form.component.scss";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import StudioStore from "stores/studio.store";
import PropertyStore from "stores/property.store";
import { yupResolver } from "@hookform/resolvers/yup";
import { deviceSchema } from "schemas/device.schemas/device.schema";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { toast } from "react-toastify";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";

interface DeviceFormProps {
  isEditing?: boolean;
  deviceStore?: DeviceStore;
  deviceBrandStore?: DeviceBrandStore;
  studioStore?: StudioStore;
  propertyStore?: PropertyStore;
  onDirty: (isDirty: boolean) => void;
}

const DeviceForm = ({
  isEditing = false,
  deviceStore,
  deviceBrandStore,
  studioStore,
  propertyStore,
  onDirty,
}: DeviceFormProps): JSX.Element => {
  const navigate = useNavigate();

  const device = deviceStore?.currentDevice?.data;
  const currentLanguage = studioStore?.currentLanguage;
  const currentLanguages = studioStore?.studio?.localizations ?? [];

  // filter device properties
  const deviceProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.DEVICE
  );

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(deviceSchema(currentLanguages)),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: device,
  });

  useEffect(() => {
    onDirty(formIsDirty);
  }, [formIsDirty]);

  // handle device form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (device == null) {
      return;
    }

    deviceStore?.setCurrentDevice({
      ...device,
      ...data,
    });

    if (!isEditing) {
      const createdDevice = await deviceStore?.createDevice(data);

      // navigate to created device
      if (createdDevice?._id != null && studioStore?.studio?._id != null) {
        navigate(
          `/studios/${studioStore?.studio?._id}/devices/${createdDevice._id}/info`
        );
      }
    } else {
      await deviceStore?.updateDevice(data);
    }

    setFormIsDirty(false);
  };

  if (device == null || currentLanguage == null) {
    return <></>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      className="device-form-input-container"
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader label={isEditing ? "Gerät bearbeiten" : "Gerät hinzufügen"}>
        <FilledButton
          disabled={isUploading || !formIsDirty}
          label="Speichern"
          type="submit"
          color="secondary"
        />
      </PageHeader>
      <ComponentWrapper>
        <SizedContainer size={ContainerSizes.XL}>
          {device.system?.archived && (
            <InfoBox className="mb-30">
              Geräte ohne Übungen werden automatisch archiviert. Sobald Übungen
              hinterlegt sind wird das Gerät für alle Kunden sichtbar.
            </InfoBox>
          )}
          <OutlinedTextInput
            label="Gerätename"
            inputRef={register(`title.${currentLanguage?.value}`)}
            validationMessage={errors.title?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-30"
            label="Artikelnummer"
            inputRef={register("sku")}
            validationMessage={errors.sku?.message?.toString()}
          />
          <SelectDropDown
            label="Hersteller"
            labelPropertyName="title"
            valuePropertyName="_id"
            isLoading={deviceBrandStore?.deviceBrands?.isLoading}
            items={deviceBrandStore?.deviceBrands?.data ?? []}
            selectedItem={device?.brand}
            onChange={(item) => {
              setValue("brand", item);
              clearErrors("brand");
              if (item !== undefined) setFormIsDirty(true);
            }}
            inputRef={register("brand")}
            validationMessage={errors.brand?.message?.toString()}
          />
          <SelectDropDown
            label="Eigenschaften"
            isMulti
            labelPropertyName={`title.${currentLanguage.value}`}
            valuePropertyName="_id"
            isLoading={propertyStore?.properties?.isLoading}
            items={deviceProperties ?? []}
            selectedItem={device?.properties}
            onChange={(item) => {
              setValue("properties", item);
              clearErrors("properties");
              if (item !== undefined) setFormIsDirty(true);
            }}
            inputRef={register("properties")}
            validationMessage={errors.properties?.message?.toString()}
          />

          <SizedContainer className="mt-30" size={ContainerSizes.M}>
            <FileUpload
              label="Gerätebild"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              className="mt-15"
              aspectRatio={1 / 1}
              folder="devices"
              inputRef={register("coverImageUrl")}
              validationMessage={errors.coverImageUrl?.message?.toString()}
              fileUrl={getProperty(device, "coverImageUrl")}
              onFilesUploaded={(files) => {
                if (files && files.length > 0) {
                  setValue("coverImageUrl", files[0].path);
                  clearErrors("coverImageUrl");
                  setFormIsDirty(true);
                } else {
                  setValue("coverImageUrl", undefined);
                }
              }}
            />
          </SizedContainer>
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "deviceStore",
  "deviceBrandStore",
  "studioStore",
  "propertyStore"
)(observer(DeviceForm));
