import React, { useEffect, useState } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";
import GymoCustomerStore from "stores/gymo.customer.store";

interface GymoCustomerNavListProps {
  studioStore?: StudioStore;
  gymoCustomerStore?: GymoCustomerStore;
}

const GymoCustomerNavList = ({
  studioStore,
  gymoCustomerStore,
}: GymoCustomerNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { gymoCustomerID } = useParams();
  const customers = gymoCustomerStore?.gymoCustomers?.data ?? [];
  const [isSearchActive, setIsSearchActive] = useState(false);

  const gymoCustomerSearchResult =
    gymoCustomerStore?.gymoCustomerSearchResult ?? [];

  // if isSearch is active, use gymoCustomerSearchResult otherwise use customers
  const gymoCustomersToRender = isSearchActive
    ? gymoCustomerSearchResult
    : customers;

  useEffect(() => {
    gymoCustomerStore?.fetchAndSetGymoCustomers({});
  }, []);

  const _buildGymoCustomerList = (): JSX.Element => {
    if (gymoCustomerStore?.gymoCustomers?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {customers.length === 0 && gymoCustomerID !== "new" && (
            <ListPlaceholder
              title="Keine Kunden vorhanden"
              description="Sobald sich Kunden bei gymo.io registrieren, werden diese hier angezeigt."
            />
          )}

          {gymoCustomersToRender.length === 0 &&
            customers.length > 0 &&
            isSearchActive &&
            gymoCustomerID !== "new" && <NavListNoSearchResultComponent />}

          {gymoCustomersToRender.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  index={i + 1}
                  imageUrl={getProperty(item, "appConfiguration.iconUrl")}
                  title={getProperty(item, "name")}
                  subtitle={getProperty(item, "location.city")}
                  isActive={item._id === gymoCustomerID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/gymo-customers/${item._id}/info`
                      );
                    }
                  }}
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline className="border-right" classNameActions="full-width">
        <OutlinedTextInput
          className="mr-20"
          placeholder="Kunde suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              customers?.length > 0
            ) {
              setIsSearchActive(true);
              // TODO implement search
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildGymoCustomerList()}
    </Column>
  );
};

export default inject(
  "studioStore",
  "gymoCustomerStore"
)(observer(GymoCustomerNavList));
