import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import ServiceStore from "stores/service.store";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import Column from "components/general.compoenents/column.component/column.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { weekDaysOptions } from "globals/data/globals.data";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { formatDate, getDropdownWeekDay } from "globals/helpers/global.helper";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  TimeSlot,
  serviceSchema,
} from "schemas/service.schemas/service.schema";
import { toast } from "react-toastify";
import moment from "moment";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface ScheduleFormProps {
  serviceStore?: ServiceStore;
  onDirty?: (isDirty: boolean) => void;
}

const ScheduleForm = ({
  serviceStore,
  onDirty,
}: ScheduleFormProps): JSX.Element => {
  const service = serviceStore?.currentService?.data;

  // Prepare dates for date pickers
  (service!.availableFrom as any) = service?.availableFrom
    ? moment(serviceStore?.currentService?.data?.availableFrom).format(
        "YYYY-MM-DD"
      )
    : undefined;

  (service!.availableUntil as any) = service?.availableUntil
    ? moment(serviceStore?.currentService?.data?.availableUntil).format(
        "YYYY-MM-DD"
      )
    : undefined;

  const singleEvents = serviceStore?.singleEventsForCurrentService?.data ?? [];

  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm({
    resolver: yupResolver(serviceSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: service,
  });

  useEffect(() => {
    if (onDirty) {
      onDirty(formIsDirty);
    }
  }, [formIsDirty]);

  // handle service  form submit
  const onSubmit = async (data: any): Promise<void> => {
    const availableFrom = moment(data.availableFrom);
    const availableUntil = moment(data.availableUntil);

    // Set the earliest and latest times for the availability dates
    availableFrom.utc().startOf("day");
    availableUntil.utc().endOf("day");

    // Update the form data with the modified availability dates
    data.availableFrom = availableFrom.toISOString();
    data.availableUntil = availableUntil.toISOString();

    serviceStore?.setCurrentService({
      ...service,
      ...data,
    });

    await serviceStore?.updateService(service!._id!, data);

    setFormIsDirty(false);
  };

  const handleRemoveTimeSlot = (index: number): void => {
    const timeSlots = getValues("timeSlots");

    // check if the exercise with the index exists
    if (timeSlots[index] == null) {
      return;
    }

    // remove the exercise from the list
    timeSlots.splice(index, 1);

    if (service != null) {
      // update the form
      setValue("timeSlots", timeSlots);

      // update the training plan store
      serviceStore?.setCurrentService({
        ...service,
        timeSlots,
      });
    }
  };

  const handleAddTimeSlot = (): void => {
    const timeSlots = getValues("timeSlots");

    // check if a timeslot exists and if true
    // add a new timeslot and add the date of the previous timeslot

    let newTimeSlot: TimeSlot = {
      endTime: undefined as any,
      startTime: undefined as any,
      timeZone: "Europe/Vienna",
      weekDay: undefined as any,
      maxParticipants: undefined as any,
    };

    if (timeSlots && timeSlots.length !== 0) {
      // copy last time slot
      const lastTimeSlot = { ...timeSlots[timeSlots.length - 1] };

      // if weekDay is 7 (sunday) set weekDay to 1 (monday) otherwise add 1 to weekDay
      lastTimeSlot.weekDay =
        lastTimeSlot.weekDay === 7 ? 1 : +lastTimeSlot.weekDay + 1;

      newTimeSlot = { ...lastTimeSlot };
      delete newTimeSlot?._id;
    }

    timeSlots.push(newTimeSlot);

    if (service != null) {
      // update the form
      setValue("timeSlots", timeSlots);

      // update the training plan store
      serviceStore?.setCurrentService({
        ...service,
        timeSlots,
      });
    }
  };

  if (service == null) {
    return <></>;
  }

  const _buildRegularSchedules = (): JSX.Element => {
    return (
      <ComponentWrapper
        className="mb-20"
        title="Wöchentliche Zeitpläne"
        actions={
          <SizedContainer size={ContainerSizes.M}>
            <Row>
              <OutlinedTextInput
                className="mr-10"
                type="date"
                label="Verfügbar von"
                inputRef={register("availableFrom", {
                  required: "Dieses Feld ist erforderlich",
                  validate: (value) =>
                    moment(value).format("YYYY-MM-DD") <=
                      moment(new Date()).format("YYYY-MM-DD") ||
                    "Datum muss in der Zukunft liegen",
                })}
                validationMessage={errors.availableFrom?.message?.toString()}
              />
              {/* // TODO fix  availableFrom and availableUntil */}
              <OutlinedTextInput
                className="mr-10"
                label="Verfügbar bis"
                type="date"
                inputRef={register("availableUntil", {
                  required: "Dieses Feld ist erforderlich",
                  validate: (value) =>
                    moment(value).format("YYYY-MM-DD") <=
                      moment(new Date()).format("YYYY-MM-DD") ||
                    "Datum muss in der Zukunft liegen",
                })}
                validationMessage={errors.availableUntil?.message?.toString()}
              />
            </Row>
          </SizedContainer>
        }
      >
        <Column>
          {service.timeSlots.length > 0 &&
            service.timeSlots.map((timeSlot, index) => {
              return (
                <SizedContainer
                  key={`$set-item-${index}`}
                  size={ContainerSizes.XXL}
                  className="mt-15"
                >
                  <Row alignItems="center">
                    <SelectDropDown
                      className="mr-15"
                      label="Wochentag"
                      items={weekDaysOptions ?? []}
                      selectedItem={getDropdownWeekDay(
                        getProperty(timeSlot, "weekDay")
                      )}
                      onChange={(item) => {
                        // @ts-expect-error
                        setValue(`timeSlots[${index}].weekDay`, item?.value);
                        // @ts-expect-error
                        clearErrors(`timeSlots[${index}].weekDay`);
                        if (item !== undefined) setFormIsDirty(true);
                      }}
                      inputRef={register(`timeSlots.${index}.weekDay`)}
                      validationMessage={errors.timeSlots?.[
                        index
                      ]?.weekDay?.message?.toString()}
                    />
                    <SizedContainer size={ContainerSizes.XL}>
                      <Row alignItems="center">
                        <OutlinedTextInput
                          className="mr-10"
                          label="Startzeit"
                          type="time"
                          inputRef={register(`timeSlots.${index}.startTime`)}
                          validationMessage={errors.timeSlots?.[
                            index
                          ]?.startTime?.message?.toString()}
                        />
                        <OutlinedTextInput
                          className="mr-15"
                          label="Endzeit"
                          type="time"
                          inputRef={register(`timeSlots.${index}.endTime`)}
                          validationMessage={errors.timeSlots?.[
                            index
                          ]?.endTime?.message?.toString()}
                        />
                        <OutlinedTextInput
                          label="Teilnehmer Kapazität"
                          type="number"
                          inputRef={register(
                            `timeSlots.${index}.maxParticipants`
                          )}
                          validationMessage={errors.timeSlots?.[
                            index
                          ]?.maxParticipants?.message?.toString()}
                        />
                        <IconButton
                          icon={faTrash}
                          className="mb-5 ml-15"
                          onClick={() => {
                            handleRemoveTimeSlot(index);

                            setFormIsDirty(true);
                          }}
                        />
                      </Row>
                    </SizedContainer>
                  </Row>
                  <Row>
                    <OutlinedTextArea
                      label="Info zu diesem Termin"
                      placeholder="Kursbeschreibung für diesen Zeitplan (zb. Trainer, Räumlichkeiten, Equipment, etc.)"
                      rows={4}
                      inputRef={register(`timeSlots.${index}.info`)}
                      validationMessage={errors.timeSlots?.[
                        index
                      ]?.info?.message?.toString()}
                    />
                  </Row>
                  <Spacer />
                </SizedContainer>
              );
            })}
          <LinkButton
            label="Tag hinzufügen"
            onClick={() => {
              handleAddTimeSlot();
              setFormIsDirty(true);
            }}
          />
        </Column>
      </ComponentWrapper>
    );
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader label="Zeitpläne bearbeiten">
        <FilledButton label="Speichern" type="submit" color="secondary" />
      </PageHeader>
      {_buildRegularSchedules()}
      <ComponentWrapper title="Unregelmäßige Termine">
        {(singleEvents == null || singleEvents?.length === 0) && (
          <InfoBox>
            <RunningText>
              Sobald Termine vorhanden sind werden diese hier angezeigt.
              <br />
              Im Kalender könen Einzeltermine erstellt und verwaltet werden.
            </RunningText>
          </InfoBox>
        )}

        {serviceStore?.singleEventsForCurrentService?.data?.map(
          (singleEvent, index) => {
            return (
              <Row key={index}>
                <RunningText className="mr-10 bold">{index + 1}.</RunningText>
                <RunningText className="mr-10">
                  {formatDate(singleEvent?.date, "dddd DD.MM.yyyy")}
                </RunningText>
                <RunningText>
                  {singleEvent?.startTime} - {singleEvent?.endTime} Uhr
                </RunningText>
              </Row>
            );
          }
        )}
      </ComponentWrapper>
    </form>
  );
};

export default inject("serviceStore")(observer(ScheduleForm));
