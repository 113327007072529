import React, { useEffect, useState } from "react";
import "./loading.page.scss";
import { useNavigate } from "react-router";
import { inject, observer } from "mobx-react";
import UserStore from "stores/user.store";
import { getRefreshToken } from "globals/helpers/storage.helper";
import StudioStore from "stores/studio.store";
import Loading from "components/general.compoenents/loading.components/loading.component/loading.component";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";

interface LoadingPageProps {
  userStore?: UserStore;
  studioStore?: StudioStore;
}

const LoadingPage = ({
  userStore,
  studioStore,
}: LoadingPageProps): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleState();
    }, 1200);
  }, [userStore?.user]);

  const handleState = async (): Promise<void> => {
    const user = userStore?.user;
    // User exists, navigate to studios
    if (user != null) {
      if (!user.setupCompleted) {
        return navigate("/user-setup");
      }

      // Check if user got invitated to a studio
      const userInvitations =
        await HttpInvitationService.getInstance().getInvitationsOfCurrentUser();

      if (userInvitations.length > 0) {
        return navigate("/handle-invitations");
      }

      // Fetch user studios
      const currentStudio = await studioStore?.getUserStudios();

      // fetch client roles and set them
      await userStore?.fetchAndSetClientRoles();

      // Fetch user account kinds (INTERNAL, GOOGLE, ...)
      userStore?.fetchAndSetCurrentUserAccountKinds();

      if (currentStudio == null) {
        // TODO navigate to create studio
        return navigate("/user-setup");
      }

      navigate(`/studios/${currentStudio._id}/customers`);
    }
  };

  const initialize = async (): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // Check if auth token exists
    const refreshToken = getRefreshToken();
    if (refreshToken == null) {
      userStore?.clear();
      return navigate("/sign-in");
    }

    // Try getting user
    const user = await userStore?.fetchAndSetCurrentUser();

    if (user == null) {
      userStore?.clear();
      return navigate("/sign-in");
    }
  };

  return (
    <div className="loading-container">
      <Loading size="large" />
    </div>
  );
};

export default inject("userStore", "studioStore")(observer(LoadingPage));
