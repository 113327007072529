import { Provider as MobxProvider } from "mobx-react";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import Routing from "routing/routing";
import StudioStore from "stores/studio.store";
import UserStore from "stores/user.store";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.scss";
import { ModalStore } from "stores/modal.store";
import DeviceStore from "stores/device.store";
import DeviceBrandStore from "stores/device.brand.store";
import ExerciseStore from "stores/exercise.store";
import "react-loading-skeleton/dist/skeleton.css";
import PropertyStore from "stores/property.store";
import FeedStore from "stores/feed.store";
import TrainingPlanStore from "stores/training.plan.store";
import ServiceStore from "stores/service.store";
import Intro from "components/general.compoenents/intro.component/intro.component";
import BookingStore from "stores/booking.store";
import PaymentStore from "stores/payment.store";
import StudioDeviceStore from "stores/studio.device.store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import CustomerStore from "stores/customer.store";
import GymoCustomerStore from "stores/gymo.customer.store";
import EventStore from "stores/event.store";

const userStore = new UserStore();
const studioStore = new StudioStore();
const modalStore = new ModalStore();
const deviceStore = new DeviceStore();
const studioDeviceStore = new StudioDeviceStore();
const deviceBrandStore = new DeviceBrandStore();
const exerciseStore = new ExerciseStore();
const propertyStore = new PropertyStore();
const feedStore = new FeedStore();
const trainingPlanStore = new TrainingPlanStore();
const serviceStore = new ServiceStore();
const bookingStore = new BookingStore();
const paymentStore = new PaymentStore();
const customerStore = new CustomerStore();
const gymoCustomerStore = new GymoCustomerStore();
const eventStore = new EventStore();

const stores = {
  userStore,
  studioStore,
  modalStore,
  deviceStore,
  studioDeviceStore,
  deviceBrandStore,
  exerciseStore,
  propertyStore,
  feedStore,
  trainingPlanStore,
  serviceStore,
  bookingStore,
  paymentStore,
  customerStore,
  gymoCustomerStore,
  eventStore,
};

const App = (): JSX.Element => {
  const updateDynamicStyleVariable = (): void => {
    if (window.innerHeight < 250) {
      setTimeout(() => {
        updateDynamicStyleVariable();
      }, 100);

      return;
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme !== null) {
      const darkModeOn = theme === "dark";
      if (darkModeOn) {
        document.body.classList.add("theme--dark");
        document.body.classList.remove("theme--light");
      } else {
        document.body.classList.remove("theme--dark");
        document.body.classList.add("theme--light");
      }
    } else {
      localStorage.setItem("theme", "light");
    }

    updateDynamicStyleVariable();

    window.addEventListener("resize", (event) => {
      updateDynamicStyleVariable();
    });

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", (event) => {
        updateDynamicStyleVariable();
      });
    }

    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN ?? "",
      });
    }
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ?? ""}
    >
      <MobxProvider {...stores}>
        <Sentry.ErrorBoundary>
          <Intro />
          <Routing />
          <ToastContainer autoClose={2700} position="top-right" />
        </Sentry.ErrorBoundary>
      </MobxProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
