import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./logged.in.user.dropdown.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faSlidersUp,
} from "@fortawesome/pro-regular-svg-icons";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import UserStore from "stores/user.store";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import StudioStore from "stores/studio.store";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import GymoIcon from "assets/icons/gymo-profile-placeholder.png";

interface LoggedInUserDropdownProps {
  className?: string;
  userStore?: UserStore;
  studioStore?: StudioStore;
}

const LoggedInUserDropdown = ({
  className,
  userStore,
  studioStore,
}: LoggedInUserDropdownProps): JSX.Element => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const userRole = userStore?.userRole ?? "";

  const [isOpen, setIsOpen] = useState(false);
  const ImageDropdownPropsClassName = classNames(
    {
      "dropdown-wrapper": true,
    },
    className
  );

  const handleClickOutside = (event: any): void => {
    if (ref.current && !(ref.current as any).contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // handle logout
  const handleLogout = (): void => {
    setIsOpen(false);

    userStore?.clear();
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div
        className="dropdown-element"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {userStore?.user?.profileImageUrl == null ||
        userStore?.user?.profileImageUrl?.trim().length === 0 ? (
          <img src={GymoIcon} className="user-profile-image" />
        ) : (
          <ImageWithAuthHeader
            src={userStore?.user?.profileImageUrl}
            alt=""
            className="user-profile-image"
          />
        )}

        <div className="user-info-wrapper">
          <RunningText className="user-info-wrapper-username text-color-inverted bold">
            {userStore?.user?.firstName}
          </RunningText>
          <SmallText className="text-color-inverted">{userRole}</SmallText>
        </div>
      </div>
    );
  };

  const _buildDropUpContent = (): JSX.Element => {
    if (!isOpen) return <></>;

    return (
      <div className="dropdown-content">
        <div className="dropdown-content-container top-container">
          <div
            className="dropdown-down-item"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                setIsOpen(false);
                navigate(
                  `/studios/${studioStore?.studio?._id}/account/profile`
                );
              }
            }}
          >
            <div className="dropdown-down-item-icon">
              <FontAwesomeIcon
                className="sidebar-item-icon"
                icon={faSlidersUp}
              />
            </div>
            <RunningText className="dropdown-down-item-label">
              Einstellungen
            </RunningText>
          </div>
          <div className="dropdown-down-item" onClick={() => handleLogout()}>
            <div className="dropdown-down-item-icon">
              <FontAwesomeIcon
                className="sidebar-item-icon"
                icon={faArrowRightFromBracket}
              />
            </div>
            <RunningText className="dropdown-down-item-label">
              Abmelden
            </RunningText>
          </div>
        </div>
        {/* // TODO COMMENT OUT FOR THE MOMENT UNTIL LOGIC FOR MULTIPLE STUDIOS IS IMPLEMENTET */}
        {/* <div className="dropdown-content-container bottom-container">
          <div
            className="dropdown-down-item"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                setIsOpen(false);
                navigate(
                  `/studios/${studioStore?.studio?._id}/account/my-studio`
                );
              }
            }}
          >
            <div className="dropdown-down-item-icon">
              <FontAwesomeIcon
                className="sidebar-item-icon"
                icon={faFolderBlank}
              />
            </div>

            <RunningText className="dropdown-down-item-label">
              {studioStore?.studio?.name ?? "Mein Studio"}
            </RunningText>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div className={ImageDropdownPropsClassName} ref={ref}>
      {_buildDropUpContent()}
      {_buildPreview()}
    </div>
  );
};

export default inject(
  "userStore",
  "studioStore"
)(observer(LoggedInUserDropdown));
