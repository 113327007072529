import { Grouped } from "schemas/grouped.schema";
import { GenericHttpClient } from "./config/http.generic.client";
import { Event } from "schemas/events.schemas/event.schema";
import { EventWithBookingInfo } from "schemas/service.schemas/booking.info.schema";

export class HttpEventService extends GenericHttpClient<Event> {
  static _instance: HttpEventService;
  static getInstance(): HttpEventService {
    if (this._instance == null) {
      this._instance = new HttpEventService("/events");
    }
    return this._instance;
  }

  async cancelEvent(args: { eventID: string; data: any }): Promise<Event> {
    const response = await this.put(
      `/events/${args.eventID}/cancel`,
      args.data
    );
    return response.data as Event;
  }

  async getEventsGroupedByDate(args: {
    query: any;
  }): Promise<Array<Grouped<EventWithBookingInfo>>> {
    const response = await this.get(`/events/grouped`, {
      params: args.query,
    });
    return response.data as Array<Grouped<EventWithBookingInfo>>;
  }

  async getSingleEventsForService(args: {
    serviceID: string;
  }): Promise<Event[]> {
    const response = await this.get(
      `/events/services/${args.serviceID}/single`
    );
    return response.data as Event[];
  }
}
