import AppPreviewComponent from "components/app.preview.component/app.preview.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import StudioStore from "stores/studio.store";
import "./app.setup.page.scss";
import ColorInput from "components/input.components/color.input.component/color.input.component";
import { useNavigate } from "react-router";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import Center from "components/general.compoenents/center.component/center.component";
import UserStore from "stores/user.store";
import Column from "components/general.compoenents/column.component/column.component";

interface StudioSetupProps {
  studioStore?: StudioStore;
  userStore?: UserStore;
}

const StudioSetup = ({
  studioStore,
  userStore,
}: StudioSetupProps): JSX.Element => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const editingStudio = studioStore?.editingStudio;

  useEffect(() => {
    studioStore?.setEditingProject();
  }, []);

  const _buildForm = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <Center>
        <SizedContainer size={ContainerSizes.L}>
          <Column>
            <Headline className="mt-15">
              Erzähl uns was über dein{" "}
              <span className="color-primary">Studio.</span>
            </Headline>
            <RunningText className="mb-20">
              Starte jetzt die vollumfänglich Fitness-App für ihre Mitglieder.
            </RunningText>
            <OutlinedTextInput
              label="Studio Name"
              value={editingStudio.name}
              onChange={(value) => {
                (editingStudio.name as any) = value;
                studioStore.setEditingStudio({ ...editingStudio });
              }}
            />
            <OutlinedTextInput
              label="Studio Email"
              className="mt-15"
              value={editingStudio.email ?? ""}
              onChange={(value) => {
                (editingStudio.email as any) = value;
                studioStore.setEditingStudio({ ...editingStudio });
              }}
            />
            <ColorInput
              label="Farbe"
              value={
                editingStudio?.studioProperties.appTheme.themeData[1].colorTheme
                  .primary
              }
              onChange={(value) => {
                for (
                  let i = 0;
                  i < editingStudio.studioProperties.appTheme.themeData.length;
                  i++
                ) {
                  editingStudio.studioProperties.appTheme.themeData[
                    i
                  ].colorTheme.primary = value;
                }

                studioStore.setEditingStudio({ ...editingStudio });
              }}
            />
            <FilledButton
              label="Weiter"
              className="mt-15"
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await userStore?.fetchAndSetClientRoles();
                await studioStore?.updateCurrentStudio(editingStudio);
                setIsLoading(false);

                setTimeout(() => {
                  studioStore.showIntro = true;
                }, 3000);

                navigate("/");
              }}
            />
          </Column>
        </SizedContainer>
      </Center>
    );
  };

  const _buildPreview = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <div
        className="studio-setup-app-preview-container"
        style={{
          backgroundColor:
            editingStudio.studioProperties.appTheme.themeData[1].colorTheme
              .primary,
        }}
      >
        <AppPreviewComponent />
      </div>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildPreview()}
      leftGrow={1}
      rightChild={_buildForm()}
      rightGrow={1}
    />
  );
};

export default inject("studioStore", "userStore")(observer(StudioSetup));
