import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes, PropertyType } from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ServiceStore from "stores/service.store";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import StudioStore from "stores/studio.store";
import PropertyStore from "stores/property.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { serviceSchema } from "schemas/service.schemas/service.schema";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useNavigate, useParams } from "react-router";
import { servicePublishedStatus } from "globals/data/globals.data";
import { toast } from "react-toastify";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";

interface ServiceFormProps {
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
  propertyStore?: PropertyStore;
  disabled?: boolean;
  isEditing?: boolean;
  onDirty?: (isDirty: boolean) => void;
}

const ServiceForm = ({
  serviceStore,
  studioStore,
  propertyStore,
  disabled = false,
  isEditing,
  onDirty,
}: ServiceFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { studioID, serviceID } = useParams();

  const service = serviceStore?.currentService?.data;
  const currentLanguage = studioStore?.currentLanguage;

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  // filterd service properties
  const serviceProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.SERVICE
  );

  const tagProperties = propertyStore?.properties?.data.filter(
    (property) => property.type === PropertyType.TAG
  );

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    getValues,
  } = useForm({
    resolver: yupResolver(serviceSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: service,
  });

  useEffect(() => {
    if (onDirty) {
      onDirty(formIsDirty);
    }
  }, [formIsDirty]);

  // handle service  form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (service == null) {
      return;
    }

    serviceStore?.setCurrentService({
      ...service,
      ...data,
    });

    if (!isEditing) {
      const createdService = await serviceStore?.createService(data);

      // navigate to created service
      if (createdService?._id != null && studioStore?.studio?._id != null) {
        navigate(
          `/studios/${studioStore?.studio?._id}/services/${createdService._id}/info`
        );
      }
    } else {
      await serviceStore?.updateService(service._id!, data);
    }

    setFormIsDirty(false);
  };

  const showPublishInfo = (): boolean => {
    if (
      isPublished &&
      (service?.timeSlots == null ||
        service?.timeSlots?.length === 0 ||
        service?.availableFrom == null ||
        service?.availableUntil == null)
    ) {
      return true;
    }

    return false;
  };

  if (service == null) {
    return <></>;
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      onChange={() => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      {!disabled && (
        <PageHeader
          label={
            isEditing
              ? "Dienstleistung bearbeiten"
              : "Dienstleistung hinzufügen"
          }
        >
          <FilledButton
            disabled={isUploading || !formIsDirty}
            label="Speichern"
            color="secondary"
            type="submit"
          />
        </PageHeader>
      )}
      {disabled && (
        <PageHeader label="Dienstleistung">
          <FilledButton
            label="Dienstleistung öffnen"
            onClick={() => {
              if (studioID == null || serviceID == null) {
                return;
              }

              navigate(`/studios/${studioID}/services/${serviceID}/info`);
            }}
          />
        </PageHeader>
      )}

      <ComponentWrapper
        disabled={disabled}
        title="Verfügbarkeit"
        className=" mb-15"
        info={{
          title: "Info zu Verfügbarkeit",
          content:
            "In diesem Punkt, kannst du entscheiden, ob die Dienstleistung für deine Mitglieder sichtbar und zu verfügung steht. Oder du kannst ihn als Entwurf speichern und später veröffentlichen.",
        }}
      >
        <SizedContainer size={ContainerSizes.XL}>
          <SelectDropDown
            label="Verfügbarkeit"
            className="mb-10"
            selectedItem={servicePublishedStatus.find(
              (item) => item.value === service?.isPublished
            )}
            items={servicePublishedStatus ?? []}
            onChange={(item) => {
              setValue("isPublished", item?.value);
              clearErrors("isPublished");
              if (item !== undefined) setFormIsDirty(true);
              setIsPublished(item?.value);
            }}
            inputRef={register("isPublished")}
            validationMessage={errors.isPublished?.message?.toString()}
          />
          {showPublishInfo() && (
            <InfoBox>
              Dienstleisungen ohne hinterlegten Zeitplan werden automatisch als
              Entwurf gespeichert.
            </InfoBox>
          )}
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper disabled={disabled} title="Informationen">
        <SizedContainer size={ContainerSizes.XL}>
          <Row alignItems="flex-start">
            <Column alignItems="flex-start" className="mr-15">
              <FileUpload
                label="Titelbild"
                isUploading={(uploading) => {
                  setIsUploading(uploading);
                }}
                aspectRatio={1 / 1}
                folder="services"
                inputRef={register("coverImageUrl")}
                validationMessage={errors.coverImageUrl?.message?.toString()}
                fileUrl={getProperty(service, "coverImageUrl")}
                onLibraryChoosed={(data) => {
                  setValue("coverImageUrl", data.url);
                  clearErrors("coverImageUrl");
                  setFormIsDirty(true);
                }}
                onFilesUploaded={(files) => {
                  if (files && files.length > 0) {
                    setValue("coverImageUrl", files[0].path);
                    clearErrors("coverImageUrl");
                    setFormIsDirty(true);
                  }
                }}
              />
            </Column>

            <Column>
              <OutlinedTextInput
                className="mt-35"
                label="Titel"
                placeholder="zb. Power-Workout, Yoga, Personal Training, etc."
                inputRef={register("title")}
                validationMessage={errors.title?.message?.toString()}
              />

              <OutlinedTextInput
                label="Subtitel"
                inputRef={register("subTitle")}
                validationMessage={errors.subTitle?.message?.toString()}
              />

              <SelectDropDown
                label="Ressource"
                labelPropertyName={`title.${currentLanguage!.value}`}
                valuePropertyName="_id"
                isLoading={propertyStore?.properties?.isLoading}
                items={serviceProperties ?? []}
                selectedItem={service?.category}
                onChange={(item) => {
                  setValue("category", item);
                  clearErrors("category");
                  if (item !== undefined) setFormIsDirty(true);
                }}
                inputRef={register("category")}
                validationMessage={getFormattedErrorMessage(
                  "Ressource",
                  errors.category?.message?.toString()
                )}
              />

              <SelectDropDown
                isMulti
                label="Tags"
                labelPropertyName={`title.${currentLanguage!.value}`}
                valuePropertyName="_id"
                isLoading={propertyStore?.properties?.isLoading}
                items={tagProperties ?? []}
                selectedItem={service?.properties}
                onChange={(item) => {
                  setValue("properties", item);
                  clearErrors("properties");
                  if (item !== undefined) setFormIsDirty(true);
                }}
                inputRef={register("properties")}
                validationMessage={errors.properties?.message?.toString()}
              />

              <ColorPicker
                className="mt-10"
                name="color"
                label="Kalenderfarbe"
                inputRef={register("color")}
                defaultValue={getValues("color") ?? "#313634e0"}
                validationMessage={errors.color?.message?.toString()}
                onChange={(color: any) => {
                  setValue("color", color);
                  setFormIsDirty(true);
                }}
              />
            </Column>
          </Row>

          <Column className="mt-15">
            <TextEditor
              content={service?.content}
              inputContentChanged={(content: any) => {
                setValue("content", content);
                clearErrors("content");
                setFormIsDirty(true);
              }}
              validationMessage={errors.content?.message?.toString()}
            />
          </Column>
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "serviceStore",
  "studioStore",
  "propertyStore"
)(observer(ServiceForm));
