import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import React from "react";
import addOnPlaceHolder from "assets/illustrations/placeholders/no_topic_placeholder.png";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MarketPlaceAddOnItem from "../components/marketplace.add.on.component/marketplace.add.on.component";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { subscriptionPlans } from "globals/data/subscription.data";
import { inject, observer } from "mobx-react";

interface MarketPlacePageProps {
  modalStore?: ModalStore;
}

const MarketPlacePage = ({ modalStore }: MarketPlacePageProps): JSX.Element => {
  const _buildAddOnList = (): JSX.Element => {
    return (
      <PageContainer>
        <ComponentWrapper title="Verfügbare Add-ons" className="mb-25">
          <MarketPlaceAddOnItem
            title="Kurs Buchungen & Verwaltung"
            roleAlias="BOOKINGS_ADD_ON"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting."
            price="ab 16€"
            onClick={() => {
              modalStore?.openModal(ModalType.SUBSCRIPTION, ModalSize.LARGE, {
                plan: subscriptionPlans.booking,
              });
            }}
          />
        </ComponentWrapper>

        <ComponentWrapper title="Comming Soon Add-ons">
          <MarketPlaceAddOnItem
            roleAlias="NOT_EXISTING"
            available={false}
            title="Kurs Buchungen & Verwaltung"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting."
            price="10€"
            onClick={() => {}}
          />
        </ComponentWrapper>
      </PageContainer>
    );
  };

  return (
    <MainLayout
      topBars={[<PageHeadline key="marketplace" title="Marktplatz" />]}
    >
      <SplitLayout
        useAsPage
        leftChild={_buildAddOnList()}
        leftGrow={2}
        maxWidthLeft={900}
        // minWidthLeft={650}
        rightChild={
          <PagePlaceholder
            title="Info zu den Add-ons"
            description="Gymo wächst Jahr für Jahr mit neuen Features und Add-ons."
            imagePath={addOnPlaceHolder}
          />
        }
        rightGrow={1}
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject("modalStore")(observer(MarketPlacePage));
