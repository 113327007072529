import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import { ContainerSizes } from "globals/enums/global.enum";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import ProfileTabPage from "pages/account.page/components/profile.tab.page/profile.tab.page";
import React, { useEffect } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import CustomerStore from "stores/customer.store";
import CustomerBookingTab from "./components/customer.booking.tab/customer.booking.tab";
import CustomerInfoTab from "./components/customer.info.tab/customer.info.tab";
import CustomerOverviewTab from "./components/customer.overview.tab/customer.overview.tab";
import CreateCustomerTrainingPlan from "./components/training.plans.overview.tab/components/create.customer.training.plan.component";
import TrainingPlansOverViewTab from "./components/training.plans.overview.tab/training.plans.overview.tab";
import "./customer.detail.page.scss";

interface CustomerDetailPageProps {
  customerStore?: CustomerStore;
}

const CustomerDetailPage = ({
  customerStore,
}: CustomerDetailPageProps): JSX.Element => {
  const { customerID } = useParams();

  const currentCustomer = customerStore?.currentCustomer;

  useEffect(() => {
    if (customerID == null) {
      return;
    }

    customerStore?.fetchAndSetCustomer({ customerID });
    customerStore?.fetchAndSetCustomerTrainingPlans({
      customerID,
      refresh: true,
    });
    customerStore?.fetchAndSetCustomerBookings({ customerID, refresh: true });
  }, [customerID]);

  const _buildTabs = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="customers">
        <LinkTabs
          tabs={[
            <Tab
              label="Übersicht"
              key="1"
              path="overview"
              rootPath={`customers/${customerID!}`}
            />,
            <Tab
              label="Kundendaten"
              key="2"
              path="info"
              rootPath={`customers/${customerID!}`}
            />,
            <Tab
              label="Trainingspläne"
              key="3"
              path="training-plans"
              rootPath={`customers/${customerID!}`}
            />,
            <Tab
              label="Buchungen"
              key="4"
              path="bookings"
              rootPath={`customers/${customerID!}`}
              permissionAlias="DASHBOARD_BOOKINGS"
            />,
          ]}
        />
      </PageHeadline>
    );
  };

  if (currentCustomer?.isLoading) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildTabs()]}>
      <PageContainer backgroundColor="color-surface">
        <SizedContainer size={ContainerSizes.XXL}>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index={true as any}
                path="profile"
                element={<ProfileTabPage />}
              />
              <Route path="overview" element={<CustomerOverviewTab />} />
              <Route path="info" element={<CustomerInfoTab />} />
              <Route path="training-plans">
                <Route path="" element={<TrainingPlansOverViewTab />} />
                <Route
                  path=":trainingPlanID"
                  element={<CreateCustomerTrainingPlan />}
                />
              </Route>
              <Route path="bookings" element={<CustomerBookingTab />} />
            </Route>
          </Routes>
        </SizedContainer>
      </PageContainer>
    </MainLayout>
  );
};

export default inject("customerStore")(observer(CustomerDetailPage));
