import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useParams } from "react-router";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import { ModalStore, ModalType } from "stores/modal.store";
import BookingStore from "stores/booking.store";
import BookingUserList from "./components/booking.user.list.component/booking.user.list.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import EventStore from "stores/event.store";
import EventInfoPage from "./components/event.info.page.component/event.info.page.component";

interface BookingDetailPageProps {
  bookingStore?: BookingStore;
  modalStore?: ModalStore;
  eventStore?: EventStore;
}

const BookingDetailPage = ({
  bookingStore,
  modalStore,
  eventStore,
}: BookingDetailPageProps): JSX.Element => {
  const { eventID, timeSlotID, date } = useParams();

  const bookingDataIsLoading = bookingStore?.currentBookingsForEvent?.isLoading;
  const eventIsLoading = eventStore?.currentEvent?.isLoading;
  const currentEvent = eventStore?.currentEvent?.data;

  const bookings = bookingStore?.currentBookingsForEvent?.data.filter(
    (booking) => {
      return booking.status === "BOOKED";
    }
  );

  const canceledBookings = bookingStore?.currentBookingsForEvent?.data?.filter(
    (booking) => {
      return booking.status === "CANCELED";
    }
  );

  useEffect(() => {
    initializePage();
  }, [timeSlotID, date, eventID]);

  const initializePage = async (): Promise<void> => {
    if (timeSlotID == null || date == null) {
      return;
    }

    // fetch event
    await eventStore?.findAndSetCurrentEvent({
      date,
      eventID: eventID ?? "null",
      timeSlotID,
    });

    bookingStore?.setCurrentBookingsForEvent([]);

    // only fetch bookings for existing events (dynamic events have id null)
    if (eventID != null && eventID !== "null") {
      await bookingStore?.fetchAndSetCurrentBookingsForEvent({
        eventID,
      });
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab label="Informationen" key="booking-info" path="info" />,
          <Tab label={"Teilnehmer"} key="booking-users" path="users" />,
        ]}
      />
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="bookings">
        {_buildTabs()}
      </PageHeadline>
    );
  };

  const _buildBookedBookingList = (): JSX.Element => {
    return (
      <ComponentWrapper
        noPadding
        className="mb-15"
        title="Buchungen"
        actions={
          <FilledButton
            onClick={() => {
              if (currentEvent != null && date != null) {
                // create initial booking item and open modal
                bookingStore?.createInitialBookingItem({
                  currentEvent,
                  date: new Date(date),
                  timeSlotID: timeSlotID ?? "null",
                });
                modalStore?.openModal(ModalType.ADD_BOOKING_FOR_USER_MODAL);
              }
            }}
            label="Buchung hinzufügen"
            disabled={eventStore?.currentEvent?.data?.status === "CANCELED"}
            key="add-booking"
          />
        }
      >
        <BookingUserList
          bookings={bookings ?? []}
          bookingAction={(bookingID: string) => {
            modalStore?.showConfirmAlert({
              title: "Buchung stornieren",
              description:
                "Möchtest du diese Buchung wirklich stornieren? Die Teilnehmer werden benachrichtigt.",
              confirmLabel: "Stornieren",
              onConfirm: async () => {
                bookingStore?.cancelBookingFromEvent({ bookingID });
              },
            });
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildUserBookingList = (): JSX.Element => {
    return (
      <>
        {_buildBookedBookingList()}
        {canceledBookings != null && canceledBookings.length > 0 && (
          <ComponentWrapper title="Stornierungen" noPadding>
            <BookingUserList bookings={canceledBookings} />
          </ComponentWrapper>
        )}
      </>
    );
  };

  if (eventIsLoading ?? bookingDataIsLoading) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        <Routes>
          <Route element={<Outlet />}>
            <Route
              path="info"
              element={
                <EventInfoPage key={`${eventID}-${timeSlotID}-${date}`} />
              }
            />
            <Route path="users" element={_buildUserBookingList()} />
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "bookingStore",
  "modalStore",
  "eventStore"
)(observer(BookingDetailPage));
