import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Image from "components/image.component/image.component";
import React, { useEffect } from "react";
import { ImageSizes } from "globals/enums/global.enum";
import { TeamMember } from "schemas/user.schemas/user.schema";
import { inject, observer } from "mobx-react";
import Row from "components/general.compoenents/row.component/row.component";
import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import StudioStore from "stores/studio.store";
import { Invitation } from "schemas/invitation.schema";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HttpUserService } from "services/httpClients/http.user.client";
import { Logging } from "globals/helpers/logging.helper";

interface TeamTabProps {
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const TeamTab = ({ studioStore, modalStore }: TeamTabProps): JSX.Element => {
  const teamMembers = studioStore?.teamMembers?.data ?? [];
  const openTeamMemberInvitations =
    studioStore?.openTeamMemberInvitations?.data ?? [];
  const teamMembersAreLoading = studioStore?.teamMembers?.isLoading ?? false;

  useEffect(() => {
    studioStore?.getTeamMembers({});
    studioStore?.getOpenTeamMemberInvitations({});
  }, []);

  const resendInvitation = async (invitationID: string): Promise<void> => {
    await HttpInvitationService.getInstance().resendInvation(invitationID);
  };

  const deleteInvitation = async (invitationID: string): Promise<void> => {
    try {
      await HttpInvitationService.getInstance().deleteOne({ id: invitationID });
      await studioStore?.getOpenTeamMemberInvitations({ refresh: true });

      toast.success("Einladung wurde erfolgreich gelöscht");
    } catch (err) {
      Logging.error({
        className: "TeamTab",
        methodName: "deleteInvitation",
        message: "Einladung konnte nicht gelöscht werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  const handleRemoveTeamMember = async (userID: string): Promise<void> => {
    try {
      modalStore?.showConfirmAlert({
        onConfirm: async () => {
          const response = await HttpUserService.getInstance().deleteOne({
            id: userID,
          });

          if (response != null) {
            studioStore?.getTeamMembers({ refresh: true });
            toast.success("Teammitglied wurde erfolgreich enterfernt");
          } else {
            toast.error("Teammitglied konnte nicht entfernt werden");
          }
        },
        confirmLabel: "Löschen",
        title: "Möchtest du diesen Benutzer wirklich löschen?",
        description:
          "Wenn du diesen Benutzer löscht, wird er nicht mehr auf deinem Studio zugreifen können.",
      });
    } catch (err) {
      Logging.error({
        className: "TeamTab",
        methodName: "handleRemoveTeamMember",
        message: "Einladung konnte nicht gelöscht werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  const _buildTeamMembers = (): JSX.Element => {
    return (
      <ListDataTable
        isLoading={teamMembersAreLoading}
        data={teamMembers}
        columns={[
          {
            child: (
              <RunningText className="device-brand-table-header">
                Name
              </RunningText>
            ),
            flex: 2,
          },
          {
            child: (
              <RunningText className="device-brand-table-header">
                Rolle
              </RunningText>
            ),
          },
          {
            child: <></>,
            flex: 1,
          },
          {
            child: <></>,
          },
        ]}
        dataTableItemBuilder={(dataItem: TeamMember) => {
          if (dataItem.firstName == null && dataItem.lastName == null) {
            // skip this item
          }

          return {
            key: dataItem._id,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      className="mr-15"
                      imageUrl={dataItem.profileImageUrl}
                      size={ImageSizes.S}
                      rounded={true}
                    />
                    <Column>
                      <RunningText className="bold">
                        {dataItem.firstName} {dataItem.lastName}
                      </RunningText>
                      <RunningText>{dataItem.email}</RunningText>
                    </Column>
                  </Row>
                ),
              },
              {
                child: <RunningText>{dataItem?.roleAlias ?? "-"}</RunningText>,
              },
              { child: <></> },
              {
                child: (
                  <FontAwesomeIcon
                    onClick={() => {
                      handleRemoveTeamMember(dataItem._id);
                    }}
                    icon={faTrash}
                  />
                ),
              },
            ],
          };
        }}
        itemClassName="device-brand-table-item"
      />
    );
  };

  const _buildOpenTeamMemberInvitations = (): JSX.Element => {
    return (
      <ListDataTable
        data={openTeamMemberInvitations}
        columns={[
          {
            child: (
              <RunningText className="device-brand-table-header">
                Email
              </RunningText>
            ),
            flex: 2,
          },
          {
            child: <></>,
          },
          {
            child: <></>,
            flex: 1,
          },
          {
            child: <></>,
          },
        ]}
        dataTableItemBuilder={(dataItem: Invitation) => {
          return {
            key: dataItem._id ?? dataItem.email,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      className="mr-15"
                      imageUrl={undefined}
                      size={ImageSizes.S}
                      rounded={true}
                    />
                    <Column>
                      <RunningText className="bold">
                        {dataItem.email}
                      </RunningText>
                      <RunningText>{dataItem.role?.title ?? "--"}</RunningText>
                    </Column>
                  </Row>
                ),
              },
              {
                child: (
                  <LinkButton
                    className="pl-0 pr-0"
                    label="Erneut senden"
                    onClick={() => {
                      if (dataItem._id == null) {
                        return;
                      }
                      resendInvitation(dataItem._id);
                    }}
                  />
                ),
              },
              { child: <></> },
              {
                child: (
                  <IconButton
                    icon={faTrash}
                    onClick={() => {
                      if (dataItem._id == null) {
                        return;
                      }
                      deleteInvitation(dataItem._id);
                    }}
                  />
                ),
              },
            ],
          };
        }}
      />
    );
  };

  return (
    <Column>
      <Row className="mb-20" justifyContent="space-between" alignItems="center">
        <Headline>Team</Headline>
        <FilledButton
          label="Hinzufügen"
          onClick={() => {
            modalStore?.openModal(
              ModalType.INVITE_TEAM_MEMBER_MODAL,
              ModalSize.SMALL
            );
          }}
        />
      </Row>
      <ComponentWrapper className="mb-15" noPadding>
        {_buildTeamMembers()}
      </ComponentWrapper>
      {openTeamMemberInvitations.length > 0 && (
        <>
          <ComponentWrapper noPadding title="Offene Einladungen">
            {_buildOpenTeamMemberInvitations()}
          </ComponentWrapper>
        </>
      )}
    </Column>
  );
};

export default inject("studioStore", "modalStore")(observer(TeamTab));
