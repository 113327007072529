import Column from "components/general.compoenents/column.component/column.component";
import LargeHeadline from "components/text.components/large.headline.component/large.headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import "./customer.fact.card.component.scss";

interface CustomerFactCardComponentProps {
  title: string;
  value: string | undefined;
}

const CustomerFactCardComponent = ({
  title,
  value,
}: CustomerFactCardComponentProps): JSX.Element => {
  return (
    <Column className="fact-card-wrapper">
      <RunningText className="mb-15 text-color-inverted">{title}</RunningText>
      <LargeHeadline className="text-color-inverted">
        {value ?? "-"}
      </LargeHeadline>
    </Column>
  );
};

export default CustomerFactCardComponent;
