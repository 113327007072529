import React from "react";
import "./publishing.preview.component.scss";
import publishPreviewImage from "assets/illustrations/publishing_preview.png";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";

interface PublishingPreviewProps {
  studioStore?: StudioStore;
}

const PublishingPreview = ({
  studioStore,
}: PublishingPreviewProps): JSX.Element => {
  const editingStudio = studioStore?.editingStudio;

  return (
    <div className="publishing-preview-container">
      <img className="publish-preview-image" src={publishPreviewImage} />
      <div className="app-icon-preview">
        <img
          className="studio-icon"
          src={editingStudio?.appConfiguration?.iconUrl}
        />
        <div className="app-name">{editingStudio?.appConfiguration?.name}</div>
      </div>
    </div>
  );
};

export default inject("studioStore")(observer(PublishingPreview));
