import AppPreviewComponent from "components/app.preview.component/app.preview.component";
import ColorInput from "components/input.components/color.input.component/color.input.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import SplitLayout from "layouts/split.layout/split.layout";
import React, { useEffect } from "react";
import StudioStore from "stores/studio.store";
import "./configurator.tab.scss";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { ModalStore } from "stores/modal.store";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import { Upload } from "schemas/upload.schema";
import LocalizationConfiguration from "components/studio.components/localization.configuration.component/localization.configuration";
import UserStore from "stores/user.store";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import Column from "components/general.compoenents/column.component/column.component";

interface ConfiguratorTabProps {
  userStore?: UserStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const ConfiguratorTab = ({
  userStore,
  studioStore,
  modalStore,
}: ConfiguratorTabProps): JSX.Element => {
  const editingStudio = studioStore?.editingStudio;

  useEffect(() => {
    studioStore?.setEditingProject();
  }, []);

  const saveAppConfig = (): void => {
    studioStore?.saveEditingProject();
  };

  const _buildForm = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <Column>
        <PageHeader label="App Konfigurator">
          <FilledButton
            label="Speichern"
            color="secondary"
            onClick={saveAppConfig}
          />
        </PageHeader>
        <form className="studio-setup-form mb-20" noValidate>
          <ComponentWrapper title="Look & Feel">
            <SizedContainer size={ContainerSizes.XL}>
              <OutlinedTextInput
                label="Studio Name"
                className="mb-30"
                value={editingStudio.name}
                onChange={(value) => {
                  setProperty(editingStudio, "name", value);

                  studioStore.setEditingStudio({ ...editingStudio });
                }}
              />
              <ColorInput
                label="Farbe"
                value={
                  editingStudio?.studioProperties.appTheme.themeData[1]
                    .colorTheme.primary
                }
                onChange={(value) => {
                  for (
                    let i = 0;
                    i <
                    editingStudio.studioProperties.appTheme.themeData.length;
                    i++
                  ) {
                    editingStudio.studioProperties.appTheme.themeData[
                      i
                    ].colorTheme.primary = value;
                  }

                  studioStore.setEditingStudio({ ...editingStudio });
                }}
              />
              <Wrap>
                <SizedContainer className="mr-30" size={ContainerSizes.S}>
                  <FileUpload
                    label="Logo Light"
                    className="upload-wrap-item mt-15"
                    aspectRatio={1 / 1}
                    folder="logos"
                    fileUrl={
                      editingStudio.studioProperties.appTheme.themeData.find(
                        (theme: any) => theme.themeMode === "LIGHT"
                      ).logoUrl
                    }
                    onFilesUploaded={(files?: Upload[]) => {
                      if (files != null && files.length > 0) {
                        const lightAppTheme =
                          editingStudio.studioProperties.appTheme.themeData.find(
                            (theme: any) => theme.themeMode === "LIGHT"
                          );

                        if (lightAppTheme != null) {
                          lightAppTheme.logoUrl = files[0].path;
                          studioStore.setEditingStudio({ ...editingStudio });
                        }
                      }
                    }}
                  />
                </SizedContainer>
                <SizedContainer size={ContainerSizes.S}>
                  <FileUpload
                    label="Logo Dark"
                    className="upload-wrap-item mt-15"
                    aspectRatio={1 / 1}
                    folder="logos"
                    fileUrl={
                      editingStudio.studioProperties.appTheme.themeData.find(
                        (theme: any) => theme.themeMode === "DARK"
                      ).logoUrl
                    }
                    onFilesUploaded={(files?: Upload[]) => {
                      if (files != null && files.length > 0) {
                        const darkAppTheme =
                          editingStudio.studioProperties.appTheme.themeData.find(
                            (theme: any) => theme.themeMode === "DARK"
                          );

                        if (darkAppTheme != null) {
                          darkAppTheme.logoUrl = files[0].path;
                          studioStore.setEditingStudio({ ...editingStudio });
                        }
                      }
                    }}
                  />
                </SizedContainer>
              </Wrap>
              <InfoBox>
                Logos werden abhängig von Light- oder Darkmode in der App
                angezeigt.
              </InfoBox>
            </SizedContainer>
          </ComponentWrapper>
        </form>
        {<LocalizationConfiguration />}
      </Column>
    );
  };

  const _buildPreview = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <div className="app-configurator-preview-container">
        <AppPreviewComponent />
      </div>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildForm()}
      leftGrow={2}
      rightChild={_buildPreview()}
      rightGrow={1}
    />
  );
};

export default inject(
  "userStore",
  "studioStore",
  "modalStore"
)(observer(ConfiguratorTab));
