import SubscriptionForm from "components/stripe.components/subscription.form.component/subscription.form.component";
import React from "react";
import "./subscription.modal.component.scss";

const SubscriptionModal = (): JSX.Element => {
  return (
    <div className="subscription-modal-wrapper">
      <SubscriptionForm />
    </div>
  );
};

export default SubscriptionModal;
