import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ArchiveList from "components/table.components/archive.list.component/archive.list.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Headline from "components/text.components/headline.component/headline.component";
import { toast } from "react-toastify";
import FeedStore from "stores/feed.store";
import { Feed } from "schemas/feed.schemas/feed.schema";
import { HttpFeedService } from "services/httpClients/http.feed.client";

interface ArchiveFeedTabProps {
  feedStore?: FeedStore;
}

const ArchiveFeedTab = ({ feedStore }: ArchiveFeedTabProps): JSX.Element => {
  const archivedFeed = feedStore?.archivedFeed?.data;
  const isLoading = feedStore?.archivedFeed?.isLoading;

  useEffect(() => {
    feedStore?.fetchAndSetArchivedFeedItems();
  }, []);

  if (archivedFeed == null) {
    return <></>;
  }

  const _handleRestore = async (item: Feed): Promise<void> => {
    if (item._id != null) {
      const response = await HttpFeedService.getInstance().unarchiveOne({
        id: item._id,
      });

      if (response != null) {
        feedStore?.fetchAndSetArchivedFeedItems();
        toast.success("Beitrag wurde wiederhergestellt");
      }
    }
  };

  return (
    <>
      <Headline className="mb-10 mt-20">News</Headline>
      <ComponentWrapper noPadding>
        <ArchiveList
          isLoading={isLoading}
          items={archivedFeed}
          fields={[
            {
              fieldPath: "coverImageUrl",
              fieldType: "image",
            },
            { fieldPath: "title", header: "Titel" },
            {
              header: "Archivert am",
              fieldPath: "system.archivedAt",
              fieldType: "date",
            },
          ]}
          restoreOnClick={(item: Feed) => {
            _handleRestore(item);
          }}
        />
      </ComponentWrapper>
    </>
  );
};

export default inject("feedStore")(observer(ArchiveFeedTab));
