import React, { useEffect, useState } from "react";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { useNavigate, useParams } from "react-router-dom";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import ExerciseStore from "stores/exercise.store";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { CollectionDataType } from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import UserStore from "stores/user.store";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import { ModalStore, ModalType } from "stores/modal.store";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";
import StudioDeviceStore from "stores/studio.device.store";

interface ExerciseNavListProps {
  studioStore?: StudioStore;
  exerciseStore?: ExerciseStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
  studioDeviceStore?: StudioDeviceStore;
}

const ExerciseNavList = ({
  studioStore,
  exerciseStore,
  userStore,
  modalStore,
  studioDeviceStore,
}: ExerciseNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { exerciseID } = useParams();
  const exercises = exerciseStore?.exercises?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);
  const exercisesSearchResult = exerciseStore?.exercisesSearchResult ?? [];

  // if isSearch is active, use exercisesSearchResult otherwise use exercises
  const exercisesToRender = isSearchActive ? exercisesSearchResult : exercises;

  const addExerciseAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_ADD_EXERCISE_BUTTON",
  });

  useEffect(() => {
    exerciseStore?.fetchAndSetExercises({});

    if (!addExerciseAccess) {
      // fetch for selection modal
      studioDeviceStore?.fetchAndSetStudioDevices({});
    }
  }, []);

  const handleAddExercise = (): void => {
    if (studioStore?.studio?._id != null && addExerciseAccess) {
      navigate(`/studios/${studioStore?.studio?._id}/exercises/new/info`);
    } else {
      modalStore?.openModal(ModalType.STUDIO_DEVICE_SELECTION_MODAL);
    }
  };

  const _buildNewExercisePreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(`/studios/${studioStore?.studio?._id}/exercises/new/info`);
          }
        }}
        title={
          exerciseStore?.currentExercise?.data?.title?.de != null &&
          exerciseStore?.currentExercise?.data?.title?.de !== ""
            ? exerciseStore?.currentExercise?.data?.title?.de
            : "Neue Übung"
        }
        imageUrl={exerciseStore?.currentExercise?.data?.coverImageUrl}
        subtitle="Erstelle eine neue Übung"
        isActive
      />
    );
  };

  const _buildExerciseList = (): JSX.Element => {
    if (exerciseStore?.exercises?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {exerciseID === "new" && _buildNewExercisePreview()}

          {exercises.length === 0 && exerciseID !== "new" && (
            <ListPlaceholder
              title="Du hast noch kein Gerät hinzugefügt!"
              description="Um zu den Übungen zu gelangen, musst du zunächst deine 
              Studio-Geräte hinzufügen. Später hast du hier automatisch
              alle Übungenzu deinen Geräten aufgelistet und hast zudem 
              die Möglichkeit eigene zu erstellen."
              actionLabel="Geräte auswählen"
              onClick={() => {
                modalStore?.openModal(ModalType.STUDIO_DEVICE_SELECTION_MODAL);
              }}
            />
          )}

          {exercisesToRender.length === 0 &&
            exercises.length > 0 &&
            isSearchActive &&
            exerciseID !== "new" && <NavListNoSearchResultComponent />}

          {exercisesToRender.map((item, i) => {
            const deviceAssignmentCount = item?.devices?.length ?? 0;
            const infoIsMissing =
              item?.infos == null || item?.infos?.length === 0;
            const agonistMuscleGroups = item?.muscleGroups?.agonist;

            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  index={i + 1}
                  imageUrl={getProperty(item, "coverImageUrl")}
                  title={getProperty(item, "title.de")}
                  subtitle={agonistMuscleGroups
                    ?.map(
                      (muscleGroup, index) =>
                        `${muscleGroup?.title?.de}${
                          index !== agonistMuscleGroups?.length - 1 ? "," : ""
                        }`
                    )
                    .join(" ")}
                  rightLabel={
                    item.type === CollectionDataType.TEMPLATE ? (
                      <>
                        {(infoIsMissing || deviceAssignmentCount === 0) && (
                          <HighlightedInfoText
                            color="error"
                            className="mr-5 bold"
                          >
                            !
                          </HighlightedInfoText>
                        )}
                        <HighlightedInfoText color="success">
                          TEMPLATE
                        </HighlightedInfoText>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  isActive={item._id === exerciseID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null && item?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/exercises/${item?._id}/info`
                      );
                    }
                  }}
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            color="secondary"
            label="Hinzufügen"
            onClick={() => {
              handleAddExercise();
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Übung suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              exercises?.length > 0
            ) {
              setIsSearchActive(true);
              exerciseStore?.searchAndSetExercises(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildExerciseList()}
    </Column>
  );
};

export default inject(
  "exerciseStore",
  "studioStore",
  "userStore",
  "modalStore",
  "studioDeviceStore"
)(observer(ExerciseNavList));
