import React, { useEffect } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

interface CheckOutFormProps {
  onSuccess: (setupIntent: any) => void;
  onError: () => void;
  onReady: () => void;
  submit: boolean;
}

const CheckoutForm = ({
  onSuccess,
  onError,
  onReady,
  submit,
}: CheckOutFormProps): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (submit != null) {
      handleSubmit();
    }
  }, [submit]);

  const handleSubmit = async (): Promise<void> => {
    if (!stripe || !elements) {
      onError();
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    } as any);

    if (error) {
      onError();
      toast.error("Rechnungsdetails konnten nicht verifiziert werden");
    } else {
      onSuccess(setupIntent);
    }
  };

  return (
    <form
      onSubmit={() => {
        handleSubmit();
      }}
    >
      <PaymentElement
        onReady={() => {
          onReady();
        }}
      />
    </form>
  );
};

export default CheckoutForm;
