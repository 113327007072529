import { AddressElement, useElements } from "@stripe/react-stripe-js";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { inject, observer } from "mobx-react";
import React from "react";
import { ModalStore } from "stores/modal.store";
import PaymentStore from "stores/payment.store";

interface AddressElementComponentProps {
  paymentStore?: PaymentStore;
  modalStore?: ModalStore;
}

const AddressElementComponent = ({
  paymentStore,
  modalStore,
}: AddressElementComponentProps): JSX.Element => {
  const paymentCustomer = paymentStore?.paymentCustomer;

  const elements = useElements();

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (!elements) return;

    const addressElement: any = elements.getElement("address");

    if (!addressElement) return;

    const { complete, value } = await addressElement.getValue();

    if (complete) {
      await paymentStore?.updatePaymentCustomer({ customer: value });
    }

    modalStore?.closeModal();
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <TitleText>Adresse</TitleText>
      <AddressElement
        options={{
          mode: "billing",
          defaultValues:
            paymentCustomer?.address != null ? paymentCustomer : undefined,
        }}
      />
      <FilledButton
        label="Speichern"
        color="secondary"
        type="submit"
        className="mt-15"
      />
    </form>
  );
};

export default inject(
  "paymentStore",
  "modalStore"
)(observer(AddressElementComponent));
