import * as yup from "yup";
import { languageSchema } from "schemas/language.string.schema";
import { Localization } from "schemas/studio.schemas/studio.schema";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const propertySchema = (languages: Localization[]) =>
  yup.object().shape({
    _id: yup.string().notRequired(),
    title: languageSchema(languages).required(),
    type: yup.string().required("Type ist verpflichtend"),
    coverImageUrl: yup.string().notRequired(),
    coverIconUrl: yup.string().notRequired(),
    parentID: yup.string().notRequired(),
  });

export interface Property
  extends yup.InferType<ReturnType<typeof propertySchema>> {}
