import React from "react";
import "./progress.bar.component.scss";

interface ProgressBarProps {
  items: any[];
}

const ProgressBar = ({ items }: ProgressBarProps): JSX.Element => {
  const setActiveItems = (items: any, currentIndex: number): any => {
    const newItems = [...items];
    for (let i = 0; i < currentIndex; i++) {
      newItems[i].active = true;
    }
    return newItems;
  };

  const activeItems: any = setActiveItems(
    items,
    items.findIndex((item) => item.active)
  );

  return (
    <div className="timeline-container">
      <ul className="timeline">
        {activeItems.map((item: any, index: any) => {
          return (
            <li
              className={`timeline-event ${item.active ? "active" : ""}`}
              key={`time-line-item-${index}`}
            >
              {index !== 0 && (
                <div
                  className={`timeline-line ${item.active ? "active" : ""}`}
                />
              )}
              <span className="timeline-dot"></span>
              <span className="timeline-label">{item.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProgressBar;
