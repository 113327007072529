export const getExtension = (filename: string): string => {
  const cleanFilename = filename.split("?")[0]; // Remove any query parameters
  const parts = cleanFilename.split(".");
  return parts[parts.length - 1];
};

export const isImage = (filename: string): boolean => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
    case "webp":
    case "svg":
      return true;
  }
  return false;
};

export const isVideo = (filename: string): boolean => {
  const ext = getExtension(filename);

  if (filename.includes("stream")) {
    return true;
  }

  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      return true;
  }
  return false;
};
