import MobileHeader from "components/navigation.components/mobile.header.component/mobile.header.component";
import AccountPage from "pages/account.page/account.page";
import SignInPage from "pages/auth.pages/sign.in.page/sign.in.page";
import CustomerPage from "pages/customer.pages/customer.page/customer.page";
import DashboardPage from "pages/dashboard.page/dashboard.page";
import DeviceBrandDetailPage from "pages/device.pages/device.brand.detail.page/device.brand.detail.page";
import DeviceBrandPage from "pages/device.pages/device.brand.page/device.brand.page";
import DeviceDetailPage from "pages/device.pages/device.detail.page/device.detail.page";
import DevicePage from "pages/device.pages/device.page/device.page";
import ExerciseDetailPage from "pages/exercise.pages/exercise.detail.page/exercise.detail.page";
import ExercisePage from "pages/exercise.pages/exercise.page/exercise.page";
import ForgotPasswordPage from "pages/general.pages/forgot.password.page/forgot.password.page";
import HandleInvitationPage from "pages/general.pages/handle.invitation.page/handle.invitation.page";
import InvitationPage from "pages/general.pages/invitation.page/invitation.page";
import NotFoundPage from "pages/general.pages/not.found.page/not.found.page";
import PasswordResetPage from "pages/general.pages/password.reset.page/password.reset.page";
import LoadingPage from "pages/loading.page/loading.page";
import NewsDetailPage from "pages/news.pages/news.detail.page/news.detail.page";
import NewsPage from "pages/news.pages/news.page/news.page";
import TrainingPlanDetailPage from "pages/training.plan.pages/training.plan.detail.page/training.plan.detail.page";
import TrainingPlanPage from "pages/training.plan.pages/training.plan.page/training.plan.page";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./routing.protected.config";
import ServicePage from "pages/service.pages/service.page/service.page";
import ServiceDetailPage from "pages/service.pages/service.detail.page/service.detail.page";
import BookingPage from "pages/booking.pages/booking.page/booking.page";
import BookingDetailPage from "pages/booking.pages/booking.detail.page/booking.detail.page";
import StudioDevicePage from "pages/device.pages/studio.device.page/studio.device.page";
import StudioDeviceDetailPage from "pages/device.pages/studio.device.detail.page/studio.device.detail.page";
import FeedConfigDetailPage from "pages/studio.pages/home.configurator.page/home.configurator.detail.page/home.configurator.detail.page";
import HomeConfiguratorPage from "pages/studio.pages/home.configurator.page/home.configurator.page/home.configurator.page";
import CustomerDetailPage from "pages/customer.pages/customer.detail.page/customer.detail.page";
import ArchivePage from "pages/archive.page/archive.page";
import PropertyPage from "pages/property.pages/property.page/property.page";
import PropertyDetailPage from "pages/property.pages/property.detail.page/property.detail.page";
import MainModal from "components/modal.components/main.modal.component/main.modal.component";
import MarketPlacePage from "pages/marketplace.pages/marketplace.page/marketplace.page";
// import SignUpPage from "pages/auth.pages/sign.up.page/sign.up.page";
import AppConfiguratorPage from "pages/studio.pages/app.configurator.page/app.configurator.page";
import AppSetupPage from "pages/setup.pages/app.setup.page/app.setup.page";
import UserSetupPage from "pages/setup.pages/user.setup.page/user.setup.page";
import DeviceSetupPage from "pages/setup.pages/device.setup.page/device.setup.page";
import GymoCustomerDetailPage from "pages/gymo.customer.pages/gymo.customer.detail.page/gymo.customer.detail.page";
import GymoCustomerPage from "pages/gymo.customer.pages/gymo.customer.page/gymo.customer.page";
import EventPage from "pages/event.pages/event.page/event.page";
import FiboPreviewVideoPage from "pages/fibo.preview.video.page/fibo.preview.video.page";

const Routing = (): JSX.Element => {
  return (
    <BrowserRouter>
      <MainModal />
      <MobileHeader />

      {/* //! TODO HARDCODED FOR FIBO DEMO */}
      <Routes>
        <Route path="/preview-video" element={<FiboPreviewVideoPage />} />
      </Routes>

      <Routes>
        <Route path="/" element={<LoadingPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/invitation" element={<InvitationPage />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        {/* <Route path="/sign-up" element={<SignUpPage />}></Route> */}
      </Routes>

      <Routes>
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/handle-invitations"
            element={<HandleInvitationPage />}
          />
          <Route path="/user-setup" element={<UserSetupPage />} />
          <Route
            path="/studios/:studioID/device-setup"
            element={<DeviceSetupPage />}
          />
          <Route
            path="/studios/:studioID/app-setup"
            element={<AppSetupPage />}
          />
        </Route>

        <Route
          element={
            <div className="main-content">
              <ProtectedRoute />
            </div>
          }
        >
          <Route path="/studios/:studioID/*" element={<DashboardPage />}>
            <Route path="app/*" element={<AppConfiguratorPage />} />

            <Route path="feed-configs" element={<HomeConfiguratorPage />}>
              <Route
                path=":feedConfigID/*"
                element={<FeedConfigDetailPage />}
              />
            </Route>

            <Route path="devices" element={<DevicePage />}>
              <Route path=":deviceID/*" element={<DeviceDetailPage />} />
            </Route>

            <Route path="studio-devices" element={<StudioDevicePage />}>
              <Route
                path=":studioDeviceID/*"
                element={<StudioDeviceDetailPage />}
              />
            </Route>

            <Route path="customers" element={<CustomerPage />}>
              <Route path=":customerID/*" element={<CustomerDetailPage />} />
            </Route>

            <Route path="device-brands" element={<DeviceBrandPage />}>
              <Route
                path=":deviceBrandID/*"
                element={<DeviceBrandDetailPage />}
              />
            </Route>

            <Route path="exercises" element={<ExercisePage />}>
              <Route path=":exerciseID/*" element={<ExerciseDetailPage />} />
            </Route>

            <Route path="news-feed" element={<NewsPage />}>
              <Route path=":feedID/*" element={<NewsDetailPage />} />
            </Route>

            <Route path="training-plans" element={<TrainingPlanPage />}>
              <Route
                path=":trainingPlanID/*"
                element={<TrainingPlanDetailPage />}
              />
            </Route>

            <Route path="services" element={<ServicePage />}>
              <Route path=":serviceID/*" element={<ServiceDetailPage />} />
            </Route>

            <Route path="bookings" element={<BookingPage />}>
              <Route
                path=":eventID/:timeSlotID/:date/*"
                element={<BookingDetailPage />}
              />
            </Route>

            <Route path="properties" element={<PropertyPage />}>
              <Route path=":propertyID/*" element={<PropertyDetailPage />} />
            </Route>

            <Route path="archive/*" element={<ArchivePage />}></Route>

            <Route path="account/*" element={<AccountPage />}></Route>

            <Route path="marketplace/*" element={<MarketPlacePage />}></Route>

            <Route path="events/*" element={<EventPage />}></Route>

            <Route path="gymo-customers" element={<GymoCustomerPage />}>
              <Route
                path=":gymoCustomerID/*"
                element={<GymoCustomerDetailPage />}
              />
            </Route>
            {/* Fallback route */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
