import React, { useEffect, useState } from "react";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { SkeletonSelectionListDataTableItem } from "components/table.components/list.data.table.component/list.data.table.component";
import Row from "components/general.compoenents/row.component/row.component";
import UserSelectionList from "components/table.components/user.selection.list.component/user.selection.list.component";
import CustomerStore from "stores/customer.store";
import BookingStore from "stores/booking.store";
import { User } from "schemas/user.schemas/user.schema";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes, ImageSizes } from "globals/enums/global.enum";
import {
  getProperty,
  setProperty,
} from "globals/helpers/assign.object.keys.helper";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";
import ModalHeader from "../modal.header.component/modal.header.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Image from "components/image.component/image.component";
import Column from "components/general.compoenents/column.component/column.component";
import { LargeText } from "components/text.components/large.text.component/large.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import ModalNoSearchResultComponent from "../no.search.result.components/modal.no.search.result.component/modal.no.search.result.component";
import EventStore from "stores/event.store";

interface AddBookingModalProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
  bookingStore?: BookingStore;
  eventStore?: EventStore;
}

const AddBookingModal = ({
  eventStore,
  modalStore,
  bookingStore,
  customerStore,
}: AddBookingModalProps): JSX.Element => {
  const [customerSelected, setCustomerSelected] = useState(false);
  const customers = customerStore?.customers?.data ?? [];
  const currentSelectedCustomers = customerStore?.currentSelectedCustomers;

  const _currentCalendarEvent = eventStore?.currentPreparedCalendarEvent?.data;
  const _currentEvent = eventStore?.currentEvent?.data;
  const isCalenderEvent = modalStore?.customData?.isCalenderEvent ?? false;
  const event = isCalenderEvent ? _currentCalendarEvent?.event : _currentEvent;

  const initialBooking = bookingStore?.initialBooking?.data;

  const [isSearchActive, setIsSearchActive] = useState(false);
  const customersSearchResult = customerStore?.customersSearchResult ?? [];

  // get users where has bookings with status booked
  const bookedUsersForEvent = bookingStore?.currentBookingsForEvent?.data
    ?.filter((booking) => booking.status === "BOOKED")
    ?.map((booking) => booking.user?._id);

  // filter out all users from customers that are already booked this service
  const filteredCustomers = customers?.filter((customer) => {
    return !bookedUsersForEvent?.includes(customer._id);
  });

  // if isSearch is active, use customersSearchResult otherwise use customers
  const customerToRender = isSearchActive
    ? customersSearchResult
    : filteredCustomers;

  useEffect(() => {
    // set current selected customers to empty array
    customerStore?.setCurrentSelectedCustomers([]);

    // fetch all customers
    customerStore?.fetchAndSetCustomers({});
  }, []);

  // handle add booking
  const handleSubmit = async (): Promise<void> => {
    if (
      currentSelectedCustomers != null &&
      currentSelectedCustomers?.length > 0 &&
      initialBooking != null &&
      event != null
    ) {
      if (!customerSelected) {
        setCustomerSelected(true);
        return;
      }

      await bookingStore?.addBookingToEvent({
        booking: { ...initialBooking, user: currentSelectedCustomers[0] },
        eventID: event?._id ?? "null",
        updateGroupedEvents: !isCalenderEvent,
      });

      // close modal
      modalStore?.closeModal();
    }
  };

  const handleUserListClick = (user: User): void => {
    // check if the user is already selected
    const selectedUserExists = currentSelectedCustomers?.find((item) => {
      return item._id === user._id;
    });

    // if the user is already selected, remove it from the list
    if (selectedUserExists != null) {
      const fitered = currentSelectedCustomers?.filter(
        (item) => item._id !== user._id
      );
      customerStore?.setCurrentSelectedCustomers(fitered);
    } else {
      // only allow one user to be selected
      if (currentSelectedCustomers && currentSelectedCustomers?.length > 0) {
        return;
      }

      // if the user is not selected, add it to the list
      customerStore?.setCurrentSelectedCustomers([
        ...(currentSelectedCustomers ?? []),
        user,
      ]);
    }
  };

  const _buildTopBarBookingForm = (): JSX.Element => {
    if (currentSelectedCustomers == null) {
      return <></>;
    }

    return (
      <ModalHeader>
        <SizedContainer size={ContainerSizes.XML}>
          <Row alignItems="center">
            <Image
              size={ImageSizes.XL}
              className="mr-15"
              imageUrl={getProperty(
                currentSelectedCustomers[0],
                "profileImageUrl"
              )}
            />
            <Column>
              <LargeText>
                {getProperty(currentSelectedCustomers[0], "firstName")}{" "}
                {getProperty(currentSelectedCustomers[0], "lastName")}
              </LargeText>
              <RunningText>
                {getProperty(currentSelectedCustomers[0], "email")}
              </RunningText>
            </Column>
          </Row>
        </SizedContainer>
        <Row justifyContent="flex-end" alignItems="flex-end">
          <FilledButton
            color="secondary"
            label="zurück"
            onClick={() => {
              setCustomerSelected(false);
            }}
          />
          <FilledButton
            className="ml-15"
            disabled={currentSelectedCustomers?.length === 0}
            label="Buchung hinzufügen"
            onClick={() => {
              handleSubmit();
            }}
          />
        </Row>
      </ModalHeader>
    );
  };

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader
        title="Buchung hinzufügen"
        description="Wähle einen Kunden aus, um eine Buchung zu erstellen."
      >
        <Row justifyContent="space-between" alignItems="center">
          <SizedContainer size={ContainerSizes.M}>
            <OutlinedTextInput
              placeholder="User suchen"
              onChange={(value) => {
                if (value != null && value?.trim().length > 0) {
                  setIsSearchActive(true);
                  customerStore?.searchAndSetCustomers(value);
                } else {
                  setIsSearchActive(false);
                }
              }}
            />
          </SizedContainer>
          <Row justifyContent="flex-end" alignItems="center">
            <FilledButton
              className="ml-15"
              disabled={currentSelectedCustomers?.length === 0}
              label="Weiter"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Row>
        </Row>
      </ModalHeader>
    );
  };

  const _builUserList = (): JSX.Element => {
    if (customerStore?.customers?.isLoading === true) {
      return <SkeletonSelectionListDataTableItem count={5} />;
    } else {
      if (isSearchActive && customerToRender.length === 0) {
        return <ModalNoSearchResultComponent />;
      }

      return (
        <div className="exercises-list-wrapper">
          <UserSelectionList
            users={customerToRender}
            selectedUsers={currentSelectedCustomers ?? []}
            onClick={(user: User) => {
              handleUserListClick(user);
            }}
          ></UserSelectionList>
        </div>
      );
    }
  };

  const _builBookingForm = (): JSX.Element => {
    return (
      <PageContainer>
        <SizedContainer size={ContainerSizes.XML} className="mb-25">
          <ComponentWrapper noPadding title="Buchung" className="mt-5">
            <Row alignItems="center" className="pt-10 pb-10 pl-10 pr-10">
              <Image
                size={ImageSizes.M}
                className="mr-15"
                imageUrl={event?.service?.coverImageUrl}
              />
              <Column>
                <LargeText>{event?.service?.title}</LargeText>
                <RunningText>
                  {event?.startTime} - {event?.endTime} Uhr
                </RunningText>
              </Column>
            </Row>
          </ComponentWrapper>
        </SizedContainer>

        <TitleText>Zusatz Info</TitleText>
        <OutlinedTextArea
          className="mt-10"
          value={getProperty(initialBooking, "additionalBookingInfo")}
          onChange={(value) => {
            if (value == null || initialBooking == null) {
              return;
            }
            setProperty(initialBooking, "additionalBookingInfo", value);
            bookingStore?.setInitialBooking(initialBooking);
          }}
        />

        <Row alignItems="center" justifyContent="flex-start">
          <FilledCheckbox checked={true} onChange={(prop: any) => {}} />
          <RunningText className="ml-5 mt-5">
            Buchungsbestätigung an Kunden senden
          </RunningText>
        </Row>
      </PageContainer>
    );
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container">
        {!customerSelected ? _buildTopBar() : _buildTopBarBookingForm()}
      </div>
      <div className="selection-modal-body-container">
        {!customerSelected ? _builUserList() : _builBookingForm()}
      </div>
    </div>
  );
};

export default inject(
  "customerStore",
  "modalStore",
  "eventStore",
  "bookingStore"
)(observer(AddBookingModal));
