import React from "react";
import classNames from "classnames";
import "./sidebar.component.scss";
import SidebarNavigationItem from "../sidebar.item.comonent/sidebar.item.component";
import SidebarItemGroup from "../sidebar.item.group.component/sidebar.item.group.component";
import {
  faClose,
  faUsers,
  faMobileNotch,
  faNewspaper,
  faDumbbell,
  faClipboard,
  faChartNetwork,
  faBoxesStacked,
  faCircleQ,
  faHouse,
  faTags,
  faShop,
  faUserGroup,
  faCalendar,
} from "@fortawesome/pro-regular-svg-icons";
import GymoLogo from "components/general.compoenents/gymo.logo.component/gymo.logo.component";
import { Navigate, useParams } from "react-router";
import LoggedInUserDropdown from "components/input.components/dropdown.components/logged.in.user.dropdown.component/logged.in.user.dropdown.component";
import Row from "components/general.compoenents/row.component/row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarUpsell from "../sidebar.upsell.component/sidebar.upsell.component";
import Column from "components/general.compoenents/column.component/column.component";

interface SidebarNavigationProps {
  className?: string;
}

const SidebarNavigation = ({
  className,
}: SidebarNavigationProps): JSX.Element => {
  const { studioID } = useParams();

  const sidebarNavigationClassName = classNames(
    {
      "sidebar-navigation-wrapper": true,
    },
    className
  );

  if (studioID == null) {
    return <Navigate to="/" replace />;
  }

  const getPath = (path: string): string => {
    return `/studios/${studioID}/${path}`;
  };

  const closeMobileNavigation = (): void => {
    const navigation = document.getElementById("navigation-bar");
    if (navigation == null) {
      return;
    }

    navigation.style.display = "none";
  };

  return (
    <div className={sidebarNavigationClassName} id="navigation-bar">
      <div className="sidebar-navigation-body">
        <Row justifyContent="space-between">
          <GymoLogo className="sidebar-header-logo ml-10" size={30} />
          <FontAwesomeIcon
            className="sidebar-header-close hide-on-desktop"
            icon={faClose}
            onClick={closeMobileNavigation}
          />
        </Row>
        <div className="sidebar-navigation-content">
          <SidebarItemGroup label="Studio">
            <SidebarNavigationItem
              icon={faDumbbell}
              path={getPath("devices")}
              permissionAlias="DASHBOARD_TEMPLATE_DEVICES"
            >
              Template Geräte
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-devices"
              icon={faDumbbell}
              path={getPath("studio-devices")}
              permissionAlias="DASHBOARD_DEVICES"
            >
              Geräte
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-exercises"
              icon={faChartNetwork}
              path={getPath("exercises")}
              permissionAlias="DASHBOARD_EXERCISES"
            >
              Übungen
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faBoxesStacked}
              path={getPath("device-brands")}
              permissionAlias="DASHBOARD_DEVICE_BRANDS"
            >
              Gerätehersteller
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faTags}
              path={getPath("properties")}
              permissionAlias="DASHBOARD_PROPERTIES"
            >
              Properties
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faUserGroup}
              path={getPath("gymo-customers")}
              permissionAlias="DASHBOARD_GYMO_CUSTOMERS"
            >
              Kunden
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-training-plans"
              icon={faClipboard}
              path={getPath("training-plans")}
              permissionAlias="DASHBOARD_PLANS"
            >
              Trainingspläne
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faCircleQ}
              path={getPath("services")}
              permissionAlias="DASHBOARD_SERVICES"
            >
              Dienstleistungen
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faCalendar}
              path={getPath("events")}
              permissionAlias="DASHBOARD_BOOKINGS"
            >
              Buchungen
            </SidebarNavigationItem>
          </SidebarItemGroup>

          <SidebarItemGroup label="App">
            <SidebarNavigationItem
              id="intro-app-configurator"
              icon={faMobileNotch}
              path={getPath("app")}
              permissionAlias="DASHBOARD_APP_CONFIGURATION"
            >
              App Konfiguration
            </SidebarNavigationItem>
            <SidebarNavigationItem
              icon={faHouse}
              path={getPath("feed-configs")}
              permissionAlias="DASHBOARD_HOME_CONFIGURATION"
            >
              Home Konfiguration
            </SidebarNavigationItem>
            <SidebarNavigationItem
              id="intro-news-feed"
              icon={faNewspaper}
              path={getPath("news-feed")}
              permissionAlias="DASHBOARD_FEEDS"
            >
              Beiträge
            </SidebarNavigationItem>
          </SidebarItemGroup>

          <SidebarItemGroup label="Verwalten">
            <SidebarNavigationItem
              id="intro-customers"
              icon={faUsers}
              path={getPath("customers")}
              permissionAlias="DASHBOARD_CUSTOMERS"
            >
              Kunden
            </SidebarNavigationItem>
            <SidebarNavigationItem
              id="intro-customers"
              icon={faShop}
              path={getPath("marketplace")}
              permissionAlias="DASHBOARD_MARKETPLACE"
            >
              Add-ons
            </SidebarNavigationItem>
          </SidebarItemGroup>
        </div>
      </div>
      <Column>
        <SidebarUpsell />
        <LoggedInUserDropdown />
      </Column>
    </div>
  );
};

export default SidebarNavigation;
