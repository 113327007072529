/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from "@hookform/resolvers/yup";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deviceBrandSchema } from "schemas/device.schemas/device.brand.schema";
import DeviceBrandStore from "stores/device.brand.store";

interface DeviceBrandFormProps {
  deviceBrandStore?: DeviceBrandStore;
  isEditing: boolean;
}

const DeviceBrandForm = ({
  deviceBrandStore,
  isEditing,
}: DeviceBrandFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { studioID } = useParams();

  const deviceBrand = deviceBrandStore?.currentDeviceBrand?.data;

  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(deviceBrandSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: deviceBrand,
  });

  // handle device brand form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (deviceBrand == null) {
      return;
    }

    deviceBrandStore?.setCurrentDeviceBrand({
      ...deviceBrand,
      ...data,
    });

    if (!isEditing) {
      const createdDeviceBrand = await deviceBrandStore?.createDeviceBrand(
        data
      );

      // navigate to created device
      if (studioID != null && createdDeviceBrand != null) {
        navigate(
          `/studios/${studioID}/device-brands/${createdDeviceBrand._id!}/info`
        );
      }
    } else {
      await deviceBrandStore?.updateDeviceBrand(deviceBrand._id!, data);
    }

    setFormIsDirty(false);
  };

  if (deviceBrand == null) {
    return <></>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
        setFormIsDirty(false);
      })}
      className="device-brand-form-input-container"
      onChange={(data) => {
        if (!formIsDirty) {
          setFormIsDirty(true);
        }
      }}
    >
      <PageHeader
        label={
          isEditing
            ? "Gerätehersteller bearbeiten"
            : "Gerätehersteller hinzufügen"
        }
      >
        <FilledButton
          disabled={isUploading || !formIsDirty}
          label="Speichern"
          type="submit"
          color="secondary"
        />
      </PageHeader>
      <ComponentWrapper>
        <SizedContainer size={ContainerSizes.XL}>
          <OutlinedTextInput
            label="Name"
            inputRef={register("title")}
            validationMessage={errors.title?.message?.toString()}
          />

          <OutlinedTextInput
            className="mt-30"
            label="Homepage"
            inputRef={register("websiteUrl")}
            validationMessage={errors.websiteUrl?.message?.toString()}
          />

          <OutlinedTextArea
            className="mt-30"
            label="Info"
            inputRef={register("info")}
            validationMessage={errors.info?.message?.toString()}
          />
        </SizedContainer>
        <SizedContainer className="mt-30" size={ContainerSizes.M}>
          <FileUpload
            label="Hersteller Logo"
            folder="device-brands"
            isUploading={(uploading) => {
              setIsUploading(uploading);
            }}
            fileUrl={deviceBrand?.logoUrl}
            inputRef={register("logoUrl")}
            validationMessage={errors.logoUrl?.message?.toString()}
            onFilesUploaded={(files) => {
              if (files && files.length > 0) {
                setValue("logoUrl", files[0].path);
                setFormIsDirty(true);
                clearErrors("logoUrl");
              } else {
                setValue("logoUrl", undefined);
              }
            }}
          />
        </SizedContainer>
      </ComponentWrapper>
    </form>
  );
};

export default inject("deviceBrandStore")(observer(DeviceBrandForm));
