import { FeedConfig } from "schemas/feed.schemas/feed.config.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpFeedConfigService extends GenericHttpClient<FeedConfig> {
  static _instance: HttpFeedConfigService;
  static getInstance(): HttpFeedConfigService {
    if (this._instance == null) {
      this._instance = new HttpFeedConfigService("/feed-configs");
    }
    return this._instance;
  }
}
