import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getEventTypeIcon,
  getStatusIcon,
} from "globals/helpers/calendar.helper";
import { PreparedCalendarEvent } from "globals/intefaces/global.interface";

interface CalendarItemProps {
  event: PreparedCalendarEvent;
}

const CalendarItem = ({ event }: CalendarItemProps): JSX.Element => {
  const eventIsCanceled = event?.event?.status !== "ACTIVE";
  const typeIcon = getEventTypeIcon(event?.event?.type);
  const statusIcon = getStatusIcon(eventIsCanceled);

  return (
    <div>
      <FontAwesomeIcon
        className="mr-5"
        icon={eventIsCanceled ? statusIcon : typeIcon}
      />
      {eventIsCanceled ? <s>{event.title}</s> : <span>{event.title}</span>}
    </div>
  );
};

export default CalendarItem;
