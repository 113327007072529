export const images = [
  {
    id: 1,
    url: {
      default: "https://api.gymo.io/api/files/library-images/blue1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_blue1.png",
    },
    tags: ["Blau", "Sprinten", "Weiblich", "Power"],
  },
  {
    id: 2,
    url: {
      default: "https://api.gymo.io/api/files/library-images/blue2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_blue2.png",
    },
    tags: ["Blau", "Muskeln", "Männlich"],
  },
  {
    id: 3,
    url: {
      default: "https://api.gymo.io/api/files/library-images/blue3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_blue3.png",
    },
    tags: ["Blau", "Muskeln", "Männlich"],
  },
  {
    id: 4,
    url: {
      default: "https://api.gymo.io/api/files/library-images/blue4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_blue4.png",
    },
    tags: ["Blau", "Seilspringen", "Weiblich"],
  },
  {
    id: 5,
    url: {
      default: "https://api.gymo.io/api/files/library-images/brown1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_brown1.png",
    },
    tags: ["Gesund", "Dehnen", "Weiblich"],
  },
  {
    id: 6,
    url: {
      default: "https://api.gymo.io/api/files/library-images/brown2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_brown1.png",
    },
    tags: ["Gesund", "Dehnen", "Weiblich"],
  },
  {
    id: 7,
    url: {
      default: "https://api.gymo.io/api/files/library-images/brown3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_brown3.png",
    },
    tags: ["Gesund", "Dehnen", "Weiblich"],
  },
  {
    id: 8,
    url: {
      default: "https://api.gymo.io/api/files/library-images/dark1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_dark1.png",
    },
    tags: ["Dunkel", "Muskeln", "Männlich", "Dehnen"],
  },
  {
    id: 9,
    url: {
      default: "https://api.gymo.io/api/files/library-images/dark2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_dark2.png",
    },
    tags: ["Dunkel", "Boxen", "Männlich", "Power"],
  },
  {
    id: 10,
    url: {
      default: "https://api.gymo.io/api/files/library-images/dark3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_dark3.png",
    },
    tags: ["Dunkel", "Crossfit", "Männlich", "Gewichte", "Power"],
  },
  {
    id: 11,
    url: {
      default: "https://api.gymo.io/api/files/library-images/dark4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_dark4.png",
    },
    tags: ["Dunkel", "Weiblich"],
  },
  {
    id: 12,
    url: {
      default: "https://api.gymo.io/api/files/library-images/purple1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_purple1.png",
    },
    tags: ["Lila", "Männlich", "Sprinten", "Power"],
  },
  {
    id: 13,
    url: {
      default: "https://api.gymo.io/api/files/library-images/purple2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_purple2.png",
    },
    tags: ["Lila", "Weiblich", "Sprinten", "Power"],
  },
  {
    id: 14,
    url: {
      default: "https://api.gymo.io/api/files/library-images/purple3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_purple3.png",
    },
    tags: ["Lila", "Weiblich", "Gewichte"],
  },
  {
    id: 15,
    url: {
      default: "https://api.gymo.io/api/files/library-images/purple4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_purple4.png",
    },
    tags: ["Lila", "Weiblich", "Gesund", "Dehnen"],
  },
  {
    id: 16,
    url: {
      default: "https://api.gymo.io/api/files/library-images/grey1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_grey1.png",
    },
    tags: ["Grau", "Männlich", "Sprinten"],
  },
  {
    id: 17,
    url: {
      default: "https://api.gymo.io/api/files/library-images/grey2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_grey2.png",
    },
    tags: ["Grau", "Männlich", "Seilspringen", "Power"],
  },
  {
    id: 18,
    url: {
      default: "https://api.gymo.io/api/files/library-images/grey3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_grey3.png",
    },
    tags: ["Grau", "Männlich", "Muskeln"],
  },
  {
    id: 19,
    url: {
      default: "https://api.gymo.io/api/files/library-images/grey4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_grey4.png",
    },
    tags: ["Grau", "Weiblich", "Seilspringen"],
  },
  {
    id: 23,
    url: {
      default: "https://api.gymo.io/api/files/library-images/red4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_red4.png",
    },
    tags: ["Rot", "Weiblich", "Gesund"],
  },
  {
    id: 20,
    url: {
      default: "https://api.gymo.io/api/files/library-images/red1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_red1.png",
    },
    tags: ["Rot", "Weiblich", "Gesund"],
  },
  {
    id: 21,
    url: {
      default: "https://api.gymo.io/api/files/library-images/red2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_red2.png",
    },
    tags: ["Rot", "Lila", "Männlich", "Seilspringen"],
  },
  {
    id: 22,
    url: {
      default: "https://api.gymo.io/api/files/library-images/yellow1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_yellow1.png",
    },
    tags: ["Gelb", "Boxen", "Männlich", "Power"],
  },
  {
    id: 23,
    url: {
      default: "https://api.gymo.io/api/files/library-images/yellow2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_yellow2.png",
    },
    tags: ["Gelb", "Muskeln", "Männlich"],
  },
  {
    id: 24,
    url: {
      default: "https://api.gymo.io/api/files/library-images/yellow3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_yellow3.png",
    },
    tags: ["Gelb", "Sprinten", "Weiblich"],
  },
  {
    id: 25,
    url: {
      default: "https://api.gymo.io/api/files/library-images/yellow4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_yellow4.png",
    },
    tags: ["Gelb", "Sprinten", "Weiblich"],
  },

  {
    id: 26,
    url: {
      default: "https://api.gymo.io/api/files/library-images/studio1.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_studio1.png",
    },
    tags: ["Dehnen", "Weiblich", "Männlich"],
  },
  {
    id: 27,
    url: {
      default: "https://api.gymo.io/api/files/library-images/studio2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_studio2.png",
    },
    tags: ["Gesund", "Weiblich"],
  },
  {
    id: 28,
    url: {
      default: "https://api.gymo.io/api/files/library-images/studio3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_studio3.png",
    },
    tags: ["Weiblich", "Rot", "Grau"],
  },
  {
    id: 29,
    url: {
      default: "https://api.gymo.io/api/files/library-images/studio4.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_studio4.png",
    },
    tags: ["Weiblich", "Power"],
  },
  {
    id: 30,
    url: {
      default: "https://api.gymo.io/api/files/library-images/studio6.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_studio6.png",
    },
    tags: ["Männlich", "Power", "Grau"],
  },

  {
    id: 31,
    url: {
      default: "https://api.gymo.io/api/files/library-images/device1.JPG",
      square: "https://api.gymo.io/api/files/library-images/q_device1.png",
    },
    tags: ["Geräte", "Grau"],
  },

  {
    id: 32,
    url: {
      default: "https://api.gymo.io/api/files/library-images/device2.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_device2.png",
    },
    tags: ["Geräte", "Gelb"],
  },

  {
    id: 33,
    url: {
      default: "https://api.gymo.io/api/files/library-images/device3.jpg",
      square: "https://api.gymo.io/api/files/library-images/q_device3.png",
    },
    tags: ["Geräte", "Grau", "Gelb"],
  },
];
