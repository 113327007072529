import { Editor } from "@tiptap/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faH1,
  faH2,
  faItalic,
  faStrikethrough,
  faLink,
  faLinkSlash,
  faList,
  faImage,
  faCircleXmark,
} from "@fortawesome/pro-regular-svg-icons";

import { faBold } from "@fortawesome/free-solid-svg-icons";

import "./text.editor.toolbar.component.scss";
import { HttpUploadService } from "services/httpClients/http.upload.client";

interface TextEditorToolbarProps {
  editor: Editor | null;
}

const TextEditorToolbar = ({ editor }: TextEditorToolbarProps): JSX.Element => {
  const [videoUrlInputVisible, setVideoUrlInputVisible] = useState(false);
  const [linkUrlInputVisible, setLinkUrlInputVisible] = useState(false);
  const [linkUrlContent, setLinkUrlContent] = useState("");
  const [videoUrlContent, setVideoUrlContent] = useState("");
  const linkInputRef = useRef<HTMLInputElement>(null);
  const videoLinkInputRef = useRef<HTMLInputElement>(null);

  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: ["image/*"],
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 10,
  });

  const insertImageFromFile = async (file: any): Promise<void> => {
    const uploadedAttachment =
      await HttpUploadService.getInstance().uploadFiles({
        files: [file],
        folder: "attachments",
      });
    if (uploadedAttachment) {
      editor
        ?.chain()
        .focus()
        .setImage({ src: uploadedAttachment[0].path })
        .run();
    }
    clear();
  };

  useEffect(() => {
    if (plainFiles && plainFiles.length > 0) {
      insertImageFromFile(plainFiles[0]);
    }
  }, [plainFiles]);

  const openLinkInput = useCallback(() => {
    setLinkUrlInputVisible(true);
    const previousUrl = editor!.getAttributes("link").href;
    if (previousUrl) {
      setLinkUrlContent(previousUrl);
    }

    setTimeout(() => {
      linkInputRef.current!.focus();
    }, 150);
  }, [editor]);

  // const openVideoInput = useCallback(() => {
  //   setVideoUrlInputVisible(true);
  //   setTimeout(() => {
  //     videoLinkInputRef.current!.focus();
  //   }, 150);
  // }, [editor]);

  const setLink = (event: any): void => {
    if (event.key === "Enter") {
      // Empty
      if (linkUrlContent === "") {
        (editor!.chain().focus().extendMarkRange("link") as any)
          .unsetLink()
          .run();

        return;
      }

      // Update link
      (editor!.chain().focus().extendMarkRange("link") as any)
        .setLink({ href: linkUrlContent })
        .updateAttributes("link", { linktype: "link" })
        .run();

      setLinkUrlInputVisible(false);
      setLinkUrlContent("");
    }
  };

  const setVideo = (event: any): void => {
    if (event.key === "Enter") {
      // empty
      if (videoUrlContent === "") {
        (editor!.chain().focus().extendMarkRange("link") as any)
          .unsetLink()
          .run();
        return;
      }

      // update link
      editor!.commands.setYoutubeVideo({
        src: videoUrlContent,
      });

      setVideoUrlInputVisible(false);
      setVideoUrlContent("");
    }
  };

  if (editor == null) {
    return <></>;
  }

  return (
    <div className="bubble-menu">
      <span
        className="bubble-menu-link-url-input bubble-menu-item"
        style={{ visibility: linkUrlInputVisible ? "visible" : "hidden" }}
      >
        <input
          ref={linkInputRef}
          className="link-input"
          placeholder="Enter a link"
          value={linkUrlContent}
          onChange={(event) => {
            setLinkUrlContent(event.target.value);
          }}
          onKeyPress={setLink}
          onBlur={() => {
            setLinkUrlInputVisible(false);
          }}
        />
        <FontAwesomeIcon
          className="toolbar-icon"
          icon={faCircleXmark}
          onClick={() => {
            setLinkUrlInputVisible(false);
          }}
        />
      </span>

      <span
        className="bubble-menu-link-url-input bubble-menu-item"
        style={{
          visibility: videoUrlInputVisible ? "visible" : "hidden",
        }}
      >
        <input
          ref={videoLinkInputRef}
          className="link-input"
          placeholder="Enter a video link"
          value={videoUrlContent}
          onChange={(event) => {
            setVideoUrlContent(event.target.value);
          }}
          onKeyPress={setVideo}
          onBlur={() => {
            setVideoUrlInputVisible(false);
          }}
        />
        <FontAwesomeIcon
          className="toolbar-icon"
          icon={faCircleXmark}
          onClick={() => {
            setVideoUrlInputVisible(false);
          }}
        />
      </span>
      <>
        <div
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={`bubble-menu-item ${
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faH1} />
        </div>
        <div
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`bubble-menu-item ${
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faH2} />
        </div>
        <div
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`bubble-menu-item ${
            editor.isActive("bold") ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faBold} />
        </div>
        <div
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`bubble-menu-item ${
            editor.isActive("italic") ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faItalic} />
        </div>
        <div
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={`bubble-menu-item ${
            editor.isActive("strike") ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faStrikethrough} />
        </div>
        {!editor.isActive("link", { linktype: "link" }) && (
          <div onClick={openLinkInput} className={"bubble-menu-item "}>
            <FontAwesomeIcon className="toolbar-icon" icon={faLink} />
          </div>
        )}
        {editor.isActive("link", { linktype: "link" }) && (
          <div
            onClick={() => (editor.chain().focus() as any).unsetLink().run()}
            className={"bubble-menu-item is-active"}
          >
            <FontAwesomeIcon className="toolbar-icon" icon={faLinkSlash} />
          </div>
        )}
        <div
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`bubble-menu-item ${
            editor.isActive("bulletList") ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faList} />
        </div>
        <div
          onClick={() => {
            openFileSelector();
          }}
          className={`bubble-menu-item ${
            editor.isActive("image") ? "is-active" : ""
          }`}
        >
          <FontAwesomeIcon className="toolbar-icon" icon={faImage} />
        </div>
        {/* <div onClick={openVideoInput} className={"bubble-menu-item"}>
          <FontAwesomeIcon className="toolbar-icon" icon={faVideo} />
        </div> */}
      </>
    </div>
  );
};

export default TextEditorToolbar;
