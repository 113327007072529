import { Service } from "schemas/service.schemas/service.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpServiceProvider extends GenericHttpClient<Service> {
  static _instance: HttpServiceProvider;
  static getInstance(): HttpServiceProvider {
    if (this._instance == null) {
      this._instance = new HttpServiceProvider("/services");
    }
    return this._instance;
  }
}
