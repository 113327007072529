import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";

const NotFoundPage = (): JSX.Element => {
  return (
    <div className="not-found-page-container">
      <Headline>404</Headline>
      <RunningText>
        It looks like you were travelling the web at eactly 88mph. While we work
        on powering your browser back to 1.21 Jigowatts please visit the buttons
        below...
      </RunningText>
    </div>
  );
};

export default NotFoundPage;
