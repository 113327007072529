import { languageOptions } from "globals/data/globals.data";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import StudioStore from "stores/studio.store";
import SelectDropDown from "../select.dropdown.component/select.dropdown.component";

interface LanguageDropdownProps {
  studioStore?: StudioStore;
}

const LanguageDropdown = ({
  studioStore,
}: LanguageDropdownProps): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage;
  const [languageMenuIsOpen, setLanguageMenuIsOpen] = useState(
    undefined as any
  );

  // studioStore!.introTest = true;

  if (
    studioStore?.studio?.localizations == null ||
    studioStore?.studio?.localizations.length === 1
  ) {
    return <></>;
  }

  return (
    <SelectDropDown
      selectedItem={currentLanguage}
      placeholder="Sprache"
      menuIsOpen={languageMenuIsOpen}
      items={languageOptions}
      onChange={(lanuage) => {
        studioStore?.setCurrentLanguage(lanuage);
        setLanguageMenuIsOpen(undefined);
      }}
    />
  );
};

export default inject("studioStore")(observer(LanguageDropdown));
