import Column from "components/general.compoenents/column.component/column.component";
import React from "react";
import upsellImage from "assets/illustrations/upsell_image.png";
import "./subscription.options.component.scss";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";

interface SubscriptionOptionsProps {
  plan: any;
  onPriceChoosen: (plan: any) => void;
}

const SubscriptionOptions = ({
  plan,
  onPriceChoosen,
}: SubscriptionOptionsProps): JSX.Element => {
  const paymentOptions = plan.paymentOptions;
  const planKeys = Object.keys(paymentOptions);

  const _buildPlanItem = (price: any): JSX.Element => {
    return (
      <div className="plan-item-wrapper">
        {price.savingsLabel != null && (
          <div className="sales-container">
            <TitleText className="sales-container-text">
              {price.savingsLabel}
            </TitleText>
          </div>
        )}
        <Column className="plan-item" alignItems="center">
          <Headline className="mb-20">{price.name}</Headline>
          <TitleText>{price.price}€</TitleText>
          <RunningText className="mb-20">pro Monat</RunningText>
          <FilledButton
            label="Auswählen"
            onClick={() => {
              onPriceChoosen({ ...price, roleAlias: plan.roleAlias });
            }}
          />
        </Column>
      </div>
    );
  };

  return (
    <Column>
      <img className="upsell-image" src={upsellImage} />
      <Row justifyContent="space-around" alignItems="center">
        {planKeys.map((optionKey: string) => {
          return _buildPlanItem(paymentOptions[optionKey]);
        })}
      </Row>
    </Column>
  );
};

export default SubscriptionOptions;
