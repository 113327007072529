import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ChartData } from "schemas/chart.schemas/chart.data.schema";
import { LineChartItem } from "schemas/chart.schemas/line.chart.item.schema";
import moment from "moment";

interface StatisticLineChartProps {
  chartData?: ChartData;
}

const StatisticLineChart = ({
  chartData,
}: StatisticLineChartProps): JSX.Element => {
  // Combine all data sets into a single array of LineChartItem objects
  const combinedData: LineChartItem[] = chartData?.dataSets.reduce(
    (result: any, dataSet) => {
      return [
        ...result,
        ...dataSet.data.map((item) => ({ ...item, label: dataSet.label })),
      ];
    },
    []
  );

  // Helper function to generate a random color
  function getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  if (!chartData) return <></>;

  return (
    <LineChart
      width={473}
      height={252}
      data={combinedData}
      margin={{ top: 5, bottom: 5 }}
    >
      <XAxis
        dataKey="x"
        tickFormatter={(timestamp) => moment(timestamp).format("DD.MM.")}
      />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />

      {chartData.dataSets.map((dataSet) => (
        <Line
          key={dataSet.label}
          type="monotone"
          data={dataSet.data}
          dataKey="y"
          name={dataSet.label}
          stroke={getRandomColor()}
          strokeWidth={2}
        />
      ))}
    </LineChart>
  );
};

export default StatisticLineChart;
