import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { Event } from "schemas/events.schemas/event.schema";
import { Service, serviceToJson } from "schemas/service.schemas/service.schema";
import { HttpEventService } from "services/httpClients/http.event.client";
import { HttpServiceProvider } from "services/httpClients/http.service.client";

class ServiceStore {
  // Properties
  private _serviceDataList: PaginationDataList<Service> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _serviceDataListSearchResult: Service[] = [];

  private _archivedServiceDataList: PaginationDataList<Service> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentServiceData: DataItem<Service> = {
    data: undefined,
    isLoading: false,
  };

  private _singleEventsForCurrentService: PaginationDataList<Event> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setServices = (services: Service[]): void => {
    this._serviceDataList.data = services;
  };

  setServicesSearchResult = (services: Service[]): void => {
    this._serviceDataListSearchResult = services;
  };

  setArchivedServices = (services: Service[]): void => {
    this._archivedServiceDataList.data = services;
  };

  setCurrentService = (service: Service | undefined): void => {
    this._currentServiceData.data = service;
  };

  setSingleEventsForCurrentService = (events: Event[]): void => {
    this._singleEventsForCurrentService.data = events;
  };

  //! Getters
  get service(): PaginationDataList<Service> | undefined {
    if (this._serviceDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._serviceDataList));
  }

  get serviceSearchResult(): Service[] | undefined {
    if (this._serviceDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._serviceDataListSearchResult));
  }

  get archivedServices(): PaginationDataList<Service> | undefined {
    if (this._archivedServiceDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._archivedServiceDataList));
  }

  get currentService(): DataItem<Service> | undefined {
    if (this._serviceDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentServiceData));
  }

  get singleEventsForCurrentService(): PaginationDataList<Event> | undefined {
    if (this._singleEventsForCurrentService == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._singleEventsForCurrentService));
  }

  //! Methods
  fetchAndSetServices = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._serviceDataList != null &&
        this._serviceDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._serviceDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._serviceDataList.pageIndex += 1;
      } else {
        this._serviceDataList.pageIndex = 0;
        this._serviceDataList.data = [];
      }

      this._serviceDataList.isLoading = true;

      const services = await HttpServiceProvider.getInstance().find({});

      if (services == null) {
        this._serviceDataList.isLoading = false;
        return;
      }

      this.setServices(services);
      this._serviceDataList.isLoading = false;
    } catch (err) {
      this._serviceDataList.isLoading = false;
      Logging.error({
        className: "ServiceStore",
        methodName: "fetchAndSetServices",
        message: "Services konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetSingleEventsForCurrentService = async (args: {
    serviceID: string;
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._singleEventsForCurrentService != null &&
        this._singleEventsForCurrentService.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._singleEventsForCurrentService.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._singleEventsForCurrentService.pageIndex += 1;
      } else {
        this._singleEventsForCurrentService.pageIndex = 0;
        this._singleEventsForCurrentService.data = [];
      }

      this._singleEventsForCurrentService.isLoading = true;

      const events =
        await HttpEventService.getInstance().getSingleEventsForService({
          serviceID: args.serviceID,
        });

      if (events == null) {
        this._singleEventsForCurrentService.isLoading = false;
        return;
      }

      this.setSingleEventsForCurrentService(events);
      this._singleEventsForCurrentService.isLoading = false;
    } catch (err) {
      this._singleEventsForCurrentService.isLoading = false;
      Logging.error({
        className: "ServiceStore",
        methodName: "fetchAndSetSingleEventsForCurrentService",
        message: "Termine konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetService = async (args: { serviceID: string }): Promise<void> => {
    try {
      this._currentServiceData.isLoading = true;

      const service = await HttpServiceProvider.getInstance().findOne({
        id: args.serviceID,
      });

      if (service == null) {
        this._currentServiceData.isLoading = false;
        return;
      }

      this.setCurrentService(service);
      this._currentServiceData.isLoading = false;
    } catch (err) {
      this._currentServiceData.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "fetchAndSetService",
        message: "Service konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialServiceItem = (): Service => {
    const initialServiceItem: any = {
      title: "",
      category: "COURSE",
      timeSlots: [],
    };

    this.setCurrentService(initialServiceItem);

    return initialServiceItem;
  };

  createService = async (service: Service): Promise<Service | undefined> => {
    try {
      this._currentServiceData.isLoading = true;
      this._serviceDataList.isLoading = true;

      const createdService = await HttpServiceProvider.getInstance().create({
        data: serviceToJson(service),
      });

      if (createdService == null) {
        this._currentServiceData.isLoading = false;
        this._serviceDataList.isLoading = false;
        return;
      }

      this._serviceDataList.data.unshift(createdService);

      this._currentServiceData.isLoading = false;
      this._serviceDataList.isLoading = false;
      toast.success("Service wurde erfolgreich erstellt");
      return createdService;
    } catch (err) {
      this._currentServiceData.isLoading = false;
      this._serviceDataList.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "createService",
        message: "Service konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateService = async (
    id: string,
    service: Service
  ): Promise<Service | undefined> => {
    try {
      this._serviceDataList.isLoading = true;
      this._currentServiceData.isLoading = true;

      const updatedService = await HttpServiceProvider.getInstance().updateOne({
        id,
        data: serviceToJson(service),
      });

      if (updatedService == null) {
        this._serviceDataList.isLoading = false;
        this._currentServiceData.isLoading = false;
        return;
      }

      // find updated service in this._trainingPlanDataList.data and replace it
      // and set service as first item in list
      const servicesWithoutUpdatedItem = this._serviceDataList.data.filter(
        (item) => item._id !== updatedService._id
      );

      this._serviceDataList.data = [
        updatedService,
        ...servicesWithoutUpdatedItem,
      ];

      this.setCurrentService(updatedService);

      this._serviceDataList.isLoading = false;
      this._currentServiceData.isLoading = false;
      toast.success("Service wurde erfolgreich aktualisiert");
      return updatedService;
    } catch (err) {
      this._serviceDataList.isLoading = false;
      this._currentServiceData.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "updateService",
        message: "Service konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  archiveService = async (service: Service): Promise<void> => {
    try {
      this._serviceDataList.isLoading = true;
      this._currentServiceData.isLoading = true;

      const archivedService =
        await HttpServiceProvider.getInstance().archiveOne({
          id: service._id!,
        });

      if (archivedService == null) {
        this._serviceDataList.isLoading = false;
        this._currentServiceData.isLoading = false;
        return;
      }

      // remove archived service from this._serviceDataList.data
      const servicesWithoutArchivedItem = this._serviceDataList.data.filter(
        (item) => item._id !== service._id
      );

      this._serviceDataList.data = servicesWithoutArchivedItem;

      this._serviceDataList.isLoading = false;
      this._currentServiceData.isLoading = false;
      toast.success("Service wurde erfolgreich archiviert");
    } catch (err) {
      this._serviceDataList.isLoading = false;
      this._currentServiceData.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "archiveService",
        message: "Service konnte nicht archiviert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetArchivedServices = async (): Promise<void> => {
    try {
      this._archivedServiceDataList.isLoading = true;

      const archivedServices =
        await HttpServiceProvider.getInstance().findArchived();

      if (archivedServices == null) {
        this._archivedServiceDataList.isLoading = false;
        return;
      }

      this._archivedServiceDataList.data = archivedServices;
      this._archivedServiceDataList.isLoading = false;
    } catch (err) {
      this._archivedServiceDataList.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "fetchAndSetArchivedServices",
        message: "Archivierte Services konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetServices = async (searchTerm: string): Promise<void> => {
    try {
      if (this._serviceDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetServices({ refresh: true });
        return;
      }

      this._serviceDataList.isLoading = true;

      const services = await HttpServiceProvider.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (services == null) {
        this._serviceDataList.isLoading = false;
        return;
      }

      this.setServicesSearchResult(services);
      this._serviceDataList.isLoading = false;
    } catch (err) {
      this._serviceDataList.isLoading = false;

      Logging.error({
        className: "ServiceStore",
        methodName: "searchAndSetServices",
        message: "Kurse konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default ServiceStore;
