import React from "react";
import "./form.item.wrapper.component.scss";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";

const FormItemWrapper = ({
  children,
  title,
  description,
}: {
  children?: React.ReactNode;
  title: string;
  description?: string;
}): JSX.Element => {
  return (
    <div className="form-item-wrapper">
      <div className="form-item-cotnainer">
        <TitleText className="form-item-title">{title}</TitleText>
        {description != null && (
          <SmallText className="mt-5">{description}</SmallText>
        )}
      </div>
      <div className="form-item-cotnainer">
        <div className="form-item-cotnainer-content">{children}</div>
      </div>
    </div>
  );
};

export default FormItemWrapper;
