import React, { useEffect, useState } from "react";
import StudioStore from "stores/studio.store";
import DeviceBrandStore from "stores/device.brand.store";
import { inject, observer } from "mobx-react";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DeviceBrand } from "schemas/device.schemas/device.brand.schema";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import DeviceBrandForm from "./components/device.brand.form.component/device.brand.form.component";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";

interface DeviceBrandDetailPageProps {
  studioStore?: StudioStore;
  deviceBrandStore?: DeviceBrandStore;
}

const DeviceBrandDetailPage = ({
  studioStore,
  deviceBrandStore,
}: DeviceBrandDetailPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { deviceBrandID } = useParams();
  const [isEditing, setIsEditing] = useState(deviceBrandID !== "new");
  const isLoading = deviceBrandStore?.currentDeviceBrand?.isLoading;
  const deviceBrand: DeviceBrand | undefined =
    deviceBrandStore?.currentDeviceBrand?.data;

  useEffect(() => {
    initializePage();
  }, [deviceBrandID]);

  const initializePage = async (): Promise<void> => {
    if (deviceBrandID == null) {
      return;
    }

    if (deviceBrandID !== "new") {
      deviceBrandStore?.fetchAndSetDeviceBrand({
        deviceBrandID,
      });

      // fetch available devices for current device brand
      deviceBrandStore?.fetchAndSetDeviceBrandDevices({
        deviceBrandID,
        refresh: true,
      });

      setIsEditing(true);
    }

    if (deviceBrandID === "new") {
      deviceBrandStore?.createInitialDeviceBrand();
      setIsEditing(false);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab label="Informationen" key="device-brand-info-tab" path="info" />,
        ]}
      />
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath="device-brands"
        actions={
          deviceBrandID !== "new" ? (
            <FilledButton
              label="Löschen"
              color={"danger"}
              onClick={() => {
                if (deviceBrand?._id != null) {
                  const deleteDeviceBrand =
                    deviceBrandStore?.deleteDeviceBrand(deviceBrand);

                  if (
                    deleteDeviceBrand != null &&
                    studioStore?.studio?._id != null
                  ) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/device-brands`
                    );
                  }
                }
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={Math.random()}>
        <div className="device-brand-detail-wrapper">
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path="info"
                element={<DeviceBrandForm isEditing={isEditing} />}
              />
            </Route>
          </Routes>
        </div>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "deviceBrandStore",
  "studioStore"
)(observer(DeviceBrandDetailPage));
