import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import { formatDate } from "globals/helpers/global.helper";
import { inject, observer } from "mobx-react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Booking } from "schemas/booking.schemas/booking.schema";
import { ModalStore } from "stores/modal.store";

interface BookingUserListProps {
  bookings: Booking[];
  modalStore?: ModalStore;
  bookingAction?: (bookingID: string) => void;
  actionLabel?: string;
}

const BookingUserList = ({
  bookings,
  modalStore,
  bookingAction,
  actionLabel,
}: BookingUserListProps): JSX.Element => {
  const { studioID } = useParams();
  const navigate = useNavigate();

  return (
    <ListDataTable
      noDataMessage="Zu diesem Termin sind noch keine Teilnehmer angemeldet."
      onClick={(data: Booking) => {
        if (!data.user || !studioID) return;

        modalStore?.openNavigateModal({
          navigate,
          path: `/studios/${studioID}/customers/${data.user?._id}/overview`,
          title: "Möchten Sie das Kundenprofil anzeigen?",
          description:
            "Zur Kundenansicht navigieren? Klicken Sie auf OK oder Abbrechen, um auf der aktuellen Seite zu bleiben.",
        });
      }}
      data={bookings}
      gap={20}
      columns={[
        {
          child: <RunningText>Teilnehmer</RunningText>,
          flex: 3,
        },

        {
          child: <RunningText>Buchungsdatum</RunningText>,
        },
        {
          child: <></>,
          flex: 2,
        },
        {
          child: <></>,
        },
      ]}
      dataTableItemBuilder={(dataItem: Booking, index: number) => {
        return {
          key: dataItem._id,
          children: [
            {
              child: (
                <Row alignItems="center">
                  <RunningText className="mr-20">{index + 1}</RunningText>
                  <Image
                    className="mr-20"
                    rounded={false}
                    imageUrl={dataItem.user?.profileImageUrl}
                    size={ImageSizes.M}
                  />
                  <div>
                    <RunningText>
                      {dataItem.user?.firstName} {dataItem.user?.lastName}
                    </RunningText>
                    <SmallText>{dataItem.user?.email}</SmallText>
                  </div>
                </Row>
              ),
            },

            {
              child: (
                <RunningText>
                  {formatDate(dataItem?.system?.createdAt, "DD.MM.YYYY")}
                </RunningText>
              ),
            },

            {
              child: <></>,
            },
            {
              child: (
                <>
                  {bookingAction && (
                    <FilledButton
                      label={actionLabel ?? "Stornieren"}
                      color="danger"
                      onClick={() => {
                        bookingAction?.(dataItem._id);
                      }}
                    />
                  )}
                </>
              ),
            },
          ],
        };
      }}
    />
  );
};

export default inject("modalStore")(observer(BookingUserList));
