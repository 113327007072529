import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useNavigate, useOutlet } from "react-router-dom";
import ServiceNavList from "components/list.components/nav.list.components/service.nav.list.component/service.nav.list.component";
import servicePlaceholder from "assets/illustrations/placeholders/service_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import ServiceStore from "stores/service.store";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface ServicePageProps {
  serviceStore?: ServiceStore;
  studioStore?: StudioStore;
}

const ServicePage = ({
  serviceStore,
  studioStore,
}: ServicePageProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const services = serviceStore?.service?.data ?? [];

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<ServiceNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Du hast noch keine Dienstleistungen hinzugefügt"
            description="Füge ein Dienstleistung hinzu, um sie hier anzuzeigen."
            titleNothingSelected="Du hast noch keine Dienstleistung ausgewählt"
            descriptionNothingSelected="Wähle einen Dienstleistung aus, um sie hier anzuzeigen."
            imagePath={servicePlaceholder}
            data={services}
            noDataActions={
              <FilledButton
                label="Dienstleistung hinzufügen"
                onClick={() => {
                  if (studioStore?.studio?._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/services/new/info`
                    );
                  }
                }}
              />
            }
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject("serviceStore", "studioStore")(observer(ServicePage));
