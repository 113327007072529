import classNames from "classnames";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import Column from "../column.component/column.component";
import "./component.wrapper.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { inject, observer } from "mobx-react";

interface ComponentWrapperProps {
  title?: string;
  children: JSX.Element | JSX.Element[] | any;
  actions?: JSX.Element | JSX.Element[];
  noPadding?: boolean;
  className?: string;
  disabled?: boolean;
  info?: { title: string; content: string };
  autoWidth?: boolean;
  modalStore?: ModalStore;
}

const ComponentWrapper = ({
  title,
  children,
  actions,
  noPadding = false,
  className,
  disabled = false,
  info,
  autoWidth = false,
  modalStore,
}: ComponentWrapperProps): JSX.Element => {
  const componenContainerClassName = classNames(
    {
      "component-container": true,
      "component-container--auto-width": autoWidth,
    },
    className
  );

  const componentWrapperClassName = classNames(
    {
      "component-wrapper-container": true,
      "component-wrapper-container--no-padding": noPadding,
    },
    className
  );

  const componentWrapperContentClassName = classNames({
    "component-wrapper-container-content": true,
    "component-wrapper-container-content--disabled": disabled,
  });

  return (
    <Column className={componenContainerClassName}>
      {(title != null || actions != null) && (
        <div className="component-wrapper-container-header">
          <TitleText>{title}</TitleText>
          {actions}
        </div>
      )}
      <div className={componentWrapperClassName}>
        {info != null && (
          <FontAwesomeIcon
            icon={faCircleInfo}
            color="grey"
            className="component-wrapper-container--info-item"
            onClick={() => {
              modalStore?.openModal(ModalType.CONFIRM_MODAL, ModalSize.SMALL, {
                confirmLabel: "Verstanden",
                title: info.title,
                description: info.content,
              });
            }}
          />
        )}
        <div className={componentWrapperContentClassName}>{children}</div>
      </div>
    </Column>
  );
};

export default inject("modalStore")(observer(ComponentWrapper));
