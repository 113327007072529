import { weekDaysOptions } from "globals/data/globals.data";
import moment from "moment";
import { ClientRole } from "schemas/token.role.schema";
import { Logging } from "./logging.helper";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";

export const getDropdownWeekDay = (weekDay: number): any => {
  const weekDayOption = weekDaysOptions.find(
    (option) => option.value === weekDay
  );

  return weekDayOption;
};

export const formatDate = (
  value: Date | undefined,
  format?: string
): string => {
  if (!value || value == null) {
    return "";
  }

  if (!format) {
    return moment(value).format("yyyy-MM-DD")?.toString();
  }
  return moment(value).format(format)?.toString();
};

// separate token roles by membership
export const filterClientRolesByMembership = (
  tokenRoles: ClientRole[]
): { studioRoles: ClientRole[]; userRoles: ClientRole[] } => {
  const studioRoles = tokenRoles.filter(
    (tokenRole) => tokenRole.membership === "STUDIO"
  );

  const userRoles = tokenRoles.filter(
    (tokenRole) => tokenRole.membership === "USER"
  );

  return { studioRoles, userRoles };
};

// check if alias exists in permission array of given token role
export const checkIfAliasIsInTokenRoles = (
  tokenRoles: ClientRole[],
  alias: string
): boolean => {
  try {
    for (const tokenRole of tokenRoles) {
      if (
        tokenRole.permissions.includes(alias) ||
        tokenRole.roleAlias === alias
      ) {
        return true;
      }
    }
    return false;
  } catch (err) {
    Logging.error({
      className: "GlobalHelper",
      methodName: "checkIfAliasIsInTokenRoles",
      exception: err,
    });

    return false;
  }
};

// claclulate age from date of birth
export const calculateAge = (dateOfBirth: Date): string => {
  const today = new Date();
  const birth = new Date(dateOfBirth);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birth.getDate())
  ) {
    age--;
  }

  if (age < 0) {
    return "";
  }

  return age?.toString();
};

export const getFormattedDateValue = (
  date: Date | undefined
): string | undefined => {
  try {
    if (date !== undefined) {
      return new Date(date).toISOString().substring(0, 10);
    }
    return undefined;
  } catch (_) {
    return undefined;
  }
};

export const filterAndSortTrainingPlans = (
  trainingPlans: TrainingPlan[],
  type: "CUSTOMER" | "USER"
): TrainingPlan[] => {
  return trainingPlans
    .filter((trainingPlan: TrainingPlan) => trainingPlan.type === type)
    .sort((a: TrainingPlan, b: TrainingPlan) => {
      const aModified = a.system?.modifiedAt ?? 0;
      const bModified = b.system?.modifiedAt ?? 0;
      return Number(aModified) - Number(bModified);
    });
};

export const getWeekDayFromDate = (date: Date): string => {
  const weekdayIndex = moment(date).weekday();

  // find the weekday in the weekDaysOptions array and add 1 to the index
  // because moment starts with 0 and the array with 1
  const weekday = weekDaysOptions.find(
    (option) => option.value === weekdayIndex + 1
  );

  return weekday ? weekday.label : "Unbekannter Wochentag";
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
