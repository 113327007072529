import classNames from "classnames";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import "./widget.template.component.scss";

interface WidgetTemplateProps {
  title: string;
  description: string;
  imageUrl: string;
  isActive?: boolean;
  onClick?: () => void;
}

const WidgetTemplate = ({
  title,
  description,
  imageUrl,
  isActive = false,
  onClick,
}: WidgetTemplateProps): JSX.Element => {
  const widgetTemplateClassName = classNames({
    "widget-template-container": true,
    "widget-template-container-active": isActive,
  });

  return (
    <Row className={widgetTemplateClassName} onClick={onClick}>
      <Column justifyContent="flex-start">
        <TitleText>{title}</TitleText>
        <RunningText>{description}</RunningText>
      </Column>
      <ImageWithAuthHeader
        className="widget-template-container-image ml-15"
        src={imageUrl}
      />
    </Row>
  );
};

export default WidgetTemplate;
