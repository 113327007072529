import React, { useCallback, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import classNames from "classnames";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import "./file.upload.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { Upload } from "schemas/upload.schema";
import { HttpUploadService } from "services/httpClients/http.upload.client";
import Loading from "components/general.compoenents/loading.components/loading.component/loading.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import { isImage, isVideo } from "globals/helpers/file.type.helper";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import Column from "components/general.compoenents/column.component/column.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import LinkButton from "../link.button.component/link.button.component";
import { ModalStore } from "stores/modal.store";
import { inject, observer } from "mobx-react";

interface FileUploadProps {
  label?: string;
  fileUrl?: string;
  folder: string;
  accept?: Accept;
  onFilesUploaded?: (files?: Upload[]) => void;
  className?: string;
  aspectRatio?: number;
  inputRef?: any;
  validationMessage?: string;
  isUploading?: (isLoading: boolean) => void;
  onLibraryChoosed?: (data: any) => void;
  modalStore?: ModalStore;
}

const FileUpload = ({
  label,
  fileUrl,
  folder,
  accept = {
    "image/*": [".jpeg", ".jpg", ".png"],
  },
  onFilesUploaded,
  className,
  aspectRatio = 4 / 3,
  inputRef,
  validationMessage,
  isUploading,
  onLibraryChoosed,
  modalStore,
}: FileUploadProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(fileUrl);
  const [hasFile, setHasFile] = useState(Boolean(fileUrl));

  const onDrop: any = useCallback(async (acceptedFiles: any) => {
    setIsLoading(true);
    isUploading?.(true);

    const uploads: Upload[] | undefined =
      await HttpUploadService.getInstance().uploadFiles({
        files: acceptedFiles,
        folder,
      });

    setSelectedFileUrl(uploads?.[0].path);
    setHasFile(true);

    onFilesUploaded?.(uploads);

    setIsLoading(false);
    isUploading?.(false);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  useEffect(() => {
    // Call initial if undefined
    if (
      onFilesUploaded != null &&
      (selectedFileUrl == null || selectedFileUrl === "")
    ) {
      onFilesUploaded(undefined);
    }
  }, [selectedFileUrl]);

  const fileUploadWrapperClassName = classNames({}, className);

  const fileUploadClassName = classNames("file-upload-container", {
    "file-upload-container--dragging": isDragActive,
    "file-upload-container--error": validationMessage != null,
  });

  const removeFile = (): void => {
    setSelectedFileUrl("");
    setHasFile(false);
    onFilesUploaded?.([{ path: "" }] as Upload[]);
  };

  const _buildPlaceholder = (): JSX.Element => {
    return (
      <div className="file-upload-placeholder">
        <FontAwesomeIcon
          className="file-upload-placeholder-icon mb-10"
          icon={faImage}
        />
        <RunningText className="file-upload-placeholder-text">
          Datei hochladen
        </RunningText>
      </div>
    );
  };

  return (
    <Column className={fileUploadWrapperClassName}>
      <Row justifyContent="space-between" alignItems="center">
        <>{label != null && <TitleText className="mb-5">{label}</TitleText>}</>
        <>
          {hasFile ? (
            <LinkButton
              color="danger"
              className="pr-5 pt-0 pb-5 pl-0"
              label="Datei entfernen"
              onClick={removeFile}
            />
          ) : (
            <>
              {onLibraryChoosed != null && (
                <LinkButton
                  className="pr-5 pt-0 pb-5"
                  label="Aus Bibliothek"
                  onClick={() => {
                    if (hasFile) {
                      removeFile();
                    } else {
                      modalStore?.showTemplateModal({
                        onSave: (data) => {
                          if (onLibraryChoosed) onLibraryChoosed(data);
                          setSelectedFileUrl(data?.url);
                          setHasFile(true);
                        },
                      });
                    }
                  }}
                />
              )}
            </>
          )}
        </>
      </Row>
      <div
        className={fileUploadClassName}
        style={{ aspectRatio }}
        {...getRootProps()}
      >
        <input {...inputRef} type="hidden" />
        <input {...getInputProps()} />
        {(selectedFileUrl == null || selectedFileUrl === "") &&
          !isLoading &&
          _buildPlaceholder()}
        {isLoading && <Loading size="medium" theme="dark" />}
        {selectedFileUrl != null && !isLoading && isImage(selectedFileUrl) && (
          <ImageWithAuthHeader className="file-image" src={selectedFileUrl} />
        )}
        {selectedFileUrl != null && !isLoading && isVideo(selectedFileUrl) && (
          <video
            height="100%"
            width="100%"
            style={{ objectFit: "cover", borderRadius: "10px" }}
            controls
            controlsList="nodownload"
          >
            <source src={selectedFileUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <SmallText className="validation-message mb-10">
        {validationMessage}
      </SmallText>
    </Column>
  );
};

export default inject("modalStore")(observer(FileUpload));
