import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useOutlet } from "react-router-dom";
import StudioDeviceNavList from "components/list.components/nav.list.components/studio.device.nav.list.component/studio.device.nav.list.component";
import devicePlaceholder from "assets/illustrations/placeholders/device_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import StudioDeviceStore from "stores/studio.device.store";
import { ModalStore, ModalType } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface StudioDevicePageProps {
  modalStore?: ModalStore;
  studioDeviceStore?: StudioDeviceStore;
}

const StudioDevicePage = ({
  modalStore,
  studioDeviceStore,
}: StudioDevicePageProps): JSX.Element => {
  const outlet = useOutlet();

  const studioDevices = studioDeviceStore?.devices?.data ?? [];

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<StudioDeviceNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Dein Studio hat noch keine Geräte."
            description="Wähle deine Studio Geräte aus um dein Studio mit Übungen zu füllen."
            data={studioDevices}
            titleNothingSelected="Du hast noch kein Gerät ausgewählt"
            descriptionNothingSelected="Wähle ein Gerät aus, um es hier anzuzeigen."
            imagePath={devicePlaceholder}
            noDataActions={
              <FilledButton
                label="Gräte hinzufügen"
                onClick={() => {
                  modalStore?.openModal(
                    ModalType.STUDIO_DEVICE_SELECTION_MODAL
                  );
                }}
              />
            }
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject(
  "studioDeviceStore",
  "modalStore"
)(observer(StudioDevicePage));
