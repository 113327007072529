import classNames from "classnames";
import { ImageSizes } from "globals/enums/global.enum";
import React from "react";
import fallbackImage from "assets/icons/fallback_image.png";
import "./image.component.scss";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";

interface ImageProps {
  imageUrl?: string;
  rounded?: boolean;
  className?: string;
  size?: ImageSizes;
  empty?: boolean;
}

const Image = ({
  imageUrl,
  rounded = false,
  className,
  size = ImageSizes.S,
  empty = false,
}: ImageProps): JSX.Element => {
  let imageSize;

  switch (size) {
    case ImageSizes.S:
      imageSize = "table-image-small";
      break;
    case ImageSizes.M:
      imageSize = "table-image-medium";
      break;
    case ImageSizes.L:
      imageSize = "table-image-large";
      break;
    case ImageSizes.XXL:
      imageSize = "table-image-xxl";
      break;
    case ImageSizes.FULL_SIZE:
      imageSize = "table-image-full-size";
      break;
    default:
      imageSize = "table-image-xl";
  }

  const tableImageClassName = classNames(
    {
      "table-image-empty": empty,
      "table-image": !empty,
      "rounded-image": rounded,
    },
    imageSize,
    className
  );

  if (!empty) {
    return (
      <ImageWithAuthHeader
        src={
          imageUrl != null && imageUrl?.trim() !== "" ? imageUrl : fallbackImage
        }
        alt="image"
        className={tableImageClassName}
      />
    );
  } else {
    return <div className={tableImageClassName}></div>;
  }
};

export default Image;
