import * as yup from "yup";

export const userGoalSchema = yup.object().shape({
  type: yup.string().required(),
  value: yup.number().required(),
  unit: yup.string().required(),
  createdAt: yup.date().required(),
  targetDate: yup.date().required(),
});

export interface UserGoal extends yup.InferType<typeof userGoalSchema> {}
