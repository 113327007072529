import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TwitterPicker } from "react-color";
import "./color.picker.component.scss";

interface ColorPickerProps {
  name: string;
  label?: string;
  className?: string;
  inputRef?: any;
  validationMessage?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const ColorPicker = ({
  name,
  label,
  className,
  inputRef,
  validationMessage,
  defaultValue = "#30D598",
  onChange,
}: ColorPickerProps): JSX.Element => {
  const ref = useRef(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState(defaultValue);
  const colors = [
    "#9DC08B",
    "#609966",
    "#CFB997",
    "#FFBFA9",
    "#DE88A5",
    "#7A4069",
    "#F7B449",

    "#97DEFF",
    "#B1B2FF",
    "#537FE7",
    "#4B56D2",
    "#AA77FF",
    "#EB6440",
    "#C96868",
  ];

  const handleClickOutside = (event: any): void => {
    if (ref.current && !(ref.current as any).contains(event.target)) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClick = (): void => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = (): void => {
    setDisplayColorPicker(false);
  };

  const handleOnChange = (color: any): void => {
    setDisplayColorPicker(false);
    setCurrentColor(color.hex);

    if (onChange != null) {
      onChange(color.hex);
    }

    if (inputRef != null) {
      inputRef.onChange(color.hex);
    }
  };

  const colorPickerClassName = classNames(
    {
      "color-picker-container": true,
      "validation-border": validationMessage != null,
    },
    className
  );

  const twitterStyle = {
    default: {
      input: {
        display: "none",
      },
      hash: {
        display: "none",
      },
    },
  };

  return (
    <div className={colorPickerClassName} ref={ref}>
      {label && <p className="color-picker-label">{label}</p>}
      <div
        className="color-preview"
        style={{ background: currentColor }}
        onClick={handleClick}
      />
      {displayColorPicker && (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <TwitterPicker
            styles={twitterStyle}
            triangle="hide"
            color={currentColor}
            onChange={handleOnChange}
            colors={colors}
          />
        </div>
      )}
      <input
        type="hidden"
        name={name}
        defaultValue={defaultValue}
        {...inputRef}
      />
      {validationMessage && (
        <span className="validation-message">{validationMessage}</span>
      )}
    </div>
  );
};

export default ColorPicker;
