import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import { formatDate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import { Booking } from "schemas/booking.schemas/booking.schema";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";

interface CustomerBookingListProps {
  bookings: Booking[];
  onClick?: (dataItem: Booking) => void;
  onCancelBooking?: (dataItem: Booking) => void;
  isLoading?: boolean;
}

const _buildBookingStatusTag = (booking: Booking): JSX.Element => {
  if (booking?.status == null) return <></>;

  switch (booking?.status) {
    case "CANCELED":
      return <HighlightedInfoText color="error">Storniert</HighlightedInfoText>;
    case "BOOKED":
      return <HighlightedInfoText color="success">Gebucht</HighlightedInfoText>;
    case "QUEUE":
      return (
        <HighlightedInfoText color="warning">Reserviert</HighlightedInfoText>
      );
    default:
      return <></>;
  }
};

const CustomerBookingList = ({
  bookings,
  onClick,
  onCancelBooking,
  isLoading,
}: CustomerBookingListProps): JSX.Element => {
  return (
    <ListDataTable
      isLoading={isLoading != null ? isLoading : false}
      onClick={onClick}
      data={bookings}
      noDataMessage="Keine Buchungen vorhanden"
      columns={[
        {
          child: <></>,
        },
        {
          child: <RunningText>Titel</RunningText>,
        },
        {
          child: <RunningText>Kategorie</RunningText>,
        },
        {
          child: <RunningText>Status</RunningText>,
        },
        {
          child: <RunningText>Gebucht am</RunningText>,
          flex: 1,
        },
        {
          child: <></>,
        },
      ]}
      dataTableItemBuilder={(dataItem: Booking) => {
        return {
          key: dataItem._id,
          children: [
            {
              child: (
                <Image
                  size={ImageSizes.S}
                  imageUrl={dataItem?.event?.service?.coverImageUrl}
                ></Image>
              ),
            },
            {
              child: (
                <>
                  <RunningText>{dataItem?.event?.service?.title}</RunningText>
                  <SmallText>{dataItem?.event?.service?.subTitle}</SmallText>
                </>
              ),
            },
            {
              child: (
                <RunningText>
                  {dataItem?.event?.service?.category?.title?.de}
                </RunningText>
              ),
            },
            {
              child: _buildBookingStatusTag(dataItem),
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem.system?.createdAt, "DD.MM.YYYY")}
                </RunningText>
              ),
            },
            {
              child: (
                <>
                  {dataItem.status !== "CANCELED" && (
                    <FilledButton
                      backgroundColor="transparent"
                      label="Stornieren"
                      color="danger"
                      onClick={() => {
                        onCancelBooking?.(dataItem);
                      }}
                    />
                  )}
                </>
              ),
            },
          ],
        };
      }}
    />
  );
};

export default CustomerBookingList;
