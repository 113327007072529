import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import "./list.placeholder.component.scss";

interface ListPlaceholderProps {
  title: string;
  description: string;
  actionLabel?: string;
  imageUrl?: string;
  onClick?: () => void;
}

const ListPlaceholder = ({
  title,
  description,
  actionLabel,
  imageUrl,
  onClick,
}: ListPlaceholderProps): JSX.Element => {
  return (
    <Column
      className="list-placeholder-container"
      justifyContent="center"
      alignItems="center"
    >
      {imageUrl != null && (
        <img src={imageUrl} className="list-placeholder-container-image" />
      )}
      <TitleText className="list-placeholder-container-text mb-10 bold">
        {title}
      </TitleText>
      <RunningText className="list-placeholder-container-text">
        {description}
      </RunningText>
      {actionLabel != null && (
        <FilledButton
          className="mt-15"
          label={actionLabel}
          onClick={onClick}
          color="secondary"
        />
      )}
    </Column>
  );
};

export default ListPlaceholder;
