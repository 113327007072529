import { Property } from "schemas/property.schemas/property.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpPropertyService extends GenericHttpClient<Property> {
  static _instance: HttpPropertyService;
  static getInstance(): HttpPropertyService {
    if (this._instance == null) {
      this._instance = new HttpPropertyService("/properties");
    }
    return this._instance;
  }
}
