import Column from "components/general.compoenents/column.component/column.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import "./invite.team.member.modal.scss";
import Row from "components/general.compoenents/row.component/row.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import { toast } from "react-toastify";
import { Logging } from "globals/helpers/logging.helper";
import ModalHeader from "../modal.header.component/modal.header.component";

interface InviteTeamMemberModalProps {
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const InviteTeamMemberModal = ({
  studioStore,
  modalStore,
}: InviteTeamMemberModalProps): JSX.Element => {
  const [invitations, setInvitations] = useState([{ email: "", role: "" }]);
  const [isLoading, setIsLoading] = useState(false);

  const roles = studioStore?.teamMemberRoles?.data ?? [];

  useEffect(() => {
    studioStore?.getTeamMemberRoles({});
  }, []);

  const inviteTeamMembers = async (): Promise<void> => {
    setIsLoading(true);
    try {
      for (let i = 0; i < invitations.length; i++) {
        const invitation = invitations[i];

        await HttpInvitationService.getInstance().create({
          data: invitation as any,
        });
      }

      await studioStore?.getOpenTeamMemberInvitations({ refresh: true });

      toast.success("Einladungen wurden erfolgreich versendet");
      setIsLoading(false);
      modalStore?.closeModal();
    } catch (err) {
      Logging.error({
        className: "InviteTeamMemberModal",
        methodName: "inviteTeamMembers",
        message: "Einladungen konnten nicht versendet werden",
        exception: err,
      });

      setIsLoading(false);
    }
  };

  const _buildHeadline = (): JSX.Element => {
    return (
      <ModalHeader
        title="Team Mitglied hinzufügen"
        description="Füge Team-Mitglieder zu deinem Studio hinzu."
      >
        <Row justifyContent="flex-end">
          <FilledButton
            label="Einladen"
            isLoading={isLoading}
            disabled={invitations.length === 0}
            onClick={() => {
              inviteTeamMembers();
            }}
          />
        </Row>
      </ModalHeader>
    );
  };

  // TODO: Add validation

  return (
    <Column
      className="modal-padding"
      justifyContent="center"
      alignItems="center"
    >
      {_buildHeadline()}
      <div className="mb-15" />
      {invitations.map((invitation, index) => {
        return (
          <Row key={index} alignItems="center" justifyContent="center">
            <SizedContainer className="mr-15" size={ContainerSizes.M}>
              <OutlinedTextInput
                className="mb-20"
                placeholder="Email"
                onChange={(value) => {
                  if (value == null) {
                    return;
                  }
                  invitation.email = value;
                }}
              />
            </SizedContainer>

            <SizedContainer size={ContainerSizes.S}>
              <SelectDropDown
                items={roles}
                labelPropertyName="title"
                valuePropertyName="_id"
                selectedItem={roles.find(
                  (role) => role?._id === invitation.role
                )}
                onChange={(role) => {
                  invitation.role = role?._id;

                  setInvitations([...invitations]);
                }}
              />
            </SizedContainer>
            {invitations.length > 1 && (
              <FontAwesomeIcon
                className="cursor-pointer ml-15"
                icon={faCircleXmark}
                onClick={() => {
                  invitations.splice(index, 1);
                  setInvitations([...invitations]);
                }}
              />
            )}
          </Row>
        );
      })}
      <Row className="mt-15" justifyContent="space-between">
        <LinkButton
          disabled={invitations.length >= 5}
          label="Mitglied hinzufügen"
          onClick={() => {
            if (invitations.length >= 5) {
              return;
            }
            setInvitations([...invitations, { email: "", role: "" }]);
          }}
        />
      </Row>
    </Column>
  );
};

export default inject(
  "studioStore",
  "modalStore"
)(observer(InviteTeamMemberModal));
