import React, { useEffect, useState } from "react";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { useNavigate, useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import DeviceBrandStore from "stores/device.brand.store";
import Column from "components/general.compoenents/column.component/column.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface DeviceBrandNavListProps {
  studioStore?: StudioStore;
  deviceBrandStore?: DeviceBrandStore;
}

const DeviceBrandNavList = ({
  studioStore,
  deviceBrandStore,
}: DeviceBrandNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { deviceBrandID } = useParams();
  const deviceBrands = deviceBrandStore?.deviceBrands?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);

  const deviceBrandSearchResult =
    deviceBrandStore?.deviceBrandsSearchResult ?? [];

  // if isSearch is active, use deviceBrandSearchResult otherwise use deviceBrand
  const brandsToRender = isSearchActive
    ? deviceBrandSearchResult
    : deviceBrands;

  useEffect(() => {
    deviceBrandStore?.fetchAndSetDeviceBrands({});
  }, []);

  const _buildNewDeviceBrandPreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            deviceBrandStore?.createInitialDeviceBrand();
            navigate(
              `/studios/${studioStore?.studio?._id}/device-brands/new/info`
            );
          }
        }}
        title={
          deviceBrandStore?.currentDeviceBrand?.data?.title != null &&
          deviceBrandStore?.currentDeviceBrand?.data?.title !== ""
            ? deviceBrandStore?.currentDeviceBrand?.data?.title
            : "Neueer Hersteller"
        }
        imageUrl={deviceBrandStore?.currentDeviceBrand?.data?.logoUrl}
        subtitle="Lege einen neuen Hersteller an"
        isActive
      />
    );
  };

  const _buildDeviceBrandList = (): JSX.Element => {
    if (deviceBrandStore?.deviceBrands?.isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="global-nav-list-wrapper">
          {deviceBrandID === "new" && _buildNewDeviceBrandPreview()}

          {deviceBrands.length === 0 && deviceBrandID !== "new" && (
            <ListPlaceholder
              title="Du hast noch keine Gerätehersteller hinzugefügt."
              description="Hier kannst du Gerätehersteller hinzufügen."
              actionLabel="Hinzufügen"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/device-brands/new/info`
                  );
                }
              }}
            />
          )}

          {brandsToRender.length === 0 &&
            deviceBrands.length > 0 &&
            isSearchActive &&
            deviceBrandID !== "new" && <NavListNoSearchResultComponent />}

          {brandsToRender.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  imageUrl={item?.logoUrl ?? ""}
                  title={item.title}
                  isActive={item._id === deviceBrandID}
                  key={i}
                  onClick={() => {
                    if (studioStore?.studio?._id != null && item._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/device-brands/${item?._id}/info`
                      );
                    }
                  }}
                />
                <Spacer width="88%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/device-brands/new/info`
                );
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Gerätehersteller suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              deviceBrands?.length > 0
            ) {
              setIsSearchActive(true);
              deviceBrandStore?.searchAndSetDeviceBrands(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildDeviceBrandList()}
    </Column>
  );
};

export default inject(
  "studioStore",
  "deviceBrandStore"
)(observer(DeviceBrandNavList));
