import {
  IconDefinition,
  faArrowsRetweet,
  faBan,
  faCalendarCheck,
  faCalendarLinesPen,
} from "@fortawesome/pro-regular-svg-icons";

export const getStatusLabel = (eventType: string): string => {
  if (eventType === "SERIES") {
    return "Serientermin";
  } else if (eventType === "SINGLE") {
    return "Einzeltermin";
  } else if (eventType === "MODIFIED_SERIES") {
    return "Überarbeiteter Serientermin";
  } else {
    return "Einzeltermin";
  }
};

export const getStatusIcon = (eventIsCanceled: boolean): IconDefinition => {
  return eventIsCanceled ? faBan : faCalendarCheck;
};

export const getEventTypeIcon = (eventType: string): IconDefinition => {
  if (eventType === "SERIES") {
    return faArrowsRetweet;
  } else if (eventType === "SINGLE") {
    return faCalendarCheck;
  } else if (eventType === "MODIFIED_SERIES") {
    return faCalendarLinesPen;
  } else {
    return faCalendarCheck;
  }
};
