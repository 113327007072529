import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { ImageSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import React from "react";
import UserStore from "stores/user.store";
import "./marketplace.add.on.component.scss";

interface MarketPlaceAddOnIteProps {
  available?: boolean;
  imageUrl?: string;
  title: string;
  description: string;
  price?: string;
  onClick?: () => void;
  roleAlias?: string;
  userStore?: UserStore;
}

const MarketPlaceAddOnItem = ({
  available = true,
  imageUrl,
  title,
  description,
  price,
  onClick,
  roleAlias,
  userStore,
}: MarketPlaceAddOnIteProps): JSX.Element => {
  const hasAddOn = userStore?.checkIfHasRole({ alias: roleAlias });

  if (hasAddOn) return <></>;

  return (
    <Row className="marketplace-item-wrapper" justifyContent="flex-start">
      <Column className="image-wrapper mr-20">
        <Image
          imageUrl={imageUrl}
          size={ImageSizes.FULL_SIZE}
          className="marketplace-item-image"
        />
      </Column>

      <Column
        justifyContent="space-between"
        className="marketplace-content-wrapper"
      >
        <Column>
          <SmallHeadline className="mb-10"> {title}</SmallHeadline>
          <RunningText>{description}</RunningText>
        </Column>

        <Row>
          <Column justifyContent="space-between" alignItems="stretch">
            {available && price && <SmallHeadline>{price}</SmallHeadline>}
          </Column>
          <Column alignItems="center">
            {available && <FilledButton label="Hinzufügen" onClick={onClick} />}
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

export default inject("userStore")(observer(MarketPlaceAddOnItem));
