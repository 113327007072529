import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router";

export enum ModalType {
  INVISIBLE = "INVISIBLE",
  EDIT_DEVICE_EXERCISE_MODAL = "EDIT_DEVICE_EXERCISE_MODAL",
  PLAN_EXERCISE_SELECTION_MODAL = "PLAN_EXERCISE_SELECTION_MODAL",
  INVITE_TEAM_MEMBER_MODAL = "INVITE_TEAM_MEMBER_MODAL",
  CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL",
  CONFIRM_MODAL = "CONFIRM_MODAL",
  SUBSCRIPTION = "SUBSCRIPTION",
  STUDIO_DEVICE_SELECTION_MODAL = "STUDIO_DEVICE_SELECTION_MODAL",
  EXERCISE_TEMPLATE_VERSION_SELECTION_MODAL = "EXERCISE_TEMPLATE_VERSION_SELECTION_MODAL",
  ADD_BOOKING_FOR_USER_MODAL = "ADD_BOOKING_FOR_USER_MODAL",
  EDIT_PAYMENT_ADDRESS_MODAL = "EDIT_PAYMENT_ADDRESS_MODAL",
  TRAINING_PLAN_SELECTION_MODAL = "TRAINING_PLAN_SELECTION_MODAL",
  TEMPLATE_LIBRARY_MODAL = "TEMPLATE_LIBRARY_MODAL",
  ASK_TO_NAVIGATE = "ASK_TO_NAVIGATE",
  EVENT_MODAL = "EVENT_MODAL",
  ARCHIVE_SERVICE_MODAL = "ARCHIVE_SERVICE_MODAL",
}

export enum ModalSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface NavigateData {
  path: string;
  navigate: NavigateFunction;
  title: string;
  description: string;
}

export class ModalStore {
  // Properties
  currentModal: ModalType = ModalType.INVISIBLE;
  size: ModalSize = ModalSize.MEDIUM;
  customData: any;
  navigateData: NavigateData | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  // Methods
  openModal = (
    type: ModalType,
    size: ModalSize = ModalSize.MEDIUM,
    customData?: any
  ): void => {
    this.currentModal = type;
    this.customData = customData;
    this.size = size;
  };

  closeModal = (): void => {
    this.currentModal = ModalType.INVISIBLE;
  };

  openNavigateModal = (navigateData: NavigateData): void => {
    const askToNavigate = localStorage.getItem("askToNavigate");

    // if askToNavigate is false, navigate without showing the modal
    if (askToNavigate === "false" && navigateData?.navigate != null) {
      const navigate = navigateData?.navigate;
      const path = navigateData?.path;

      // navigate to the path
      navigate(path);
      return;
    }

    this.currentModal = ModalType.ASK_TO_NAVIGATE;
    this.navigateData = navigateData;
    this.size = ModalSize.SMALL;
  };

  showConfirmAlert = (args: {
    onConfirm: () => void | Promise<void>;
    confirmLabel: string;
    title: string;
    description: string;
    onCancel?: () => void;
    cancelLabel?: string;
  }): void => {
    this.currentModal = ModalType.CONFIRM_MODAL;
    this.customData = {
      onConfirm: args.onConfirm,
      confirmLabel: args.confirmLabel,
      title: args.title,
      description: args.description,
      onCancel: args.onCancel,
      cancelLabel: args.cancelLabel,
    };
    this.size = ModalSize.SMALL;
  };

  showTemplateModal = (args: {
    onSave: (data: any) => void | Promise<void>;
  }): void => {
    this.currentModal = ModalType.TEMPLATE_LIBRARY_MODAL;
    this.customData = {
      onSave: args.onSave,
    };
    this.size = ModalSize.MEDIUM;
  };
}
