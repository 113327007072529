import React, { useEffect, useState } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";
import TrainingPlanStore from "stores/training.plan.store";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import "./training.plan.nav.list.component.scss";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface TrainingPlanNavListProps {
  studioStore?: StudioStore;
  trainingPlanStore?: TrainingPlanStore;
}

const TrainingPlanNavList = ({
  studioStore,
  trainingPlanStore,
}: TrainingPlanNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { trainingPlanID } = useParams();
  const trainingPlans = trainingPlanStore?.trainingPlans?.data ?? [];

  const [isSearchActive, setIsSearchActive] = useState(false);

  const trainingPlanSearchResult =
    trainingPlanStore?.trainingPlansSearchResult ?? [];

  // if isSearch is active, use trainingPlanSearchResult otherwise use trainingPlan
  const plansToRender = isSearchActive
    ? trainingPlanSearchResult
    : trainingPlans;

  useEffect(() => {
    trainingPlanStore?.fetchAndSetTrainingPlans({});
  }, []);

  const _getPlanLabel = (plan: TrainingPlan): JSX.Element => {
    if (
      plan.type === "STUDIO" &&
      (plan?.isPublished == null || plan.isPublished)
    ) {
      return (
        <HighlightedInfoText color="success">STUDIO PLAN</HighlightedInfoText>
      );
    } else if (plan.type === "STUDIO" && !plan.isPublished) {
      return <HighlightedInfoText color="warning">VORLAGE</HighlightedInfoText>;
    } else {
      return <></>;
    }
  };

  const _getPlanSubtitle = (plan: TrainingPlan): string => {
    const exercises = plan?.exercises?.length ?? 0;

    if (exercises === 1) {
      return "1 Übung";
    } else if (exercises > 1) {
      return `${exercises} Übungen`;
    } else {
      return "Keine Übungen";
    }
  };

  const _buildNewTrainingPlanPreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(
              `/studios/${studioStore?.studio?._id}/training-plans/new/info`
            );
          }
        }}
        imageUrl={trainingPlanStore?.currentTrainingPlan?.data?.coverImageUrl}
        title="Neuer Trainingsplan"
        subtitle="Erstelle einen neuen Plan"
        isActive
      />
    );
  };

  const _buildTrainingPlanList = (): JSX.Element => {
    if (trainingPlanStore?.trainingPlans?.isLoading === true) {
      return (
        <div className="plan-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    } else {
      return (
        <div className="plan-list-wrapper">
          {trainingPlanID === "new" && _buildNewTrainingPlanPreview()}

          {trainingPlans.length === 0 && trainingPlanID !== "new" && (
            <ListPlaceholder
              title="Du hast noch keinen Trainingsplan erstellt"
              description="Erstelle einige Trainingspläne, um deine Bibliothek zu füllen.
              Du kannst diese später veröffentlichen und zuweisen."
              actionLabel="Trainingsplan hinzufügen"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/training-plans/new/info`
                  );
                }
              }}
            />
          )}

          {plansToRender.length === 0 &&
            trainingPlans.length > 0 &&
            isSearchActive &&
            trainingPlanID !== "new" && <NavListNoSearchResultComponent />}

          {plansToRender?.map((item, i) => {
            return (
              <Column key={item._id} alignItems="center">
                <NavListItem
                  index={i + 1}
                  imageUrl={getProperty(item, "coverImageUrl")}
                  title={getProperty(item, "title")}
                  subtitle={_getPlanSubtitle(item)}
                  isActive={item._id === trainingPlanID}
                  onClick={() => {
                    if (studioStore?.studio?._id != null && item?._id != null) {
                      navigate(
                        `/studios/${studioStore?.studio?._id}/training-plans/${item._id}/info`
                      );
                    }
                  }}
                  rightLabel={_getPlanLabel(item)}
                />
                <Spacer width="85%" />
              </Column>
            );
          })}
        </div>
      );
    }
  };

  return (
    <Column>
      <PageHeadline
        className="border-right"
        classNameActions="full-width"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/training-plans/new/info`
                );
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Trainingsplan suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              trainingPlans?.length > 0
            ) {
              setIsSearchActive(true);
              trainingPlanStore?.searchAndSetTrainingPlans(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildTrainingPlanList()}
    </Column>
  );
};

export default inject(
  "studioStore",
  "trainingPlanStore"
)(observer(TrainingPlanNavList));
