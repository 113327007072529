import { TeamRole } from "schemas/team.role.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpRoleService extends GenericHttpClient<TeamRole> {
  static _instance: HttpRoleService;
  static getInstance(): HttpRoleService {
    if (this._instance == null) {
      this._instance = new HttpRoleService("/roles");
    }
    return this._instance;
  }
}
