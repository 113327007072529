import { makeAutoObservable } from "mobx";
import { Device, deviceToJson } from "schemas/device.schemas/device.schema";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { HttpDeviceService } from "services/httpClients/http.device.client";
import { toast } from "react-toastify";
import {
  DataItem,
  getSkipAndLimitFromPage,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { Logging } from "globals/helpers/logging.helper";

class DeviceStore {
  // Properties
  private _devicesDataList: PaginationDataList<Device> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _devicesDataListSearchResult: Device[] = [];

  private _currentDeviceData: DataItem<Device> = {
    data: undefined,
    isLoading: false,
  };

  private _currentDeviceExercisesDataList: PaginationDataList<Exercise> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 150,
    isLoading: false,
  };

  private _currentSelectedExercises?: Exercise[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setDevices = (devices: Device[]): void => {
    this._devicesDataList.data = [...this._devicesDataList.data, ...devices];
  };

  setDevicesSearchResult = (devices: Device[]): void => {
    this._devicesDataListSearchResult = devices;
  };

  setCurrentDevice = (device: Device): void => {
    this._currentDeviceData.data = device;
  };

  setCurrentDeviceExercises = (exercises: Exercise[] | undefined): void => {
    if (exercises == null) {
      this._currentDeviceExercisesDataList.data = [];
      return;
    }

    this._currentDeviceExercisesDataList.data = exercises;
  };

  setCurrentSelectedExercises = (exercises: Exercise[] | undefined): void => {
    this._currentSelectedExercises = exercises;
  };

  //! Getters
  get devices(): PaginationDataList<Device> | undefined {
    if (this._devicesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._devicesDataList));
  }

  get devicesSearchResult(): Device[] | undefined {
    if (this._devicesDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._devicesDataListSearchResult));
  }

  get currentDevice(): DataItem<Device> | undefined {
    if (this._currentDeviceData == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceData));
  }

  get currentDeviceExercises(): PaginationDataList<Exercise> | undefined {
    if (this._currentDeviceExercisesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceExercisesDataList));
  }

  get currentSelectedExercises(): Exercise[] | undefined {
    if (this._currentSelectedExercises == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentSelectedExercises));
  }

  //! Methods
  createDevice = async (device: Device): Promise<Device | undefined> => {
    try {
      this._currentDeviceData.isLoading = true;
      this._devicesDataList.isLoading = true;

      const newDevice = await HttpDeviceService.getInstance().create({
        data: deviceToJson(device),
      });

      if (newDevice == null) {
        this._currentDeviceData.isLoading = false;
        this._devicesDataList.isLoading = false;
        return;
      }

      this._devicesDataList.data.unshift(newDevice);
      this.setCurrentDevice(newDevice);
      this._currentDeviceData.isLoading = false;
      this._devicesDataList.isLoading = false;
      toast.success("Gerät wurde erfolgreich erstellt");
      return newDevice;
    } catch (err) {
      this._currentDeviceData.isLoading = false;
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "DeviceStore",
        methodName: "createDevice",
        message: "Gerät konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialDevice = async (): Promise<Device> => {
    const initialDevice: any = {};

    this.setCurrentDevice(initialDevice);

    return initialDevice;
  };

  fetchAndSetDevices = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this.devices != null &&
        this._devicesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._devicesDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._devicesDataList.pageIndex += 1;
      } else {
        this._devicesDataList.pageIndex = 0;
        this._devicesDataList.data = [];
      }

      this._devicesDataList.isLoading = true;

      const devices = await HttpDeviceService.getInstance().find({
        query: getSkipAndLimitFromPage({
          pageIndex: this._devicesDataList.pageIndex,
          itemsInPage: this._devicesDataList.itemsInPage,
        }),
      });

      if (devices == null) {
        this._devicesDataList.isLoading = false;
        return;
      }

      this.setDevices(devices);
      this._devicesDataList.isLoading = false;
    } catch (err) {
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "DeviceStore",
        methodName: "fetchAndSetDevices",
        message: "Geräte konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetDeviceWithDeviceExercises = async (args: {
    deviceID: string;
  }): Promise<void> => {
    // todo check method
    try {
      let device;

      if (!this._currentDeviceData.isLoading) {
        this._currentDeviceData.isLoading = true;
        device = await HttpDeviceService.getInstance().findOne({
          id: args.deviceID,
        });

        if (device == null) {
          this._currentDeviceData.isLoading = false;
          return;
        }

        this.setCurrentDevice(device);
        this._currentDeviceData.isLoading = false;
      }

      if (!this._currentDeviceExercisesDataList.isLoading && device != null) {
        this._currentDeviceExercisesDataList.isLoading = true;
        const deviceExercises =
          await HttpDeviceService.getInstance().getDeviceExercises(device._id!);

        this.setCurrentDeviceExercises(deviceExercises);
        this._currentDeviceExercisesDataList.isLoading = false;
      }
    } catch (err) {
      this._currentDeviceExercisesDataList.isLoading = false;
      this._currentDeviceData.isLoading = false;

      Logging.error({
        className: "DeviceStore",
        methodName: "fetchAndSetDeviceWithDeviceExercises",
        message: "Geräte konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateDevice = async (device: Device): Promise<Device | undefined> => {
    try {
      this._currentDeviceData.isLoading = true;
      this._devicesDataList.isLoading = true;

      const updatedDevice = await HttpDeviceService.getInstance().updateOne({
        id: device._id!,
        data: deviceToJson(device),
      });

      if (updatedDevice == null) {
        this._currentDeviceData.isLoading = false;
        this._devicesDataList.isLoading = false;
        return;
      }

      // find updated device in this._devicesDataList.data and replace it
      // and set device as first item in list
      const devicesWithoutUpdatedItem = this._devicesDataList.data.filter(
        (item) => item._id !== updatedDevice._id
      );

      this._devicesDataList.data = [
        updatedDevice,
        ...devicesWithoutUpdatedItem,
      ];

      this.setCurrentDevice(updatedDevice);
      this._currentDeviceData.isLoading = false;
      this._devicesDataList.isLoading = false;
      toast.success("Gerät wurde erfolgreich aktualisiert");
      return updatedDevice;
    } catch (err) {
      this._currentDeviceData.isLoading = false;
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "DeviceStore",
        methodName: "updateDevice",
        message: "Gerät konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetDevices = async (searchTerm: string): Promise<void> => {
    try {
      if (this._devicesDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetDevices({ refresh: true });
        return;
      }

      this._devicesDataList.isLoading = true;

      const devices = await HttpDeviceService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (devices == null) {
        this._devicesDataList.isLoading = false;
        return;
      }

      this.setDevicesSearchResult(devices);
      this._devicesDataList.isLoading = false;
    } catch (err) {
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "DeviceStore",
        methodName: "searchAndSetDevices",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default DeviceStore;
