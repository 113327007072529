import classNames from "classnames";
import React, { useState } from "react";
import "./tag.filter.bar.component.scss";

interface TagFilterBarComponentProps {
  tags: string[];
  onTagSelectionChange: (selectedTags: Set<string>) => void;
  className?: string;
}

const TagFilterBarComponent = ({
  tags,
  onTagSelectionChange,
  className,
}: TagFilterBarComponentProps): JSX.Element => {
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());

  const tagFilterBarClassName = classNames(
    {
      "tag-filter-bar": true,
    },
    className
  );

  const onTagClick = (tag: string): void => {
    const newSelectedTags = new Set(selectedTags);
    if (selectedTags.has(tag)) {
      newSelectedTags.delete(tag);
    } else {
      newSelectedTags.add(tag);
    }
    setSelectedTags(newSelectedTags);
    onTagSelectionChange(newSelectedTags);
  };

  return (
    <div className={tagFilterBarClassName}>
      {tags.map((tag) => (
        <button
          key={tag}
          className={`tag-btn ${
            selectedTags.has(tag) ? "tag-btn--selected" : ""
          }`}
          onClick={() => onTagClick(tag)}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default TagFilterBarComponent;
