import { DeviceBrand } from "schemas/device.schemas/device.brand.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpDeviceBrandService extends GenericHttpClient<DeviceBrand> {
  static _instance: HttpDeviceBrandService;
  static getInstance(): HttpDeviceBrandService {
    if (this._instance == null) {
      this._instance = new HttpDeviceBrandService("/device-brands");
    }
    return this._instance;
  }
}
