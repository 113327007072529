import { Feed } from "schemas/feed.schemas/feed.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpFeedService extends GenericHttpClient<Feed> {
  static _instance: HttpFeedService;
  static getInstance(): HttpFeedService {
    if (this._instance == null) {
      this._instance = new HttpFeedService("/feeds");
    }
    return this._instance;
  }
}
