/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Logging } from "globals/helpers/logging.helper";
import {
  CalendarPaginationDataList,
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { HttpEventService } from "services/httpClients/http.event.client";
import { Event, eventToJson } from "schemas/events.schemas/event.schema";
import { PreparedCalendarEvent } from "globals/intefaces/global.interface";
import { toast } from "react-toastify";
import { Grouped } from "schemas/grouped.schema";
import { isSameDay } from "globals/helpers/global.helper";
import { EventWithBookingInfo } from "schemas/service.schemas/booking.info.schema";
import moment from "moment";

class EventStore {
  //! Properties

  // list of events in the EVENT format (not for the calendar)
  // for the calender this list is transformed in the prepareEventsForCalendar method
  private _eventDataList: CalendarPaginationDataList<Event> = {
    data: [],
    months: [],
    isLoading: false,
  };

  // current event for for the calendar event modal
  private _currentPreparedCalendarEventData: DataItem<PreparedCalendarEvent> = {
    data: undefined,
    isLoading: false,
  };

  // list of grouped events for the Booking List page
  private _groupedEventDataList: PaginationDataList<
    Grouped<EventWithBookingInfo>
  > = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  // current event for the Booking List page
  private _currentEvent: DataItem<Event> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setEvents = (events: Event[]): void => {
    this._eventDataList.data = events;
  };

  setCurrentPreparedEvent = (event: PreparedCalendarEvent): void => {
    this._currentPreparedCalendarEventData.data = event;
  };

  setGroupedEvents = (events: Array<Grouped<EventWithBookingInfo>>): void => {
    this._groupedEventDataList.data = events;
  };

  setCurrentEvent = (event: Event | undefined): void => {
    this._currentEvent.data = event;
  };

  //! Getters
  get events(): PaginationDataList<Event> | undefined {
    if (this._eventDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._eventDataList));
  }

  get currentPreparedCalendarEvent():
    | DataItem<PreparedCalendarEvent>
    | undefined {
    if (this._currentPreparedCalendarEventData == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentPreparedCalendarEventData));
  }

  get groupedEvents():
    | PaginationDataList<Grouped<EventWithBookingInfo>>
    | undefined {
    if (this._groupedEventDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._groupedEventDataList));
  }

  get currentEvent(): DataItem<Event> | undefined {
    if (this._currentEvent == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentEvent));
  }

  prepareEventsForCalendar(events: Event[]): PreparedCalendarEvent[] {
    const transformedEvents: PreparedCalendarEvent[] = [];

    for (let i = 0; i < events.length; i++) {
      const event = events[i];

      if (
        !event.date ||
        !event.startTime ||
        !event.endTime ||
        !event.service ||
        !event.service.title
      ) {
        continue;
      }

      try {
        const startDate = moment(new Date(event.date));
        const startTime = event.startTime.split(":");
        startDate.set({
          hour: parseInt(startTime[0]),
          minute: parseInt(startTime[1]),
        });

        const endDate = moment(new Date(event.date));
        const endTime = event.endTime.split(":");
        endDate.set({
          hour: parseInt(endTime[0]),
          minute: parseInt(endTime[1]),
        });

        const transformedEvent: PreparedCalendarEvent = {
          title: event.service.title,
          start: startDate.toDate(),
          end: endDate.toDate(),
          event,
        };

        transformedEvents.push(transformedEvent);
      } catch (err) {
        Logging.error({
          className: "EventStore",
          methodName: "prepareEventsForCalendar",
          message:
            "Bei der Verarbeitung der Termine ist ein Fehler aufgetreten.",
          exception: err,
          showAlert: true,
        });
      }
    }

    return transformedEvents;
  }

  //! Methods

  fetchAndSetGroupedEvents = async (args: {
    from: Date;
    to: Date;
    refresh?: boolean;
  }): Promise<void> => {
    try {
      if (this._groupedEventDataList.isLoading) {
        return;
      }

      if (args.refresh) {
        // set to initial state
        this._groupedEventDataList.data = [];
        this._groupedEventDataList.pageIndex = 0;
      }

      this._groupedEventDataList.isLoading = true;

      // fetch events for time period
      // TODO add pagination
      const events =
        await HttpEventService.getInstance().getEventsGroupedByDate({
          query: {
            startDate: args.from,
            endDate: args.to,
          },
        });

      if (events == null) {
        this._groupedEventDataList.isLoading = false;
        return;
      }

      this.setGroupedEvents(events);
      this._groupedEventDataList.isLoading = false;
    } catch (err) {
      Logging.error({
        className: "EventStore",
        methodName: "fetchAndSetGroupedEvents",
        message: "Bei der verarbeitung der Termine ist ein Fehler aufgetreten.",
        exception: err,
        showAlert: true,
      });
    }
  };

  findAndSetCurrentEvent = async (args: {
    eventID: string;
    timeSlotID: string;
    date: string;
  }): Promise<Event | undefined> => {
    try {
      const groupedEvents = this.groupedEvents?.data;
      this._currentEvent.isLoading = true;

      if (groupedEvents == null) {
        this._currentEvent.isLoading = false;
        return;
      }

      // wehen eventID is given find the event by id and set it as current event
      // otherwise if eventID == null or "null" and timeSlotID and date are given find the event by timeSlotID and date and set it as current event
      if (args.eventID != null && args.eventID !== "null") {
        // Find the event by id and set it as current event
        for (const group of groupedEvents) {
          const event = group.data?.find(
            (event) => event._id?.toString() === args.eventID?.toString()
          );
          if (event) {
            this.setCurrentEvent(event);
            this._currentEvent.isLoading = false;
            return event;
          }
        }
      } else if (
        args.timeSlotID != null &&
        args.timeSlotID !== "null" &&
        args.date != null
      ) {
        // Find the event by timeSlotID and date and set it as current event
        const targetDate = new Date(args.date);

        for (const group of groupedEvents) {
          const event = group.data?.find(
            (event) =>
              event.timeSlotID?.toString() === args.timeSlotID?.toString() &&
              isSameDay(new Date(event.date), targetDate)
          );
          if (event) {
            this.setCurrentEvent(event);
            this._currentEvent.isLoading = false;
            return event;
          }
        }
      } else {
        this.setCurrentEvent(undefined);
        this._currentEvent.isLoading = false;
        return undefined;
      }
    } catch (err) {
      this._currentEvent.isLoading = false;

      Logging.error({
        className: "EventStore",
        methodName: "findAndSetCurrentEvent",
        message: "Bei der verarbeitung der Termine ist ein Fehler aufgetreten.",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetEvents = async (args: {
    from: Date;
    to: Date;
    refresh?: boolean;
  }): Promise<void> => {
    try {
      if (this._eventDataList.isLoading) {
        return;
      }

      if (args.refresh) {
        // set to initial state
        this._eventDataList.months = [];
        // this._eventDataList.data = [];
        this.setEvents([]);
      } else {
        // check if the month is already loaded and return if true
        const isMonthLoaded = this._eventDataList.months.some(
          (month) => month.from.getTime() === args.from.getTime()
        );

        if (isMonthLoaded) {
          return;
        }
      }

      this._eventDataList.isLoading = true;

      // fetch events for time period
      const events = await HttpEventService.getInstance().find({
        query: {
          startDate: args.from,
          endDate: args.to,
        },
      });

      if (events == null) {
        this._eventDataList.isLoading = false;
        return;
      }

      // add month to loaded months and add events add event list
      this._eventDataList.months.push({ from: args.from, to: args.to });
      this.setEvents([...this._eventDataList.data, ...events]);

      this._eventDataList.isLoading = false;
    } catch (err) {
      this._eventDataList.isLoading = false;

      Logging.error({
        className: "EventStore",
        methodName: "fetchAndSetEvents",
        message: "Termine konnten nicht geladen werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  // update event and fetch events for current month if event is new
  // if event is dynamic (id is null) fetch events for current month
  // else update event in this._eventDataList.data
  updateEvent = async (args: {
    id: string;
    event: Event;
    cancelEvent?: boolean;
  }): Promise<Event | undefined> => {
    const { id, event } = args;
    let updatedEvent: Event | undefined;

    try {
      this._currentPreparedCalendarEventData.isLoading = true;

      // if cancelEvent is true cancel event else update event
      if (args.cancelEvent) {
        // cancel event
        updatedEvent = await HttpEventService.getInstance().cancelEvent({
          eventID: id,
          data: eventToJson(event),
        });
      } else {
        // update event
        updatedEvent = await HttpEventService.getInstance().updateOne({
          id,
          data: eventToJson(event),
        });
      }

      if (updatedEvent == null) {
        this._currentPreparedCalendarEventData.isLoading = false;
        return;
      }

      // update event in grouped event data list
      this.updateEventByIdOrTimeslotAndDate({
        eventID: args.id,
        date: args.event.date,
        timeSlotID: args.event.timeSlotID ?? "",
        updatedEvent,
      });

      // prepare updated event and set it as current event
      const preparedUpdatedEvent = this.prepareEventsForCalendar([
        updatedEvent,
      ])[0];

      this.setCurrentPreparedEvent(preparedUpdatedEvent);

      this._currentPreparedCalendarEventData.isLoading = false;
      toast.success("Termin wurde erfolgreich aktualisiert.");

      return updatedEvent;
    } catch (err) {
      this._currentPreparedCalendarEventData.isLoading = false;

      Logging.error({
        className: "EventStore",
        methodName: "updateEvent",
        message: "Termin konnte nicht aktualisiert werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateGroupedEvent = async (args: {
    id: string;
    event: Event;
    cancelEvent?: boolean;
  }): Promise<Event | undefined> => {
    const { id, event } = args;
    let updatedEvent: Event | undefined;

    try {
      this._currentEvent.isLoading = true;

      // if cancelEvent is true, cancel event else update event
      if (args.cancelEvent) {
        // cancel event
        updatedEvent = await HttpEventService.getInstance().cancelEvent({
          eventID: id,
          data: eventToJson(event),
        });
      } else {
        // update event
        updatedEvent = await HttpEventService.getInstance().updateOne({
          id,
          data: eventToJson(event),
        });
      }

      if (updatedEvent == null) {
        this._currentEvent.isLoading = false;
        return;
      }

      // update event in grouped event data list
      this.updateEventByIdOrTimeslotAndDate({
        eventID: args.id,
        date: args.event.date,
        timeSlotID: args.event.timeSlotID ?? "",
        updatedEvent,
        updateGroupedEvents: true,
      });

      this._currentEvent.data = updatedEvent;
      this._currentEvent.isLoading = false;
      toast.success("Termin wurde erfolgreich aktualisiert.");

      return updatedEvent;
    } catch (err) {
      this._currentEvent.isLoading = false;

      Logging.error({
        className: "EventStore",
        methodName: "updateGroupedEvent",
        message: "Gruppiertes Termin konnte nicht aktualisiert werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialEvent = (date: Date): PreparedCalendarEvent => {
    const initialEvent = {
      title: "Neues Event",
      start: date,
      end: date,
      event: {
        date,
      },
    } as PreparedCalendarEvent;

    this.setCurrentPreparedEvent({ ...initialEvent });

    return initialEvent;
  };

  createEvent = async (event: Event): Promise<Event | undefined> => {
    try {
      this._eventDataList.isLoading = true;
      this._currentPreparedCalendarEventData.isLoading = true;

      const createdEvent = await HttpEventService.getInstance().create({
        data: eventToJson(event),
      });

      if (createdEvent == null) {
        this._eventDataList.isLoading = false;
        this._currentPreparedCalendarEventData.isLoading = false;
        return;
      }

      // prepare created event and set it as current event
      const preparedEvent = this.prepareEventsForCalendar([createdEvent])[0];

      this._eventDataList.data.push(createdEvent);
      this.setCurrentPreparedEvent(preparedEvent);

      this._eventDataList.isLoading = false;
      this._currentPreparedCalendarEventData.isLoading = false;
      toast.success("Termin wurde erfolgreich erstellt.");
      return createdEvent;
    } catch (err) {
      this._eventDataList.isLoading = false;
      this._currentPreparedCalendarEventData.isLoading = false;

      Logging.error({
        className: "EventStore",
        methodName: "updateEvent",
        message: "Termin konnte nicht aktualisiert werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  // load the events for a specific month
  loadMonthForCalendar = async (args: {
    date: Date;
    refresh?: boolean;
  }): Promise<void> => {
    const startOfMonth = new Date(
      args.date.getFullYear(),
      args.date.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    const endOfMonth = new Date(
      args.date.getFullYear(),
      args.date.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    );

    await this.fetchAndSetEvents({
      from: startOfMonth,
      to: endOfMonth,
      refresh: args.refresh,
    });
  };

  updateEventByIdOrTimeslotAndDate = (args: {
    eventID: string | null;
    timeSlotID: string;
    date: Date;
    updatedEvent: Event;
    updateGroupedEvents?: boolean;
  }): void => {
    try {
      const { eventID, timeSlotID, date, updatedEvent, updateGroupedEvents } =
        args;

      if (updateGroupedEvents) {
        // Same logic as before for grouped events
        let groupIndex = -1;
        let eventIndex = -1;

        for (let i = 0; i < this._groupedEventDataList.data.length; i++) {
          const group = this._groupedEventDataList.data[i];

          for (let j = 0; j < group.data.length; j++) {
            const event = group.data[j];

            if (
              event._id === eventID ||
              (event.timeSlotID === timeSlotID &&
                isSameDay(new Date(event.date), new Date(date)))
            ) {
              groupIndex = i;
              eventIndex = j;
              break;
            }
          }

          if (groupIndex > -1) {
            break;
          }
        }

        if (groupIndex > -1 && eventIndex > -1) {
          const updatedGroupedEvents = this._groupedEventDataList.data.slice();
          updatedGroupedEvents[groupIndex].data[eventIndex] = updatedEvent;
          this.setGroupedEvents(updatedGroupedEvents);
        }
      } else {
        // Logic for non-grouped events
        let eventIndex = -1;

        for (let i = 0; i < this._eventDataList.data.length; i++) {
          const event = this._eventDataList.data[i];

          if (
            event._id === eventID ||
            (event.timeSlotID === timeSlotID &&
              isSameDay(new Date(event.date), new Date(date)))
          ) {
            eventIndex = i;
            break;
          }
        }

        if (eventIndex > -1) {
          const updatedEvents = this._eventDataList.data.slice();
          updatedEvents[eventIndex] = updatedEvent;
          this.setEvents(updatedEvents);
        }
      }
    } catch (err) {
      Logging.error({
        className: "EventStore",
        methodName: "updateEventByIdOrTimeslotAndDate",
        message: "updateEventByIdOrTimeslotAndDate failed",
        exception: err,
        showAlert: false,
      });
    }
  };
}

export default EventStore;
