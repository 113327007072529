import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import "./app.preview.component.scss";

interface AppPreviewComponentProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
}

const AppPreviewComponent = ({
  studioStore,
  feedStore,
}: AppPreviewComponentProps): JSX.Element => {
  useEffect(() => {
    sendConfigupdates({
      action: "UPDATE_STUDIO",
      data: studioStore?.editingStudio,
    });
  }, [studioStore?.editingStudio]);

  useEffect(() => {
    sendConfigupdates({
      action: "UPDATE_FEEDCONFIG_ITEM",
      data: feedStore?.currentFeedConfig?.data,
    });
  }, [feedStore?.currentFeedConfig?.data]);

  const initialize = (): void => {
    setTimeout(() => {
      setIntervalLimited(
        () => {
          sendConfigupdates({
            action: "UPDATE_STUDIO",
            data: studioStore?.editingStudio,
          });
        },
        1000,
        10
      );
    }, 2000);
  };

  const sendConfigupdates = (args: { action: any; data: any }): void => {
    if (studioStore?.editingStudio !== null) {
      try {
        (
          document.getElementById("flutter_widget") as any
        )?.contentWindow.postMessage(
          {
            sender: "gymo_dashboard",
            action: args.action,
            data: JSON.stringify({
              ...args.data,
            }),
            WEB_API_KEY: process.env.REACT_APP_GYMO_PREVIEW_APP_AUTH_TOKEN,
          },
          "*"
        );
      } catch (_) {}
    }
  };

  const setIntervalLimited = (
    callback: () => void,
    interval: number,
    limit: number
  ): void => {
    for (let i = 0; i < limit; i++) {
      setTimeout(callback, i * interval);
    }
  };

  return (
    <div
      className="app-preview-container hide-on-mobile"
      style={{
        backgroundColor:
          studioStore?.editingStudio?.studioProperties.appTheme.themeData[1]
            .colorTheme.primary,
      }}
    >
      <iframe
        className="app-preview-iframe"
        id="flutter_widget"
        src="https://app-preview.gymo.io/"
        onLoad={() => {
          initialize();
        }}
      ></iframe>
    </div>
  );
};

export default inject(
  "studioStore",
  "feedStore"
)(observer(AppPreviewComponent));
