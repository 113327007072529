import Column from "components/general.compoenents/column.component/column.component";
import React from "react";
import noSearchResultImage from "assets/icons/no_search_result.svg";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Gleap from "gleap";
import Center from "components/general.compoenents/center.component/center.component";

export const ModalNoSearchResultComponent = (): JSX.Element => {
  return (
    <Center>
      <Column alignItems="center">
        <img src={noSearchResultImage} alt="" className="mb-20" />
        <SmallHeadline className="mt-20 mb-5">
          Leider haben wir kein Ergebnis gefunden.
        </SmallHeadline>
        <RunningText>
          Probier es mit einem anderen Suchbegriff oder schreib uns dein
          Problem.
        </RunningText>
        <FilledButton
          label="Fehler melden"
          className="mt-25"
          onClick={() => {
            Gleap.open();
          }}
        />
      </Column>
    </Center>
  );
};

export default ModalNoSearchResultComponent;
