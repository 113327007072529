import * as yup from "yup";

export const deviceBrandSchema = yup.object().shape({
  _id: yup.string(),
  title: yup.string().required("Titel ist verpflichtend"),
  logoUrl: yup.string().notRequired(),
  websiteUrl: yup.string().url("URL Format ungültig").notRequired(),
  info: yup.string().notRequired(),
});

export interface DeviceBrand extends yup.InferType<typeof deviceBrandSchema> {
  logoUrl?: string;
  websiteUrl?: string;
  info?: string;
}

export const deviceBrandToJson = (deviceBrand: DeviceBrand): any => {
  return {
    ...deviceBrand,
  };
};
