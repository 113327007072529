import { makeAutoObservable } from "mobx";
import { Device, StudioDevice } from "schemas/device.schemas/device.schema";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import {
  DataItem,
  getSkipAndLimitFromPage,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { HttpStudioDeviceService } from "services/httpClients/http.studio.device.client";
import { Logging } from "globals/helpers/logging.helper";

class StudioDeviceStore {
  // Properties
  private _devicesDataList: PaginationDataList<StudioDevice> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _devicesDataListSearchResult: StudioDevice[] = [];

  private _archivedDevicesDataList: PaginationDataList<StudioDevice> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentDeviceData: DataItem<StudioDevice> = {
    data: undefined,
    isLoading: false,
  };

  private _currentSelectedDevices: DataItem<Device[]> = {
    data: undefined,
    isLoading: false,
  };

  private _currentDeviceExercisesDataList: PaginationDataList<Exercise> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 150,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setStudioDevices = (studioDevices: StudioDevice[]): void => {
    this._devicesDataList.data = [
      ...this._devicesDataList.data,
      ...studioDevices,
    ];
  };

  setStudioDevicesSearchResult = (studioDevices: StudioDevice[]): void => {
    this._devicesDataListSearchResult = studioDevices;
  };

  setArchivedStudioDevices = (studioDevices: StudioDevice[]): void => {
    this._archivedDevicesDataList.data = studioDevices;
  };

  setCurrentDevice = (studioDevice: StudioDevice): void => {
    this._currentDeviceData.data = studioDevice;
  };

  setCurrentSelectedDevices = (args: {
    devices?: Device[];
    studioDevices?: StudioDevice[];
  }): void => {
    if (args.devices != null) {
      this._currentSelectedDevices.data = args.devices;
      return;
    }

    if (args.studioDevices != null) {
      (this._currentSelectedDevices.data as any) = args.studioDevices.map(
        (studioDevice) => studioDevice.device
      );
    }
  };

  setCurrentDeviceExercises = (exercises: Exercise[] | undefined): void => {
    if (exercises == null) {
      this._currentDeviceExercisesDataList.data = [];
      return;
    }

    this._currentDeviceExercisesDataList.data = exercises;
  };

  //! Getters
  get devices(): PaginationDataList<StudioDevice> | undefined {
    if (this._devicesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._devicesDataList));
  }

  get devicesSearchResult(): StudioDevice[] | undefined {
    if (this._devicesDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._devicesDataListSearchResult));
  }

  get archivedDevices(): PaginationDataList<StudioDevice> | undefined {
    if (this._archivedDevicesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._archivedDevicesDataList));
  }

  get currentDevice(): DataItem<StudioDevice> | undefined {
    if (this._currentDeviceData == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceData));
  }

  get currentDeviceExercises(): PaginationDataList<Exercise> | undefined {
    if (this._currentDeviceExercisesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentDeviceExercisesDataList));
  }

  get getCurrentSelectedDevices(): DataItem<Device[]> | undefined {
    if (this._currentSelectedDevices == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentSelectedDevices));
  }

  //! Methods
  fetchAndSetStudioDevices = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this.devices != null &&
        this._devicesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._devicesDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._devicesDataList.pageIndex += 1;
      } else {
        this._devicesDataList.pageIndex = 0;
        this._devicesDataList.data = [];
      }

      this._devicesDataList.isLoading = true;

      const devices = await HttpStudioDeviceService.getInstance().find({
        query: getSkipAndLimitFromPage({
          pageIndex: this._devicesDataList.pageIndex,
          itemsInPage: this._devicesDataList.itemsInPage,
        }),
      });

      if (devices != null) {
        // filter out the archived devices and set them to the archive list
        this.setArchivedStudioDevices(
          devices.filter((device) => device.archived)
        );
        this.setStudioDevices(devices.filter((device) => !device.archived));
      }

      this._devicesDataList.isLoading = false;
    } catch (err) {
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "StudioDeviceStore",
        methodName: "fetchAndSetStudioDevices",
        message: "Geräte konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetStudioDeviceWithDeviceExercises = async (args: {
    studioDeviceID: string;
  }): Promise<void> => {
    try {
      let device;

      if (!this._currentDeviceData.isLoading) {
        this._currentDeviceData.isLoading = true;
        device = await HttpStudioDeviceService.getInstance().findOne({
          id: args.studioDeviceID,
        });

        if (device == null) {
          this._currentDeviceData.isLoading = false;
          return;
        }

        this.setCurrentDevice(device);
        this._currentDeviceData.isLoading = false;
      }

      if (!this._currentDeviceExercisesDataList.isLoading && device != null) {
        this._currentDeviceExercisesDataList.isLoading = true;
        const deviceExercises =
          await HttpStudioDeviceService.getInstance().getDeviceExercises(
            args.studioDeviceID
          );

        this.setCurrentDeviceExercises(deviceExercises);
        this._currentDeviceExercisesDataList.isLoading = false;
      }
    } catch (err) {
      this._currentDeviceExercisesDataList.isLoading = false;
      this._currentDeviceData.isLoading = false;

      Logging.error({
        className: "StudioDeviceStore",
        methodName: "fetchAndSetStudioDeviceWithDeviceExercises",
        message: "Geräte konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetStudioDevices = async (searchTerm: string): Promise<void> => {
    try {
      if (this._devicesDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetStudioDevices({ refresh: true });
        return;
      }

      this._devicesDataList.isLoading = true;

      const devices = await HttpStudioDeviceService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (devices == null) {
        this._devicesDataList.isLoading = false;
        return;
      }

      this.setStudioDevicesSearchResult(devices);
      this._devicesDataList.isLoading = false;
    } catch (err) {
      this._devicesDataList.isLoading = false;

      Logging.error({
        className: "StudioDeviceStore",
        methodName: "searchAndSetStudioDevices",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default StudioDeviceStore;
