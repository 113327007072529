import React from "react";
import noSearchResultImage from "assets/icons/no_search_result_white.svg";
import Gleap from "gleap";
import ListPlaceholder from "components/placeholder.components/list.placeholder.component/list.placeholder.component";

export const NavListNoSearchResultComponent = (): JSX.Element => {
  return (
    <ListPlaceholder
      imageUrl={noSearchResultImage}
      title="Leider haben wir kein Ergebnis gefunden."
      description="Probier es mit einem anderen Suchbegriff oder schreib uns dein Problem."
      actionLabel="Fehler melden"
      onClick={() => {
        Gleap.open();
      }}
    />
  );
};

export default NavListNoSearchResultComponent;
