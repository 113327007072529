import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useNavigate, useOutlet } from "react-router-dom";
import ExerciseNavList from "components/list.components/nav.list.components/exercise.nav.list.component/exercise.nav.list.component";
import exercisePlacholder from "assets/illustrations/placeholders/exercise_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import ExerciseStore from "stores/exercise.store";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import UserStore from "stores/user.store";
import { ModalStore, ModalType } from "stores/modal.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";

interface ExercisePageProps {
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
}

const ExercisePage = ({
  exerciseStore,
  studioStore,
  userStore,
  modalStore,
}: ExercisePageProps): JSX.Element => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const exercises = exerciseStore?.exercises?.data ?? [];

  const addExerciseAccess = userStore?.checkIfUserHasPermission({
    alias: "DASHBOARD_ADD_EXERCISE_BUTTON",
  });

  const handleAddExercise = (): void => {
    if (studioStore?.studio?._id != null && addExerciseAccess) {
      navigate(`/studios/${studioStore?.studio?._id}/exercises/new/info`);
    } else {
      modalStore?.openModal(ModalType.STUDIO_DEVICE_SELECTION_MODAL);
    }
  };

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<ExerciseNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Dein Studio hat noch keine Übungen."
            description="Wähle deine Studio Geräte aus um dein Studio mit Übungen zu füllen."
            data={exercises}
            titleNothingSelected="Du hast noch keine Übung ausgewählt"
            descriptionNothingSelected="Wähle eine Übung aus, um sie hier anzuzeigen."
            imagePath={exercisePlacholder}
            noDataActions={
              <FilledButton
                color="secondary"
                label={
                  addExerciseAccess ? "Übung hinzufügen" : "Gräte hinzufügen"
                }
                onClick={() => {
                  handleAddExercise();
                }}
              />
            }
          />
        }
      />
    </MainLayout>
  );
};

export default inject(
  "exerciseStore",
  "studioStore",
  "userStore",
  "modalStore"
)(observer(ExercisePage));
