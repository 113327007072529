import React from "react";
import "./booking.nav.list.item.component.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BookingNavListItemProps {
  isActive: boolean;
  newItem?: boolean;
  onClick: () => void;
  progressBarValue?: number;
  color?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: any;
}

const BookingNavListItem = ({
  progressBarValue,
  color,
  isActive,
  onClick,
  children,
  className,
  icon,
}: BookingNavListItemProps): JSX.Element => {
  const bookingNavListItemClassName = classNames(
    {
      "booking-nav-list-item-wrapper": true,
    },
    className
  );

  const style = {
    background: color,
  };

  const progressBarStyle = {
    width: `${progressBarValue}%`,
  };

  return (
    <div
      className={bookingNavListItemClassName}
      onClick={onClick}
      style={style}
    >
      {isActive && <div className="booking-is-selected"></div>}
      <FontAwesomeIcon
        color="white"
        icon={icon}
        className="nav-list-item-icon"
      />
      {children}
      <div
        className="nav-list-item-progress-bar"
        style={progressBarStyle}
      ></div>
    </div>
  );
};

export default BookingNavListItem;
