import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import {
  Exercise,
  InfoList,
  exerciseToJson,
  InfoData,
} from "schemas/exercise.schemas/exercise.schema";
import { HttpExerciseService } from "services/httpClients/http.exercise.client";

class ExerciseStore {
  // Properties
  private _exercisesDataList: PaginationDataList<Exercise> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _exercisesDataListSearchResult: Exercise[] = [];

  private _currentExerciseData: DataItem<Exercise> = {
    data: undefined,
    isLoading: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  //! Setter
  setExercises = (exercises: Exercise[]): void => {
    this._exercisesDataList.data = exercises;
  };

  setExercisesSearchResult = (exercises: Exercise[]): void => {
    this._exercisesDataListSearchResult = exercises;
  };

  setCurrentExercise = (exercise: Exercise | undefined): void => {
    this._currentExerciseData.data = exercise;
    this._currentExerciseData = { ...this._currentExerciseData };
  };

  //! Getters
  get exercises(): PaginationDataList<Exercise> | undefined {
    if (this._exercisesDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._exercisesDataList));
  }

  get exercisesSearchResult(): Exercise[] | undefined {
    if (this._exercisesDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._exercisesDataListSearchResult));
  }

  get currentExercise(): DataItem<Exercise> | undefined {
    if (this._currentExerciseData == null) {
      return;
    }
    return this._currentExerciseData; // Don't return a copy, because we need to change the data
  }

  //! Methods
  fetchAndSetExercises = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._exercisesDataList != null &&
        this._exercisesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._exercisesDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._exercisesDataList.pageIndex += 1;
      } else {
        this._exercisesDataList.pageIndex = 0;
        this._exercisesDataList.data = [];
      }

      this._exercisesDataList.isLoading = true;

      const exercises = await HttpExerciseService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._exercisesDataList.pageIndex,
            itemsInPage: this._exercisesDataList.itemsInPage,
          }),
        },
      });

      if (exercises == null) {
        this._exercisesDataList.isLoading = false;
        return;
      }

      this.setExercises(exercises);
      this._exercisesDataList.isLoading = false;
    } catch (err) {
      this._exercisesDataList.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "fetchAndSetExercises",
        message: "Übungen konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetExercise = async (args: { exerciseID: string }): Promise<void> => {
    try {
      this._currentExerciseData.isLoading = true;

      const exercise = await HttpExerciseService.getInstance().findOne({
        id: args.exerciseID,
      });

      if (exercise == null) {
        this._currentExerciseData.isLoading = false;
        return;
      }

      this.setCurrentExercise(exercise);
      this._currentExerciseData.isLoading = false;
    } catch (err) {
      this._currentExerciseData.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "fetchAndSetExercise",
        message: "Übung konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialExercise = async (): Promise<Exercise> => {
    const initialExercise: any = {};

    this.setCurrentExercise({ ...initialExercise });

    return initialExercise;
  };

  addNewExerciseInfoListItem = async (): Promise<void> => {
    const infoListItem: InfoList = {
      label: { de: "Neue Info" },
      data: [{ label: { de: "" }, description: { de: "" } }],
    };

    if (this._currentExerciseData.data?.infos == null) {
      return;
    }

    this._currentExerciseData.data.infos = [
      infoListItem,
      ...this._currentExerciseData.data?.infos,
    ];
  };

  addNewExerciseInfoDataItem = async (infoListIndex: number): Promise<void> => {
    const infoDataItem: InfoData = {
      label: { de: "" },
      description: { de: "" },
    };

    if (this._currentExerciseData.data?.infos == null) {
      return;
    }

    this._currentExerciseData.data.infos[infoListIndex].data = [
      infoDataItem,
      ...this._currentExerciseData.data.infos[infoListIndex].data,
    ];
  };

  removeExerciseInfoListItem = async (infoListIndex: number): Promise<void> => {
    if (
      this._currentExerciseData.data?.infos == null ||
      this._currentExerciseData.data._id == null
    ) {
      return;
    }

    const newInfos = this._currentExerciseData.data.infos.filter(
      (info, index) => index !== infoListIndex
    );

    this.setCurrentExercise({
      ...this._currentExerciseData.data,
      infos: newInfos,
    });

    await this.updateExercise({
      id: this._currentExerciseData.data._id,
      exercise: this._currentExerciseData.data,
    });
  };

  removeExerciseInfoDataItem = async (
    infoListIndex: number,
    infoDataIndex: number
  ): Promise<void> => {
    if (this._currentExerciseData.data?.infos == null) {
      return;
    }

    this._currentExerciseData.data.infos[infoListIndex].data.splice(
      infoDataIndex,
      1
    );
  };

  createExercise = async (
    exercise: Exercise
  ): Promise<Exercise | undefined> => {
    try {
      this._currentExerciseData.isLoading = true;
      this._exercisesDataList.isLoading = true;

      const newExercise = await HttpExerciseService.getInstance().create({
        data: exerciseToJson(exercise),
      });

      if (newExercise == null) {
        this._currentExerciseData.isLoading = false;
        this._exercisesDataList.isLoading = false;
        return;
      }

      this._exercisesDataList.data.unshift(newExercise);
      this.setCurrentExercise(newExercise);

      this._currentExerciseData.isLoading = false;
      this._exercisesDataList.isLoading = false;
      toast.success("Übung wurde erfolgreich erstellt");
      return newExercise;
    } catch (err) {
      this._currentExerciseData.isLoading = false;
      this._exercisesDataList.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "createExercise",
        message: "Übung konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateExercise = async (args: {
    id: string;
    exercise?: Exercise;
    updatetpLatestVersion?: boolean;
  }): Promise<Exercise | undefined> => {
    const { id, exercise, updatetpLatestVersion } = args;

    try {
      let updatedExercise: Exercise | undefined;
      this._currentExerciseData.isLoading = true;
      this._exercisesDataList.isLoading = true;

      // if exercise is given update exercise with given data
      if (exercise != null && updatetpLatestVersion == null) {
        updatedExercise = await HttpExerciseService.getInstance().updateOne({
          id,
          data: exerciseToJson(exercise),
        });
      } else if (exercise == null && updatetpLatestVersion) {
        // if exercise is not given and updatetpLatestVersion is true
        // update exercise with latest version
        updatedExercise =
          await HttpExerciseService.getInstance().updateExerciseToLatestVersion(
            {
              exerciseID: id,
            }
          );
      }

      if (updatedExercise == null) {
        this._currentExerciseData.isLoading = false;
        this._exercisesDataList.isLoading = false;
        return;
      }

      // find updated exercise in this._exercisesDataList.data and replace it
      // and set exercise as first item in list
      const exercisesWithoutUpdatedItem = this._exercisesDataList.data.filter(
        (item) => item._id !== updatedExercise?._id
      );

      this._exercisesDataList.data = [
        updatedExercise,
        ...exercisesWithoutUpdatedItem,
      ];

      this.setCurrentExercise(updatedExercise);
      this._currentExerciseData.isLoading = false;
      this._exercisesDataList.isLoading = false;
      toast.success("Übung wurde erfolgreich aktualisiert");
      return updatedExercise;
    } catch (err) {
      this._currentExerciseData.isLoading = false;
      this._exercisesDataList.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "updateExercise",
        message: "Übung konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  searchAndSetExercises = async (
    searchTerm: string
  ): Promise<Exercise | undefined> => {
    try {
      if (this._exercisesDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetExercises({ refresh: true });
        return;
      }

      this._exercisesDataList.isLoading = true;

      const exercises = await HttpExerciseService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (exercises == null) {
        this._exercisesDataList.isLoading = false;
        return;
      }

      this.setExercisesSearchResult(exercises);
      this._exercisesDataList.isLoading = false;
    } catch (err) {
      this._exercisesDataList.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "searchAndSetExercises",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default ExerciseStore;
