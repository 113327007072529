import {
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import "./payment_method.component.scss";

interface PaymentMethodProps {
  paymentMethod: any;
  onClick?: (paymentMethod: any) => void;
  isSelected?: boolean;
  onDelete?: (paymentMethod: any) => void | Promise<void>;
}

const PaymentMethod = ({
  paymentMethod,
  onClick,
  isSelected,
  onDelete,
}: PaymentMethodProps): JSX.Element => {
  const paymentMethodClassName = classNames({
    "payment-method-option": true,
    "payment-method-option--active": isSelected,
  });

  const _getIcon = (cardBrand: string): any => {
    switch (cardBrand) {
      case "visa":
        return faCcVisa;

      case "mastercard":
        return faCcMastercard;

      case "discover":
        return faCcDiscover;

      case "amex":
        return faCcAmex;

      default:
        return faCreditCard;
    }
  };

  return (
    <Row
      key={paymentMethod.id}
      className={paymentMethodClassName}
      onClick={() => {
        if (onClick) {
          onClick(paymentMethod);
        }
      }}
    >
      <FontAwesomeIcon
        className="mr-15"
        icon={_getIcon(paymentMethod.card.brand)}
      />
      <TitleText>{`**** **** **** ${
        paymentMethod.card.last4 as string
      }`}</TitleText>
      {onDelete != null && (
        <FontAwesomeIcon
          onClick={() => {
            if (onDelete != null) {
              onDelete(paymentMethod);
            }
          }}
          className="ml-15"
          icon={faTrash}
        />
      )}
    </Row>
  );
};

export default PaymentMethod;
