import { StudioDevice } from "schemas/device.schemas/device.schema";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpStudioDeviceService extends GenericHttpClient<StudioDevice> {
  static _instance: HttpStudioDeviceService;
  static getInstance(): HttpStudioDeviceService {
    if (this._instance == null) {
      this._instance = new HttpStudioDeviceService("/devices");
    }
    return this._instance;
  }

  async getDeviceExercises(deviceID: string): Promise<Exercise[]> {
    const response = await this.get(`/devices/${deviceID}/exercises`);
    return response.data as Exercise[];
  }

  async handleStudioDeviceAssign(args: { deviceIDs: string[] }): Promise<void> {
    await this.put(`/devices/studio-assign`, {
      devices: args.deviceIDs,
    });
  }
}
