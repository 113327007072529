/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { Navigate } from "react-big-calendar";
import { inject, observer } from "mobx-react";
import EventStore from "stores/event.store";

interface CalendarToolbarProps {
  eventStore?: EventStore;
  toolbar: any;
}

const CalendarToolbar = ({
  toolbar,
  eventStore,
}: CalendarToolbarProps): JSX.Element => {
  // navigation
  const goToBack = (): void => {
    toolbar.onNavigate(Navigate.PREVIOUS);
    loadEventsForVisibleMonth("prev");
  };

  const goToNext = (): void => {
    toolbar.onNavigate(Navigate.NEXT);
    loadEventsForVisibleMonth("next");
  };

  const goToToday = (): void => {
    toolbar.onNavigate(Navigate.TODAY);
    loadEventsForVisibleMonth("current");
  };

  // display current month and year
  const currentMonthAndYear = (): string => {
    const date = toolbar.date;
    return `${date.toLocaleString("de-DE", {
      month: "long",
    })} ${date.getFullYear()}`;
  };

  const loadEventsForVisibleMonth = (
    direction: "prev" | "next" | "current"
  ): void => {
    const currentDate = toolbar.date;

    let visibleMonth;
    if (direction === "prev") {
      visibleMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
    } else if (direction === "next") {
      visibleMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
    } else {
      visibleMonth = currentDate;
    }

    const firstDayOfMonth = new Date(
      visibleMonth.getFullYear(),
      visibleMonth.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      visibleMonth.getFullYear(),
      visibleMonth.getMonth() + 1,
      0
    );

    eventStore?.fetchAndSetEvents({
      from: firstDayOfMonth,
      to: lastDayOfMonth,
    });
  };

  return (
    <Row justifyContent="space-between">
      <Row alignItems="center" className="ml-30 mb-15">
        <FilledButton onClick={goToToday} label="Heute" color="secondary" />
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={goToBack}
          style={{ cursor: "pointer" }}
          className="mr-15 ml-30"
          size={"xl"}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={goToNext}
          style={{ cursor: "pointer" }}
          size={"xl"}
        />
        <SmallHeadline className="ml-30">{currentMonthAndYear()}</SmallHeadline>
      </Row>
    </Row>
  );
};

export default inject("eventStore")(observer(CalendarToolbar));
