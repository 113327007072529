import Youtube from "@tiptap/extension-youtube";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import React, { useEffect, useState } from "react";
import TextStyle from "@tiptap/extension-text-style";
import "./text.editor.component.scss";
import TextEditorToolbar from "../text.editor.toolbar.component/text.editor.toolbar.component";
import Heading from "@tiptap/extension-heading";
import { SmallText } from "components/text.components/small.text.component/small.text.component";

interface TextEditorProps {
  content?: any;
  inputContentChanged?: any;
  validationMessage?: string;
}

const TextEditor = ({
  content,
  inputContentChanged,
  validationMessage,
}: TextEditorProps): JSX.Element => {
  const [editorContent, setEditorContent] = useState(content);
  const [initalized, setInitalized] = useState(false);

  useEffect(() => {
    if (inputContentChanged != null && initalized) {
      inputContentChanged(editorContent);
    }

    setInitalized(true);
  }, [editorContent]);

  useEffect(() => {
    // Clear content if null
    if (content == null || content === "") {
      editor?.commands.setContent({});
    }
  }, [content]);

  const editor = useEditor(
    {
      editable: true,
      extensions: [
        StarterKit.configure({
          heading: false,
          codeBlock: {},
        }),
        Heading.configure({
          levels: [1, 2],
        }),
        Image,
        TextStyle,
        Youtube.configure({ controls: true }),
      ],
      content,
      onUpdate({ editor }) {
        setEditorContent(editor?.getJSON());
      },
    },
    []
  );

  return (
    <>
      <div className="text-editor-container">
        <div className="text-editor-container-toolbar">
          <TextEditorToolbar editor={editor} />
        </div>
        <div className="text-editor-container-content">
          <EditorContent editor={editor} />
        </div>
      </div>
      {validationMessage != null && (
        <SmallText className="validation-message mt-5 ml-5">
          {validationMessage}
        </SmallText>
      )}
    </>
  );
};

export default TextEditor;
