import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import { ModalStore, ModalType } from "stores/modal.store";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import "./main.modal.component.scss";
import PlanExerciseSelectionModal from "../plan.exercise.selection.modal.component/plan.exercise.selection.modal.component";
import InviteTeamMemberModal from "../invite.team.member.modal/invite.team.member.modal";
import classNames from "classnames";
import ConfirmModal from "../confirm.modal.component/confirm.modal.component";
import ChangePasswordModal from "../change.password.modal/change.password.modal";
import SubscriptionModal from "../subscription.modal.component/subscription.modal.component";
import StudioDeviceSelectionModal from "../studio.device.selection.modal/studio.device.selection.modal";
import TemplateVersionSelectionModal from "../template.version.selection.modal/template.version.selection.modal";
import AddBookingModal from "../add.booking.modal/add.booking.modal";
import BillingAddressModal from "../billing.address.model/billing.address.model";
import TemplateTrainingPlanSelectionModal from "../template.training.plan.selection.modal/template.training.plan.selection.modal";
import TemplateLibraryModal from "../template.library.modal/template.library.modal";
import ExerciseSelectionModal from "../exercise.selection.modal.component/exercise.selection.modal.component";
import AskToNavigateModal from "../ask.to.navigate.modal.component/ask.to.navigate.modal.component";
import EventModal from "../event.modal.component/event.modal.component";
import ArchiveServiceModal from "../archive.service.modal.component/archive.service.modal.component";

interface MainModalProps {
  modalStore?: ModalStore;
}

const MainModal = ({ modalStore }: MainModalProps): JSX.Element => {
  const mainModalClassName = classNames({
    "main-modal-container": true,
    [`main-modal-container-${modalStore?.size ?? "medium"}`]: true,
  });

  const getComponentForPath = (): JSX.Element => {
    switch (modalStore?.currentModal) {
      case ModalType.EDIT_DEVICE_EXERCISE_MODAL:
        return <ExerciseSelectionModal />;
      case ModalType.PLAN_EXERCISE_SELECTION_MODAL:
        return <PlanExerciseSelectionModal />;
      case ModalType.INVITE_TEAM_MEMBER_MODAL:
        return <InviteTeamMemberModal />;
      case ModalType.CONFIRM_MODAL:
        return <ConfirmModal />;
      case ModalType.CHANGE_PASSWORD_MODAL:
        return <ChangePasswordModal />;
      case ModalType.SUBSCRIPTION:
        return <SubscriptionModal />;
      case ModalType.STUDIO_DEVICE_SELECTION_MODAL:
        return <StudioDeviceSelectionModal />;
      case ModalType.EXERCISE_TEMPLATE_VERSION_SELECTION_MODAL:
        return <TemplateVersionSelectionModal />;
      case ModalType.ADD_BOOKING_FOR_USER_MODAL:
        return <AddBookingModal />;
      case ModalType.EDIT_PAYMENT_ADDRESS_MODAL:
        return <BillingAddressModal />;
      case ModalType.TRAINING_PLAN_SELECTION_MODAL:
        return <TemplateTrainingPlanSelectionModal />;
      case ModalType.TEMPLATE_LIBRARY_MODAL:
        return <TemplateLibraryModal />;
      case ModalType.ASK_TO_NAVIGATE:
        return <AskToNavigateModal />;
      case ModalType.EVENT_MODAL:
        return <EventModal />;
      case ModalType.ARCHIVE_SERVICE_MODAL:
        return <ArchiveServiceModal />;

      default:
        return <></>;
    }
  };

  if (modalStore?.currentModal === ModalType.INVISIBLE) {
    return <></>;
  }

  return (
    <div className="main-modal-background-container">
      <div className="main-modal-background-container-blur">
        <div className={mainModalClassName}>
          <FontAwesomeIcon
            onClick={() => {
              modalStore?.closeModal();
            }}
            className="main-modal-container-close-icon"
            icon={faXmark}
          />
          {getComponentForPath()}
        </div>
      </div>
    </div>
  );
};

export default inject("modalStore")(observer(MainModal));
