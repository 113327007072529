import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import React from "react";
import { useOutlet } from "react-router-dom";
import GymoCustomerNavList from "components/list.components/nav.list.components/gymo.customer.nav.list.component/gymo.customer.nav.list";

const GymoCustomerPage = (): JSX.Element => {
  const outlet = useOutlet();

  return (
    <MainLayout>
      <SplitLayout
        useAsPage
        leftChild={<GymoCustomerNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
      ></SplitLayout>
    </MainLayout>
  );
};

export default GymoCustomerPage;
