import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import SplitLayout from "layouts/split.layout/split.layout";
import { useNavigate, useOutlet } from "react-router-dom";
import BookingNavList from "components/list.components/nav.list.components/booking.nav.list.component/booking.nav.list.component";
import bookingPlaceholder from "assets/illustrations/placeholders/service_placeholder.png";
import PagePlaceholder from "components/placeholder.components/page.placeholder.component/page.placeholder.component";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import EventStore from "stores/event.store";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";

interface BookingPageProps {
  studioStore?: StudioStore;
  eventStore?: EventStore;
}

const BookingPage = ({
  studioStore,
  eventStore,
}: BookingPageProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  const groupedEvents = eventStore?.groupedEvents?.data;

  return (
    <MainLayout
      topBars={[
        <PageHeadline
          title="Buchungen"
          key="events-page-headline"
          actions={[
            <FilledButton
              color="secondary"
              key="open-service"
              label="Kalender öffnen"
              onClick={() => {
                if (studioStore?.studio?._id == null) {
                  return;
                }

                eventStore?.loadMonthForCalendar({
                  date: new Date(),
                  refresh: true,
                });

                navigate(`/studios/${studioStore?.studio?._id}/events`);
              }}
            />,
          ]}
        />,
      ]}
    >
      <SplitLayout
        useAsPage
        leftChild={<BookingNavList />}
        leftGrow={1}
        maxWidthLeft={550}
        minWidthLeft={350}
        rightChild={outlet}
        rightGrow={2}
        placeholder={
          <PagePlaceholder
            title="Du hast noch keine Dienstleistungen definiert."
            description="Füge eine Dienstleistung hinzu, um sie hier anzuzeigen."
            titleNothingSelected="Du hast noch keine Dienstleistung ausgewählt"
            descriptionNothingSelected="Wähle eine Dienstleistung aus, um die Details zu sehen"
            imagePath={bookingPlaceholder}
            data={groupedEvents}
            noDataActions={
              <FilledButton
                label="Dienstleistung hinzufügen"
                onClick={() => {
                  if (studioStore?.studio?._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/services/new/info`
                    );
                  }
                }}
              />
            }
          />
        }
      ></SplitLayout>
    </MainLayout>
  );
};

export default inject("studioStore", "eventStore")(observer(BookingPage));
