import React from "react";
import { ReactComponent as GymoIcon } from "assets/icons/gymo_logo.svg";
import "./gymo.logo.component.scss";
import classNames from "classnames";

interface GymoLogoProps {
  className?: string;
  size?: number;
}

const GymoLogo = ({ className, size = 50 }: GymoLogoProps): JSX.Element => {
  const gymoLogoClass = classNames(
    {
      "gymo-icon": true,
    },
    className
  );

  return <GymoIcon className={gymoLogoClass} style={{ height: size }} />;
};

export default GymoLogo;
