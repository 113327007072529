import * as yup from "yup";
import { propertySchema } from "schemas/property.schemas/property.schema";
import { PlanExercise, planExerciseSchema } from "./plan.exercise.schema";
import { systemSchema } from "schemas/system.schema";
import { userSchema } from "schemas/user.schemas/user.schema";

export const trainingPlanSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  description: yup.string(),
  type: yup.string().required("Typ ist verpflichtend"),
  user: userSchema.notRequired(),
  exercises: yup
    .array()
    .of(yup.lazy(() => planExerciseSchema))
    .required()
    .default([]),
  coverImageUrl: yup.string().required("Bild ist verpflichtend"),
  rhythm: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  properties: yup.array().of(propertySchema([])).default(undefined),
  niveau: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .default(null),
  duration: yup
    .number()
    .integer()
    .min(1, "Dauer muss mindestens 1 sein")
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  templateID: yup.string(),
  isPublished: yup.boolean().required("Verfügbarkeit ist verpflichtend"),
  coverColor: yup.string().notRequired(),
  system: systemSchema.notRequired().default(undefined),
});

export interface TrainingPlan
  extends yup.InferType<typeof trainingPlanSchema> {}

export const exerciseSetSchema = yup.object().shape({
  type: yup.string().required(),
  set: yup.number().integer().min(1).required(),
  weight: yup
    .number()
    .typeError("Gewicht muss eine Zahl sein")
    .min(0, "Gewicht muss mindestens 0 sein")
    .required("Gewicht ist verpflichtend"),
  reps: yup
    .number()
    .integer("Wiederholung muss eine Zahl sein")
    .min(1, "Wiederholung muss mindestens 1 sein")
    .required("Wiederholung ist verpflichtend"),
  isCompleted: yup.boolean(),
});

export interface ExerciseSet extends yup.InferType<typeof exerciseSetSchema> {}

export const trainingPlanToJson = (trainingPlan: TrainingPlan): any => {
  return {
    ...trainingPlan,
    user: trainingPlan?.user?._id != null ? trainingPlan.user._id : null,
    exercises: trainingPlan.exercises
      ? trainingPlan.exercises.map((planExercise) =>
          planExerciseToJson(planExercise)
        )
      : [],
    properties: trainingPlan.properties
      ? trainingPlan.properties?.map((property) => property._id)
      : [],
  };
};

const planExerciseToJson = (planExercise: PlanExercise): any => {
  return {
    ...planExercise,
    exercise: planExercise.exercise._id,
  };
};
