import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes } from "react-router";
import ArchiveDeviceTab from "./components/archive.device.tab/archive.device.tab";
import ArchiveTrainingPlanTab from "./components/archive.training.plan.tab/archive.training.plan.tab";
import ArchiveServiceTab from "./components/archive.service.tab/archive.service.tab";
import ArchiveFeedTab from "./components/archive.feed.tab/archive.feed.tab";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

const ArchivePage = (): JSX.Element => {
  const _buildPageHeadline = (): JSX.Element => {
    return <PageHeadline showBackButton title="Archiv" />;
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <PageHeadline className="mb-15">
        <LinkTabs
          tabs={[
            <Tab
              label="Geräte"
              key="device-archive"
              path="device-archive"
              permissionAlias="ARCHIVED_DEVICES"
            />,
            <Tab
              label="Pläne"
              key="plans-archive"
              path="plans-archive"
              permissionAlias="ARCHIVED_PLANS"
            />,
            <Tab
              label="Dienstleistungen"
              key="services-archive"
              path="services-archive"
              permissionAlias="ARCHIVED_SERVICES"
            />,
            <Tab
              label="News"
              key="feed-archive"
              path="feed-archive"
              permissionAlias="ARCHIVED_FEED_ITEMS"
            />,
          ]}
        />
      </PageHeadline>
    );
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        {_buildTabs()}
        <InfoBox>
          <RunningText>
            Hier im Archiv kannst du alle gelöschten Daten einsehen und
            wiederherstellen. <br /> Bitte beachte jedoch das Elmente die du
            nicht wiederherstellst automatisch nach 1 Jahr endgültig gelöscht
            werden.
          </RunningText>
        </InfoBox>

        <Routes>
          <Route element={<Outlet />}>
            <Route path="device-archive" element={<ArchiveDeviceTab />} />
            <Route path="plans-archive" element={<ArchiveTrainingPlanTab />} />
            <Route path="services-archive" element={<ArchiveServiceTab />} />
            <Route path="feed-archive" element={<ArchiveFeedTab />} />
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default ArchivePage;
