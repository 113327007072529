/* eslint-disable @typescript-eslint/no-unused-vars */
import levenshtein from "fast-levenshtein";
import classNames from "classnames";
import Column from "components/general.compoenents/column.component/column.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Row from "components/general.compoenents/row.component/row.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { images } from "globals/data/image.library.data";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { ModalStore } from "stores/modal.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import "./template.library.modal.scss";
import TagFilterBarComponent from "components/input.components/tag.filter.bar.component/tag.filter.bar.component";

interface TemplateLibraryModalProps {
  modalStore?: ModalStore;
}

const TemplateLibraryModal = ({
  modalStore,
}: TemplateLibraryModalProps): JSX.Element => {
  const { onSave } = modalStore?.customData || {};

  const [selectedImage, setSelectedImage] = useState(null as any);
  const [urlSource, setUrlSource] = useState("square");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState(new Set());

  const getUniqueTags = (): string[] => {
    const tags = new Set();
    _getTemplateItems().forEach((item) => {
      item.tags.forEach((tag: any) => tags.add(tag));
    });

    return Array.from(tags) as string[];
  };

  const filterImages = (): string[] => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const maxLevenshteinDistance = 2;

    return _getTemplateItems().filter((item: any) => {
      // check if a search term is present
      const searchTermPresent = lowerCaseSearchTerm.trim().length > 0;

      const searchTermMatches = searchTermPresent
        ? item.tags.some((tag: string) => {
            const distance = levenshtein.get(
              tag.toLowerCase(),
              lowerCaseSearchTerm
            );
            return distance <= maxLevenshteinDistance;
          })
        : true; // if no search term is present, set searchTermMatches to true

      const tagsMatch =
        selectedTags.size === 0 ||
        Array.from(selectedTags).some((selectedTag) =>
          item.tags.includes(selectedTag)
        );

      return searchTermMatches && tagsMatch;
    });
  };

  const handleTagSelectionChange = (newSelectedTags: Set<string>): void => {
    setSelectedTags(newSelectedTags);
  };

  const _buildTemplateItem = (item: any): JSX.Element => {
    const templateImageClassName = classNames({
      "template-image-container": true,
      "template-image-container--selected": selectedImage?.id === item.id,
    });

    return (
      <div
        className={templateImageClassName}
        onClick={() => {
          setSelectedImage(item);
        }}
      >
        <img className="template-image" src={item.url[urlSource]} />
      </div>
    );
  };

  const _getTemplateItems = (): any[] => {
    // TODO For now we only use images, in the future we will also provide videos, icons, etc.
    return images;
  };

  return (
    <Column className="library-modal-wrapper">
      <ModalHeader
        title="Gymo Bibliothek"
        description="Füge Bilder und Grafiken aus der Gymo Bibliothek hinzu."
      >
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          className="mt-25"
        >
          {/* 
          // TODO for the future when we have more content
          <SizedContainer size={ContainerSizes.S}>
              <OutlinedTextInput
                className="mt-25"
                label="Suchen"
                onChange={(e) => {
                  if (e != null) {
                    setSearchTerm(e);
                  }
                }}
              />
            </SizedContainer> */}
          <TagFilterBarComponent
            tags={getUniqueTags()}
            onTagSelectionChange={handleTagSelectionChange}
          />

          <FilledButton
            label="Hinzufügen"
            disabled={!selectedImage}
            onClick={() => {
              onSave({
                id: selectedImage.id,
                url: selectedImage.url[urlSource],
              });
              modalStore?.closeModal();
            }}
          />
        </Row>
      </ModalHeader>
      <PageContainer className="">
        <Wrap justifyContent="center">
          {filterImages().map((item: any) => {
            return _buildTemplateItem(item);
          })}
        </Wrap>
      </PageContainer>
    </Column>
  );
};

export default inject("modalStore")(observer(TemplateLibraryModal));
