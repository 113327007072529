import React from "react";
import "./linear.progress.bar.component.scss";
import classNames from "classnames";

interface ProgressBarProps {
  value: number;
  color?: "primary" | "secondary";
  width?: number;
  className?: string;
}

const LinearProgressBar = ({
  value,
  color = "secondary",
  width,
  className,
}: ProgressBarProps): JSX.Element => {
  const progressBarClassName = classNames(
    {
      "progress-bar-container": true,
      "progress-bar-container--color-primary": color === "primary",
      "progress-bar-container--color-secondary": color === "secondary",
    },
    className
  );

  const progressContainerStyle = {
    width: `${width ?? 100}%`,
  };

  const progressStyle = {
    width: `${value}%`,
    backgroundColor: color,
  };

  return (
    <div className={progressBarClassName} style={progressContainerStyle}>
      <div className="progress-bar" style={progressStyle} />
    </div>
  );
};

export default LinearProgressBar;
