import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import CheckoutForm from "components/stripe.components/checkout.form.component/checkout.form.component";
import PaymentMethod from "components/stripe.components/payment.method.component/payment.method.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { wait } from "globals/helpers/await.helper";
import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ModalStore } from "stores/modal.store";
import PaymentStore from "stores/payment.store";
import UserStore from "stores/user.store";
import moment from "moment";
import "./subscription.checkout.component.scss";
import { toast } from "react-toastify";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey!);

interface SubscriptionCheckoutProps {
  price: any;
  paymentStore?: PaymentStore;
  modalStore?: ModalStore;
  userStore?: UserStore;
}

const SubscriptionCheckout = ({
  price,
  paymentStore,
  modalStore,
  userStore,
}: SubscriptionCheckoutProps): JSX.Element => {
  const setupIntentClientSecret = paymentStore?.setupIntent?.client_secret;
  const paymentMethods = paymentStore?.paymentMethods ?? [];

  const [stripeIsLoading, setStripeIsLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [createSubscriptionIsLoading, setCreateSubscriptionIsLoading] =
    useState(false);

  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState(
    "" as any
  );

  const [promotionCode, setPromotionCode] = useState(null as any);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async (): Promise<void> => {
    await paymentStore?.createSetupIntent();
    await paymentStore?.listPaymentMethods();
  };

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setSelectedPaymentMethodID(paymentMethods[0].id);
    } else {
      setSelectedPaymentMethodID("new");
    }
  }, [paymentMethods]);

  const subscribeToPlan = async (paymentMethod?: string): Promise<void> => {
    const activePaymentMethod = paymentMethod ?? selectedPaymentMethodID;

    await paymentStore!.createSubscription({
      priceID: price.priceID,
      paymentMethodID: activePaymentMethod,
      promotionCode,
    });

    // Wait until the user has the role
    let hasRole: any = false;
    do {
      await userStore?.fetchAndSetClientRoles();
      hasRole = userStore?.checkIfHasRole({
        alias: price.roleAlias,
      });
      await wait(1000);
    } while (!hasRole);

    toast.success("Abonnement wurde erfolgreich erstellt");

    setCreateSubscriptionIsLoading(false);

    modalStore?.closeModal();
  };

  const _buildPaymentDetails = (): JSX.Element => {
    if (!setupIntentClientSecret) {
      return <></>;
    }

    return (
      <ComponentWrapper title="Zahlungsmethode wählen" className="mr-15">
        <Column>
          {stripeIsLoading && paymentMethods.length === 0 && <>Lädt...</>}
          {selectedPaymentMethodID !== "new" && (
            <>
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethod
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  onClick={(paymentMethod) => {
                    setSelectedPaymentMethodID(paymentMethod.id);
                  }}
                  isSelected={paymentMethod.id === selectedPaymentMethodID}
                />
              ))}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedPaymentMethodID("new");
                }}
              >
                <RunningText className="add-payment-method">
                  Hinzufügen +
                </RunningText>
              </div>
            </>
          )}
          {selectedPaymentMethodID === "new" && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: setupIntentClientSecret,
              }}
            >
              <Column>
                <CheckoutForm
                  onSuccess={(setupIntent: any) => {
                    subscribeToPlan(setupIntent?.payment_method);
                  }}
                  submit={submit}
                  onError={() => {
                    setCreateSubscriptionIsLoading(false);
                  }}
                  onReady={() => {
                    setTimeout(() => {
                      setStripeIsLoading(false);
                    }, 300);
                  }}
                />
              </Column>
            </Elements>
          )}
        </Column>
      </ComponentWrapper>
    );
  };

  const _buildSubscriptionDetails = (): JSX.Element => {
    return (
      <SplitLayout
        className="mt-20"
        leftChild={_buildPaymentDetails()}
        rightChild={_buildSubscriptionOverview()}
      />
    );
  };

  const _buildSubscriptionOverview = (): JSX.Element => {
    // Calculate free trial period
    const freeTrialPeriod = moment.duration(price.freeTrialPeriod, "days");

    // Define the start date and period
    const startDate = moment(moment().add(freeTrialPeriod), "YYYY-MM-DD");
    const period = moment.duration(price.period, "month");

    // Calculate the end date by adding the period to the start date
    const endDate = startDate.clone().add(period).subtract(1, "day");

    // Calculate the end date of the free trial period
    const freeTrialEndDate = moment().add(freeTrialPeriod);

    // Format the dates for display
    const startDateFormatted = startDate.format("DD. MMM YYYY");
    const endDateFormatted = endDate.format("DD. MMM YYYY");
    const freeTrialEndDateFormatted = freeTrialEndDate.format("DD. MMM YYYY");

    return (
      <Column className="ml-15">
        <ComponentWrapper title="Bestellübersicht">
          <Column
            className="subscription-options"
            justifyContent="space-between"
          >
            <TitleText>Paket:</TitleText>
            <Headline className="mb-20">{price.name}</Headline>

            <TitleText>Laufzeit:</TitleText>
            <Headline>{`${price.period} Monate`}</Headline>
            <RunningText className="mb-20">
              {`Ab dem ${startDateFormatted} bis zum ${endDateFormatted}`}
            </RunningText>

            <TitleText>Verlängerungsdatem:</TitleText>
            <Headline>{endDateFormatted}</Headline>
            <RunningText className="mb-20">
              Die Kündigung ist jederzeit bis zu einem Tag vor der Verlängerung
              möglich.
            </RunningText>

            <OutlinedTextInput
              placeholder="Promotion Code"
              value={promotionCode}
              onChange={(value) => {
                setPromotionCode(value);
              }}
            />

            <Column className="sum-container mt-15">
              <Headline className="free-trial-sum mb-10">
                Heute fällig: 0€
              </Headline>
              <TitleText>{`Fällig am ${freeTrialEndDateFormatted}: ${price.periodPrice}€`}</TitleText>
            </Column>
          </Column>
        </ComponentWrapper>
        <FilledButton
          label="Kostenlose Testversion starten"
          className="mt-15 pt-20 pb-20"
          isLoading={createSubscriptionIsLoading}
          onClick={async () => {
            setCreateSubscriptionIsLoading(true);
            // Create subscription with new card
            if (selectedPaymentMethodID === "new") {
              setSubmit(false);
              setTimeout(() => {
                setSubmit(true);
              }, 100);
            } else {
              // Create subscription with existing card
              await subscribeToPlan();
            }
          }}
        />
      </Column>
    );
  };

  return _buildSubscriptionDetails();
};

export default inject(
  "paymentStore",
  "userStore",
  "modalStore"
)(observer(SubscriptionCheckout));
