import * as yup from "yup";

export const userAccountSchema = yup.object().shape({
  kind: yup.string().required(),
  verified: yup.boolean().required(),
  socialUid: yup.string().notRequired(),
  password: yup.string().notRequired(),
  verifyToken: yup.string().notRequired(),
});

export interface UserAccount extends yup.InferType<typeof userAccountSchema> {}
